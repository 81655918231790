import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import { baseInputStyle } from "../styledElements/baseInput";

const StyledTextarea = styled.textarea`
    ${baseInputStyle}
`;

const TextareaField = ({
    onChange,
    label,
    readOnly,
    rows,
    columns,
    ...rest
}) => {
    return (
        <StyledTextarea
            type="textarea"
            rows={rows}
            cols={columns}
            onChange={onChange}
            {...rest}
        />
    );
};

TextareaField.displayName = "TextareaField";

TextareaField.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    rows: PropTypes.number,
    columns: PropTypes.number,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};
TextareaField.defaultProps = {
    size: "default",
    expanded: false,
    inlineLabel: true,
    rows: 6,
    columns: 20,
    type: "text",
    readOnly: false,
    error: ""
};
export default TextareaField;
