import React, { Component } from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

const G = styled.g.attrs({
    transform: "translate(10, 10)"
})`
    transform: translate(50%, 50%);
`;

const CheckboxCheck = styled.path.attrs(({ checked }) => {
    return {
        transform: checked ? "scale(1)" : "scale(0)",
        transition: "transform 0.25s ease",
        d:
            "M-2.73,6.11-8.29.56a.86.86,0,0,1,0-1.21l1.21-1.21a.86.86,0,0,1,1.21,0l3.75,3.75,8-8a.86.86,0,0,1,1.21,0L8.29-4.9a.86.86,0,0,1,0,1.21L-1.52,6.11A.86.86,0,0,1-2.73,6.11Z"
    };
})`
    transform: scale(0);
    fill: ${props => props.theme.checkbox.color};
    transition: transform 0.25s ease;
`;

const CheckboxSquare = styled.rect.attrs({
    width: 20,
    height: 20
})`
    stroke-width: 1px;
    fill: ${props => props.theme.checkbox.background};
    stroke: ${props => props.theme.checkbox.stroke};
    rx: ${props => props.theme.checkbox.radius}px;
    ry: ${props => props.theme.checkbox.radius}px;
`;

const Checkbox = styled.svg.attrs({
    width: 20,
    height: 20,
    viewBox: "0 0 20 20"
})`
    display: inline-block;
    vertical-align: middle;
`;

const Input = styled.input`
    display: none;
    &:checked + ${Checkbox} ${CheckboxCheck} {
        transform: scale(1);
    }
`;

const Wrapper = styled.label`
    margin-bottom: 1.2rem;
    display: ${props => {
        return props.inline ? "inline-block" : "block";
    }};
    padding-right: ${props => props.theme.spacing.margin}rem;
    cursor: pointer;
`;

const Text = styled.span`
    display: inline-block;
    margin-left: 0.5rem;
    vertical-align: middle;
`;

class StyledCheckbox extends Component {
    render() {
        const {
            checked,
            text,
            onChange,
            inline,
            name,
            children,
            ...rest
        } = this.props;

        return (
            <Wrapper inline={inline}>
                <Input
                    name={name}
                    type="checkbox"
                    checked={checked}
                    onChange={onChange}
                />
                <Checkbox>
                    <CheckboxSquare />
                    <G>
                        <CheckboxCheck checked={checked} />
                    </G>
                </Checkbox>
                {text && <Text>{" " + text}</Text>}
                {children}
            </Wrapper>
        );
    }
}

StyledCheckbox.displayName = "StyledCheckbox";

StyledCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    text: PropTypes.string,
    checked: PropTypes.bool,
    inline: PropTypes.bool
};

export default StyledCheckbox;
