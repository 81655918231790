import React from "react";
import axios from "axios";
import { saveAs } from "file-saver";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LogoutWrapper } from "@cortexglobal/cortex-auth-react-router-dom";
import { Button } from "@cortexglobal/rla-components";

import Menu from "./BurgerMenu";

import MainNavLink from "./MainNavLink";

const loginUrl = "/auth/login";
class HeadOfficeNav extends React.Component {
    state = {
        menuOpen: false,
        downloading: false
    };

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange = state => {
        this.setState({ menuOpen: state.isOpen });
    };

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({ menuOpen: false });
    };
    disabledClick = e => {
        e.preventDefault();
    };

    downloadSummary = () => {
        this.setState({ downloading: true });
        axios.get(`/api/v1/manager/exit-survey/download`).then(response => {
            this.setState({ downloading: false, menuOpen: false });
            saveAs(new Blob([response.data]), "exit-survey-results.csv");
        });
    };
    render() {
        const { spacing } = this.props;
        const { downloading } = this.state;
        return (
            <Menu
                spacing={spacing}
                isOpen={this.state.menuOpen}
                onStateChange={this.handleStateChange}
            >
                <MainNavLink to="/" onClick={this.closeMenu}>
                    Home
                </MainNavLink>
                <MainNavLink to="/retailers" onClick={this.closeMenu}>
                    Retailers
                </MainNavLink>
                <MainNavLink to="/notifications" onClick={this.closeMenu}>
                    Notifications
                </MainNavLink>
                <MainNavLink to="/leagues" onClick={this.closeMenu}>
                    Leagues
                </MainNavLink>

                <Button
                    onClick={this.downloadSummary}
                    disable={downloading}
                    height={45}
                    expanded
                >
                    <FontAwesomeIcon icon="download" />{" "}
                    {downloading
                        ? "Downloading..."
                        : "Download Exit Survey Summary"}
                </Button>
                <LogoutWrapper loginUrl={loginUrl}>
                    {({ logout }) => (
                        <Button
                            href={loginUrl}
                            onClick={logout}
                            height={45}
                            expanded
                            style={{
                                fontSize: "1em",
                                fontWeight: "800",
                                textTransform: "none",
                                marginTop: "20px"
                            }}
                        >
                            <FontAwesomeIcon
                                icon="sign-out-alt"
                                transform="grow-3"
                            />{" "}
                            Logout
                        </Button>
                    )}
                </LogoutWrapper>
            </Menu>
        );
    }
}
export default HeadOfficeNav;
