import React, { Component } from "react";
import { connect } from "react-redux";
import AppContext from "../../../context/AppContext";
import RetailerSummary from "../../../components/retailer-information/RetailerSummary";
import TechnicianSummary from "../../../components/technician-summary/TechnicianSummary";
import RewardSummary from "../../../components/reward-summary/RewardSummary";
import ProgrammeEngagement from "../../../components/programme-engagement/ProgrammeEngagement";
import ExitSurvey from "../../../components/exit-survey/ExitSurvey";

import { TARGET_TECH_RAMP_RATIO } from "../../../config/app";

class Dashboard extends Component {
    generateRetailerSummary = (retailers) => {
        const retailerSummaryTotals = retailers.data.reduce(
            (totals, retailer) => {
                return {
                    ...totals,
                    current_ramps:
                        totals.current_ramps + retailer.current_ramps,
                    current_technicians:
                        totals.current_technicians +
                        retailer.current_technicians,
                    current_apprentices:
                        totals.current_apprentices +
                        retailer.current_apprentices,
                    current_service_advisors:
                        totals.current_service_advisors +
                        retailer.current_service_advisors,
                    current_sales_executives:
                        totals.current_sales_executives +
                        retailer.current_sales_executives,
                    active_technicians:
                        totals.active_technicians + retailer.active_technicians,
                    active_apprentices:
                        totals.active_apprentices + retailer.active_apprentices,
                    active_service_advisors:
                        totals.active_service_advisors +
                        retailer.active_service_advisors,
                    technician_ramp_ratio:
                        totals.technician_ramp_ratio +
                        retailer.technician_ramp_ratio,
                    technicians_required:
                        totals.technicians_required +
                        retailer.technicians_required,
                    attrition_rate:
                        totals.attrition_rate + retailer.attrition_rate,
                    new_starters: totals.new_starters + retailer.new_starters,
                    leavers: totals.leavers + retailer.leavers,
                    technician_recruitment:
                        totals.technician_recruitment +
                        retailer.technician_recruitment,
                    apprentice_recruitment:
                        totals.apprentice_recruitment +
                        retailer.apprentice_recruitment,
                    service_advisor_recruitment:
                        totals.service_advisor_recruitment +
                        retailer.service_advisor_recruitment,
                    sales_executive_recruitment:
                        totals.sales_executive_recruitment +
                        retailer.sales_executive_recruitment,
                };
            },
            {
                current_ramps: 0,
                current_technicians: 0,
                current_apprentices: 0,
                current_service_advisors: 0,
                current_sales_executives: 0,
                active_technicians: 0,
                active_apprentices: 0,
                active_service_advisors: 0,
                active_sales_executives: 0,
                technician_ramp_ratio: 0,
                target_ramp_ratio: TARGET_TECH_RAMP_RATIO,
                technicians_required: 0,
                attrition_rate: 0,
                new_starters: 0,
                leavers: 0,
                technician_recruitment: 0,
                apprentice_recruitment: 0,
                service_advisor_recruitment: 0,
                sales_executive_recruitment: 0,
            }
        );

        //Calculate the averages
        return {
            ...retailerSummaryTotals,
            technician_ramp_ratio:
                retailerSummaryTotals.technician_ramp_ratio /
                retailers.data.length,
            attrition_rate:
                retailerSummaryTotals.attrition_rate / retailers.data.length,
            // programme_engagement:
            //     ((retailerSummaryTotals.active_technicians +
            //         retailerSummaryTotals.active_apprentices) /
            //         (retailerSummaryTotals.current_technicians +
            //             retailerSummaryTotals.current_apprentices)) *
            //     100,
            technician_engagement:
                (retailerSummaryTotals.active_technicians /
                    retailerSummaryTotals.current_technicians) *
                100,
            apprentice_engagement:
                (retailerSummaryTotals.active_apprentices /
                    retailerSummaryTotals.current_apprentices) *
                100,
            service_advisor_engagement:
                (retailerSummaryTotals.active_service_advisors /
                    retailerSummaryTotals.current_service_advisors) *
                100,
            sales_executive_engagement:
                (retailerSummaryTotals.active_sales_executives /
                    retailerSummaryTotals.current_sales_executives) *
                100,
        };
    };
    getLatestMonthlySummary = (monthlyRetailersSummary) => {
        if (!monthlyRetailersSummary.loaded) {
            return;
        }
        return monthlyRetailersSummary.data.reduce((latest, month) => {
            if (latest && latest.month > month.month) {
                return latest;
            }
            return month;
        }, {});
    };
    render() {
        return (
            <AppContext.Consumer>
                {({
                    user,
                    group,
                    retailers,
                    apprentices,
                    serviceAdvisors,
                    salesExecutives,
                    monthlyRetailersSummary,
                    technicians,
                }) => {
                    //Summarise the data
                    //console.log(monthlyRetailersSummary);
                    const retailerSummary =
                        this.generateRetailerSummary(retailers);
                    const latestMonthlySummary = this.getLatestMonthlySummary(
                        monthlyRetailersSummary
                    );
                    return (
                        <React.Fragment>
                            <RetailerSummary
                                retailerSummary={retailerSummary}
                                technicians={technicians.data}
                                apprentices={apprentices.data}
                                serviceAdvisors={serviceAdvisors.data}
                                salesExecutives={salesExecutives.data}
                                loaded={
                                    apprentices.loaded &&
                                    technicians.loaded &&
                                    serviceAdvisors.loaded &&
                                    salesExecutives.loaded &&
                                    retailers.loaded
                                }
                            />
                            <RewardSummary
                                technicians={technicians.data}
                                loaded={technicians.loaded}
                            />
                            <ProgrammeEngagement />
                            <ExitSurvey />
                        </React.Fragment>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};

export default connect(mapStateToProps)(Dashboard);
