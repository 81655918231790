import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Wrapper, Link, Icon } from "./navComponents";

import AppContext from "../../context/AppContext";
import moment from "moment";

const now = moment();
const videoCompetitionClose = moment("2020-04-01", "YYYY-MM-DD");

class HeadOfficeQuickLinks extends React.Component {
    render() {
        return (
            <AppContext.Consumer>
                {({ staff }) => {
                    return (
                        <Wrapper>
                            <Link exact to="/">
                                <Icon className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="home" />
                                </Icon>{" "}
                                Overview
                            </Link>
                            <Link exact to="/retailers">
                                <Icon className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="users" />
                                </Icon>{" "}
                                Retailers
                            </Link>
                            <Link exact to="/notifications">
                                <Icon className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="envelope" />
                                </Icon>{" "}
                                Notifications
                            </Link>
                            <Link to="/leagues">
                                <Icon className="fa-layers fa-fw">
                                    <FontAwesomeIcon icon="trophy" />
                                </Icon>{" "}
                                Leagues
                            </Link>
                            {now.isBefore(videoCompetitionClose) && (
                                <Link to="/vehicle-health-check-competition">
                                    <Icon className="fa-layers fa-fw">
                                        <FontAwesomeIcon icon="video" />
                                    </Icon>{" "}
                                    VHC Videos
                                </Link>
                            )}
                        </Wrapper>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

export default HeadOfficeQuickLinks;
