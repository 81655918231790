import React from "react";
import { initialState } from "@cortexglobal/cortex-auth-react-router-dom";
import { initialState as staffInitialState } from "../modules/manager/staff/staffReducer";
const AppContext = React.createContext({
    user: initialState.user,
    group: initialState.group,
    staff: staffInitialState.allStaff,
    loading: staffInitialState.loading,
    updating: staffInitialState.updating
});

export default AppContext;
