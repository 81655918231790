import React, { Component } from "react";
import { Row, Column, Button } from "@cortexglobal/rla-components";
import {
    BackgroundImage,
    VideoWinners,
    CenteredButton
} from "./videoCompetitionComponents";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import StyledVideo from "./StyledVideo";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Loading } from "@cortexglobal/rla-components";
import { loadVideoWallWinners } from "./videoCompetitionActions";
import {
    videoWinnersGroupedSelector,
    videoShowModalSelector
} from "./videoCompetitionSelectors";
import { WinnersModal } from "./StyledModal";

const HeroOverlayTitle = styled.h1`
    position: absolute;
    text-align: left;
    top: 24%;
    color: white;
    margin-left: 30px;

    @media only screen and (max-width: 400px) {
        top: 30%;
        font-size: 1.7em;
        margin-left: 20px;
    }

    @media only screen and (max-width: 320px) {
        top: 40%;
        font-size: 1.6em;
        margin-left: 20px;
    }
`;

const HeroManagerOverlayTitle = styled.h1`
    position: absolute;
    text-align: left;
    top: 33%;
    color: white;
    margin-left: 30px;

    @media only screen and (max-width: 400px) {
        top: 30%;
        font-size: 1.7em;
        margin-left: 20px;
    }

    @media only screen and (max-width: 320px) {
        top: 40%;
        font-size: 1.6em;
        margin-left: 20px;
    }
`;



class VideoCompetitionWinners extends Component {
    componentDidMount() {
        if (!this.props.loaded) {
            this.props.loadVideoWallWinners();
        }
    }

    render() {
        const {
            videoWinners,
            showModal,
            loadVideoWall,
            loading,
            hideSummary
        } = this.props;

        return (
            <div
                className="page"
                style={{ overflow: "hidden", marginBottom: "8em", width: "1400px" }}
            >
                {showModal ? <WinnersModal>modal</WinnersModal> : null}
                {hideSummary && (
                    <Row
                        style={{
                            padding: "0",
                            textAlign: "center",
                            width: "1400px"
                        }}
                    >
                        <BackgroundImage style={{ width: "100%", position: "relative" }}>
                            <HeroManagerOverlayTitle style={{top: "50%"}}>
                                Vehicle Health Check Video
                            </HeroManagerOverlayTitle>
                        </BackgroundImage>
                    </Row>
                )}
                {hideSummary ? (
                    <React.Fragment>
                        <Row>
                            <Column medium={12} large={8} xlarge={8}>
                                <PageTitle title="The winners have been announced" />
                                <p
                                    style={{
                                       
                                        marginBottom: "1em",
                                        color: "#888889",
                                        fontSize: ".9rem"
                                    }}
                                >
                                    The competition is over, check out the
                                    winning video.
                                </p>
                            </Column>
                            <Column
                                style={{ textAlign: "center" }}
                                medium={12}
                                large={4}
                                xlarge={4}
                            >
                                <Link
                                    style={{ textDecoration: "none" }}
                                    to="/vehicle-health-check-competition"
                                >
                                    <CenteredButton
                                        style={{ margin: "1em 0 2em 0" }}
                                    >
                                        Back to all videos
                                    </CenteredButton>
                                </Link>
                            </Column>
                        </Row>
                        <Row>
                            <Column medium={12} large={6} xlarge={6}>
                                <p>
                                The winners from each area will soon be contacted by BMW Group UK/Ireland to arrange their prizes – 
                                pair of BTCC 2020 tickets. 
                                The area runners up will each get their Elite accounts topped up with £50.
                                </p>
                            </Column>
                        </Row>
                    </React.Fragment>
                ) : (
                   
                    <Row style={{ width: "100%", padding: "0", margin: "0" }}>
                          <BackgroundImage style={{ width: "100%", position: "relative" }}>
                            <HeroOverlayTitle style={{top: "50%"}}>
                                Vehicle Health Check Video
                            </HeroOverlayTitle>
                        </BackgroundImage>
                        <Column medium={12} large={6} xlarge={4}>
                            <PageTitle
                                title="The winners have been announced"
                                
                            />
                             <p
                                    style={{   
                                        marginBottom: "1em",
                                        color: "#888889",
                                        fontSize: ".9rem"
                                    }}
                                >
                                    The competition is over, check out the
                                    winning video.
                                </p>
                               
                           
                                <p>
                                The winners from each area will soon be contacted by BMW Group UK/Ireland to arrange their prizes – 
                                pair of BTCC 2020 tickets.
                                The area runners up will each get their Elite accounts topped up with £50.
                                </p>
                     
                        </Column>
                    </Row>
                    
                )}
                <VideoWinners videoWinners={videoWinners} loading={loading} />
               
                <Row>
                    <Column small={12} medium={4} large={4}>
                        <Link
                            to="/vehicle-health-check-competition"
                            style={{ textDecoration: "none" }}
                        >
                            <CenteredButton style={{ margin: "2em 0 0 0" }}>
                                Back to all videos
                            </CenteredButton>
                        </Link>
                    </Column>
                </Row>
                
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { loaded, loading } = state.videoCompetition.winners;
    const {
        modal: { show: showModal }
    } = state.videoCompetition;
    const videoWinners = videoWinnersGroupedSelector(state);

    return { loading, loaded, videoWinners, showModal };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadVideoWallWinners
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(VideoCompetitionWinners);
