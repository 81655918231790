import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";
import { Row, Column } from "@cortexglobal/rla-components";
import styled from "styled-components";
import CookieModal from "./CookieModal";
import PrivacyModal from "./PrivacyModal";
import TermsModal from "./TermsModal";

import { breakpoints, app } from "@cortexglobal/bmw-shared-components";

const Footer = styled.footer`
    padding: 1.6em 0;
    background-color: #000;
    color: white;
    width: 100%;
    height: auto;
    text-align: left;
    margin-bottom: ${props =>
        props.aboveQuickLinks ? "60px" : "0"} !important;
    p {
        padding: 0px;
        margin: 0px;
        font-weight: 800;
    }

    ul {
        margin: 0px;
        padding: 0px;
        margin-top: 10px;
        list-style: none;
        li {
            display: inline-block;
            padding: 0.4em;
            border-right: 1px solid white;
            &:last-of-type {
                border: none;
            }
        }
    }

    @media (min-width: ${breakpoints.large}px) {
        position: fixed;
        bottom: 0;
        padding-left: ${app.quickLinkWidth + 20}px;
        margin-bottom: 0 !important;

        height: 60px;
        margin-top: 60px;
        margin-bottom: 0 !important;
        p {
            font-weight: normal;
        }
        ul {
            margin-top: 0px;
            text-align: right;
            li {
                padding: 0 1.2em;
                display: inline-block;
                border-right: 1px solid white;
            }
        }
    }
`;

const FooterNavLink = styled(NavLink)`
    color: ${props => props.theme.colors.white};
`;

const FooterButton = styled.button`
    border: none;
    background: none;
    color: ${props => props.theme.colors.white};
    font-size: 14px;
    font-weight: bold;
    font-family: bmw_groupregular;
    text-decoration: underline;
    cursor: pointer;
`;

const PrivateFooter = props => {
    const { aboveQuickLinks } = props;
    const thisYear = new Date().getFullYear();

    const [cookieModalVisible, setCookieModalVisible] = useState(false);
    const [privacyModalVisible, setPrivacyModalVisible] = useState(false);
    const [termsModalVisible, setTermsModalVisible] = useState(false);

    const showCookieModal = () => setCookieModalVisible(true);
    const closeCookieModal = () => setCookieModalVisible(false);

    const showPrivacyModal = () => setPrivacyModalVisible(true);
    const closePrivacyModal = () => setPrivacyModalVisible(false);

    const showTermsModal = () => setTermsModalVisible(true);
    const closeTermsModal = () => setTermsModalVisible(false);

    return (
        <Footer aboveQuickLinks={aboveQuickLinks}>
            <PrivacyModal
                visible={privacyModalVisible}
                closeModal={closePrivacyModal}
            />
            <CookieModal
                visible={cookieModalVisible}
                closeModal={closeCookieModal}
            />
            <TermsModal
                visible={termsModalVisible}
                closeModal={closeTermsModal}
            />
            <Row collapse>
                <Column large={3}>
                    <p>&copy; BMW {thisYear}</p>
                </Column>
                <Column large={9}>
                    <ul>
                        <li>
                            <FooterButton onClick={showTermsModal}>
                                Terms &amp; Conditions
                            </FooterButton>
                        </li>
                        <li>
                            <FooterButton onClick={showPrivacyModal}>
                                Privacy
                            </FooterButton>
                        </li>
                        <li>
                            <FooterButton onClick={showCookieModal}>
                                Cookies
                            </FooterButton>
                        </li>
                    </ul>
                </Column>
            </Row>
        </Footer>
    );
};

PrivateFooter.propTypes = {
    aboveQuickLinks: PropTypes.bool
};

PrivateFooter.defaultProps = {
    aboveQuickLinks: true
};

export default PrivateFooter;
