import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Column } from "@cortexglobal/rla-components";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

const ContactList = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px;
    padding-top: 1em;

    li {
        height: auto;
        clear: both;
        svg {
            font-size: 1.6em;
            display: inline-block;
            color: #1c69d4;
        }
        h2 {
            font-size: 1.2rem;
            font-weight: 800;
        }
        h2,
        p {
            display: block;
            margin: 0px !important;
        }
        p {
            color: #9f9f9f;
            display: block;
            font-size: 0.9em;
        }
        a {
            display: block;
            margin-top: 10px;
            margin-bottom: 30px;
            color: #1c69d4;
            font-size: 1.2rem;
        }
    }
`;

const IconColumn = styled(Column)`
    max-width: 40px;
`;

// const contactItems = [
//     {
//         icon: "phone",
//         title: "Call the helpline",
//         body: "Available Mon-Fri from 9:00am to 5:30pm",
//         type: "phone",
//         href: "tel:01202567890",
//         hrefText: "01202 567 890"
//     },
//     {
//         icon: "envelope",
//         title: "Pop us an email",
//         body: "A member of the team will reply",
//         type: "email",
//         href: "mailto:help@bmwrewards.co.uk",
//         hrefText: "help@bmwrewards.co.uk"
//     }
// ];

class Contact extends Component {
    render() {
        return (
            <div className="page page-contact">
                <Row>
                    <Column medium={12}>
                        <PageTitle
                            title="Contact us"
                            subtitle="Get hold of the BMW Technician Rewards Programme
                            team"
                        />
                    </Column>
                    <Column medium={12}>
                        <ContactList>
                            <li>
                                <IconColumn
                                    small={2}
                                    medium={1}
                                    style={{ maxWidth: "40px" }}
                                >
                                    <FontAwesomeIcon
                                        icon="phone"
                                        transform="grow-3 flip-h"
                                    />
                                </IconColumn>
                                <Column
                                    small={10}
                                    medium={11}
                                    style={{ paddingLeft: "1.5rem" }}
                                >
                                    <h2>Call the helpline</h2>
                                    <p>
                                        Available Mon-Fri from 9:00am to 5:30pm
                                    </p>
                                    <a href="tel:01202017030">01202 017030</a>
                                </Column>
                            </li>
                            <li>
                                <IconColumn small={2} medium={1}>
                                    <FontAwesomeIcon
                                        icon="envelope"
                                        transform="grow-3"
                                    />
                                </IconColumn>
                                <Column
                                    small={10}
                                    medium={11}
                                    style={{ paddingLeft: "1.5rem" }}
                                >
                                    <h2>Pop us an email</h2>

                                    <p>A member of the team will reply</p>
                                    <a href="mailto:info@bmwgroupelite.co.uk">
                                        info@bmwgroupelite.co.uk
                                    </a>
                                </Column>
                            </li>
                        </ContactList>
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state.auth;

    return { user };
};

export default connect(mapStateToProps)(Contact);
