import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { yupFormattedDate } from "@cortexglobal/cortex-utilities";
import {
    SubmitButton,
    FormikSelect,
    FormikDatePicker,
    Row,
    Column
} from "@cortexglobal/rla-components";

const makeExitSurveySchema = () =>
    Yup.object().shape({
        user_uuid: Yup.string().required("Please select a technician"),
        leaving_date: yupFormattedDate().required(
            "Please select a leaving date"
        )
    });

class ExitSurveyForm extends Component {
    render() {
        const { staff, invites, submitting, onSubmit } = this.props;

        const staffWithoutInvites = staff.filter(member => {
            return (
                invites.findIndex(invite => {
                    const inviteUserUuid = invite.user
                        ? invite.user.uuid
                        : false;
                    if (!inviteUserUuid) {
                        return false;
                    }

                    return member.uuid === inviteUserUuid;
                }) === -1
            );
        });
        return (
            <div className="form-container">
                <React.Fragment>
                    <Formik
                        initialValues={{ user_uuid: "", leaving_date: "" }}
                        onSubmit={onSubmit}
                        validationSchema={makeExitSurveySchema()}
                        className="form-group"
                    >
                        {({ values, errors }) => {
                            return (
                                <Form className="form-group" autoComplete="off">
                                    <Row>
                                        <Column medium={8}>
                                            <FormikSelect
                                                name="user_uuid"
                                                label="Technician name"
                                                value={values.user_uuid}
                                                options={staffWithoutInvites.reduce(
                                                    (staffOptions, member) => {
                                                        return [
                                                            ...staffOptions,
                                                            {
                                                                value:
                                                                    member.uuid,
                                                                text: `${member.first_name} ${member.last_name}`
                                                            }
                                                        ];
                                                    },
                                                    []
                                                )}
                                            />
                                        </Column>
                                        <Column medium={4}>
                                            <FormikDatePicker
                                                name="leaving_date"
                                                label="Leaving Date"
                                                value={values.leaving_date}
                                            />
                                        </Column>
                                        <Column large={6}>
                                            <SubmitButton
                                                expanded
                                                submitting={submitting}
                                                disabled={submitting}
                                            >
                                                Submit
                                            </SubmitButton>
                                        </Column>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </React.Fragment>
            </div>
        );
    }
}

export default ExitSurveyForm;
