import axios from "axios";

export const SENT_NOTIFICATIONS_LOADING = "SENT_NOTIFICATIONS_LOADING";
export const SENT_NOTIFICATIONS_LOADED = "SENT_NOTIFICATIONS_LOADED";
export const SENT_NOTIFICATIONS_ERROR = "SENT_NOTIFICATIONS_ERROR";

export const getNotifications = () => {
    return dispatch => {
        dispatch({
            type: SENT_NOTIFICATIONS_LOADING
        });

        return axios
            .get("/api/v1/notifications/sent")
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: SENT_NOTIFICATIONS_LOADED,
                    data
                });
            })
            .catch(e => {
                dispatch({
                    type: SENT_NOTIFICATIONS_ERROR,
                    data: e.message
                });
            });
    };
};
