function validateVideo(item) {
    // if (
    // 	!('description' in item)
    // 	|| item.description === undefined
    // 	|| item.description === null
    // ) { return false; }
    // if (
    // 	!('date' in item)
    // 	|| item.date === undefined
    // 	|| item.date === null
    // ) { return false; }

    if (
        !("videoUrl" in item) ||
        item.videoUrl === undefined ||
        item.videoUrl === null
    ) {
        return false;
    }

    if (
        !("imageUrl" in item) ||
        item.imageUrl === undefined ||
        item.imageUrl === null
    ) {
        return false;
    }

    // if (
    // 	!('retailer' in item)
    // 	|| item.retailer === undefined
    // 	|| item.retailer === null
    // ) { return false; }

    return true;
}

export function validateVideos(videos) {
    if (!Array.isArray(videos)) {
        return false;
    }

    // should be deny, allow not as present: deny, allow!
    // should also be some
    const hasProps = videos.reduce(
        (accum, item, index) => (validateVideo(item) ? accum : false),
        true
    );

    if (hasProps === false) {
        return false;
    }

    return true;
}

export function filterValidVideos(videos) {
    if (!Array.isArray(videos)) {
        return [];
    }

    return videos.filter(video => validateVideo(video));
}

export function validateVideoWinners(areas) {
    if (!Array.isArray(areas)) {
        return false;
    }

    const hasProps = areas.reduce(
        (accum, item, index) =>
            validateVideo(item.winner) && validateVideo(item.runnerUp)
                ? accum
                : false,
        true
    );

    if (hasProps === false) {
        return false;
    }

    return true;
}

function isInt(number) {
    return number % 1 === 0;
}

export function groupEvery(array, groupEvery) {
    if (!Array.isArray(array)) {
        return [];
    }

    // Ensure groupEvery is a number
    if (groupEvery.constructor !== Number) {
        groupEvery = 1;
    }

    return array.reduce((accum, item, index) => {
        // Make a new sub array
        if (isInt(index / groupEvery) || index === 0) {
            accum.push([]);
        }

        // Add item to existing sub array
        accum[accum.length - 1].push(item);

        return accum;
    }, []);
}

export function isObject(object) {
    if (object === undefined || object === null) {
        return false;
    }

    return object.constructor === Object ? true : false;
}

export function flattenVideoWinners(videos) {
    if (!Array.isArray(videos)) {
        return [];
    }

    return videos.reduce((accum, item, index) => {
        if (isObject(item.winner) && Array.isArray(item.runnersUp)) {
            return accum.concat([item.winner, ...item.runnersUp]);
        } else {
            return accum;
        }
    }, []);
}

const arrayColumn = (array, column) => array.map(item => item[column]);

export const getById = (array, id) => {
    if (!Array.isArray(array) || id.constructor !== Number) {
        return false;
    }

    return array.reduce((accum, item, index) => {
        return (accum = item.id === id ? item : accum);
    }, false);
};
