import React, { Component } from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import moment from "moment";
import DatePickerInputField from "./DatePickerInputField";
import CalendarContainer from "./CalendarContainer";

class StyledDatePicker extends Component {
    render = () => {
        // /console.log(this.props);
        return (
            <ReactDatePicker
                {...this.props}
                customInput={<DatePickerInputField />}
                dateFormat={this.props.dateFormat}
                selected={
                    typeof this.props.selected === "string"
                        ? moment(this.props.selected).toDate()
                        : this.props.selected
                }
                onSelect={value => {
                    this.props.onChange(value);
                }}
                className={`form-control ${this.props.error ? "alert" : ""}`}
                popperContainer={CalendarContainer}
                popperClassName="showInFront"
                autoComplete="off"
            />
        );
    };
}

StyledDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.any.isRequired,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    initialValue: PropTypes.any,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    input: PropTypes.object,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    emptyOption: PropTypes.string,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    dateFormat: PropTypes.string
};
StyledDatePicker.defaultProps = {
    error: "",
    expanded: true,
    dateFormat: "DD/MM/YYYY",
    selected: moment()
};
export default StyledDatePicker;
