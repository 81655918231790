import { getNavigatorLanguage } from "@cortexglobal/cortex-utilities";
import {
    SET_LOGGED_IN_STATE,
    SET_REDIRECT_STATE,
    SET_AUTH_USER_DETAILS,
    SET_AUTH_USER_GROUP,
    SET_AUTH_USER_GROUPS,
    SET_AUTH_USER_LOADING
} from "./authActions";

export const initialState = {
    loggedIn: false,
    loggedInChecked: false,
    redirectToReferer: false,
    user: {
        loaded: false,
        loading: false,
        language: getNavigatorLanguage(),
        group: {},
        role: {},
        permissions: [],
        additional_props: {}
    },
    group: {
        loaded: false
    },
    groups: []
};

export default function auth(state = initialState, action) {
    switch (action.type) {
        case SET_LOGGED_IN_STATE:
            return {
                ...state,
                loggedIn: action.loggedIn,
                loggedInChecked: true
            };
        case SET_REDIRECT_STATE:
            return {
                ...state,
                redirectToReferer: action.redirect
            };
        case SET_AUTH_USER_DETAILS:
            return {
                ...state,
                user: { ...action.user, loaded: true, loading: false }
            };
        case SET_AUTH_USER_LOADING:
            return {
                ...state,
                user: { ...state.user, loading: true }
            };

        case SET_AUTH_USER_GROUP:
            return {
                ...state,
                group: { ...action.group, loaded: true }
            };

        case SET_AUTH_USER_GROUPS:
            return {
                ...state,
                groups: action.groups
            };

        default:
            return state;
    }
}
