import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Hero, Wrapper } from "./sharedComponents";

class Terms extends Component {
    render() {
        return (
            <div className="page page-home">
                <Hero />
                <Wrapper>
                    <Column medium={12}>
                        <PageTitle
                            title="BMW & MINI Elite"
                            subtitle="Technician Loyalty Programme T&amp;Cs"
                        />

                        <ol>
                            <li>
                                The BMW & MINI Technician Rewards Programme
                                referred to in the below T&Cs as the ‘Programme’
                                is only applicable to the BMW Network of
                                authorised Technicians and Maintenance
                                Technicians (‘Technicians’)
                            </li>

                            <li>
                                This Programme is meant for all current
                                Technicians, and for all Technicians, recently
                                joined (or about to join) the BMW network.
                            </li>

                            <li>
                                In order to be eligible for the Programme,
                                Technicians must have passed all compulsory BMW
                                training and must be correctly signed up on TMSi
                                and assigned an eligible Market Function Code.
                            </li>

                            <li>
                                All Technicians who have been employed by BMW
                                prior to 01/04/2019 are to be considered
                                ‘Existing Technicians’. Those employed on or
                                after the date above, are to be considered ‘New
                                Technicians’.
                            </li>
                            <li>
                                Existing Technicians will be rewarded with
                                &pound;1,000 worth of Amazon e-vouchers every
                                December on the date agreed by BMW (UK) Limited.
                            </li>

                            <li>
                                New Technicians will be rewarded with &pound;500
                                worth of Amazon e-vouchers following the
                                successful completion of their 3-month probation
                                and a further &pound;500 worth of Amazon
                                e-vouchers on completing 12-months service.
                                After completion of 12 months service, new
                                Technicians will be rewarded &pound;1,000 worth
                                of Amazon e-vouchers every year on completing
                                another 12-months service. BMW Retailers have
                                the right to extend this probation period where
                                necessary and only reward the funds following
                                passing of this extended probation period.
                            </li>
                            <li>
                                In order to be eligible for the above rewards
                                (points 5 & 6), Technicians must be enrolled to
                                the Programme prior to the date of their first
                                reward due date. This includes the date set in
                                December by BMW (UK) Limited & the anniversary
                                of start date for new starters.
                            </li>
                            <li>
                                The rewards for this Technician Loyalty
                                Programme consist of vouchers or any other
                                non-cash prize and no rewards in cash will be
                                given out for the duration of the programme
                                until further officially communicated notice
                                from BMW (UK) Limited.
                            </li>
                            <li>
                                By entering the initiative, all entrants are
                                deemed to have accepted and agreed to be bound
                                by the terms and conditions outlined in this
                                document and any additional terms and conditions
                                detailed in the relevant parts of this website
                                or programme communications, describing the
                                programme, of which the entry instructions form
                                part. Any infringement of the terms below is a
                                violation of the BMW & MINI Technician Rewards
                                Programme and may result in Rewards being
                                forfeited.
                            </li>
                            <li>
                                At the time of receiving any rewards vouchers,
                                the Technician must still be fully employed by
                                the BMW Retailer.
                            </li>
                            <li>
                                If a Technician’s contract is terminated or
                                served notice of termination, they will no
                                longer be part of the Technician Rewards
                                Programme.
                            </li>
                            <li>
                                If a Technician serves notice of resignation BMW
                                (UK) Limited reserves the right to disqualify a
                                Technician from the Technician Rewards
                                Programme.
                            </li>
                            <li>
                                BMW (UK) Limited reserves the right to
                                disqualify a Technician from the Programme, the
                                Programme’s portal and any additional leagues,
                                challenges, games, trips and spot prizes.
                            </li>
                            <li>
                                Families of technicians, or other Retailer
                                employees, or other people under the age of 18
                                are ineligible to participate in the
                                initiative(s). Entrants must reside in the UK
                                and be employed by the official BMW & MINI
                                Retailers.
                            </li>
                            <li>
                                All participants must have a valid login and
                                profile on the portal of the Technician Rewards
                                Programme in order to qualify for their bonus.
                            </li>
                            <li>
                                All Technicians, enrolled on the programme are
                                representing their respective BMW & MINI
                                Retailer and the BMW & MINI Brand, and as such
                                must conduct themselves in a professional manner
                                at all times.
                            </li>
                            <li>
                                Any potential winners of rewards, trips or
                                experiences must be 18 years of age or over.
                            </li>
                            <li>
                                By entering the programme and related
                                initiatives, all entrants agree to participate
                                in any publicity, arising from the award of a
                                prize or reward.
                            </li>
                            <li>
                                No correspondence can be entered into concerning
                                the rewards/ initiative(s).
                            </li>
                            <li>
                                No alternative rewards apart from the assigned
                                rewards vouchers will be offered. BMW (UK)
                                Limited reserves the right to withdraw any
                                reward from a Technician, who has infringed the
                                T&Cs of this programme.
                            </li>
                            <li>
                                BMW (UK) Limited reserves the right to alter,
                                amend or withdraw the programme in whole or in
                                part without notice or compensation.
                            </li>
                            <li>
                                In the event of any dispute, the decision of BMW
                                (UK) Limited will be final in all matters and no
                                correspondence will be entered into in this
                                regard.
                            </li>
                            <li>
                                Any reward received under this programme may be
                                subject to tax. BMW (UK) Limited will meet the
                                basic rate income tax liability in respect of
                                all awards made to participants in this
                                programme. BMW (UK) Limited will pay directly to
                                HMRC the total due in respect of basic rate
                                income tax on behalf of participants. Higher
                                rate tax payers are responsible for additional
                                income tax liabilities arising. BMW (UK) Limited
                                will provide upon request any information
                                required.
                            </li>
                            <li>
                                For certain events such as end-of-year events,
                                BMW (UK) Limited will also meet any higher rate
                                income tax liability (currently set at 40%) in
                                respect of awards made (where appropriate). BMW
                                (UK) Limited will pay directly to HMRC the total
                                due in respect of basic rate income tax or
                                higher rate income tax (as appropriate) on
                                behalf of participants.
                            </li>
                        </ol>
                    </Column>
                </Wrapper>
            </div>
        );
    }
}

export default Terms;
