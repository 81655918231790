import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import Icon from "./icon";
// import {
//     ZoomIn,
//     ZoomOut,
//     FadeIn,
//     FadeOut
// } from "animate-css-styled-components";

import { isBrowser } from "../functions";
import CloseButton from "./closeButton";

// Set to animate in / out based on visibility
// let animation = {
//     fade: FadeIn,
//     zoom: ZoomIn
// };

const ModalWrapper = styled.div`
    display: auto;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    position: fixed;
    padding: 0 ${(props) => props.theme.modal.padding}rem;
    background: rgba(0, 0, 0, 0.6);
    z-index: 999;
`;
const ModalInner = styled.div`
    max-width: ${(props) => props.maxWidth};
    margin: 6em auto;
    position: relative;
    border-radius: ${(props) => props.theme.modal.radius}em;
    background: ${(props) => props.theme.modal.background};
    padding: ${(props) => {
        return props.padding !== null
            ? props.padding
            : props.theme.modal.padding;
    }}rem;
    color: ${(props) => props.theme.modal.color};
`;

/** Modal  */
class Modal extends React.Component {
    constructor(props) {
        super(props);
        if (isBrowser()) {
            this.el = document.createElement("div");
        }
    }

    componentWillMount() {
        // init portal element
        if (isBrowser()) {
            try {
                document.getElementsByTagName("BODY")[0].appendChild(this.el);
            } catch (err) {}
        }
    }

    componentWillUnmount() {
        // destroy portal element
        if (isBrowser()) {
            try {
                document.getElementsByTagName("BODY")[0].removeChild(this.el);
            } catch (err) {}
        }
    }

    componentDidUpdate() {
        //bodyElement.style.overflow = this.props.visible ? "hidden" : "visible";
    }

    onClose = () => {
        this.setState({ visible: false });
        this.props.onClose();
    };

    renderModal = () => {
        const { maxWidth, children, padding, showCloseButton } = this.props;
        return (
            <ModalWrapper duration="0.25s" onClick={this.onClose}>
                <ModalInner
                    duration="0.25s"
                    onClick={(e) => e.stopPropagation()}
                    maxWidth={maxWidth}
                    padding={padding}
                >
                    {showCloseButton && <CloseButton onClick={this.onClose} />}
                    {this.props.children}
                </ModalInner>
            </ModalWrapper>
        );
    };

    render() {
        var { theme, visible } = this.props;

        if (visible && isBrowser()) {
            return ReactDOM.createPortal(this.renderModal(), this.el);
        } else {
            return null;
        }
    }
}
Modal = withTheme(Modal);

Modal.displayName = "Modal";

Modal.propTypes = {
    /** Boolean indicating whether the modal should show */
    visible: PropTypes.bool.isRequired,
    /** The function to call when the modal is closed */
    onClose: PropTypes.func.isRequired,
    /** Whether to show the close button */
    showCloseButton: PropTypes.bool,
    maxWidth: PropTypes.string,
};

Modal.defaultProps = {
    visible: false,
    showCloseButton: true,
    maxWidth: "640px",
    padding: null,
};

export default Modal;
