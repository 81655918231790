import {
    SENT_NOTIFICATIONS_LOADING,
    SENT_NOTIFICATIONS_LOADED,
    SENT_NOTIFICATIONS_ERROR
} from "./notificationsActions";

const initialState = {
    sent: {
        loaded: false,
        loading: false,
        error: false,
        errorMessage: "",
        data: []
    }
};

export default function notifications(state = initialState, action) {
    switch (action.type) {
        case SENT_NOTIFICATIONS_LOADING:
            return {
                ...state,
                sent: {
                    ...state.sent,
                    loading: true,
                    loaded: false,
                    error: false
                }
            };

        case SENT_NOTIFICATIONS_LOADED:
            return {
                ...state,
                sent: {
                    ...state.sent,
                    loading: false,
                    loaded: true,
                    error: false,
                    data: action.data
                }
            };

        case SENT_NOTIFICATIONS_ERROR:
            return {
                ...state,
                sent: {
                    ...state.sent,
                    loading: false,
                    loaded: true,
                    error: true,
                    errorMessage: action.data
                }
            };

        default:
            return state;
    }
}
