import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { LogoutWrapper } from "@cortexglobal/cortex-auth-react-router-dom";
import { Button } from "@cortexglobal/rla-components";

import Menu from "./BurgerMenu";

import MainNavLink from "./MainNavLink";

const loginUrl = "/auth/login";
class PrivateNav extends React.Component {
    state = {
        menuOpen: false
    };

    // This keeps your state in sync with the opening/closing of the menu
    // via the default means, e.g. clicking the X, pressing the ESC key etc.
    handleStateChange = state => {
        this.setState({ menuOpen: state.isOpen });
    };

    // This can be used to close the menu, e.g. when a user clicks a menu item
    closeMenu = () => {
        this.setState({ menuOpen: false });
    };
    disabledClick = e => {
        e.preventDefault();
    };
    render() {
        const { spacing } = this.props;

        return (
            <Menu
                spacing={spacing}
                isOpen={this.state.menuOpen}
                onStateChange={this.handleStateChange}
            >
                <MainNavLink to="/" onClick={this.closeMenu}>
                    Home
                </MainNavLink>
                <MainNavLink to="/team" onClick={this.closeMenu}>
                    My Team
                </MainNavLink>
                <MainNavLink to="/invites" onClick={this.closeMenu}>
                    Invites
                </MainNavLink>
                <MainNavLink to="/requests" onClick={this.closeMenu}>
                    Requests
                </MainNavLink>
                <MainNavLink to="/contact" onClick={this.closeMenu}>
                    Contact us
                </MainNavLink>
                <LogoutWrapper loginUrl={loginUrl}>
                    {({ logout }) => (
                        <Button
                            href={loginUrl}
                            onClick={logout}
                            height={45}
                            expanded
                            style={{
                                fontSize: "1em",
                                fontWeight: "800",
                                textTransform: "none",
                                marginTop: "20px"
                            }}
                        >
                            <FontAwesomeIcon
                                icon="sign-out-alt"
                                transform="grow-3"
                            />{" "}
                            Logout
                        </Button>
                    )}
                </LogoutWrapper>
            </Menu>
        );
    }
}
export default PrivateNav;
