import React, { Component } from "react";
import styled from "styled-components";
import axios from "axios";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import get from "lodash/get";

import { Modal, Row, Column, Button } from "@cortexglobal/rla-components";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { sendSurvey } from "./staffActions";

// import LinkStyleButton from "../../../components/LinkStyleButton";
import ExitSurveyForm from "./ExitSurveyForm";
import ResendExitSurveyForm from "./ResendExitSurveyForm";

const ExitSurveyHeaderLabel = styled.h3`
    margin: 1rem 0 0 0;
`;

class ExitSurvey extends Component {
    state = {
        showExitSurveyModal: false,
        submitting: false,
        invitesLoaded: false,
        invites: []
    };

    submitExitSurvey = (values, { setErrors }) => {
        // return false;
        this.setState(state => ({
            submitting: true
        }));
        values.survey_alias = "exit-survey";

        return this.props
            .sendSurvey(values)
            .then(() => this.onClose())
            .catch(e => {
                return parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    this.setState(state => ({
                        submitting: false
                    }));
                });
            });
    };

    componentDidMount() {
        axios
            .get(`/api/v1/manager/survey-invitation?survey=exit-survey`)
            .then(({ data }) => {
                this.setState({
                    submitting: false,
                    invitesLoaded: true,
                    invites: data.data
                });
            })
            .catch(e => e);
    }
    showExitSurveyModal = () => {
        this.setState({ showExitSurveyModal: true });
    };
    onClose = () => {
        this.setState({
            showExitSurveyModal: false
        });
    };
    render() {
        const {
            showExitSurveyModal,
            submitting,
            invitesLoaded,
            invites
        } = this.state;
        const { staff } = this.props;

        if (!staff.loaded) {
            return (
                <div>Please wait for staff to load, or refresh the page</div>
            );
        }
        return (
            <React.Fragment>
                <Button
                    onClick={this.showExitSurveyModal}
                    style={{ float: "right", marginLeft: "1rem" }}
                    type="darkGray"
                >
                    Send Exit Survey
                </Button>
                {showExitSurveyModal && (
                    <Modal visible={showExitSurveyModal} onClose={this.onClose}>
                        <Row>
                            <Column>
                                <h1>Send an Exit Survey</h1>
                                <p>
                                    To send an Exit Survey to a technician
                                    leaving the business, please fill out the
                                    form below. Please note: this will be sent
                                    to the email address associated to their
                                    Elite account.
                                </p>
                            </Column>
                        </Row>
                        <ExitSurveyForm
                            onClose={this.onClose}
                            onSubmit={this.submitExitSurvey}
                            submitting={submitting}
                            staff={staff.data}
                            invites={invites}
                        />
                        <Row>
                            <Column>
                                <hr style={{ margin: "1.4rem 0 0 0" }} />
                                <ExitSurveyHeaderLabel>
                                    Resend a link
                                </ExitSurveyHeaderLabel>
                            </Column>
                        </Row>
                        <ResendExitSurveyForm
                            submitting={submitting}
                            invitesLoaded={invitesLoaded}
                            invites={invites}
                            staff={staff.data}
                            onClose={this.onClose}
                        />
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    staff: get(state, "staff.allStaff", null)
});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            sendSurvey
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ExitSurvey);
