import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faInfoCircle from "@fortawesome/free-solid-svg-icons/faInfoCircle";
// import Tooltip from "../../tooltip";
import ReactTooltip from "react-tooltip-lite";

const StyledLabel = styled.label`
    width: ${props => props.width}px;
    display: ${props => (props.inlineLabel ? "inline-block" : "block")};
    text-align: ${props => props.align};
    vertical-align: middle;
    font-weight: bold;
    margin-bottom: ${props => props.marginBottom}rem;
    ${props => props.width < 100 && `padding-right: 4px`};

    // Input group styles
    ${props =>
        props.inputGroup &&
        css`
            vertical-align: top;
            height: ${props => props.height}px;
            line-height: ${props => props.height}px;
            border-radius: ${props => props.theme.input.radius}px 0 0
                ${props => props.theme.input.radius}px;
            border: 1px solid ${props => props.theme.input.borderColor};
            background: ${props => props.theme.input.background};
            border-right: none;
            text-align: center;
            padding-right: 0;
        `};
`;

const Tooltip = styled(ReactTooltip)`
    span {
        cursor: pointer;
    }
`;

const FormLabel = withTheme(props => {
    return (
        <StyledLabel htmlFor={props.name} {...props}>
            {props.children}{" "}
            {props.tooltip && (
                <Tooltip
                    tagName="span"
                    content={props.tooltip}
                    background={props.theme.tooltip.background}
                    color={props.theme.tooltip.color}
                    hoverDelay={200}
                >
                    <span>{props.tooltipIcon}</span>
                </Tooltip>
            )}
        </StyledLabel>
    );
});

FormLabel.displayName = "FormLabel";

FormLabel.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    name: PropTypes.string.isRequired,
    inlineLabel: PropTypes.bool,
    inputGroup: PropTypes.bool,
    align: PropTypes.string,
    marginBottom: PropTypes.number,
    tooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    tooltipIcon: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
FormLabel.defaultProps = {
    height: 30,
    inlineLabel: true,
    inputGroup: false,
    align: "left",
    marginBottom: 0,
    tooltipIcon: <FontAwesomeIcon icon={faInfoCircle} />
};
export default FormLabel;
