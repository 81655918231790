import styled from "styled-components";

import { Row, Column } from "@cortexglobal/rla-components";
import { Component } from "react";

export const Card = styled.div`
    background-color: #f0f0f0;
    border-radius: 5px;
    overflow: hidden;
    margin-bottom: 1em;
    height: 100%;

    .content {
        padding: 20px;
    }
`;

export const Title = styled.h2`
    width: 100%;
    min-height: ${(props) => (props.deepTitle ? "54px" : "30px")};
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    background-color: #1c69d4;
    color: white;
    font-size: 0.9rem;
    padding: 0.2rem;
    margin: 0;
`;

export const Value = styled.h3`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    font-size: ${(props) => (props.large ? "2.2rem" : "1.6rem")};
    font-weight: ${(props) => (props.weight ? props.weight : "bold")};
    margin-bottom: ${(props) => (props.condensed ? "0.2rem" : "1.2rem")};
`;

export const Label = styled.h4`
    display: block;
    width: 100%;
    text-align: center;
    font-size: 0.6rem;
    margin-bottom: ${(props) => (props.condensed ? "0.2rem" : "1.2rem")};
`;
export const BorderRow = styled(Row)`
    padding-bottom: ${(props) => (props.condensed ? "0.2rem" : "1.5rem")};
    margin-bottom: 1.5rem;
    border-bottom: 1px rgba(0, 0, 0, 0.1) solid;
`;
export const BorderColumn = styled(Column)`
    padding-left: ${(props) => (props.condensed ? "0.2rem" : "1.5rem")};
    margin-bottom: ${(props) => (props.condensed ? "0.2rem" : "1rem")};
    border-left: 1px rgba(0, 0, 0, 0.1) solid;
`;

export const ProgressBar = styled.input`
    border-radius: 10px;
    background: ${(props) => props.theme.colors.primary};
    width: ${({ percentage }) => percentage}%;
    color: transparent;
    appearance: none;
    border: 0;
`;

export const Progress = styled.div`
    .progress {
        border-radius: 10px;
        height: 20px;
        background: #afafaf;
        overflow: hidden;
        position: relative;
    }

    label {
        font-family: "bmw_groupbold";
    }

    p {
        text-align: right;
        padding: 10px 0 0;
        font-family: "bmw_groupbold";
        margin: 0;
    }
`;

export const ProgressElement = ({ title, value, total }) => {
    const safeTotal = total || value;
    //Calculate the percentage complete
    let percentage = Math.round((value / safeTotal) * 100);

    //If the percentage is greater than 100, or NaN, or Infinity, set it to 100
    if (percentage > 100 || isNaN(percentage) || !isFinite(percentage)) {
        percentage = 100;
    }

    return (
        <Progress>
            <label>{title}</label>
            <div className="progress">
                <ProgressBar
                    percentage={percentage}
                    // value={this.props.value}
                    // max={this.props.total || this.props.value}
                ></ProgressBar>
            </div>
            <p>{value}</p>
        </Progress>
    );
};
