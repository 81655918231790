import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DashboardPanel from "../dashboard/DashboardPanel";
import { Value, ProgressElement } from "../dashboard/dashboardComponents";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import CircularProgress from "../CircularProgress";
const RetailerSummaryContainer = styled.div``;

const RowFlex = styled(Row)`
    > div {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ColumnFlex = styled(Column)`
    float: none;
    padding-bottom: 1rem;

    .content {
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            margin: 0;
        }
    }
`;

class RetailerSummary extends Component {
    render() {
        const {
            retailerSummary,
            technicians,
            apprentices,
            serviceAdvisors,
            salesExecutives,
            loaded,
        } = this.props;

        if (!loaded) {
            return <Loading align="center" />;
        }
        console.log({ retailerSummary });

        const technicianPercentage =
            retailerSummary.current_technicians > 0
                ? (
                      (technicians.total /
                          retailerSummary.current_technicians) *
                      100
                  ).toFixed(0)
                : 0;
        const apprenticePercentage =
            retailerSummary.current_apprentices > 0
                ? (
                      (apprentices.total /
                          retailerSummary.current_apprentices) *
                      100
                  ).toFixed(0)
                : 0;
        const serviceAdvisorPercentage =
            retailerSummary.current_service_advisors > 0
                ? (
                      (serviceAdvisors.total /
                          retailerSummary.current_service_advisors) *
                      100
                  ).toFixed(0)
                : 0;
        const salesExecutivePercentage = 
            retailerSummary.current_sales_executives > 0
                ? (
                      (salesExecutives.total /
                          retailerSummary.current_sales_executives) *
                      100
                  ).toFixed(0)
                : 0;

        return (
            <RetailerSummaryContainer>
                <RowFlex>
                    <ColumnFlex>
                        <PageTitle
                            title="Retailer information"
                            subtitle="Your current status"
                        />
                    </ColumnFlex>
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel title="Technician Engagement">
                            <CircularProgress
                                percentage={technicianPercentage}
                                text={`${technicianPercentage}%`}
                                initialAnimation
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={8}>
                        <DashboardPanel title="Technician Engagement">
                            <ProgressElement
                                title="Total No. Technicians"
                                value={retailerSummary.current_technicians}
                                total={retailerSummary.current_technicians}
                            />
                            <ProgressElement
                                title="No. Active Technicians"
                                value={technicians.total}
                                total={retailerSummary.current_technicians}
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel title="Apprentice Engagement">
                            <CircularProgress
                                percentage={apprenticePercentage}
                                text={`${apprenticePercentage}%`}
                                initialAnimation
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={8}>
                        <DashboardPanel title="Apprentice Engagement">
                            <ProgressElement
                                title="Total No. Apprentices"
                                value={retailerSummary.current_apprentices}
                                total={retailerSummary.current_apprentices}
                            />
                            <ProgressElement
                                title="No. Active Apprentices"
                                value={apprentices.total}
                                total={retailerSummary.current_apprentices}
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel title="Service Advisor Engagement">
                            <CircularProgress
                                percentage={serviceAdvisorPercentage}
                                text={`${serviceAdvisorPercentage}%`}
                                initialAnimation
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={8}>
                        <DashboardPanel title="Service Advisor Engagement">
                            <ProgressElement
                                title="Total No. Service Advisors"
                                value={retailerSummary.current_service_advisors}
                                total={retailerSummary.current_service_advisors}
                            />
                            <ProgressElement
                                title="No. Active Service Advisors"
                                value={serviceAdvisors.total}
                                total={retailerSummary.current_service_advisors}
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel title="Sales Executive Engagement">
                            <CircularProgress
                                percentage={salesExecutivePercentage}
                                text={`${salesExecutivePercentage}%`}
                                initialAnimation
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={8}>
                        <DashboardPanel title="Sales Executive Engagement">
                            <ProgressElement
                                title="Total No. Sales Executives"
                                value={retailerSummary.current_sales_executives}
                                total={retailerSummary.current_sales_executives}
                            />
                            <ProgressElement
                                title="No. Active Sales Executives"
                                value={salesExecutives.total}
                                total={retailerSummary.current_sales_executives}
                            />
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel
                            title="Current Technician Recruitment"
                            deepTitle={true}
                        >
                            <Value>
                                {retailerSummary.technician_recruitment.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel
                            title="Current Apprentice Recruitment"
                            deepTitle={true}
                        >
                            <Value>
                                {retailerSummary.apprentice_recruitment.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel
                            title="Current Service Advisor Recruitment"
                            deepTitle={true}
                        >
                            <Value>
                                {retailerSummary.service_advisor_recruitment.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel
                            title="Current Sales Executive Recruitment"
                            deepTitle={true}
                        >
                            <Value>
                                {retailerSummary.sales_executive_recruitment.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel title="New Starters" deepTitle={true}>
                            <Value>
                                {retailerSummary.new_starters.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={4}>
                        <DashboardPanel title="Leavers" deepTitle={true}>
                            <Value>
                                {retailerSummary.leavers.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
            </RetailerSummaryContainer>
        );
    }
}

RetailerSummary.propTypes = {
    retailerSummary: PropTypes.object.isRequired,
};
export default RetailerSummary;
