import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import get from "lodash/get";
import {
    SubmitButton,
    FormikInput,
    FormikDatePicker,
    Row,
    Column,
} from "@cortexglobal/rla-components";

import {
    parseValidationErrors,
    yupFormattedDate,
} from "@cortexglobal/cortex-utilities";

import {
    PROGRAM_START_DATE,
    PROGRAM_START_DATE_IRE,
} from "@cortexglobal/bmw-shared-components";

import RoleSelect from "../../../components/roles/RoleSelect";

const makeStaffSchema = (programStartDate, isTechnicianRole) =>
    Yup.object().shape({
        first_name: Yup.string().required("Required"),
        last_name: Yup.string().required("Required"),
        email: Yup.string().email("Invalid email").required("Required"),
        start_date: yupFormattedDate()
            .max(new Date(), "Please select a start date before today")
            .required("Please select a start date"),
        probation_date: yupFormattedDate()
            .min(
                Yup.ref("start_date"),
                "Probation date must be at least 3 months after the start date"
            )
            .when("start_date", {
                is: (val) => {
                    //console.log(moment(val).isSameOrAfter(PROGRAM_START_DATE));
                    return (
                        isTechnicianRole &&
                        moment(val).isSameOrAfter(programStartDate)
                    );
                },
                then: yupFormattedDate().required(
                    "Please select a probation end date date"
                ),
            }),
        role_id: Yup.string().required("Please select a role"),
        external_ref: Yup.string()
            .min(5, "Too Short!")
            .max(10, "Too Long!")
            .required("Required"),
    });

class StaffForm extends Component {
    state = { submitting: false, newMember: false };

    componentDidMount() {
        if (!this.props.member) {
            this.setState({ newMember: true });
        }
    }
    submitForm = (values, { setErrors }) => {
        // return false;
        this.setState((state) => ({
            submitting: true,
        }));

        this.props.onSubmit(values).catch((e) => {
            return parseValidationErrors(e).then(({ errors }) => {
                setErrors(errors);
                this.setState((state) => ({
                    submitting: false,
                }));
            });
        });
    };
    getEmptyMember = () => {
        return {
            first_name: "",
            last_name: "",
            email: "",
            start_date: "",
            probation_date: "",
            role_id: "",
            external_ref: "",
        };
    };

    render() {
        const { submitting } = this.state;
        const { member, retailerType } = this.props;
        const startDate =
            retailerType === "retailer-ire"
                ? PROGRAM_START_DATE_IRE
                : PROGRAM_START_DATE;
        const isTechnicianRole =
            member && member.role && member.role.type === "technician";
        const initialRoleId = member && member.role ? member.role.id : "";

        return (
            <div className="form-container">
                <React.Fragment>
                    <Formik
                        initialValues={
                            member
                                ? { ...member, role_id: member.role.id }
                                : this.getEmptyMember()
                        }
                        onSubmit={this.submitForm}
                        validationSchema={makeStaffSchema(
                            startDate,
                            isTechnicianRole
                        )}
                        className="form-group"
                    >
                        {({ values, errors }) => {
                            return (
                                <Form className="form-group" autoComplete="off">
                                    <Row>
                                        <Column large={6}>
                                            <FormikInput
                                                type="text"
                                                name="first_name"
                                                label="First Name"
                                                value={values.first_name}
                                                placeholder="Joe"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="last_name"
                                                label="Last Name"
                                                value={values.last_name}
                                                placeholder="Bloggs"
                                            />

                                            <FormikInput
                                                type="text"
                                                name="external_ref"
                                                label="TMSI User ID"
                                                value={values.external_ref}
                                                placeholder="c123456"
                                            />
                                        </Column>
                                        <Column large={6}>
                                            <RoleSelect
                                                value={values.role_id}
                                            />
                                            <FormikInput
                                                type="email"
                                                name="email"
                                                label="Email"
                                                value={values.email}
                                                placeholder="joe.bloggs@email.com"
                                                autoComplete="false"
                                            />
                                            <FormikDatePicker
                                                name="start_date"
                                                label="Start Date"
                                                value={values.start_date}
                                                maxDate={new Date()}
                                            />

                                            {(isTechnicianRole ||
                                                (values.role_id !== "" &&
                                                    values.role_id !== "10") ||
                                                (initialRoleId !== "" &&
                                                    initialRoleId !==
                                                        values.role_id)) &&
                                                values.start_date &&
                                                moment(
                                                    values.start_date,
                                                    "DD/MM/YYYY"
                                                ).isSameOrAfter(startDate) && (
                                                    <FormikDatePicker
                                                        name="probation_date"
                                                        label="Probation Date"
                                                        openToDate={moment(
                                                            values.start_date,
                                                            "DD/MM/YYYY"
                                                        ).add(3, "months")}
                                                        value={
                                                            values.probation_date
                                                        }
                                                        minDate={moment(
                                                            values.start_date,
                                                            "DD/MM/YYYY"
                                                        ).add(3, "months")}
                                                        disabled={
                                                            values.start_date ===
                                                            ""
                                                        }
                                                    />
                                                )}
                                        </Column>
                                        <Column large={6}>&nbsp;</Column>
                                        <Column large={6}>
                                            <SubmitButton
                                                expanded
                                                submitting={submitting}
                                                disabled={submitting}
                                            >
                                                Submit
                                            </SubmitButton>
                                        </Column>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </React.Fragment>
            </div>
        );
    }
}

StaffForm.propTypes = {
    member: PropTypes.object,
    onSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
    retailerType: get(state, "auth.group.type", null),
});

export default connect(mapStateToProps)(StaffForm);
