import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { isEqual } from "lodash";
import styled from "styled-components";
// import uuid from "uuid/v4";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import {
    Loading,
    InputField,
    // DatePicker,
    Button,
    Row,
    Column
} from "@cortexglobal/rla-components";
import { getInitialInvites, submitInitialInvites } from "./staffActions";
import RoleSelect from "../../../components/roles/RoleSelect";

const StyledTableData = styled.td`
    vertical-align: top;
    padding: 1rem 0.5rem 0rem 0.5rem;
    border-bottom: 0.8rem solid #fff;
`;
const StyledTableHeader = styled.th`
    vertical-align: top;
    padding: 0 0.5rem 0.5rem 0.5rem;
`;
const StyledTableRow = styled.tr`
    background: ${props => props.theme.colors.lightGray};
`;
const StyledTableDataNoEdit = styled(StyledTableData)`
    padding-top: 1.4rem;
`;

const RemoveButton = styled(Button)`
    height: 30px;
    line-height: 33px;
    padding: 0rem 0.7rem;
`;

class InitialInvites extends Component {
    state = {
        invites: [],
        submitting: []
    };
    componentDidMount() {
        if (!this.props.initialInvites.loaded) {
            this.props.getInitialInvites();
        }
        this.setState({ invites: this.props.initialInvites.data });
    }
    componentDidUpdate(prevProps) {
        // console.log(
        //     "componentDidUpdate",
        //     this.props.initialInvites.data,
        //     prevProps.initialInvites.data,
        //     isEqual(
        //         this.props.initialInvites.data,
        //         prevProps.initialInvites.data
        //     )
        // );
        if (
            !isEqual(
                this.props.initialInvites.data,
                prevProps.initialInvites.data
            ) ||
            this.props.loading !== prevProps.loading
        ) {
            this.setState({ invites: this.props.initialInvites.data });
            if (this.props.initialInvites.loaded) {
                this.props.updateInitialInvitePendingStatus(
                    this.props.initialInvites.data.length > 0
                );
            }
        }
    }
    handleChange(changedIndex, value) {
        this.setState(state => {
            return {
                invites: state.invites.map((invite, index) => {
                    if (index === changedIndex) {
                        invite[value.name] = value.value;
                    }
                    return invite;
                })
            };
        });
    }
    //REMOVED AFTER 25/02/2019 meeting (only 'real' invite options to be used)
    // addInvite = () => {
    //     this.setState(state => {
    //         return {
    //             invites: [
    //                 ...state.invites,
    //                 {
    //                     uuid: uuid(),
    //                     external_ref: "",
    //                     first_name: "",
    //                     last_name: "",
    //                     email: "",
    //                     role_id: "",
    //                     start_date: "",
    //                     rejected_reason: {},
    //                     added: true,
    //                     delete: false
    //                 }
    //             ]
    //         };
    //     });
    // };
    submitInvites = () => {
        this.props.submitInitialInvites(this.state.invites);
    };
    render() {
        const { loading } = this.props;
        const { invites } = this.state;
        if (loading) {
            return <Loading align="center" />;
        }
        return (
            <div>
                <Row>
                    <Column>
                        {/* <h1></h1> */}
                        <PageTitle
                            title="Invite Your Team"
                            subtitle="Send invites to your team members"
                        />
                        <p>
                            By completing the form below, you’ll activate an
                            email to each member of your team of Technicians,
                            inviting them to join Elite - the BMW and MINI
                            Technician Loyalty Programme. As well as having to
                            be registered with TMSI, each Technician will
                            require an independent retailer email address with
                            access to that email. Once registered, they can then
                            choose an alternative personal email address for
                            general correspondence. Please note that the
                            programme is not open to Apprentices or MOT
                            Testers/Tyre Fitters.
                        </p>
                        <table
                            style={{
                                width: "100%",
                                textAlign: "left",
                                borderSpacing: "0"
                            }}
                        >
                            <thead>
                                <tr>
                                    <StyledTableHeader>
                                        First Name
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        Last Name
                                    </StyledTableHeader>
                                    <StyledTableHeader>Email</StyledTableHeader>
                                    <StyledTableHeader>Role</StyledTableHeader>
                                    <StyledTableHeader>
                                        TMSI ID
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        TMSI Date
                                    </StyledTableHeader>
                                    <StyledTableHeader>
                                        Remove
                                    </StyledTableHeader>
                                </tr>
                            </thead>
                            <tbody>
                                {invites.map((invite, index) => {
                                    if (invite.delete) {
                                        return null;
                                    }
                                    //console.log(invite);
                                    return (
                                        <StyledTableRow key={invite.uuid}>
                                            <StyledTableData>
                                                <InputField
                                                    name="first_name"
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        index
                                                    )}
                                                    value={invite.first_name}
                                                    error={
                                                        invite.rejected_reason &&
                                                        invite.rejected_reason
                                                            .first_name
                                                    }
                                                />
                                            </StyledTableData>
                                            <StyledTableData>
                                                <InputField
                                                    name="last_name"
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        index
                                                    )}
                                                    value={invite.last_name}
                                                    error={
                                                        invite.rejected_reason &&
                                                        invite.rejected_reason
                                                            .last_name
                                                    }
                                                />
                                            </StyledTableData>
                                            <StyledTableData>
                                                <InputField
                                                    type="email"
                                                    name="email"
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        index
                                                    )}
                                                    value={invite.email}
                                                    error={
                                                        invite.rejected_reason &&
                                                        invite.rejected_reason
                                                            .email
                                                    }
                                                    // style={{
                                                    //     border:
                                                    //         invite.rejected_reason &&
                                                    //         invite
                                                    //             .rejected_reason
                                                    //             .email
                                                    // }}
                                                />{" "}
                                            </StyledTableData>
                                            <StyledTableData>
                                                <RoleSelect
                                                    formikSelect={false}
                                                    value={invite.role_id}
                                                    onChange={this.handleChange.bind(
                                                        this,
                                                        index
                                                    )}
                                                    error={
                                                        invite.rejected_reason &&
                                                        invite.rejected_reason
                                                            .role_id
                                                    }
                                                />
                                            </StyledTableData>
                                            <StyledTableDataNoEdit>
                                                {invite.external_ref}
                                            </StyledTableDataNoEdit>
                                            <StyledTableDataNoEdit>
                                                {invite.start_date}
                                            </StyledTableDataNoEdit>
                                            <StyledTableData
                                                style={{ textAlign: "center" }}
                                            >
                                                <RemoveButton
                                                    type="alert"
                                                    onClick={this.handleChange.bind(
                                                        this,
                                                        index,
                                                        {
                                                            name: "delete",
                                                            value: true
                                                        }
                                                    )}
                                                >
                                                    X
                                                </RemoveButton>
                                            </StyledTableData>
                                        </StyledTableRow>
                                    );
                                })}
                            </tbody>
                        </table>
                    </Column>
                </Row>
                <Row>
                    <Column medium={6}>
                        {/*<Button onClick={this.addInvite}>Add Another</Button>*/}
                        &nbsp;
                    </Column>
                    <Column medium={6} style={{ textAlign: "right" }}>
                        <Button
                            style={{ fontSize: "1rem" }}
                            type="primary"
                            onClick={this.submitInvites}
                        >
                            Invite your team
                        </Button>
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { initialInvites, loading } = state.staff;

    return { initialInvites, loading };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getInitialInvites,
            submitInitialInvites
        },
        dispatch
    );
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InitialInvites);
