import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading, Row, Column, Error } from "@cortexglobal/rla-components";
import PageTitle from "../PageTitle";
import NotificationList from "./NotificationList";
import { getNotifications } from "./notificationsActions";
// import Error from "./Error";

class Notifications extends Component {
    componentWillMount() {
        this.props.getNotifications();
    }

    render() {
        const { loaded, error, data: notifications } = this.props.notifications;

        return (
            <div className="page page-notifications">
                <Row>
                    <Column medium={12}>
                        <PageTitle
                            title="Notifications"
                            subtitle="What’s going on in the network?"
                        />
                    </Column>
                    <Column medium={12}>
                        {loaded &&
                            (error ? (
                                <Error>
                                    We have encountered an issue while loading
                                    your notifications, please try again later.
                                </Error>
                            ) : (
                                <NotificationList
                                    notifications={notifications}
                                />
                            ))}
                        {!loaded && <Loading />}
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { user: state.auth, notifications: state.notifications };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getNotifications
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Notifications);
