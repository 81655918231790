import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import styled from "styled-components";
import {
    VictoryChart,
    VictoryVoronoiContainer,
    VictoryLine,
    VictoryAxis
} from "victory";

// import DashboardPanel from "../dashboard/DashboardPanel";
import { BorderColumn } from "../dashboard/dashboardComponents";
import { Row, Column, Progress } from "@cortexglobal/rla-components";

// const BorderColumn = styled(Column)`
//     padding-left: 1.5rem;
//     margin-bottom: 1.5rem;
//     border-left: 1px rgba(0, 0, 0, 0.1) solid;
// `;
const ChartColumn = styled(Column)`
    .VictoryContainer {
        margin: auto;
    }
`;
class NewTechnicianRunRate extends Component {
    state = {
        total: 0,
        data: []
    };
    componentDidMount() {
        this.processData();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.monthlyRetailersSummary.loaded === false &&
            this.props.monthlyRetailersSummary.loaded
        ) {
            this.processData();
        }
    }
    processData = () => {
        const months = this.props.monthlyRetailersSummary.data.reduce(
            (carry, monthData, index) => {
                const monthDate = moment(monthData.month, "YY-MM-DD");
                carry.total +=
                    monthData.new_technicians_month -
                    monthData.leaving_technicians_month;
                carry.data.push({
                    month: monthDate.format("MMM"),
                    techsRequired: carry.total
                });
                return carry;
            },
            {
                total: 0,
                data: []
            }
        );

        this.setState({ ...months });
    };
    generateMonthsThisYear = techsRequiredPerMonth => {
        let months = [];
        const year = moment().format("YYYY");
        for (let i = 1; i <= 12; i++) {
            const monthDate = moment(`${year}-${i}-01`, "YYYY-M-DD");
            months.push({
                date: monthDate.format("YY-MM-DD"),
                month: monthDate.format("MMM"),
                techsRequired: techsRequiredPerMonth * i
            });
        }
        return months;
    };
    render() {
        //console.log(this.state);
        const { total, data } = this.state;
        const { techniciansRequired, showProgress } = this.props;
        const techsRequiredPerMonth = techniciansRequired / 12;
        const monthsThisYear = this.generateMonthsThisYear(
            techsRequiredPerMonth
        );
        return (
            <div>
                <Row>
                    <ChartColumn medium={showProgress ? 9 : 12}>
                        <VictoryChart
                            domainPadding={15}
                            height={270}
                            style={{
                                chart: { margin: "auto", textAlign: "center" }
                            }}
                            containerComponent={
                                <VictoryVoronoiContainer
                                    labels={d =>
                                        `${d.month}: ${d.techsRequired}`
                                    }
                                    responsive={false}
                                />
                            }
                        >
                            <VictoryAxis
                                label="Month"
                                style={{
                                    axisLabel: { fontSize: 10, padding: 30 },
                                    tickLabels: { fontSize: 10, padding: 5 }
                                }}
                            />
                            <VictoryLine
                                data={monthsThisYear}
                                x="month"
                                y="techsRequired"
                                style={{
                                    data: { stroke: "#ccc" }
                                }}
                            />
                            <VictoryAxis
                                dependentAxis
                                label="New technicians"
                                style={{
                                    axisLabel: { fontSize: 10, padding: 30 },
                                    tickLabels: { fontSize: 10, padding: 5 }
                                }}
                            />
                            <VictoryLine
                                data={data}
                                x="month"
                                y="techsRequired"
                                style={{
                                    data: { stroke: "#1c69d4" },
                                    parent: { border: "1px solid #ccc" }
                                }}
                            />
                        </VictoryChart>
                    </ChartColumn>
                    {showProgress && (
                        <BorderColumn medium={3}>
                            <Progress
                                current={total}
                                total={techniciansRequired}
                            />
                        </BorderColumn>
                    )}
                </Row>
            </div>
        );
    }
}

NewTechnicianRunRate.propTypes = { showProgress: PropTypes.bool };

NewTechnicianRunRate.defaultProps = { showProgress: false };

export default NewTechnicianRunRate;
