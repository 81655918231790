import React, { Component } from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
import moment from "moment";
import DashboardPanel from "../dashboard/DashboardPanel";
import { Value } from "../dashboard/dashboardComponents";
import { Row, Column } from "@cortexglobal/rla-components";
// const PercentageDifference = styled.span`
//     display: block;
//     text-align: center;
//     margin-top: -1.6rem;
// `;
class TechnicianYTDOverview extends Component {
    render() {
        const { totals, techniciansRequired } = this.props;
        const monthOfYear = moment().format("M");
        const techsRequiredYTD = (techniciansRequired / 12) * monthOfYear;
        //console.log(monthsThisYear);
        const difference = totals.net - techsRequiredYTD;
        const percentageDifference = (totals.net / techsRequiredYTD) * 100;

        return (
            <div>
                <Row>
                    <Column medium={4} large={2}>
                        <DashboardPanel title="YTD Target" deepTitle={true}>
                            <Value>
                                {Math.round(techsRequiredYTD * 10) / 10}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={4} large={2}>
                        <DashboardPanel title="YTD New" deepTitle={true}>
                            <Value>{totals.new}</Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={4} large={2}>
                        <DashboardPanel title="YTD Leavers" deepTitle={true}>
                            <Value>{totals.leaving}</Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={4} large={2}>
                        <DashboardPanel title="YTD Net" deepTitle={true}>
                            <Value>{totals.net}</Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={4} large={2}>
                        <DashboardPanel title="YTD Difference" deepTitle={true}>
                            <Value
                                style={{
                                    color: `${
                                        difference >= 0 ? "inherit" : "#f00"
                                    }`
                                }}
                            >
                                {Math.round(difference * 10) / 10}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={4} large={2}>
                        <DashboardPanel
                            title="YTD % of target"
                            deepTitle={true}
                        >
                            <Value
                                style={{
                                    color: `${
                                        percentageDifference >= 100
                                            ? "inherit"
                                            : "#f00"
                                    }`
                                }}
                            >
                                {Math.round(percentageDifference * 10) / 10}%
                            </Value>
                        </DashboardPanel>
                    </Column>
                </Row>
            </div>
        );
    }
}

export default TechnicianYTDOverview;
