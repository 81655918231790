import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { GroupSwitcher } from "@cortexglobal/cortex-auth-react-router-dom";
import { colors } from "@cortexglobal/bmw-shared-components";

const Wrapper = styled.div`
    //overflow: auto;
    background: ${(props) => props.background};
    display: flex;
    flex-direction: row;
`;
const UserDetails = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    min-height: 3rem;
    font-size: 0.7rem;
    line-height: 1;
    p {
        margin: 0;
    }
    h2 {
        margin: 0 0 0.3rem 0;
    }
`;
const GroupSelector = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;
    padding: 0.7rem 1.5rem 0 0;
    min-height: 3rem;
    label {
        font-size: 0.7rem;
    }
`;
const ProfileImg = styled.div`
    background: url(${(props) => props.avatar});
    background-size: cover;
    background-position: 80% 65%;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    margin: ${(props) => (props.banner ? "1rem 1rem 1rem 2rem" : "1rem")};
`;
const HeadOfficeWarning = styled.div`
    background: ${colors.warning};
    color: white;
    padding: 1rem;
`;

class UserSummary extends React.Component {
    renderGroupSwitcher = (user, group) => {
        return (
            <GroupSelector>
                <GroupSwitcher label="Select a group" />
            </GroupSelector>
        );
    };
    render() {
        const { user, group, background, banner, showGroupSwitcher } =
            this.props;
        return (
            <React.Fragment>
                <Wrapper spacing={this.props.spacing} background={background}>
                    <ProfileImg
                        avatar={
                            user.additional_props
                                ? user.additional_props.avatar
                                : ""
                        }
                        banner={banner}
                    />{" "}
                    <UserDetails>
                        <h2>{`${user.first_name ? user.first_name : ""} ${
                            user.last_name ? user.last_name : ""
                        }`}</h2>
                        <p>{`${
                            user.role && user.role.name ? user.role.name : ""
                        } - ${group.name ? group.name : ""}`}</p>
                    </UserDetails>
                    {showGroupSwitcher && this.renderGroupSwitcher(user, group)}
                </Wrapper>
                {user.role &&
                    user.role.type === "head-office" &&
                    group.type !== "head-office" && (
                        <HeadOfficeWarning>
                            You are currently viewing Elite as a Manager. Any
                            changes you make will affect the users. To return to
                            the Head Office view, select your Head Office group
                            from the group selector above.
                        </HeadOfficeWarning>
                    )}
            </React.Fragment>
        );
    }
}

UserSummary.propTypes = {
    user: PropTypes.object.isRequired,
    group: PropTypes.object.isRequired,
    background: PropTypes.string,
    showGroupSwitcher: PropTypes.bool,
};
UserSummary.defaultProps = {
    background: "#f9f9f9",
    showGroupSwitcher: true,
};
export default UserSummary;
