import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import AppContext from "../../../context/AppContext";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { getRedemptions } from "./redemptionActions";
import {
    BodyRow,
    BodyColumn,
    BodyLabel,
    RowWrapper,
    HeaderColumn,
    HeaderRow
} from "../../../components/table/index";

class RedemptionHome extends Component {
    componentDidMount() {
        if (!this.props.redemptions.loaded) {
            this.props.getRedemptions();
        }
    }
    render() {
        const { loading, data, loaded } = this.props.redemptions;
        return (
            <Row>
                <AppContext.Consumer>
                    {({ staff, user, group }) => {
                        if (loading || !loaded) {
                            return <Loading align="center" />;
                        }
                        return (
                            <React.Fragment>
                                <Row>
                                    <Column large={8}>
                                        <PageTitle
                                            title="Redemptions"
                                            subtitle="Your team's redemptions"
                                        />
                                    </Column>
                                </Row>

                                {data.length > 0 ? (
                                    <React.Fragment>
                                        <HeaderRow>
                                            <HeaderColumn large={4}>
                                                Name
                                            </HeaderColumn>
                                            <HeaderColumn large={4}>
                                                Date Redeemed:
                                            </HeaderColumn>
                                            <HeaderColumn large={4}>
                                                Total Redeemed
                                            </HeaderColumn>
                                        </HeaderRow>
                                        {data.map(redemption => {
                                            return (
                                                <RowWrapper
                                                    key={redemption.uuid}
                                                >
                                                    <BodyRow showingDetail>
                                                        <BodyColumn large={4}>
                                                            <BodyLabel>
                                                                Name:
                                                            </BodyLabel>
                                                            {
                                                                redemption.user_name
                                                            }{" "}
                                                        </BodyColumn>
                                                        <BodyColumn large={4}>
                                                            <BodyLabel>
                                                                Date Redeemed:
                                                            </BodyLabel>
                                                            {
                                                                redemption.awarded_at
                                                            }{" "}
                                                        </BodyColumn>

                                                        <BodyColumn large={4}>
                                                            <BodyLabel>
                                                                Total Redeemed:
                                                            </BodyLabel>
                                                            &pound;{" "}
                                                            {-redemption.amount}{" "}
                                                        </BodyColumn>
                                                    </BodyRow>
                                                </RowWrapper>
                                            );
                                        })}
                                    </React.Fragment>
                                ) : (
                                    <Row>
                                        Your team have yet to make any
                                        redemptions
                                    </Row>
                                )}
                            </React.Fragment>
                        );
                    }}
                </AppContext.Consumer>
            </Row>
        );
    }
}
const mapStateToProps = state => {
    const { redemptions } = state;

    return { redemptions };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ getRedemptions }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RedemptionHome);
