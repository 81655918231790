import { addLocaleData } from "react-intl";
import en from "react-intl/locale-data/en";
import es from "react-intl/locale-data/es";
import fr from "react-intl/locale-data/fr";
import it from "react-intl/locale-data/it";

// Our translated strings TODO - Look at doing this dynamically with dynamic imports (or ajax?)
import messagesEnGB from "./locales/en-GB.data.json";
import messagesEn from "./locales/en.data.json";
import messagesFr from "./locales/fr.data.json";
const localeData = { "en-GB": messagesEnGB, en: messagesEn, fr: messagesFr };

addLocaleData([...en, ...es, ...fr, ...it]);

export const getMessages = language => {
    // Split locales with a region code
    const languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

    //Test French
    //return localeData.fr;

    // Try full locale, try locale without region code, fallback to 'en'
    return (
        localeData[language] ||
        localeData[languageWithoutRegionCode] ||
        localeData.en
    );
};
