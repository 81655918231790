import styled from "styled-components";

import { app } from "../../config/theme";

export const AppContainer = styled.div`
    padding-bottom: 80px;
    padding-left: 0;
    z-index: -9999px;
    min-height: 78vh;
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        padding-left: ${app.quickLinkWidth + 20}px;
        margin: 0 0rem;
    }
    @media (min-width: ${props => props.theme.breakpoints.xlarge}px) {
        max-width: 1400px;
    }
`;

export const SummaryContainer = styled.div`
    padding-top: ${app.headerHeight}px;
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        padding-left: ${app.quickLinkWidth + 20}px;
        padding-bottom: 0;
    }
`;
