import { groupEvery, flattenVideoWinners } from "./videoCompetitionFunctions";

export const videoCompetitionGroupedSelector = state => groupEvery(state.videoCompetition.entries.data, 3);

export const videoWinnersGroupedSelector = state => state.videoCompetition.winners.data;

export const videoCompetitionSelector = state => state.videoCompetition.entries.data;

export const videoCompetitionWinnersSelector = state => flattenVideoWinners(state.videoCompetition.winners.data);

export const videoShowModalSelector = state => state.videoCompetition.modal.show;

export const videoLoadedCountSelector = state => state.videoCompetition.entries.videosLoaded;

export const shownVideoSelector = state => state.videoCompetition.modal.id;

