import React, { Component } from "react";
import { HeroColumn, Hero, ContentColumn } from "../../components/auth";
import { Row } from "@cortexglobal/rla-components";

import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { ResetPasswordForm } from "@cortexglobal/cortex-auth-react-router-dom";

class ResetPassword extends Component {
    render() {
        return (
            <div className="page page-login">
                <Row collapse equaliseChildHeight>
                    <HeroColumn collapse>
                        <Hero />
                    </HeroColumn>
                    <ContentColumn>
                        <div className="form-container">
                            <PageTitle title="Reset your Password for the BMW Group Technician Portal" />

                            <ResetPasswordForm location={this.props.location} />
                        </div>
                    </ContentColumn>
                </Row>
            </div>
        );
    }
}

export default ResetPassword;
