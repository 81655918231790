// Libraries
import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Other
import { hideVideoModal } from "./videoCompetitionActions";
import { StyledVideo } from "./videoCompetitionComponents";
import {
    shownVideoSelector,
    videoCompetitionWinnersSelector,
    videoCompetitionSelector
} from "./videoCompetitionSelectors";
import { getById } from "./videoCompetitionFunctions";

const Modal = props => (
    <div onClick={props.hideVideoModal} className={props.className}>
        {/* {console.log("modal props")}
        {console.log(props)} */}
        <StyledVideo
            user={props.video.user}
            retailer={props.video.retailer}
            date={props.video.date}
        >
            <source src={props.video.videoUrl} type="video/mp4" />
        </StyledVideo>
    </div>
);

export const StyledModal = styled(Modal)`
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.8);
`;

const winnersMapStateToProps = state => {


    const val = {
        video: getById(
            videoCompetitionWinnersSelector(state),
            shownVideoSelector(state)
        )
    };
	return val;
};

const entriesMapStateToProps = state => ({
    video: getById(videoCompetitionSelector(state), shownVideoSelector(state))
});

const mapDispatchToProps = dispatch =>
    bindActionCreators({ hideVideoModal }, dispatch);

export const EntriesModal = connect(
    entriesMapStateToProps,
    mapDispatchToProps
)(StyledModal);

export const WinnersModal = connect(
    winnersMapStateToProps,
    mapDispatchToProps
)(StyledModal);
