import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { SubmitButton, FormikInput, Panel } from "@cortexglobal/rla-components";
import { trans } from "@cortexglobal/rla-intl";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { forgotten } from "@cortexglobal/cortex-auth-redux";

const ForgottenSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required")
});

class ForgottenPassword extends Component {
    state = {
        submitted: false,
        message: ""
    };
    submitForm = (values, { setErrors }) => {
        this.props.forgotten(values).then(data => {
            console.log(data);
            this.setState({
                submitted: true,
                message: data.data.response_trans
            });
        });
        return true;
    };

    render() {
        const { submitted, message } = this.state;
        return (
            <div>
                {submitted && (
                    <Panel type="success" padding={1}>
                        <p
                            style={{
                                textAlign: "center",
                                paddingBottom: "1rem"
                            }}
                        >
                            {message}
                        </p>
                    </Panel>
                )}
                <Formik
                    initialValues={{
                        email: ""
                    }}
                    onSubmit={this.submitForm}
                    validationSchema={ForgottenSchema}
                >
                    {({ submitting }) => (
                        <Form className="form-group">
                            <FormikInput
                                type="text"
                                name="email"
                                label="Email"
                                placeholder="joe@bloggs.com"
                            />

                            <SubmitButton
                                expanded
                                label="Send me a reset link"
                                submitting={submitting}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            forgotten
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ForgottenPassword);
