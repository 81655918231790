import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch } from "react-router-dom";

import Header from "./PrivateHeader";
import UserSummary from "../../components/global/UserSummary";
import QuickLinks from "./QuickLinks";
import Footer from "./Footer";
import { AppContainer, SummaryContainer } from "./AppContainers";
import { Loading } from "@cortexglobal/rla-components";

import Home from "../../modules/manager/home/Home";
import InvitesHome from "../../modules/manager/invites/InvitesHome";
import RequestsHome from "../../modules/manager/requests/RequestsHome";
import RedemptionHome from "../../modules/manager/redemption/RedemptionHome";
import TeamHome from "../../modules/manager/team/TeamHome";
import Profile from "../../modules/profile/Profile";
import EditProfile from "../../modules/profile/EditProfile";
import Contact from "../../modules/contact/Contact";

import {
    Notifications,
    app,
    breakpoints,
    VideoCompetition,
    VideoCompetitionWinners,
} from "@cortexglobal/bmw-shared-components";
import AppContext from "../../context/AppContext";
import { getStaff } from "../../modules/manager/staff/staffActions";
import Leagues from "../../modules/manager/leagues/Leagues";
import moment from "moment";
import Competitions from "../../modules/manager/competitions/Competitions";

const now = moment();
const videoCompetitionClose = moment("2020-04-01", "YYYY-MM-DD");

class PrivateManagerApp extends Component {
    state = {
        mobileView: false,
        windowWidth: 0,
    };

    throttledHandleWindowResize = () => {
        // console.log(window.innerWidth);
        this.setState({
            mobileView: window.innerWidth < 750,
            windowWidth: window.innerWidth,
        });
        // return throttle(() => {
        //     this.setState({
        //     mobileView: window.innerWidth < 750,
        //     windowWidth: window.innerWidth
        // });
        // }, 200);
    };

    componentDidMount() {
        if (!this.props.staff.loaded) {
            this.props.getStaff();
        }
        this.throttledHandleWindowResize();
        window.addEventListener("resize", this.throttledHandleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.throttledHandleWindowResize);
    }

    render() {
        const { user, group, staff, loading } = this.props;
        const { mobileView, windowWidth } = this.state;
        // console.log(user);
        const canViewRedemptions =
            user?.role?.team === "head-office" ||
            (Array.isArray(user?.permissions) &&
                user?.permissions.includes(
                    (permission) => permission === "rewards-approve"
                ));
        return (
            <AppContext.Provider
                value={{ user, group, staff, loading, mobileView, windowWidth }}
            >
                <Header />
                {user.loaded ? (
                    <React.Fragment>
                        <SummaryContainer>
                            <UserSummary user={user} group={group} banner />
                        </SummaryContainer>
                        <AppContainer>
                            <Switch>
                                <Route exact path="/" component={Home} />
                                <Route
                                    exact
                                    path="/team"
                                    component={TeamHome}
                                />
                                <Route
                                    exact
                                    path="/invites"
                                    component={InvitesHome}
                                />
                                <Route
                                    exact
                                    path="/requests"
                                    component={RequestsHome}
                                />
                                {canViewRedemptions && (
                                    <Route
                                        exact
                                        path="/redemption"
                                        component={RedemptionHome}
                                    />
                                )}
                                <Route
                                    exact
                                    path="/notifications"
                                    component={Notifications}
                                />
                                <Route path="/leagues" component={Leagues} />
                                <Route
                                    path="/competitions"
                                    component={Competitions}
                                />
                                <Route
                                    exact
                                    path="/profile"
                                    component={Profile}
                                />
                                <Route
                                    exact
                                    path="/edit-profile"
                                    component={EditProfile}
                                />
                                <Route
                                    exact
                                    path="/contact"
                                    component={Contact}
                                />
                                {now.isBefore(videoCompetitionClose) && (
                                    <Route
                                        exact
                                        path="/vehicle-health-check-competition-winners"
                                        render={() => (
                                            <VideoCompetitionWinners
                                                hideSummary={true}
                                            />
                                        )}
                                    />
                                )}
                                {now.isBefore(videoCompetitionClose) && (
                                    <Route
                                        exact
                                        path="/vehicle-health-check-competition"
                                        render={() => (
                                            <VideoCompetition
                                                hideSummary={true}
                                            />
                                        )}
                                    />
                                )}
                            </Switch>
                        </AppContainer>
                        <QuickLinks canViewRedemptions={canViewRedemptions} />
                    </React.Fragment>
                ) : (
                    <Loading align="center" />
                )}
                <Footer />
            </AppContext.Provider>
        );
    }
}
const mapStateToProps = (state) => {
    const { user, group } = state.auth;
    const { allStaff: staff, loading } = state.staff;

    return { user, group, staff, loading };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ getStaff }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PrivateManagerApp);
