import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Loading, Row, Column, Error } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import SentNotificationList from "./SentNotificationList";
import { getNotifications } from "./notificationsActions";
import NotifyRetailerButton from "./NotifyRetailerButton";

class SentNotifications extends Component {
    componentWillMount() {
        if (!this.props.notifications.loaded) {
            this.props.getNotifications();
        }
    }

    render() {
        const {
            loading,
            loaded,
            error,
            data: notifications
        } = this.props.notifications.sent;
        return (
            <div className="page page-notifications">
                <Row>
                    <Column medium={8}>
                        <PageTitle
                            title="Notifications"
                            subtitle="What’s going on in the network?"
                        />
                    </Column>
                    <Column medium={4} style={{ paddingTop: "1.5rem" }}>
                        <NotifyRetailerButton wording="Notify the Network" />
                    </Column>
                </Row>
                <Row>
                    <Column medium={12}>
                        <PageTitle
                            title="Sent Notifications"
                            subtitle="The notifications you've sent."
                        />
                        {loaded &&
                            (error ? (
                                <Error>
                                    We have encountered an issue while loading
                                    your sent notifications, please try again
                                    later.
                                </Error>
                            ) : (
                                <SentNotificationList
                                    notifications={notifications}
                                />
                            ))}
                        {loading && <Loading />}
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return { user: state.auth, notifications: state.notificationsSent };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            getNotifications
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SentNotifications);
