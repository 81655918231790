import {
    NOTIFICATIONS_LOADED,
    NOTIFICATIONS_LOADING,
    NOTIFICATIONS_ERROR,
    NOTIFICATIONS_SUMMARY_LOADING,
    NOTIFICATIONS_SUMMARY_LOADED,
    NOTIFICATIONS_SUMMARY_ERROR,
    NOTIFICATIONS_RESET_UNREAD
} from "./notificationsActions";

export const initialState = {
    loaded: false,
    loading: false,
    error: false,
    errorMessage: "",
    data: [],
    summary: {
        loaded: false,
        loading: false,
        error: false,
        errorMessage: "",
        data: {
            total: 0,
            unread: 0,
            read: 0
        }
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case NOTIFICATIONS_LOADING:
            return {
                ...state,
                loading: true,
                loaded: false,
                error: false
            };

        case NOTIFICATIONS_LOADED:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: false,
                data: action.data
            };

        case NOTIFICATIONS_ERROR:
            return {
                ...state,
                loading: false,
                loaded: true,
                error: true,
                errorMessage: action.data
            };

        case NOTIFICATIONS_SUMMARY_LOADING:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loading: true,
                    loaded: false,
                    error: false
                }
            };

        case NOTIFICATIONS_SUMMARY_LOADED:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loading: false,
                    loaded: true,
                    error: false,
                    data: action.data
                }
            };

        case NOTIFICATIONS_SUMMARY_ERROR:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    loading: false,
                    loaded: false,
                    error: true,
                    errorMessage: action.data
                }
            };

        case NOTIFICATIONS_RESET_UNREAD:
            return {
                ...state,
                summary: {
                    ...state.summary,
                    data: {
                        ...state.summary.data,
                        unread: 0
                    }
                }
            };

        default:
            return state;
    }
};
