import {
    STAFF_LOADING,
    STAFF_UPDATING,
    UPDATING_USER_UUID,
    UPDATE_USER,
    ADD_USER,
    LOAD_INITIAL_INVITES,
    LOAD_STAFF
} from "./staffActions";

export const initialState = {
    loading: false,
    updating: false,
    updatingUserUuid: "",
    initialInvites: { loaded: false, data: [] },
    allStaff: { loaded: false, data: [] }
};

export default function staff(state = initialState, action) {
    switch (action.type) {
        case STAFF_LOADING:
            return {
                ...state,
                loading: action.data
            };
        case STAFF_UPDATING:
            return {
                ...state,
                updating: action.data
            };

        case UPDATING_USER_UUID:
            return {
                ...state,
                updatingUserUuid: action.data
            };
        case UPDATE_USER:
            return {
                ...state,
                allStaff: {
                    ...state.allStaff,
                    data: state.allStaff.data.map(member => {
                        if (member.uuid === action.uuid) {
                            member = action.data;
                        }
                        return member;
                    })
                }
            };
        case ADD_USER:
            return {
                ...state,
                allStaff: {
                    ...state.allStaff,
                    data: [...state.allStaff.data, action.data]
                }
            };

        case LOAD_INITIAL_INVITES:
            return {
                ...state,
                initialInvites: { loaded: true, data: action.data }
            };
        case LOAD_STAFF:
            return {
                ...state,
                allStaff: { loaded: true, data: action.data }
            };

        default:
            return state;
    }
}
