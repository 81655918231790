import {
    LOAD_VIDEO_WALL,
    LOADING_VIDEO_WALL,
    LOAD_VIDEO_WALL_ERROR,
    SHOW_VIDEO_MODAL,
    HIDE_VIDEO_MODAL,
    COUNT_LOADED_VIDEOS,
    LOADED_ALL_VIDEO_WALL,
    LOAD_VIDEO_WALL_WINNERS,
    LOADED_VIDEO_WALL_WINNERS
} from "./videoCompetitionActions";

const initialState = {
    modal: { show: false, id: null },
    entries: {
        loaded: false,
        loading: false,
        videosLoaded: 0,
        allVideosLoaded: false,
        data: []
    },
    winners: {
        loaded: false,
        loading: false,
        data: []
    }
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SHOW_VIDEO_MODAL:
            return {
                ...state,
                modal: {
                    show: true,
                    id: action.data
                }
            };

        case HIDE_VIDEO_MODAL:
            return {
                ...state,
                modal: {
                    show: false,
                    id: null
                }
            };

        case COUNT_LOADED_VIDEOS:
            return {
                ...state,
                entries: {
                    ...state.entries,
                    videosLoaded: state.entries.videosLoaded + action.data
                }
            };

        case LOAD_VIDEO_WALL_WINNERS:
            return {
                ...state,
                winners: {
                    ...state.winners,
                    loading: true
                }
            };

        case LOADED_VIDEO_WALL_WINNERS:
            return {
                ...state,
                winners: {
                    ...state.winners,
                    data: action.data,
                    loading: false,
                    loaded: true
                }
            };

        case LOADED_ALL_VIDEO_WALL:
            return {
                ...state,
                entries: {
                    ...state.entries,
                    allVideosLoaded: true
                }
            };

        case LOADING_VIDEO_WALL:
            return {
                ...state,
                entries: {
                    ...state.entries,
                    loading: true
                }
            };

        case LOAD_VIDEO_WALL:
            return {
                ...state,
                entries: {
                    ...state.entries,
                    loading: false,
                    loaded: true,
                    data: [...state.entries.data, ...action.data]
                }
            };

        case LOAD_VIDEO_WALL_ERROR:
            return {
                ...state,
                entries: {
                    ...state.entries,
                    loading: false
                }
            };

        default:
            return state;
    }
};
