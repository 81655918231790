import React, { Component } from "react";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";

class LevelTitle extends Component {

    render() {
        let { level } = this.props;
        return <th
            style={{
                whiteSpace: "nowrap",
                textAlign: "center"
            }}
        >
            <FontAwesomeIcon
                color={level.color}
                icon="trophy"
            />{" "}
            {level.name}
        </th>
    }
}

export default LevelTitle;
