import React, { Component } from "react";
import styled from "styled-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

import ActionsRequiredTable from "./ActionsRequiredTable";
import { Row, Column } from "@cortexglobal/rla-components";
import AppContext from "../../context/AppContext";
import Loading from "@cortexglobal/rla-components/lib/components/Loading";

const ActionsRequiredContainer = styled.div``;

class RetailerInformation extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {({ staff }) => {
                    //console.log(staff);
                    if (!staff.loaded) {
                        return <Loading align="center" />;
                    }
                    const staffNeedingAction = staff.data.filter(
                        member => member.actions_required.length > 0
                    );
                    return (
                        <ActionsRequiredContainer>
                            <Row>
                                <Column>
                                    <PageTitle
                                        title="Actions required"
                                        subtitle="These technicians need your attention"
                                    />
                                </Column>
                            </Row>
                            <Row>
                                <Column>
                                    {staffNeedingAction.length > 0 ? (
                                        <ActionsRequiredTable
                                            staff={staffNeedingAction}
                                        />
                                    ) : (
                                        <p>No actions required</p>
                                    )}
                                </Column>
                            </Row>
                        </ActionsRequiredContainer>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

export default RetailerInformation;
