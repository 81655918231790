import {
    LOAD_CURRENT_TECHNICIAN_SUMMARY,
    LOAD_CURRENT_APPRENTICE_SUMMARY,
    LOAD_CURRENT_SERVICE_ADVISOR_SUMMARY,
    LOAD_CURRENT_SALES_EXECUTIVE_SUMMARY,
} from "./technicianActions";

export const initialState = {
    technicians: { loading: false, loaded: false, data: {} },
    apprentices: { loading: false, loaded: false, data: {} },
    serviceAdvisors: { loading: false, loaded: false, data: {} },
    salesExecutives: { loading: false, loaded: false, data: {} },
};

export default function technicians(state = initialState, action) {
    switch (action.type) {
        case LOAD_CURRENT_TECHNICIAN_SUMMARY:
            return {
                ...state,
                technicians: {
                    loading: false,
                    loaded: true,
                    data: action.data,
                },
            };
        case LOAD_CURRENT_APPRENTICE_SUMMARY:
            return {
                ...state,
                apprentices: {
                    loading: false,
                    loaded: true,
                    data: action.data,
                },
            };
        case LOAD_CURRENT_SERVICE_ADVISOR_SUMMARY:
            return {
                ...state,
                serviceAdvisors: {
                    loading: false,
                    loaded: true,
                    data: action.data,
                },
            };
        case LOAD_CURRENT_SALES_EXECUTIVE_SUMMARY:
            return {
                ...state,
                salesExecutives: {
                    loading: false,
                    loaded: true,
                    data: action.data,
                },
            };

        default:
            return state;
    }
}
