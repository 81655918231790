import React from "react";
import styled from "styled-components";
import { colors } from "../config/theme";

const MainHeading = styled.h1`
    font-size: 1.3rem;
    line-height: 1.4rem;
    margin: ${({ margin }) => margin};
`;

const SubHeading = styled.p`
    width: 100%;
    font-size: 0.8rem;
    line-height:20px
    color: ${colors.darkGray};
    display: block;
    margin: 0 0 1.8rem 0;
`;

const PageTitle = ({ title, subtitle, margin = "1.4rem 0 0.2rem 0" }) => {
    return (
        <div>
            <MainHeading margin={margin}>{title}</MainHeading>
            {subtitle && <SubHeading>{subtitle}</SubHeading>}
        </div>
    );
};

export default PageTitle;
