import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Hero, Wrapper } from "./sharedComponents";

class Cookies extends Component {
    render() {
        return (
            <div className="page page-home">
                <Hero />
                <Row>
                    <Column medium={12} large={8}>
                        <PageTitle
                            title="BMW & MINI Elite"
                            subtitle="Cookie Policy"
                        />
                    </Column>
                </Row>
                <Wrapper>
                    <Column>
                        <h1>BMW COOKIE POLICY.</h1>
                        <h2>What is a cookie?</h2>
                        <p>
                            A cookie is a small text file that stores Internet
                            settings. Almost every website uses cookie
                            technology. The cookie is downloaded by your
                            Internet browser the first time you visit a website.
                            The next time you visit this website from the same
                            device, the cookie and the information in it are
                            either sent back to the originating website
                            (first-party cookies) or to another website to which
                            it belongs (third-party cookies). By that, the
                            website can detect that it has already been opened
                            using this browser, and in some cases it will then
                            vary the content it shows.{" "}
                        </p>

                        <p>
                            Some cookies are extremely useful because they can
                            improve your user experience when you return to a
                            website you have already visited. This assumes that
                            you are using the same device and the same browser
                            as before; if so, cookies will remember your
                            preferences, will know how you use the website, and
                            will adapt the content you are shown so that it is
                            more relevant to your personal interests and needs.
                        </p>

                        <h2>Your cookie settings on this website.</h2>
                        <h3>
                            Cookies on this website that do not require
                            approval.
                        </h3>
                        <p>
                            Cookies that are essential, also known as ‘strictly
                            necessary’ cookies, enable features without which
                            you would not be able to use the website as
                            intended. These cookies are used exclusively by BMW
                            and are therefore known as first-party cookies. They
                            are only saved on your computer while you are
                            actually browsing the website. An example of why
                            strictly necessary cookies are used is so that when
                            you open the car configurator, you receive a version
                            of it which corresponds to the data volume which
                            your current Internet connection can handle.
                        </p>

                        <p>
                            Another example of what these cookies do is
                            facilitate a switch from http to https when you
                            change pages, so that the security of data
                            transmitted is maintained. Furthermore, a cookie of
                            this kind is used to store your decision about the
                            use of cookies on our website. Your consent is not
                            required for the use of strictly necessary cookies.{" "}
                        </p>

                        <p>
                            Strictly necessary cookies cannot be disabled using
                            the features of this website.
                        </p>

                        <h2>Categories of cookies.</h2>
                        <p>
                            Based on what function cookies have and the purpose
                            for which cookies are used, there are four
                            categories of cookie: strictly necessary cookies,
                            performance cookies, functional cookies and
                            marketing cookies.
                        </p>

                        <h3>Strictly necessary cookies</h3>
                        <p>
                            Are essential in order to enable you to move around
                            the website and use its features. Without these
                            cookies, some services cannot be provided – for
                            example, remembering previous actions (e.g. entered
                            text and vehicle configurations) when navigating
                            back to a page in the same session.
                        </p>

                        <h3>Performance cookies</h3>
                        <p>
                            Gather information about how a website is used – for
                            example, which pages a visitor opens most often, and
                            whether the user receives error messages from some
                            pages. These cookies do not save information that
                            would allow the user to be identified. The collected
                            information is aggregated, and therefore anonymous.
                            These cookies are used exclusively to improve the
                            performance of the website, and with it the user
                            experience.
                        </p>

                        <h3>Functional cookies</h3>
                        <p>
                            Enable a website to save information which has
                            already been entered (such as user names, languages
                            choices, and your location), so that it can offer
                            you improved and more personalised functions. For
                            example, a website can offer your local,
                            price-relevant tax information if it uses a cookie
                            to remember the region in which you are currently
                            located. Functional cookies are also used to enable
                            features you request such as playing videos. These
                            cookies collect anonymous information and cannot
                            track your movements on other websites.
                        </p>

                        <h3>Marketing cookies</h3>
                        <p>
                            Are used to deliver adverts and other communications
                            more relevant to you and your interests. They are
                            also used to limit the number of times you see an
                            advertisement and to help measure the effectiveness
                            of advertising campaigns. They remember whether you
                            have visited a website or not, and this information
                            can be shared with other organisations such as
                            advertisers (this includes advertising technologies
                            on websites such as Facebook, LinkedIn and Twitter).
                            Cookies for improving group targeting and
                            advertising will often be linked to site
                            functionality provided by other organisations.
                        </p>
                        <h2>Your cookie settings on this website.</h2>
                        <p>
                            You can completely disable cookies in your browser
                            at any time.
                        </p>

                        <p>
                            It is important to note that if you change your
                            settings and block certain cookies, you will not be
                            able to take full advantage of some features of our
                            site, and we might not be able to provide some
                            features you have previously chosen to use.
                        </p>
                    </Column>
                </Wrapper>
            </div>
        );
    }
}

export default Cookies;
