export const colors = {
    primary: "#1C69D4",
    secondary: "#82aac7",
    black: "#171e26",
    white: "#ffffff",
    lightGray: "#EEEEED",
    mediumGray: "#b7b7b7",
    darkGray: "#888889",
    altGray: "#797979",
    alert: "#FF0000",
    warning: "#ff9800",
    success: "#1d933f",
    altSuccess: "#1AE500",
    info: "#00bcd4",
    background: "#ffffff",
    platinum: "#a2b8e4",
    gold: "#d59900",
    silver: "#a9a9a9",
    bronze: "#ff8604",
    highlight: "#e6c2c1"
};

export const sizes = {
    small: "1.5",
    default: "2",
    large: "3"
};

export const spacing = {
    radius: 0,
    padding: 1.2,
    margin: 1.2
};

export const breakpoints = {
    small: 0,
    medium: 350,
    large: 700,
    xlarge: 1050
};

export const app = {
    quickLinkWidth: 60,
    headerHeight: "62px"
};
const fonts = `
    @font-face {
        font-family: 'bmw_groupbold';
        src: url('/fonts/bmwgroup_bold-webfont.woff2') format('woff2'),
            url('/fonts/bmwgroup_bold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }

    @font-face {
        font-family: 'bmw_group_condensedbold';
        src: url('/fonts/bmwgroup_cond_bold-webfont.woff2') format('woff2'),
            url('/fonts/bmwgroup_cond_bold-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }

    @font-face {
        font-family: 'bmw_group_condensedregular';
        src: url('/fonts/bmwgroup_cond_regular-webfont.woff2') format('woff2'),
            url('/fonts/bmwgroup_cond_regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }

    @font-face {
        font-family: 'bmw_grouplight';
        src: url('/fonts/bmwgroup_light-webfont.woff2') format('woff2'),
            url('/fonts/bmwgroup_light-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }

    @font-face {
        font-family: 'bmw_groupregular';
        src: url('/fonts/bmwgroup_regular-webfont.woff2') format('woff2'),
            url('/fonts/bmwgroup_regular-webfont.woff') format('woff');
        font-weight: normal;
        font-style: normal;

    }
`;

export const theme = {
    app: app,
    colors: colors,
    breakpoints: breakpoints,
    spacing: spacing,
    fonts: fonts,
    body: {
        color: colors.black,
        background: colors.background,
        fontFamily: "'bmw_groupregular','Arial', sans-serif",
        fontSize: 14,
        fontBold: "'bmw_groupbold','bmw_groupregular','Arial', sans-serif"
    },
    badge: {
        color: colors.white
    },
    header: {
        color: colors.black,
        background: colors.white,
        logoMargin: spacing.margin / 2 + "em 0"
    },
    anchor: {
        color: colors.black
    },
    navigation: {
        background: colors.black,
        boxShadow: "none",
        navLink: {
            margin: 0,
            color: colors.black,
            disabledColor: colors.darkGray,
            activeColor: colors.secondary,
            padding: 1,
            textTransform: "uppercase",
            fontWeight: "normal",
            activeFontWeight: "bold"
        }
    },
    typography: {
        margin: spacing.margin
    },
    checklist: {
        margin: spacing.margin,
        border: "2px solid rgba(255, 255, 255, 0.10)"
    },
    table: {
        margin: spacing.margin,
        padding: "0.5",
        rowHover: "#545963",
        rowHighlight: colors.highlight,
        thBackground: colors.lightGray,
        tdLineColor: colors.mediumGray
    },
    button: {
        fontSize: 13,
        borderWidth: 1,
        borderRadius: 1,
        fontWeight: "bold",
        textTransform: "none",
        types: {
            default: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.primary
            },
            secondary: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.secondary
            },
            gray: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.altGray
            },
            alert: {
                hollow: false,
                foregroundColor: colors.white,
                backgroundColor: colors.alert
            }
        }
    },
    row: {
        center: false
    },
    column: {
        columns: 12,
        padding: spacing.padding,
        breakpoints: {
            small: 0,
            medium: 350,
            large: 700,
            xlarge: 1050
        }
    },
    panel: {
        default: "lightGray",
        headingBackground: "rgba(0, 0, 0, 0.05)",
        headingColor: colors.black,
        radius: 0,
        boxShadow: `${colors.mediumGray} 0px 2px 6px`
    },
    icon: {
        colors: colors
    },
    input: {
        sizes: sizes,
        borderColor: colors.darkGray,
        radius: 3,
        background: colors.white,
        color: colors.black,
        focusColor: colors.primary,
        iconColor: colors.darkGray,
        iconBackground: colors.lightGray,
        iconScale: 0.4
    },
    modal: {
        padding: spacing.padding,
        margin: spacing.margin,
        radius: spacing.radius,
        background: colors.white,
        closeButtonColor: colors.black,
        color: colors.black
    },
    tooltip: {
        color: colors.white,
        background: colors.primary,
        padding: spacing.padding,
        margin: spacing.margin,
        radius: spacing.radius
    },
    link: {
        color: colors.darkGray,
        colors: colors,
        fontWeight: "bold",
        textDecoration: "none"
    },
    dashboard: {
        panel: {
            bar: {
                iconSize: "1.2",
                iconColor: colors.black,
                padding: "0.5",
                background: "rgba(0, 0, 0, 0.05)",
                fontWeight: "bold",
                fontSize: "0.9",
                titleColor: colors.black
            },
            content: {
                background: colors.lightGray
            },
            wrapper: {
                radius: 0,
                boxShadow: `${colors.mediumGray} 0px 2px 6px`
            },
            resize: {}
        }
    },

    userInfo: {
        spacingX: 0.4,
        spacingY: 0.3,
        padding: spacing.padding,
        height: 5.8,
        icon: {
            size: 0.8,
            color: colors.white
        },
        notification: {
            size: 0.7,
            top: -0.5,
            right: -0.5,
            minWidth: 1,
            color: colors.white,
            backgroundColor: colors.primary
        },
        title: {
            size: 0.8,
            color: colors.white
        },
        text: {
            size: 0.6,
            color: colors.white
        },
        backgroundColor: colors.primary,
        backgroundColorTwo: colors.primary
    },
    menu: {
        color: colors.primary,
        background: colors.background,
        hoverBackground: colors.mediumGray,
        borderBottom: "1px solid " + colors.accent,
        padding: 0.8
    },
    carousel: {
        item: {
            padding: spacing.padding,
            background: "transparent",
            color: "inherit"
        },
        arrows: {
            size: 1.2,
            color: colors.mediumGray
        },
        dots: {
            background: colors.black,
            activeBackground: colors.primary
        }
    },
    dropzone: {
        default: "primary",
        types: {
            primary: {
                radius: 0,
                padding: spacing.padding,
                margin: spacing.margin,
                background: colors.secondary,
                border: "none",
                color: colors.mediumGray
            }
        }
    },
    tabordion: {
        padding: spacing.padding,
        borderColor: colors.darkGray,
        content: {
            color: colors.white
        },
        default: {
            color: colors.black,
            background: colors.darkGray
        },
        active: {
            color: colors.black,
            background: colors.darkGray
        },
        dividers: {
            height: 2.5,
            padding: 0.7,
            fontSize: 0.4,
            fontWeight: "bold",
            color: colors.black,
            background: colors.black,
            border: "1px solid " + colors.black,
            radius: 2
        }
    },
    steps: {
        barHeight: 15,
        spacing: -2,
        circleDiameter: 30,
        progressColor: colors.primary,
        backgroundColor: colors.black,
        borderColor: colors.mediumGray,
        borderRadius: 100,
        labelColor: "inherit",
        margin: spacing.margin,
        border: 1,
        padding: 2
    },
    footer: {
        color: colors.white,
        background: colors.black
    },
    progress: {
        borderColor: colors.secondary,
        radius: 3,
        background: colors.black,
        barColor: colors.secondary,
        textColor: colors.mediumGray,
        textColorAlt: colors.mediumGray
    },
    circularProgress: {
        trailColor: colors.black,
        strokeColor: colors.primary,
        trailWidth: 10,
        strokeWidth: 10,
        strokeLinecap: "butt",
        textColor: colors.black
    },
    select: {
        background: colors.lightGray,
        color: colors.black,
        highlighted: colors.highlight,
        hover: colors.mediumGray
    },
    checkbox: {
        color: colors.black,
        background: colors.white,
        stroke: colors.darkGray,
        radius: 0
    }
};
