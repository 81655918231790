import {
    LOAD_BADGE_LEVELS,
    LOAD_BADGE_TRACKS,
    RESET_BADGE_TRACKS,
    UPDATE_BADGE,
    UPDATE_TRACK
} from "./badgeActions";

const initialTracks = {
    loaded: false,
    data: []
};

const initialState = {
    tracks: initialTracks,
    levels: {
        loaded: false,
        data: []
    }
};

export default function badge(state = initialState, action) {
    switch (action.type) {
        case LOAD_BADGE_TRACKS:
            return {
                ...state,
                tracks: { loaded: true, data: action.data }
            };

        case LOAD_BADGE_LEVELS:
            return {
                ...state,
                levels: { loaded: true, data: action.data }
            };

        case RESET_BADGE_TRACKS:
            return {
                ...state,
                tracks: initialTracks
            };

        case UPDATE_BADGE:
            return state;

        case UPDATE_TRACK:
            return state;

        default:
            return state;
    }
}
