import React, { Component } from "react";
import styled from "styled-components";
import { StyledOverlay } from './videoCompetitionComponents';


class Video extends Component {
    constructor(props) {
        super(props);
        this.videoRef = React.createRef();
    }

    state = {
        hover: false,
        play: false
    };

    isHovered = e => {
        this.setState({ hover: true });
    };

    isNotHovered = e => {
        this.setState({ hover: false });
    };

    togglePlayback = () => {
        this.setState({ play: !this.state.play }, () => {
            this.state.play ? this.play() : this.pause();
        });
    };

    play = () => {
        this.videoRef.current.play();
    };

    pause = () => {
        this.videoRef.current.pause();
    };

    render() {
		  //const retailer = "Marshalls Worthing";
		  const { retailer } = this.props;
		  
        return (
            <div
                className={this.props.className}
                onClick={this.togglePlayback}
                onMouseLeave={this.isNotHovered}
                onMouseEnter={this.isHovered}
            >
                <video
                    ref={this.videoRef}
                    className="video-container video-container-overlay"
                    loop
                    muted
                    data-reactid=".0.1.0.0"
                >
                    {this.props.children}
                </video>
                
            </div>
        );
    }
}


const StyledVideo = styled(Video)`
    max-width: 1400px;
    display: block;
    text-align: center;
    padding: 0;
    position: relative;

    video {
        width: 100%;
        max-width: 800px;
        height: 100%;
        max-height: 550px;
        background-color: darkGrey;
    }
`;

export default StyledVideo;
