import React from "react";
import moment from "moment";
import { CompetitionThumbnail, MainHeading, SubHeading } from "./Competitions";

const CompetitionDetail = ({ competition }) => {
    return (
        <div>
            <MainHeading>{competition.name}</MainHeading>
            <p>
                {moment(competition.start_date).format("DD/MM/YYYY")} -{" "}
                {moment(competition.end_date).format("DD/MM/YYYY")}
            </p>
            {competition.thumbnail && (
                <CompetitionThumbnail
                    src={competition.thumbnail}
                    alt={competition.name}
                />
            )}
            <p>{competition.description}</p> <SubHeading>Role(s)</SubHeading>
            <p>
                {competition.roles.map((role, index) => {
                    const isLast = index === competition.roles.length - 1;
                    return isLast
                        ? role.name
                        : index === competition.roles.length - 2
                        ? role.name + " and "
                        : role.name + ", ";
                })}
            </p>
            <SubHeading>Prizes</SubHeading>
            <ul>
                {competition.prizes.map((prize) => {
                    return (
                        <li key={prize.uuid}>
                            {prize.quantity} x {prize.name}
                        </li>
                    );
                })}
            </ul>
            <SubHeading>Winners</SubHeading>
            {competition.winning_entries.length === 0 ? (
                <p>No winners yet</p>
            ) : (
                <ul>
                    {competition.winning_entries
                        .sort((a, b) => {
                            if (!a.prize || !b.prize) return 0;
                            const prizeA = a.prize.toUpperCase(); // ignore upper and lowercase
                            const prizeB = b.prize.toUpperCase(); // ignore upper and lowercase
                            if (prizeA < prizeB) {
                                return -1;
                            }
                            if (prizeA > prizeB) {
                                return 1;
                            }

                            // names must be equal
                            return 0;
                        })
                        .map((winner) => {
                            if (!winner.name || !winner.prize) {
                                return null;
                            }
                            return (
                                <li key={winner.uuid}>
                                    <strong>{winner.name}</strong> -{" "}
                                    {winner.prize}
                                </li>
                            );
                        })}
                </ul>
            )}
        </div>
    );
};

export default CompetitionDetail;
