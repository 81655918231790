import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { Row, Column } from "@cortexglobal/rla-components";
import uuid from "uuid/v4";
import {
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    FormikDatePicker,
    FormikTextarea,
    InputError
} from "@cortexglobal/rla-components";

const BadgeSchema = Yup.object().shape({
    uuid: Yup.string(),
    metric_id: Yup.string(),
    name: Yup.string().required("Required"),
    alias: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    from: Yup.string().nullable(),
    to: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    period: Yup.string().nullable()
});

class TrackForm extends Component {
    state = { submitting: false };

    submitForm = (values, { setErrors }) => {
        console.log("form submission", values);
        // return false;
        this.setState(state => ({
            submitting: true
        }));

        this.props
            .updateTrack(values)
            .then(data => {
                this.setState(state => ({
                    submitting: false
                }));
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    this.setState(state => ({
                        submitting: false
                    }));
                })
            );
    };

    render() {
        let { track } = this.props;
        const { submitting } = this.state;
        return (
            <React.Fragment>
                <Row collapse>
                    <Column collapse>
                        <Formik
                            initialValues={{
                                uuid: track ? track.uuid : uuid(),
                                metric_id: track ? track.metric_id : "",
                                name: track ? track.name : "",
                                alias: track ? track.alias : "",
                                description: track ? track.description : "",
                                from: track ? track.from : "",
                                to: track ? track.to : "",
                                icon: track ? track.icon : "",
                                period: track ? track.period : ""
                            }}
                            onSubmit={this.submitForm}
                            validationSchema={BadgeSchema}
                            className="form-group"
                        >
                            {({ values, errors }) => (
                                <Form className="form-group">
                                    <Row>
                                        <Column large={6}>
                                            <FormikInput
                                                type="hidden"
                                                name="uuid"
                                                value={values.uuid}
                                            />
                                            <FormikInput
                                                type="text"
                                                name="name"
                                                label="Name"
                                                placeholder=""
                                                value={values.name}
                                                labelAlign="right"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="alias"
                                                label="Alias"
                                                placeholder=""
                                                value={values.alias}
                                                labelAlign="right"
                                            />
                                            <FormikTextarea
                                                type="text"
                                                name="description"
                                                label="Description"
                                                placeholder=""
                                                value={values.description}
                                                labelAlign="right"
                                            />
                                        </Column>
                                        <Column large={6}>
                                            <FormikInput
                                                type="text"
                                                name="metric_id"
                                                label="Metric"
                                                placeholder=""
                                                value={values.metric_id}
                                                labelAlign="right"
                                            />
                                            <FormikDatePicker
                                                type="text"
                                                name="from"
                                                label="From"
                                                placeholder=""
                                                value={values.from}
                                                labelAlign="right"
                                            />
                                            <FormikDatePicker
                                                type="text"
                                                name="to"
                                                label="To"
                                                placeholder=""
                                                value={values.to}
                                                labelAlign="right"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="icon"
                                                label="Icon"
                                                placeholder=""
                                                value={values.icon}
                                                labelAlign="right"
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="period"
                                                label="Period"
                                                placeholder=""
                                                value={values.period}
                                                labelAlign="right"
                                            />
                                        </Column>
                                    </Row>
                                    <Row collapse>
                                        <Column collapse>
                                            <SubmitButton
                                                expanded
                                                type="primary"
                                                label="Update / Save"
                                                height={40}
                                                submitting={submitting}
                                                disabled={submitting}
                                            />
                                        </Column>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default TrackForm;
