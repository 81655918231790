import React, { Component } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { Row, Column } from "@cortexglobal/rla-components";
import uuid from "uuid/v4";

import {
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    FormikTextarea,
    InputError
} from "@cortexglobal/rla-components";

const BadgeSchema = Yup.object().shape({
    uuid: Yup.string(),
    badge_track_id: Yup.number(),
    badge_level_id: Yup.number(),
    name: Yup.string().required("Required"),
    description: Yup.string().required("Required"),
    target: Yup.string().nullable(),
    icon: Yup.string().nullable(),
    custom_targets: Yup.string().nullable(),
    rules_class: Yup.string().nullable(),
    one_off: Yup.string().nullable()
});

class BadgeForm extends Component {
    state = { submitting: false };

    submitForm = (values, { setErrors }) => {
        console.log(values);
        // return false;
        this.setState(state => ({
            submitting: true
        }));

        this.props
            .updateBadge(values)
            .then(data => {
                this.setState(state => ({
                    submitting: false
                }));
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    this.setState(state => ({
                        submitting: false
                    }));
                })
            );
    };

    render() {
        let { badge } = this.props;
        const { submitting } = this.state;
        return (
            <React.Fragment>
                <Row collapse>
                    <Column collapse>
                        <Formik
                            initialValues={{
                                uuid: badge.uuid ? badge.uuid : uuid(),
                                badge_track_id: badge.badge_track_id
                                    ? badge.badge_track_id
                                    : 0,
                                badge_level_id: badge.badge_level_id
                                    ? badge.badge_level_id
                                    : 0,
                                name: badge.name ? badge.name : "",
                                description: badge.description
                                    ? badge.description
                                    : "",
                                target: badge.target ? badge.target : "",
                                icon: badge.icon ? badge.icon : "",
                                custom_targets: badge.custom_targets
                                    ? badge.custom_targets
                                    : "",
                                rules_class: badge.rules_class
                                    ? badge.rules_class
                                    : "",
                                one_off: badge.one_off ? badge.one_off : ""
                            }}
                            onSubmit={this.submitForm}
                            validationSchema={BadgeSchema}
                            className="form-group"
                        >
                            {({ values, errors }) => (
                                <Form className="form-group">
                                    <Row>
                                        <Column large={6}>
                                            <FormikInput
                                                type="hidden"
                                                name="uuid"
                                                value={values.uuid}
                                            />
                                            <FormikInput
                                                type="hidden"
                                                name="badge_track_id"
                                                value={values.badge_track_id}
                                            />
                                            <FormikInput
                                                type="hidden"
                                                name="badge_level_id"
                                                value={values.badge_level_id}
                                            />
                                            <FormikInput
                                                type="text"
                                                name="name"
                                                label="Name"
                                                placeholder=""
                                                value={values.name}
                                                labelAlign="right"
                                            />
                                            <FormikTextarea
                                                type="text"
                                                name="description"
                                                label="Description"
                                                placeholder=""
                                                value={values.description}
                                                labelAlign="right"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="icon"
                                                label="Icon"
                                                placeholder=""
                                                value={values.icon}
                                                labelAlign="right"
                                            />
                                        </Column>
                                        <Column large={6}>
                                            <FormikInput
                                                type="text"
                                                name="rules_class"
                                                label="Rules Class"
                                                placeholder=""
                                                value={values.rules_class}
                                                labelAlign="right"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="target"
                                                label="Target"
                                                placeholder=""
                                                value={values.target}
                                                labelAlign="right"
                                            />
                                            <FormikInput
                                                type="text"
                                                name="custom_targets"
                                                label="Custom Targets"
                                                placeholder=""
                                                value={values.custom_targets}
                                                labelAlign="right"
                                            />
                                            <FormikCheckbox
                                                type="text"
                                                name="one_off"
                                                label="One Off"
                                                placeholder=""
                                                value={values.one_off}
                                                labelAlign="right"
                                            />
                                        </Column>
                                    </Row>
                                    <Row collapse>
                                        <Column collapse>
                                            <SubmitButton
                                                expanded
                                                type="primary"
                                                label="Update / Save"
                                                height={40}
                                                submitting={submitting}
                                                disabled={submitting}
                                            />
                                        </Column>
                                    </Row>
                                </Form>
                            )}
                        </Formik>
                    </Column>
                </Row>
            </React.Fragment>
        );
    }
}

export default BadgeForm;
