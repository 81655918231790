import axios from "axios";

export const REDEMPTIONS_LOADING = "REDEMPTIONS_LOADING";
export const LOAD_REDEMPTIONS = "LOAD_REDEMPTIONS";
export const getRedemptions = () => {
    return (dispatch, getState) => {
        // if (getState().staff.loading) {
        //     return;
        // }
        if (!getState().redemptions.loaded) {
            dispatch({ type: REDEMPTIONS_LOADING, data: true });
        }

        axios
            .get(`/api/v1/manager/redemptions`)
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_REDEMPTIONS,
                    data: data
                });
                dispatch({ type: REDEMPTIONS_LOADING, data: false });
            })
            .catch(e => e);
    };
};
