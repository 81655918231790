import axios from "axios";
import { SET_AUTH_USER_DETAILS } from "@cortexglobal/cortex-auth-redux";
import { setItem } from "@cortexglobal/cortex-utilities";
export const updateUser = values => {
    return dispatch => {
        return axios
            .put(`/api/v1/user`, values)
            .then(response => {
                const { data } = response.data;
                setItem("user", data);

                dispatch({
                    type: SET_AUTH_USER_DETAILS,
                    user: data
                });
            })
            .catch(e => e);
    };
};
