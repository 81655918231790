import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const StyledHeader = styled.th`
    cursor: pointer;
    padding: 1rem 0;
    color: #9f9f9f;
    text-align: ${props => {
        return props.align ? props.align : "center";
    }};
    vertical-align: ${props => {
	return props.valign ? props.valign : "middle";
    }};
`;

class SortableHeader extends React.Component {
    showSortIcon = () => {
        const {
            property,
            sortSettings: { sortBy, sortDirection }
        } = this.props;
        if (property !== sortBy) {
            return <FontAwesomeIcon icon="sort" />;
        }
        if (sortDirection === "asc") {
            return <FontAwesomeIcon icon="sort-down" />;
        } else {
            return <FontAwesomeIcon icon="sort-up" />;
        }
    };
    sortData = () => {
        this.props.sortFunction(this.props.property);
    };
    render() {
        const { children, align, valign } = this.props;
        //console.log(sorting);
        return (
            <StyledHeader onClick={this.sortData} align={align} valign={valign}>
                {children} {this.showSortIcon()}
            </StyledHeader>
        );
    }
}

SortableHeader.propTypes = {
    sortFunction: PropTypes.func,
    property: PropTypes.string,
    sortSettings: PropTypes.shape({
        sortBy: PropTypes.string,
        sortDirection: PropTypes.oneOf(["", "asc", "desc"])
    })
};
SortableHeader.defaultProps = {
    sortSettings: { sortBy: "", sortDirection: "" }
};
export default SortableHeader;
