import React, { Component } from "react";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";

class BadgeTile extends Component {
    showModal = (modalKey, item) => {
        this.props.showModal(modalKey, item);
    };

    render() {
        let { track, level } = this.props;
        let component = track.badges.map(badge => {
            if (badge.level_name === level.name) {
                return (
                    <td
                        key={track.uuid + "-" + level.alias}
                        style={{
                            textAlign: "center"
                        }}
                    >
                        <div
                            style={{
                                margin: "auto",
                                width: "130px",
                                height: "80px",
                                textAlign: "center",
                                border: "2px solid",
                                padding: "3px",
                                borderColor: level.color,
                                background: "white"
                            }}
                        >
                            <div
                                style={{
                                    float: "right",
                                    color: level.color,
                                    cursor: "pointer"
                                }}
                                onClick={this.showModal.bind(
                                    this,
                                    "badgeForm",
                                    badge
                                )}
                            >
                                <FontAwesomeIcon icon="cog" />
                            </div>
                            <div style={{ fontWeight: "bold" }}>
                                {badge.name}
                            </div>
                            <div style={{ fontSize: "8pt" }}>
                                {badge.description}
                            </div>
                        </div>
                    </td>
                );
            }
        });

        let filtered = component.filter(function(el) {
            return el != null;
        });

        if (filtered[0]) {
            return component;
        } else {
            return (
                <td key={track.uuid + "-" + level.alias}>
                    <div
                        style={{
                            margin: "auto",
                            width: "130px",
                            height: "60px",
                            fontSize: "8pt",
                            cursor: "pointer",
                            padding: "20px",
                            textAlign: "center"
                        }}
                        onClick={this.showModal.bind(this, "badgeForm", {
                            badge_track_id: track.id,
                            badge_level_id: level.id
                        })}
                    >
                        <FontAwesomeIcon icon="plus" /> Add Badge
                    </div>
                </td>
            );
        }
    }
}

export default BadgeTile;
