import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Route, Switch } from "react-router-dom";

import {
    VideoCompetition,
    VideoCompetitionWinners,
} from "@cortexglobal/bmw-shared-components";

import Header from "./PrivateHeader";
import UserSummary from "../../components/global/UserSummary";
import HeadOfficeQuickLinks from "./HeadOfficeQuickLinks";
import Footer from "./Footer";
import { AppContainer, SummaryContainer } from "./AppContainers";
import { Loading } from "@cortexglobal/rla-components";

import Dashboard from "../../modules/headOffice/home/Dashboard";
import Profile from "../../modules/profile/Profile";
import EditProfile from "../../modules/profile/EditProfile";
import Contact from "../../modules/contact/Contact";

import AppContext from "../../context/AppContext";
import {
    getRetailers,
    getRetailersMonthlySummary,
} from "../../components/retailer-information/retailerActions";
import {
    getCurrentTechnicianSummary,
    getCurrentApprenticeSummary,
    getCurrentServiceAdvisorSummary,
    getCurrentSalesExecutiveSummary,
} from "../../components/technician-summary/technicianActions";
import RetailerHome from "../../modules/headOffice/home/RetailerHome";
import SentNotifications from "../notifications/SentNotifications";
import Leagues from "../../modules/headOffice/leagues/Leagues";
import moment from "moment";

const now = moment();
const videoCompetitionClose = moment("2020-04-01", "YYYY-MM-DD");

class PrivateHeadOfficeApp extends Component {
    state = {
        mobileView: false,
        windowWidth: 0,
    };

    throttledHandleWindowResize = () => {
        // console.log(window.innerWidth);
        this.setState({
            mobileView: window.innerWidth < 750,
            windowWidth: window.innerWidth,
        });
        // return throttle(() => {
        //     this.setState({
        //     mobileView: window.innerWidth < 750,
        //     windowWidth: window.innerWidth
        // });
        // }, 200);
    };

    componentDidMount() {
        if (!this.props.summaries.loaded) {
            this.props.getRetailers();
        }
        if (!this.props.monthly.loaded) {
            this.props.getRetailersMonthlySummary();
        }
        if (!this.props.technicians.loaded) {
            this.props.getCurrentTechnicianSummary();
        }
        if (!this.props.apprentices.loaded) {
            this.props.getCurrentApprenticeSummary();
        }
        if (!this.props.serviceAdvisors.loaded) {
            this.props.getCurrentServiceAdvisorSummary();
        }
        if (!this.props.salesExecutives.loaded) {
            this.props.getCurrentSalesExecutiveSummary();
        }
        this.throttledHandleWindowResize();
        window.addEventListener("resize", this.throttledHandleWindowResize);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.throttledHandleWindowResize);
    }

    render() {
        const {
            user,
            group,
            monthly,
            summaries,
            loading,
            technicians,
            apprentices,
            serviceAdvisors,
            salesExecutives,
        } = this.props;
        const { mobileView, windowWidth } = this.state;
        return (
            <AppContext.Provider
                value={{
                    user,
                    group,
                    technicians,
                    apprentices,
                    serviceAdvisors,
                    salesExecutives,
                    retailers: summaries,
                    monthlyRetailersSummary: monthly,
                    loading,
                    mobileView,
                    windowWidth,
                }}
            >
                <Header />
                {user.loaded ? (
                    <React.Fragment>
                        <SummaryContainer>
                            <UserSummary user={user} group={group} banner />
                        </SummaryContainer>
                        <AppContainer>
                            <Switch>
                                <Route exact path="/" component={Dashboard} />
                                <Route
                                    exact
                                    path="/retailers"
                                    component={RetailerHome}
                                />
                                <Route exact path="/" component={Dashboard} />
                                <Route path="/leagues" component={Leagues} />

                                <Route
                                    exact
                                    path="/profile"
                                    component={Profile}
                                />
                                <Route
                                    exact
                                    path="/edit-profile"
                                    component={EditProfile}
                                />
                                <Route
                                    exact
                                    path="/contact"
                                    component={Contact}
                                />
                                <Route
                                    exact
                                    path="/notifications"
                                    component={SentNotifications}
                                />
                                {now.isBefore(videoCompetitionClose) && (
                                    <Route
                                        exact
                                        path="/vehicle-health-check-competition-winners"
                                        component={VideoCompetitionWinners}
                                    />
                                )}
                                {now.isBefore(videoCompetitionClose) && (
                                    <Route
                                        exact
                                        path="/vehicle-health-check-competition"
                                        render={() => (
                                            <VideoCompetition
                                                hideSummary={true}
                                            />
                                        )}
                                    />
                                )}
                            </Switch>
                        </AppContainer>
                        <HeadOfficeQuickLinks />
                    </React.Fragment>
                ) : (
                    <Loading align="center" />
                )}
                <Footer />
            </AppContext.Provider>
        );
    }
}
const mapStateToProps = (state) => {
    const { user, group } = state.auth;
    const { summaries, monthly } = state.retailer;
    const { technicians, apprentices, serviceAdvisors, salesExecutives } = state.technicians;

    return {
        user,
        group,
        summaries,
        monthly,
        technicians,
        apprentices,
        serviceAdvisors,
        salesExecutives,
    };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRetailers,
            getRetailersMonthlySummary,
            getCurrentTechnicianSummary,
            getCurrentApprenticeSummary,
            getCurrentServiceAdvisorSummary,
            getCurrentSalesExecutiveSummary,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateHeadOfficeApp);
