import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Hero, Wrapper } from "./sharedComponents";

class Terms extends Component {
    render() {
        return (
            <div className="page page-home">
                <Hero />
                <Wrapper>
                    <Column medium={12}>
                        <PageTitle
                            title="BMW & MINI Elite"
                            subtitle="Technician Loyalty Programme T&amp;Cs"
                        />

                        <ol>
                            <li>
                                The BMW & MINI Ireland Technician Rewards
                                Programme described below (the ‘Programme’) is
                                only applicable to the network of authorised
                                technicians and/or maintenance technicians
                                (‘Technicians’) operated by BMW Automotive
                                (Ireland) Limited (‘BMW Ireland’).
                            </li>
                            <li>
                                This Programme is meant for all current
                                Technicians and for all Technicians about to be
                                employed by BMW or MINI authorised distributors
                                (‘Retailers’).
                            </li>
                            <li>
                                The rewards and prizes for this Programme
                                consist of vouchers or at BMW Ireland’s
                                discretion, another form of non-cash prize (the
                                ‘Rewards’).
                            </li>
                            <li>
                                The Programme’s portal may host leagues,
                                challenges, games, trips and spot prizes in
                                which Technicians may enter or compete from time
                                to time.
                            </li>
                            <li>
                                In order to be eligible for the Programme,
                                Technicians must have passed all compulsory BMW
                                training and must be correctly signed up on TMSi
                                and assigned an eligible Market Function Code.
                            </li>
                            <li>
                                All Technicians employed by Retailers prior to
                                01/03/2020 is each deemed an ‘Existing
                                Technician’. Those employed by Retailers on or
                                after that date is each deemed a ‘New
                                Technician’.
                            </li>
                            <li>
                                Existing Technicians will be rewarded with
                                &pound;1,000 worth of Amazon e-vouchers every
                                December on the date agreed by BMW (UK) Limited.
                            </li>
                            <li>
                                New Technicians will be rewarded with &pound;500
                                worth of Amazon e-vouchers following the
                                successful completion of their 3-month probation
                                and a further &pound;500 worth of Amazon
                                e-vouchers on completing 12-months service.
                                After completion of 12 months service, new
                                Technicians will be rewarded &pound;1,000 worth
                                of Amazon e-vouchers every year on completing
                                another 12-months service. The employing
                                Retailer has the sole discretion to extend this
                                probation period where necessary and only reward
                                the new Technician on the successful completion
                                of this extended probation period.
                            </li>
                            <li>
                                In order to be eligible for the above rewards
                                (points 7 & 8), Technicians must be enrolled to
                                the Programme prior to the date of their first
                                reward due date. This includes the date set in
                                December by BMW Ireland & the anniversary of
                                start date for new starters.
                            </li>
                            <li>
                                Upon entry into this initiative, all
                                participants are deemed to have accepted and
                                agreed to be bound by the terms and conditions
                                outlined in this document and any additional
                                terms and conditions detailed in the relevant
                                parts of this website or programme
                                communications, describing the programme, of
                                which the entry instructions form part. Any
                                infringement of such terms is a violation of the
                                Programme and may result in Rewards being
                                forfeited.
                            </li>
                            <li>
                                Each Technician must have a valid login and
                                profile on the portal of the Programme in order
                                to qualify for Rewards.
                            </li>
                            <li>
                                Each Technician, enrolled on the Programme is
                                representing their respective Retailer and the
                                BMW & MINI Brands, and as such must conduct
                                themselves in a professional manner at all
                                times.
                            </li>
                            <li>
                                Any potential winners of Rewards, trips or
                                experiences must be 18 years of age or older.
                            </li>
                            <li>
                                By participating in the Programme and related
                                initiatives, all entrants agree to participate
                                in any publicity, arising from a Reward.
                            </li>
                            <li>
                                Any reward received under this programme may be
                                subject to tax which shall be the primary
                                responsibility of the recipient. Without
                                prejudice to the foregoing and only should a tax
                                liability arise, BMW Ireland will pay to the
                                Retailer the then basic rate income tax
                                liability (currently 20% as at 01.01.2020) in
                                respect of all reward made to Technician in this
                                Programme and the Retailer is responsible for
                                discharging this amount. The recipient is
                                responsible for any USC, PRSI or other social
                                security costs and higher income tax ratepayers
                                are responsible for additional income tax
                                liabilities arising. BMW Ireland Limited will
                                provide upon request confirmation of the amounts
                                paid to the participant’s Retailer. BMW Ireland
                                may exercise its discretion to contribute
                                further to tax liabilities for certain specified
                                challenges, games, trips, spot prizes or end of
                                year events.
                            </li>
                            <li>
                                A Technician must be employed by the BMW
                                Retailer when receiving any Reward.
                            </li>
                            <li>
                                If a Technician’s contract is terminated or
                                notice of termination is served, he/she will no
                                longer be part of the Programme nor entitled to
                                any Rewards.
                            </li>
                            <li>
                                If a Technician serves notice of resignation BMW
                                Ireland reserves the right to disqualify a
                                Technician from the Technician Rewards
                                Programme.
                            </li>
                            <li>
                                BMW Ireland reserves the right to remove a
                                Technician from the Programme, the Programme’s
                                portal and/or any additional leagues,
                                challenges, games, trips and spot prizes who has
                                either infringed the terms and conditions of
                                this Programme or the spirit in which the
                                Programme is operated.
                            </li>
                            <li>
                                Families of Technicians, or other Retailer
                                employees, or other people under the age of 18
                                are ineligible to participate in the Programme.
                                Entrants must reside in the UK or Ireland and be
                                employed by the official BMW & MINI Retailers.
                            </li>
                            <li>
                                Any proposed Reward can be withdrawn from a
                                Technician who has infringed the terms,
                                conditions or spirit of this Programme.
                            </li>
                            <li>
                                BMW Ireland reserves the right in its sole
                                discretion to alter, amend or withdraw the
                                Programme in whole or in part at any time
                                without notice or compensation. BMW does not
                                need to correspond about the Rewards or the
                                Programme.
                            </li>
                            <li>
                                In the event of any dispute, the decision of BMW
                                Ireland will be final in all matters and no
                                correspondence will be entered into in this
                                regard.
                            </li>
                        </ol>
                    </Column>
                </Wrapper>
            </div>
        );
    }
}

export default Terms;
