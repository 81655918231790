import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const BadgeItemDefault = styled.span`
    background: ${props => props.color};
    border: 3px solid ${props => props.color};
    opacity: ${props => (props.achieved || props.forceFullOpacity ? 1 : 0.3)};
    border-radius: 100%;
    display: inline-block;
    margin: 0 auto;
    width: 45px;
    height: 45px;
    color: #000;
    line-height: 43px;
    position: relative;
    &:before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        background: rgba(255, 255, 255, 0.7);
        border-radius: 0 45px 45px 0;
        transform: rotate(400deg);
        transform-origin: 0;
    }
    &:after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 50%;
        background: rgba(255, 255, 255, 0.85);
        border-radius: 0 45px 45px 0;
        transform: rotate(-140deg);
        transform-origin: 0;
    }
`;

export const BadgeWrapper = styled.div`
    //background: gold;
    text-align: center;
    display: inline-block;
    position: relative;
    width: 61px;
    img {
        width: 100%;
        display: block;
    }
`;
export const BadgeName = styled.p`
    opacity: ${props => (props.achieved || props.forceFullOpacity ? 1 : 0.4)};
    margin-bottom: 0;
    font-size: 12px;
    font-family: ${props => props.theme.body.fontBold};
`;
export const BadgeDescription = styled.p`
    font-size: 8px;
    margin-top: 0.5em;
    color: #9f9f9f;
    margin-bottom: 0;
`;
export const BadgeCount = styled.div`
    position: absolute;
    right: 0px;
    top: 0px;
    border-radius: 50%;
    background-color: rgb(255, 0, 0);
    color: white;
    width: 15px;
    height: 15px;
    margin-right: 0px;
    margin-top: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
export const CountText = styled.div`
    margin-top: 2px;
    font-size: 8px;
    font-family: ${props => props.theme.body.fontBold};
`;

export const BadgeIcon = styled(FontAwesomeIcon)`
    font-size: 1rem;
    position: absolute;
    left: 11px;
    top: 11px;
    z-index: 5;
    color: #ffffff;
`;

export const BadgeImage = styled.img`
    opacity: ${props => (props.achieved || props.forceFullOpacity ? 1 : 0.4)};
`;

export const Badge = ({
    badge,
    forceFullOpacity = false,
    showIcon = true,
    showCount = true,
    ...rest
}) => {
    const achieved = badge.achievement_count > 0;
    // console.log(badge);
    return (
        <BadgeWrapper {...rest}>
            {badge.icon ? (
                <BadgeImage
                    src={badge.icon}
                    alt={badge.name}
                    achieved={achieved}
                    forceFullOpacity={forceFullOpacity}
                />
            ) : (
                <BadgeItemDefault
                    color={badge.level_color ? badge.level_color : badge.color}
                    achieved={achieved}
                    forceFullOpacity={forceFullOpacity}
                >
                    {showIcon && <BadgeIcon icon="trophy" transform="grow-3" />}
                    {/* {badge.awarded} */}
                </BadgeItemDefault>
            )}
            {achieved && showCount && (
                <BadgeCount>
                    <CountText>{badge.achievement_count}</CountText>
                </BadgeCount>
            )}
        </BadgeWrapper>
    );
};
