import React from "react";
import styled from "styled-components";
import PropsTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Modal,
    Row,
    Column,
    SubmitButton,
    Button,
    FormikDatePicker
} from "@cortexglobal/rla-components";
import {
    parseValidationErrors,
    yupFormattedDate
} from "@cortexglobal/cortex-utilities";
import {
    extendProbation,
    approveProbation
} from "../modules/manager/staff/staffActions";

const ErrorMessage = styled.p`
    padding-top: 1rem;
    color: ${props => props.theme.colors.alert};
`;
const ExtendSchema = Yup.object().shape({
    probation_date: yupFormattedDate().required("Required")
});

class UpdateProbationButton extends React.Component {
    state = { submitting: false, showingModal: "", error: "" };
    showModal = () => {
        this.setState({
            showingModal: this.props.newStatus
        });
    };
    onClose = () => {
        this.setState({
            showingModal: ""
        });
    };

    approve = () => {
        this.setState(state => ({
            submitting: true,
            error: ""
        }));
        return this.props
            .approveProbation(this.props.member.uuid)
            .then(() => this.onClose())
            .catch(e => {
                console.log(JSON.stringify(e), e.message);
                this.setState(state => ({
                    submitting: false,
                    error: e.message
                }));
                // return parseValidationErrors(e).then(({ errors }) => {
                //     console.log(errors);
                //     this.setState(state => ({
                //         submitting: false,
                //         error: errors
                //     }));
                // });
            });
    };
    extend = (values, { setErrors }) => {
        this.setState(state => ({
            submitting: true
        }));

        return this.props
            .extendProbation(this.props.member.uuid, values)
            .then(() => this.onClose())
            .catch(e => {
                console.log(e);
                return parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    this.setState(state => ({
                        submitting: false
                    }));
                });
            });
    };
    render() {
        const {
            updating,
            updatingUserUuid,
            userUuid,
            children,
            updateUserStatus,
            newStatus,
            member,
            extendProbation,
            approveProbation,
            ...rest
        } = this.props;
        const { showingModal, submitting, error } = this.state;
        //console.log("children", children);
        return (
            <React.Fragment>
                <SubmitButton
                    {...rest}
                    disabled={updating}
                    submitting={userUuid === updatingUserUuid}
                    onClick={this.showModal}
                >
                    {children}
                </SubmitButton>
                {showingModal === "approve" && (
                    <Modal
                        visible={showingModal === "approve"}
                        onClose={this.onClose}
                    >
                        <Row>
                            <Column>
                                <h1 style={{ textAlign: "center" }}>
                                    Approve Probation for {member.first_name}{" "}
                                    {member.last_name}
                                </h1>
                            </Column>
                        </Row>
                        <Row style={{ maxWidth: "66%" }} center>
                            <Column>
                                {member.reward_track === "anniversary" && (
                                    <p>
                                        This will reward your technician
                                        &pound;500.
                                    </p>
                                )}
                                <p>Are you sure you want to proceed?</p>
                                <Button
                                    onClick={this.approve}
                                    expanded
                                    type="alert"
                                >
                                    Approve
                                </Button>

                                {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Column>
                        </Row>
                    </Modal>
                )}
                {showingModal === "extend" && (
                    <Modal
                        visible={showingModal === "extend"}
                        onClose={this.onClose}
                    >
                        <Row>
                            <Column>
                                <h1 style={{ textAlign: "center" }}>
                                    Extend Probation for {member.first_name}{" "}
                                    {member.last_name}
                                </h1>

                                <Formik
                                    initialValues={{
                                        start_date: member.start_date,
                                        probation_date: member.probation_date
                                    }}
                                    onSubmit={this.extend}
                                    validationSchema={ExtendSchema}
                                    className="form-group"
                                >
                                    {({ values, errors }) => {
                                        //console.log(values, member);
                                        return (
                                            <Form className="form-group">
                                                <Row
                                                    style={{ maxWidth: "66%" }}
                                                    center
                                                >
                                                    <Column>
                                                        <input
                                                            type="hidden"
                                                            name="start_date"
                                                            value={
                                                                values.start_date
                                                            }
                                                        />

                                                        <FormikDatePicker
                                                            name="probation_date"
                                                            label="Probation Date"
                                                            openToDate={moment(
                                                                values.start_date,
                                                                "DD/MM/YYYY"
                                                            ).add(3, "months")}
                                                            value={
                                                                values.probation_date
                                                            }
                                                            minDate={moment(
                                                                values.start_date,
                                                                "DD/MM/YYYY"
                                                            ).add(3, "months")}
                                                        />
                                                    </Column>

                                                    <Column>
                                                        <SubmitButton
                                                            expanded
                                                            label="Extend"
                                                            submitting={
                                                                submitting
                                                            }
                                                            disabled={
                                                                submitting
                                                            }
                                                        />
                                                    </Column>
                                                </Row>
                                            </Form>
                                        );
                                    }}
                                </Formik>
                            </Column>
                        </Row>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { updating, updatingUserUuid } = state.staff;
    return { updating, updatingUserUuid };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            extendProbation,
            approveProbation
        },
        dispatch
    );

UpdateProbationButton.propTypes = {
    newStatus: PropsTypes.string.isRequired,
    member: PropsTypes.object.isRequired
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UpdateProbationButton);
