import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Button } from "@cortexglobal/rla-components";

import StaffForm from "./StaffForm";
import { inviteStaffMember } from "./staffActions";
// import LinkStyleButton from "../../../components/LinkStyleButton";

class InviteStaff extends Component {
    state = {
        showInviteModal: false,
    };
    showInviteModal = () => {
        this.setState({ showInviteModal: true });
    };
    inviteStaffMember = (values) => {
        return this.props.inviteStaffMember(values).then(() => this.onClose());
    };
    onClose = () => {
        this.setState({
            showInviteModal: false,
        });
    };
    render() {
        const { showInviteModal } = this.state;
        return (
            <React.Fragment>
                <Button
                    onClick={this.showInviteModal}
                    style={{ float: "right" }}
                >
                    Invite a Team Member
                </Button>
                {showInviteModal && (
                    <Modal visible={showInviteModal} onClose={this.onClose}>
                        <h1>Invite a Team Member</h1>
                        <StaffForm onSubmit={this.inviteStaffMember} />
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {};
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            inviteStaffMember,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(InviteStaff);
