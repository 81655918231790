import React, { Component } from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DashboardPanel from "../dashboard/DashboardPanel";
import {
    Label,
    Value,
    // BorderRow,
    BorderColumn,
} from "../dashboard/dashboardComponents";

const perTechnicianLiability = 1000;
class RewardSummary extends Component {
    renderSummary = (loaded, technicians) => {
        if (!loaded) {
            return <Loading align="center" />;
        }
        return (
            <React.Fragment>
                <Column medium={4}>
                    <Label>YTD total liability</Label>
                    <Value>
                        &pound;
                        {(
                            technicians.total * perTechnicianLiability
                        ).toLocaleString()}
                    </Value>
                </Column>
                <BorderColumn medium={4}>
                    <Label>Anniversary rewards liability</Label>
                    <Value>
                        &pound;
                        {(
                            technicians.new * perTechnicianLiability
                        ).toLocaleString()}
                    </Value>
                </BorderColumn>
                <BorderColumn medium={4}>
                    <Label>Fixed-date rewards liability</Label>
                    <Value>
                        &pound;
                        {(
                            technicians.existing * perTechnicianLiability
                        ).toLocaleString()}
                    </Value>
                </BorderColumn>
            </React.Fragment>
        );
    };
    render() {
        const { loaded, technicians } = this.props;

        return (
            <div>
                <Row>
                    <Column>
                        <PageTitle
                            title="Reward Summary"
                            subtitle="Check the reward liability"
                        />
                    </Column>
                </Row>
                <Row equaliseChildHeight={true}>
                    <Column>
                        <DashboardPanel title="Loyalty Reward - Liability">
                            {this.renderSummary(loaded, technicians)}
                        </DashboardPanel>
                    </Column>
                </Row>
            </div>
        );
    }
}

RewardSummary.propTypes = {};

export default RewardSummary;
