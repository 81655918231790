import React from "react";
import PropTypes from "prop-types";
//import 'react-select/dist/react-select.css';
import ReactSelect, { Creatable } from "react-select";

import { baseInputStyle } from "./baseInput";
const customStyles = {
    option: (provided, state) => ({
        ...provided
    }),
    control: (provided, state) => ({
        ...provided,
        border: `1px solid
        ${props => {
            console.log("props", props);
            return props.error
                ? props.theme.input.error.borderColor
                : props.theme.input.borderColor;
        }}`
    }),
    singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
    }
};
class SelectSelectAdvanced extends React.Component {
    shouldKeyDownEventCreateNewOption = ({ keyCode }) => {
        return [9, 13, this.props.tags ? 188 : ""].indexOf(keyCode) != -1; // Create new options only on tab and enter
    };

    render() {
        const {
            options,
            name,
            onChange,
            error,
            creatable,
            tags,
            value,
            ...rest
        } = this.props;
        //console.log(value, options);

        const Component = creatable || tags ? Creatable : ReactSelect;
        return (
            <Component
                styles={customStyles}
                style={baseInputStyle}
                theme={theme => ({
                    ...theme,
                    // height: "30px",
                    borderRadius: 3,
                    colors: {
                        ...theme.colors,
                        neutral20: error ? "#f44336" : "#888889"
                    }
                })}
                name={name}
                options={options.map(option => ({
                    value: option.value,
                    label: option.text
                }))}
                value={value}
                onChange={onChange}
                shouldKeyDownEventCreateNewOption={
                    this.shouldKeyDownEventCreateNewOption
                }
                {...rest}
            />
        );
    }
}

SelectSelectAdvanced.displayName = "SelectSelectAdvanced";

SelectSelectAdvanced.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired
        })
    ),
    labelProps: PropTypes.shape({
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
            .isRequired
    }),
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

SelectSelectAdvanced.defaultProps = {
    error: "",
    labelProps: {
        label: ""
    }
};

export default SelectSelectAdvanced;
