import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import StyledCheckbox from "../styledElements/styledCheckbox";

const FormikCheckbox = ({
    type,
    name,
    label,
    labelWidth,
    labelAlign,
    inline,
    errorStyle,
    ...props
}) => {
    return (
        <React.Fragment>
            <Field name={name}>
                {({ field: { value, onChange }, form: { values } }) => {
                    //console.log("value", value, "values", values);
                    return (
                        <StyledCheckbox
                            name={name}
                            checked={value}
                            onChange={onChange}
                            text={label}
                            labelWidth={labelWidth}
                            labelAlign={labelAlign}
                            inline={inline}
                        />
                    );
                }}
            </Field>

            <ErrorMessage name={name}>
                {msg => <InputError error={msg} errorStyle={errorStyle} />}
            </ErrorMessage>
        </React.Fragment>
    );
};

FormikCheckbox.displayName = "FormikCheckbox";

FormikCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    inline: PropTypes.bool,
    errorStyle: PropTypes.object
};

FormikCheckbox.defaultProps = {
    size: "default",
    type: "text",
    readOnly: false,
    height: 30,
    inline: false
};

export default FormikCheckbox;
