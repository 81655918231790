// Libraries
import React, { Component } from "react";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Other
import { showVideoModal, hideVideoModal } from "./videoCompetitionActions";
import { videoShowModalSelector } from "./videoCompetitionSelectors";

class VideoPlaceholder extends Component {
    handleClick = () => {
        const {
            showVideoModal,
            hideVideoModal,
            showModal,
            video: { id: videoId }
        } = this.props;

        showModal ? hideVideoModal() : showVideoModal(videoId);
    };

    render() {
        const { src, className, video } = this.props;

        if (Object.keys(video).length === 0) {
            return <div style={{fontWeight: "bold"}}>N/A</div>;
        }

        return (
            <button onClick={this.handleClick}>
                <img
                    className={className}
                    src={src}
                    alt={video !== null ? video.description : ""}
                />
            </button>
        );
    }
}

const StyledVideoPlaceholder = styled(VideoPlaceholder)`
    font-size: 100%;
    font-family: inherit;
    width: 100%;
    max-width: 1400px;
    display: block;
    text-align: center;
    border: 0;
    padding: 0.5em 0;
    position: relative;
    cursor: pointer;
    img {
        max-height: 100%;
    }
`;

const mapStateToProps = state => {
    const showModal = videoShowModalSelector(state);

    return { showModal };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators({ showVideoModal, hideVideoModal }, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StyledVideoPlaceholder);
