import React, { Component } from "react";
import { Row, Column } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Route, Link } from "react-router-dom";
import ManagerComparisonLeague from "./ManagerComparisonLeague";
import ManagerChampionsLeague from "./ManagerChampionsLeague";

import ThisOrThatImg from "../../../img/this-or-that-button.png";
import ChampionsLeagueImg from "../../../img/champions-league-button.png";

import { ButtonImage } from "../../../components/Buttons";

class Leagues extends Component {
    render() {
        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <PageTitle
                            title="Select league"
                            subtitle="Select the league you wish to view"
                        />
                    </Column>
                </Row>
                <Row>
                    <Column medium={4}>
                        <Link to="/leagues/this-or-that">
                            <ButtonImage
                                src={ThisOrThatImg}
                                alt="This or That"
                            />
                        </Link>
                    </Column>
                </Row>
                <Route
                    path="/leagues/this-or-that"
                    component={ManagerComparisonLeague}
                />
            </React.Fragment>
        );
    }
}

export default Leagues;
