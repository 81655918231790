import React, { Component } from "react";
import AppContext from "../../../context/AppContext";
import { Row, Column, Loading, Panel } from "@cortexglobal/rla-components";
import { requested } from "../../../config/statusGroups";
import DetailRow from "../../../components/DetailRow";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

import {
    BodyRow,
    BodyColumn,
    BodyLabel,
    RowWrapper,
    HeaderColumn,
    HeaderRow,
    StyledUpdateStatusButton,
} from "../../../components/table/index";
import ShowHideButton from "../../../components/table/ShowHideButton";
class RequestsHome extends Component {
    state = {
        showing: "",
    };
    render() {
        return (
            <Row>
                <AppContext.Consumer>
                    {({ staff, loading, user, group }) => {
                        if (loading) {
                            return <Loading align="center" />;
                        }
                        const activeStaff = staff.data.filter((member) => {
                            return requested.indexOf(member.status) !== -1;
                        });
                        return (
                            <React.Fragment>
                                <Row>
                                    <Column large={8}>
                                        <PageTitle
                                            title="Staff Account Requests"
                                            subtitle="Your current staff pending account requests"
                                        />
                                    </Column>
                                    <Column
                                        large={4}
                                        style={{ marginTop: "1rem" }}
                                    />
                                </Row>

                                {activeStaff.length > 0 ? (
                                    <React.Fragment>
                                        <HeaderRow>
                                            <HeaderColumn large={3}>
                                                Name
                                            </HeaderColumn>
                                            <HeaderColumn large={3}>
                                                Job Title
                                            </HeaderColumn>
                                            <HeaderColumn large={6}>
                                                Actions
                                            </HeaderColumn>
                                        </HeaderRow>
                                        {activeStaff.map((member) => {
                                            const showingDetail =
                                                this.state.showing ===
                                                member.uuid;
                                            return (
                                                <RowWrapper key={member.uuid}>
                                                    <BodyRow showingDetail>
                                                        <BodyColumn large={3}>
                                                            <BodyLabel>
                                                                Name:
                                                            </BodyLabel>
                                                            {member.first_name}{" "}
                                                            {member.last_name}
                                                        </BodyColumn>
                                                        <BodyColumn large={3}>
                                                            <BodyLabel>
                                                                Role:
                                                            </BodyLabel>
                                                            {member.role &&
                                                                member.role
                                                                    .name}
                                                        </BodyColumn>

                                                        <BodyColumn large={6}>
                                                            <Column medium={4}>
                                                                <StyledUpdateStatusButton
                                                                    newStatus="active"
                                                                    user={
                                                                        member
                                                                    }
                                                                >
                                                                    Approve
                                                                </StyledUpdateStatusButton>
                                                            </Column>
                                                            <Column medium={4}>
                                                                <StyledUpdateStatusButton
                                                                    newStatus="approval-rejected"
                                                                    user={
                                                                        member
                                                                    }
                                                                >
                                                                    Reject
                                                                </StyledUpdateStatusButton>
                                                            </Column>
                                                            <Column medium={4}>
                                                                <ShowHideButton
                                                                    showDetails={() => {
                                                                        const showing =
                                                                            showingDetail
                                                                                ? ""
                                                                                : member.uuid;
                                                                        this.setState(
                                                                            (
                                                                                state
                                                                            ) => ({
                                                                                showing,
                                                                            })
                                                                        );
                                                                    }}
                                                                    showingDetail={
                                                                        showingDetail
                                                                    }
                                                                />
                                                            </Column>
                                                        </BodyColumn>
                                                    </BodyRow>

                                                    <DetailRow
                                                        member={member}
                                                        group={group}
                                                        shown={showingDetail}
                                                    />
                                                </RowWrapper>
                                            );
                                        })}
                                    </React.Fragment>
                                ) : (
                                    <Panel
                                        style={{
                                            width: "66%",
                                            borderRadius: 0,
                                            boxShadow: "none",
                                        }}
                                    >
                                        <Row>
                                            <Column>
                                                <h4>
                                                    No staff account requests
                                                    pending
                                                </h4>
                                                <p>
                                                    When a staff member requests
                                                    an account, their requests
                                                    will appear here for your
                                                    review. Once you approve the
                                                    request, they will appear on
                                                    the 'My Team' page.
                                                </p>
                                            </Column>
                                        </Row>
                                    </Panel>
                                )}
                            </React.Fragment>
                        );
                    }}
                </AppContext.Consumer>
            </Row>
        );
    }
}

export default RequestsHome;
