import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading, Button } from "@cortexglobal/rla-components";
import {
    loadComparisonLeague,
    loadChampionsLeague
} from "../../leagues/leagueActions";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

import { LeagueTable, LeagueRow } from "../../leagues/styledComponents";

class HeadOfficeChampionsLeague extends Component {
    state = {
        showAll: false
    };
    componentDidMount() {
        if (!this.props.champions.loaded) {
            this.props.loadChampionsLeague();
        }
    }
    filterLeague() {}

    render() {
        const { champions } = this.props;
        const { showAll } = this.state;

        if (!champions.loaded || champions.loading) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <PageTitle
                            title="Champion's League"
                            subtitle="Who’s on the top spot?"
                        />
                    </Column>
                </Row>
                {!champions.loaded || champions.loading ? (
                    <Loading />
                ) : (
                    <Row>
                        <Column>
                            <LeagueTable>
                                <thead>
                                    <tr>
                                        <th>National Position</th>
                                        <th style={{ textAlign: "left" }}>
                                            Technician Name
                                        </th>
                                        <th style={{ textAlign: "left" }}>
                                            Retailer
                                        </th>
                                        <th style={{ textAlign: "center" }}>
                                            Retailer Position
                                        </th>
													 <th style={{ textAlign: "center" }}>
														  Points
													 </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {champions.data
                                        .filter(leagueEntry => {
                                            if (showAll) {
                                                return true;
                                            }
                                            if (
                                                leagueEntry.national_position <=
                                                10
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        })
                                        .map((leagueEntry, index) => {
                                            return (
                                                <LeagueRow key={index}>
                                                    <td>
                                                        {
                                                            leagueEntry.national_position
                                                        }
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "left"
                                                        }}
                                                    >
                                                        {leagueEntry.user}
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "left"
                                                        }}
                                                    >
                                                        {leagueEntry.group}
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        {leagueEntry.position}
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "center"
                                                        }}
                                                    >
                                                        {leagueEntry.score}
                                                    </td>
                                                </LeagueRow>
                                            );
                                        })}
                                </tbody>
                            </LeagueTable>
                        </Column>
                        <Column
                            style={{ textAlign: "right", paddingTop: "1rem" }}
                        >
                            <Button
                                onClick={() => {
                                    this.setState(state => ({
                                        showAll: !state.showAll
                                    }));
                                }}
                            >
                                {showAll ? "Show top 10" : "Show all"}
                            </Button>
                        </Column>
                    </Row>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { champions } = state.leagues;

    return { champions };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadChampionsLeague
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeadOfficeChampionsLeague);
