import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Hero } from "./sharedComponents";

const Wrapper = styled(Row)`
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        width: 66%;
    }
    table {
        padding-bottom: 1rem;
        th {
            padding: 0.4rem;
            background-color: ${(props) => props.theme.colors.secondary};
            color: ${(props) => props.theme.colors.white};
        }
        td {
            padding: 0.4rem;
        }
        tr {
            &:nth-of-type(even) {
                background-color: ${(props) => props.theme.colors.lightGray};
            }
        }
    }
`;

class Privacy extends Component {
    render() {
        return (
            <div className="page page-home">
                <Hero />
                <Row>
                    <Column medium={12} large={8}>
                        <PageTitle
                            title="BMW & MINI Elite"
                            subtitle="Privacy Policy"
                        />
                    </Column>
                </Row>

                <Wrapper>
                    <Column>
                        <h1>BMW PRIVACY POLICY.</h1>
                        <p>
                            BMW Group UK understands that its use of your
                            information requires your trust. BMW Group UK is
                            committed to the highest standards of data privacy
                            and will only use your information for clearly
                            described purposes and in accordance with your data
                            protection rights.
                        </p>
                        <p>WHAT BMW COMPANIES ARE COVERED BY THIS POLICY.</p>
                        <p>
                            The following BMW companies are covered by this
                            policy in the UK:
                        </p>
                        <table>
                            <thead>
                                <tr>
                                    <th>
                                        <strong>SHORT NAME</strong>
                                    </th>
                                    <th>
                                        <strong>FULL NAME</strong>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>BMW UK</td>
                                    <td>BMW (UK) Limited</td>
                                </tr>
                                <tr>
                                    <td>BMW FS</td>
                                    <td>BMW Financial Services (GB) Limited</td>
                                </tr>
                                <tr>
                                    <td>BMW Holdings</td>
                                    <td>BMW (UK) Holdings Limited</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>
                            Note: for ease of reading the companies are referred
                            to throughout this policy by their{" "}
                            <strong>Short name</strong> .
                            <br />
                            <br />
                            References in this policy to “BMW Group” “BMW Group
                            UK”, “we” or “us” are references to all of the
                            companies above. These are all registered at this
                            address:
                            <br />
                            <br />
                            Summit One
                            <br />
                            Summit Avenue
                            <br />
                            Farnborough
                            <br />
                            Hampshire
                            <br />
                            GU14 0FB
                        </p>
                        <h3>
                            <strong>What the BMW Group companies do:</strong>
                        </h3>
                        <p>
                            <strong>BMW Group</strong> provides services through
                            a number of different parties in the UK.
                            <br />
                            <br />
                            <strong>BMW UK </strong>
                            coordinates the BMW and MINI business in the UK:
                        </p>
                        <ul>
                            <li>
                                BMW UK is an importer and distributor of the BMW
                                Group’s brands, of BMW and MINI motor cars and
                                BMW motorcycles in the UK. BMW UK operates under
                                the following trading styles : BMW, MINI, BMW i
                                and BMW Motorrad (motorcycles)
                            </li>
                            <li>
                                It is the UK national sales and marketing
                                company for the BMW Group and:
                                <ul>
                                    <li>
                                        Appoints Retailers (BMW, MINI and BMW
                                        Motorrad Centres) and agents;
                                    </li>
                                    <li>
                                        Deals with technical issues and provides
                                        support to Retailers; and
                                    </li>
                                    <li>
                                        Operates the BMW &amp; MINI digital
                                        systems and services.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                        <p>
                            <strong>BMW FS</strong> is a financial services
                            company:
                        </p>
                        <ul>
                            <li>
                                BMW FS provides regulated and unregulated motor
                                finance products, services, and associated
                                general insurance products to customers in the
                                UK through Retailers of BMW Group products and
                                non BMW Group motor dealerships and independent
                                credit brokers. BMW FS operates under the
                                following trading styles: BMW Financial
                                Services, MINI Financial Services, Motorrad
                                Financial Services, Rolls-Royce Motor Cars
                                Financial Services, Aston Martin Financial
                                Services and Alphera. BMW FS is authorised and
                                regulated by the Financial Conduct Authority.
                            </li>
                        </ul>
                        <p>
                            <strong>BMW Holdings</strong>
                        </p>
                        <ul>
                            <li>
                                Provides taxation services and advises BMW Group
                                companies in the UK.
                            </li>
                        </ul>
                        <p>
                            <strong>Retailers or Retail Centres</strong>
                        </p>
                        <ul>
                            <li>
                                Unless stated otherwise, are independent
                                businesses and not part of the BMW Group;
                            </li>
                            <li>
                                They operate using the BMW brands under license
                                to sell and/or service vehicles;
                            </li>
                            <li>
                                They provide services to customers and support
                                for technical issues;
                            </li>
                            <li>
                                They undertake credit broking and insurance
                                mediation activities; and
                            </li>
                            <li>
                                They are not covered by this policy and are
                                referenced here to provide additional clarity
                                regarding your personal information.
                            </li>
                        </ul>
                        <h3>
                            <strong>
                                BMW as Data Controllers and our
                                responsibilities:
                            </strong>
                        </h3>
                        <p>
                            BMW UK is responsible for, and is the Data
                            Controller of your personal information that it
                            receives through all BMW websites (e.g. BMW.co.uk,
                            MINI.co.uk), MyBMW or MyMINI accounts,
                            ConnectedDrive and MINIConnected Services (Connected
                            Services).
                            <br />
                            <br />
                            BMW FS is the Data Controller of the information
                            which is used to grant and provide finance to you.
                            <br />
                            <br />
                            Ultimately, BMW AG is the parent company of BMW
                            Group UK and provides much of the IT infrastructure
                            through the above mentioned companies. BMW AG is
                            generally a service provider or Data Processor to
                            the above mentioned parties.
                            <br />
                            <br />
                            However, BMW AG is in addition the Data Controller
                            for information received through the usage of the
                            Connected App as well as a joint Data Controller for
                            the technical provision of Connected Services.
                            <br />
                            For more information on our Connected Services
                            visit:
                        </p>
                        <ul>
                            <li>
                                ConnectedDrive visit{" "}
                                <a href="http://www.bmw-connecteddrive.co.uk/">
                                    www.bmw-connecteddrive.co.uk
                                </a>{" "}
                                or
                            </li>
                            <li>
                                MINIConnected at{" "}
                                <a
                                    href="https://www.mini.co.uk/en_GB/home.html"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    www.mini.co.uk
                                </a>
                            </li>
                        </ul>
                        <p>
                            Contact details of each of the Data Controllers
                            listed above and their Data Protection Officers can
                            be found{" "}
                            <a
                                href="https://www.bmw.co.uk/global/privacy-policy/data-privacy-officer-contact-details"
                                title="Data Privacy Officer Contact Details"
                            >
                                here
                            </a>
                            .
                            <br />
                            <br />
                            <strong>Retail Centres</strong>
                            <br />
                            Although this Privacy Policy describes some of the
                            uses of your information made by BMW, MINI and BMW
                            Motorrad Centres, they may collect other information
                            relating to you. Each BMW, MINI and BMW Motorrad
                            Retail Centre is a Data Controller in relation to
                            the information it processes for its own purposes.
                            Their privacy policies will set out how they use
                            your information. If you have any queries regarding
                            the use of your information by BMW, MINI and BMW
                            Motorrad Centres please contact them directly or
                            visit the privacy policy on their website.
                        </p>
                        <h3>
                            <strong>Changes to this Privacy Policy:</strong>
                        </h3>
                        <p>
                            We may modify or update this privacy policy from
                            time to time.
                            <br />
                            <br />
                            If we change this privacy policy, we will notify you
                            of the changes by publishing an updated policy on
                            this website. Where changes to this privacy policy
                            will have a fundamental impact on the nature of the
                            processing or otherwise have a substantial impact on
                            you, we will give you sufficient advance notice so
                            that you have the opportunity to exercise any rights
                            you may have (e.g. to object to the processing).
                        </p>
                        <p>HOW DO WE COLLECT YOUR PERSONAL INFORMATION.</p>
                        <p>
                            These are the main ways we collect your information:
                        </p>
                        <ul>
                            <li>
                                If you contact us directly via our websites or
                                via our customer hotlines to request information
                                about our products and services.
                            </li>
                            <li>
                                If you buy a product or service directly from us
                                (e.g. Lifestyle shops).
                            </li>
                            <li>
                                If you reply to our direct marketing campaigns
                                (e.g. filling out a response card).
                            </li>
                            <li>
                                If your contact details are transferred from
                                authorised centres or other third parties.
                            </li>
                            <li>
                                If your vehicle data (incl. vehicle
                                identification number) is transferred to BMW AG
                                while you are having your vehicle serviced or
                                repaired.
                            </li>
                            <li>
                                If other BMW Group legal entities or business
                                partners transfer your personal data to us.
                            </li>
                            <li>
                                If we acquired your personal data from other
                                sources, for example social media sites.
                            </li>
                            <li>
                                When you apply for finance from BMW FS or from
                                the retailers or brokers.
                            </li>
                        </ul>
                        <p>
                            If you give information on behalf of someone else
                            you must ensure that you have their permission and
                            that they have been provided with this Privacy
                            Policy before doing so.
                            <br />
                            <br />
                            If you are under 16 please do not provide us with
                            any of your information unless you have the
                            permission of your parent or guardian.
                            <br />
                            <br />
                            Please help us to keep your information up to date
                            by informing us of any changes to your contact
                            details or privacy preferences. You may change or
                            review your preferences by clicking{" "}
                            <a href="https://www.bmw.co.uk/global/privacy-policy/changing-your-privacy-preferences">
                                here
                            </a>
                            .
                        </p>
                        <p>WHAT INFORMATION MAY BE COLLECTED ABOUT YOU.</p>
                        <p>
                            The following types of personal information about
                            you may be collected:
                        </p>
                        <p>
                            <strong>Contact Details</strong>
                        </p>
                        <ul>
                            <li>Name</li>
                            <li>Address</li>
                            <li>Phone numbers</li>
                            <li>Email address</li>
                        </ul>
                        <p>
                            <strong>Interests</strong>
                        </p>
                        <ul>
                            <li>
                                Information you provide us about your interests
                            </li>
                            <li>
                                Including the type of vehicles you are
                                interested in.
                            </li>
                        </ul>
                        <p>
                            <strong>Website and Communication Usage</strong>
                        </p>
                        <ul>
                            <li>
                                How you use our website and whether you open or
                                forward our communications, including
                                information collected through cookies and other
                                tracking technologies (our{" "}
                                <a href="https://www.bmw.co.uk/global/cookie-policy">
                                    Cookies Policy
                                </a>
                                ).
                            </li>
                        </ul>
                        <p>
                            <strong>Sales and Services Information</strong>
                        </p>
                        <ul>
                            <li>
                                Relating to purchases and services, including
                                complaints and claims.
                            </li>
                        </ul>
                        <p>
                            <strong>Credit and Anti-Fraud Information</strong>
                        </p>
                        <ul>
                            <li>
                                Which establishes your identity, such as driving
                                licences, passports and utility bills
                            </li>
                            <li>
                                Information about transactions, requests for
                                credit and non-payment of debts with us and
                                third parties and credit ratings from credit
                                reference agencies
                            </li>
                            <li>
                                Fraud, offences, suspicious transactions,
                                politically exposed person and sanctions lists
                                where your details are included.
                            </li>
                            <li>Employment history</li>
                            <li>Bank details</li>
                            <li>
                                BMW FS may also ask you to provide information
                                about your health if it becomes aware of a
                                condition that might affect your ability to
                                repay the finance without difficulty.
                            </li>
                        </ul>
                        <p>
                            If you apply for a BMW or MINI Branded insurance
                            policy BMW FS will also ask you to provide:
                        </p>
                        <ul>
                            <li>
                                certain information about your driving history,
                                your past insurance claims (if any) and any
                                accidents or motoring convictions;
                            </li>
                            <li>
                                provide the same information about any other
                                drivers that may be on the policy;
                            </li>
                            <li>
                                confirm to us that: you have all other drivers’
                                permission to provide the information to us;
                                that the other drivers are aware of the contents
                                of this privacy policy; that the other drivers
                                do not have any objection to us using or sharing
                                their information in accordance with this
                                privacy policy.
                            </li>
                        </ul>
                        <p>
                            <strong>Device and Service Usage</strong>
                        </p>
                        <ul>
                            <li>
                                How you use your device (mobile or vehicle) and
                                services offered on the device.
                            </li>
                        </ul>
                        <p>
                            <strong>Vehicle Configuration Details</strong>
                        </p>
                        <ul>
                            <li>
                                Information about the features and current
                                settings of your vehicle (identified by the
                                Vehicle Identification Number).
                            </li>
                        </ul>
                        <p>
                            <strong>Vehicle Technical Information</strong>
                        </p>
                        <ul>
                            <li>
                                About how the engine and systems within the
                                vehicle perform.
                            </li>
                        </ul>
                        <p>
                            <strong>
                                Vehicle / Device Location Information
                            </strong>
                        </p>
                        <ul>
                            <li>Your vehicle’s or mobile device’s location</li>
                        </ul>
                        <h1>HOW YOUR PERSONAL INFORMATION MAY BE USED.</h1>
                        <p>
                            Use of personal information under UK data protection
                            laws must be justified under one of a number of
                            legal grounds and we are required to set out the
                            grounds in respect of each use in this policy. An
                            explanation of the scope of the grounds available
                            can be found{" "}
                            <a href="https://www.bmw.co.uk/global/privacy-policy/how-your-information-is-used#legal-grounds">
                                here
                            </a>
                            .
                            <br />
                            <br />
                            The main uses of your information are:
                            <br />
                            <br />
                            <strong>Customer Support and Marketing</strong> - to
                            respond to enquiries and to bring you news and
                            offers.
                        </p>
                        <p>
                            BMW UK and BMW FS use your personal data for
                            customer care and for personalised communications of
                            BMW Group product and service information, with your
                            consent where necessary. For these purposes, BMW UK
                            and BMW FS may transmit this data to authorised
                            Retailers and other BMW Group companies (i.e. BMW FS
                            and BMW Holdings).
                            <br />
                            <br />
                            In order to ensure that you receive relevant and
                            personalised communications, we will use some of
                            your data to create an individual customer profile
                            and we may share your personal data between BMW
                            Group companies, authorised BMW or MINI Retailers,
                            BMW i agents and other suppliers of BMW or MINI
                            branded products and services where relevant. This
                            may include data you have provided to these
                            companies, or which is generated by your use of BMW
                            or MINI products, for example contact details,
                            preferences, customer history, vehicle data, app
                            usage and online behaviour.
                            <br />
                            <br />
                            If you have a finance agreement with BMW FS they may
                            pass on your agreement details (term, monthly
                            payment, rate, mileage, final payment, and agreement
                            number and vehicle details) to the retailer who sold
                            your vehicle to you to discuss your end of contract
                            options, and if you agree, to discuss any future
                            financing with BMW FS. They may also provide BMW UK
                            with your contact details and the start and end
                            dates of your agreement so they can send you news on
                            products and services which may interest you.
                            <br />
                            <br />
                            <strong>Vehicle Sales &amp; Service</strong> – to
                            process your sale, configure and service your
                            vehicle
                        </p>
                        <p>
                            BMW, MINI &amp; BMW Motorrad Centres will obtain
                            Contact Details, Vehicle Configuration Details,
                            Vehicle Technical Information and Sales and Services
                            Information when you purchase, service or repair a
                            vehicle from or with them as part of the sale or
                            service and will use it to provide the services you
                            request and notify you of issues in relation to your
                            vehicle. This information may be accessed by BMW UK
                            and BMW AG to troubleshoot technical or other issues
                            relating to the delivery of these services.
                            <br />
                            <br />
                            The above mentioned controllers may also receive
                            limited Vehicle Location Information during the
                            repair process which will be used only in accordance
                            with Location Information Safeguards. For further
                            information on these safeguards click{" "}
                            <a href="https://www.bmw.co.uk/global/privacy-policy/location-information-safeguards">
                                here
                            </a>
                            .
                            <br />
                            <br />
                            <strong>Vehicle Finance</strong>
                            – to assess your eligibility for finance to comply
                            with legal obligations and for legitimate interests.
                            For example to ensure that BMW FS offers the most
                            appropriate offers of finance to you, to ensure that
                            the services function correctly, to prevent fraud
                            and money laundering and to ensure that your records
                            are accurate and up to date.
                            <br />
                            <br />
                            When you apply for finance from BMW FS you must
                            provide certain personal information. BMW FS may
                            share the information with{" "}
                            <strong>
                                Credit Reference and Fraud Prevention Agencies
                            </strong>{" "}
                            to help them decide whether to offer you finance.
                            Using information about you in this way is necessary
                            for BMW FS to make this decision and if you do not
                            provide it to BMW FS they may not be able to offer
                            you finance. Your information will be used by BMW FS
                            and these agencies as follows:
                        </p>
                        <ul>
                            <li>
                                BMW FS will
                                <strong>
                                    check records about you and others held by
                                    them and by Credit Reference and Fraud
                                    Prevention agencies
                                </strong>
                                , for example to assess your application for
                                credit and verify identities to prevent and
                                detect crime and money laundering. The Credit
                                Reference Agencies will place a search footprint
                                on your credit file that may be seen by other
                                lenders. They will supply public information
                                (for example information held on the electoral
                                register) as well as shared credit and fraud
                                prevention information. The Fraud Prevention
                                Agencies will use your personal information to
                                prevent fraud and money-laundering and to verify
                                your identity.
                            </li>
                            <li>
                                If you are making a{" "}
                                <strong>joint application</strong> or if you
                                advise BMW FS of a director or that you have a
                                spouse or financial associate, they will link
                                your records together so you must be sure that
                                you have their agreement to disclose information
                                about them. Credit Reference Agencies also link
                                your records together and these links will
                                remain on your and their files until such a time
                                as you, a director or your partner successfully
                                files for a disassociation with the Credit
                                Reference Agencies to break that link.
                            </li>
                            <li>
                                If you give BMW FS f
                                <strong>
                                    alse or inaccurate information or BMW FS
                                    suspect or identifies fraud
                                </strong>
                                , they will record this and may also pass on the
                                information to Fraud Prevention Agencies and
                                other organisations involved in crime
                                prevention. Law enforcement agencies may also
                                access and use this information. If fraud is
                                detected, you could be refused certain services,
                                finance or employment.
                            </li>
                            <li>
                                BMW FS and other organisations may{" "}
                                <strong>access and use from other</strong>{" "}
                                countries the information recorded by Fraud
                                Prevention Agencies, which may be
                                publicly-available sources.
                            </li>
                            <li>
                                <strong>
                                    Your data may also be used for other
                                    purposes
                                </strong>
                                for which you give your permission or, in
                                limited circumstances, when required by law or
                                where permitted under the terms of relevant data
                                protection and privacy law.
                            </li>
                        </ul>
                        <p>
                            If you would like full details about how your
                            information will be used by us, Credit Reference
                            Agencies and Fraud Prevention Agencies, including
                            details of your rights in relation to your
                            information, or you would like to obtain contact
                            details of the Credit Reference and Fraud Prevention
                            Agencies, please: visit
                            <a href="http://www.experian.co.uk/crain">
                                www.experian.co.uk/crain
                            </a>{" "}
                            or{" "}
                            <a href="https://www.equifax.co.uk/crain">
                                www.equifax.co.uk/crain
                            </a>
                            <br />
                            <br />
                            BMW FS uses automated decision-making tools to help
                            it to decide whether to offer you finance. Based on
                            the information you provide us, we will compare this
                            against different metrics to determine whether you
                            meet the eligibility criteria for finance or to
                            determine whether you will be able to make
                            repayments on a vehicle without difficulty. If your
                            application for finance is rejected using automated
                            means alone, you will be given the opportunity to
                            ask for the decision to be reviewed manually. This
                            does not mean that the decision will be changed.
                            <br />
                            <br />
                            BMW FS does <strong>not</strong> share credit and
                            anti-fraud information with Retail Centres, BMW UK,
                            BMW Holdings or BMW AG.
                            <br />
                            <br />
                            BMW FS will also use your personal information to
                            manage its contractual obligations and to enforce
                            its rights under the finance agreement. For example,
                            if you fall into arrears or break any other term of
                            the agreement, BMW FS may pass on your details to a
                            debt collection agency for the purpose of collecting
                            the debt or to a vehicle recovery agent to repossess
                            the vehicle or to solicitors to issue court
                            proceedings against you. BMW FS may also activate
                            your GPS tracker or review publicly available
                            information to trace your whereabouts. For full
                            details of how BMW FS processes your personal
                            information please refer to the “Use of Your
                            Information” clause in your finance agreement.
                            <br />
                        </p>
                        <h3>
                            <strong>
                                ConnectedDrive – to provide digital services in
                                the vehicle.
                            </strong>
                        </h3>
                        <p>
                            BMW UK and BMW AG receive information from these
                            digital services, such as:
                            <br />
                            <br />
                            Contact Details, Vehicle Location Information as
                            well as Device and Service Usage information which
                            they use in accordance with the detailed service
                            descriptions for each element of the services set
                            out in the ConnectedDrive policy.
                        </p>
                        <ul>
                            <li>
                                Further information is available in the
                                ConnectedDrive Privacy Policy, click{" "}
                                <a href="https://btclegaldocumentcheckeu.bmwgroup.com/api/legaldocumentcheck/viewlatestdocument/cd_pp/bmw/ios/row?locale=en-gb">
                                    here
                                </a>
                                .
                            </li>
                        </ul>
                        <h3>
                            <strong>
                                <br />
                                Connected App – to provide digital services
                                relating to the vehicle through a mobile device.
                            </strong>
                        </h3>
                        <p>
                            BMW AG will obtain Contact Details, Device and
                            Vehicle Location Information as well as Device and
                            Service Usage information through the provision of
                            the BMW Connected App which it uses in accordance
                            with the BMW Connected App privacy policy and
                            Location Information Safeguards.
                        </p>
                        <p>
                            For more information on Vehicle Location Information
                            safeguards click{" "}
                            <a href="https://www.bmw.co.uk/global/privacy-policy/location-information-safeguards">
                                here
                            </a>
                            .
                        </p>
                        <h3>
                            <strong>
                                <br />
                                Quality Assurance, Research and Development – to
                                improve our products and services.
                            </strong>
                        </h3>
                        <p>
                            BMW AG may use any of the information that it
                            receives through the provision of services to BMW
                            UK, centres (including Location Information) for
                            product and service quality assurance and
                            development purposes. Before any such use is
                            undertaken your information will be de-personalised
                            so it cannot be directly linked back to you.
                        </p>
                        <h3>
                            <strong>
                                <br />
                                Compliance with legal requests for your
                                information – to comply with our legal
                                obligations to law enforcement, regulators and
                                the court service.
                            </strong>
                        </h3>
                        <p>
                            We may be legally required to provide your
                            information to law enforcement agencies, regulators,
                            courts and third party litigants in connection with
                            civil or criminal proceedings or investigations
                            anywhere in the world. Where permitted, we will
                            direct any such request to you or we may notify you
                            before responding unless to do so would prejudice
                            the prevention or detection of a crime.
                        </p>
                        <h3>
                            <strong>
                                <br />
                                Legal grounds for processing of your personal
                                information.
                            </strong>
                        </h3>
                        <p>
                            The use of your information set out above is
                            permitted under UK data protection law on the basis
                            of these principal legal grounds:
                        </p>
                        <ul>
                            <li>
                                Where you have consented to the use (you will
                                have been presented with a consent form in
                                relation to any such use and may withdraw your
                                consent at any time through the BMW Customer
                                Information Centre
                            </li>
                        </ul>
                        <ol start="1" type="1">
                            <li>
                                on 0800 561 0666 (calls are free of charge plus
                                your phone company's access charge)
                            </li>
                            <li>
                                or sending an email to{" "}
                                <a href="mailto:customer.information@bmw.co.uk">
                                    customer.information@bmw.co.uk
                                </a>{" "}
                                or{" "}
                                <a href="mailto:customer.information@mini.co.uk">
                                    customer.information@mini.co.uk
                                </a>
                            </li>
                            <li>
                                or by writing to us at:
                                <br />
                                BMW Customer Information Centre
                                <br />
                                Phoenix One
                                <br />
                                59 - 63 Farnham Road
                                <br />
                                Slough
                                <br />
                                Berkshire
                                <br />
                                SL1 3TN
                            </li>
                        </ol>
                        <ul>
                            <li>
                                Where necessary to enter into or perform our
                                contract with you
                            </li>
                            <li>
                                Where we need to use it to comply with our legal
                                obligations
                            </li>
                            <li>
                                Where we use it to achieve a legitimate interest
                                and our reasons for using it outweigh any
                                prejudice to your data protection rights (our
                                legitimate interests include promoting the BMW
                                Group business and tailoring news and offers to
                                your profile, research and development of
                                vehicle related products and services, assessing
                                your credit-worthiness, detecting fraud and
                                criminal activities).
                            </li>
                        </ul>
                        <p>
                            There may be uses that are permitted on the basis of
                            other grounds; where this is the case we will use
                            reasonable endeavours to identify the ground and
                            communicate it you as soon as possible after
                            becoming aware of the new basis.
                        </p>
                        <h3>
                            <strong> </strong>
                        </h3>
                        <h3>
                            <strong>
                                Third parties to whom we may transfer your data.
                            </strong>
                        </h3>
                        <p>
                            Personal information which we collect may be
                            transferred to or accessed by third parties on our
                            behalf, with your consent where necessary.
                            <br />
                            <br />
                            The types of third parties apart from the BMW
                            companies already stated are:
                        </p>
                        <ul>
                            <li>
                                Marketing and research companies who run and
                                manage marketing and research campaigns
                            </li>
                            <li>
                                Event companies who run and manage sponsored
                                events
                            </li>
                            <li>
                                BMW Customer Interaction Centre who provide
                                customer support
                            </li>
                            <li>
                                BMW Retailers and Retail Centres who provide
                                services to you
                            </li>
                            <li>
                                IT providers who provide us systems and services
                                for customer support
                            </li>
                            <li>
                                Card Payment Services providers who operate
                                payment platforms
                            </li>
                            <li>
                                Logistics and courier companies who transport
                                our products to you
                            </li>
                            <li>
                                Companies who provide transport to you or offer
                                you mobility services (e.g. chauffeurs)
                            </li>
                            <li>
                                Law firms who provide legal advice to us (e.g.
                                where there is a customer dispute)
                            </li>
                        </ul>
                        <p>
                            This is only shared in a secure manner, using a
                            consistent security protocol. When we share with
                            other parties we ensure that they only use your
                            personal data for the purpose it was collected and
                            do not allow them to abuse this agreement.
                            <br />
                            <br />
                            BMW Group UK may also transfer your personal
                            information to a third party without your consent if
                            it is in its legitimate interest or if there is a
                            contractual obligation to do so. For example, BMW FS
                            may pass on your personal details to a third party
                            debt recovery agent if you fail to make the payments
                            due under your finance agreement or to a vehicle
                            recovery agent to collect the vehicle at the end of
                            your finance agreement. For full details of how BMW
                            FS uses and shares your personal information please
                            refer to the “Use of Your Information” clause in the
                            finance agreement.
                        </p>
                        <p>HOW DO WE KEEP YOUR PERSONAL INFORMATION SAFE.</p>
                        <p>
                            We use technical and organisational security
                            measures including encryption and authentication
                            tools to protect your personal information, against
                            manipulation, loss, destruction, and access by third
                            parties.
                            <br />
                            <br />
                            Although data transmission over the Internet or
                            website cannot be guaranteed to be secure, we and
                            our business partners work hard to maintain
                            physical, electronic and procedural safeguards to
                            protect your information in accordance with
                            applicable data protection requirements. Our main
                            security measures are:
                        </p>

                        <ul>
                            <li>
                                Tightly restricted access to your data on a
                                “need to know” basis and for the communicated
                                purpose only
                            </li>
                            <li>
                                Transferred collected data only in encrypted
                                form
                            </li>
                            <li>
                                Highly confidential data stored only in
                                encrypted form - e.g. credit card information
                            </li>
                            <li>
                                Firewalled IT systems to prohibit unauthorised
                                access e.g. from hackers
                            </li>
                            <li>
                                Permanently monitored access to IT systems to
                                detect and stop misuse of personal data.
                            </li>
                        </ul>
                        <p>
                            If you have a personal password which enables you to
                            access certain parts of our websites or any other
                            portal, app or service we operate, do not forget
                            your responsibility for keeping this password
                            confidential. We ask you not to share your password
                            with anyone.
                        </p>
                        <p>HOW LONG WE KEEP YOUR PERSONAL INFORMATION FOR.</p>
                        <p>
                            We retain your information only as long as is
                            necessary and only for the purpose for which we
                            obtained them. We restrict access to your
                            information to only those persons who need to use it
                            for the relevant purpose.
                            <br />
                            <br />
                            Our retention periods are based on business needs
                            and your information that is no longer needed is
                            either irreversibly anonymised or destroyed
                            securely.
                            <br />
                            <br />
                            <strong>Use for marketing: </strong>
                        </p>
                        <ul>
                            <li>
                                We retain your personal information for as long
                                as is necessary, but only for the relevant
                                purpose that we collected it for. For example,
                                if you have obtained finance for the purchase of
                                a vehicle through BMW FS, we will retain your
                                personal data for the life of your finance
                                product, so that towards the end of that period
                                we can get in touch with you about your options
                                at the end of the finance term.
                            </li>
                        </ul>
                        <p>
                            <strong>Use to perform a contract: </strong>
                        </p>
                        <ul>
                            <li>
                                In relation to your information used to perform
                                any contractual obligation with you we may
                                retain that data whilst the contract remains in
                                force plus six years to deal with any queries or
                                claims thereafter.
                            </li>
                        </ul>
                        <p>
                            <strong>ConnectedDrive and Connected App: </strong>
                        </p>
                        <ul>
                            <li>
                                We set out how long we retain Vehicle Location
                                Information [and Device Information] in relation
                                to each service in the relevant service
                                descriptions.
                            </li>
                        </ul>
                        <p>
                            <strong>Where claims are contemplated: </strong>
                        </p>
                        <ul>
                            <li>
                                In relation to any information where we
                                reasonably believe it will be necessary to
                                defend or prosecute or make a claim against you,
                                us or a third party, we may retain that data for
                                as long as that claim could be pursued.
                            </li>
                        </ul>
                        <h1>WHO MAY WE SHARE YOUR INFORMATION WITH.</h1>
                        <p>
                            BMW is a global company. Your personal information
                            may be accessed by our staff, agents or contractors
                            from a country outside the European Economic Area
                            (EEA) for any of the purposes set out in this
                            policy. These countries may have in place data
                            protection laws which may be of a lower standard
                            than in the EEA. We will ensure that any of your
                            information that is accessible outside the EEA is
                            handled subject to appropriate safeguards.
                            <br />
                            <br />
                            Certain countries outside the EEA, such as Canada
                            and Switzerland, have been approved by the European
                            Commission as providing essentially equivalent
                            protection to EEA data protection laws and therefore
                            no additional legal safeguards are required. In
                            countries which have not had such approval, we will
                            either ask for your consent to the transfer or
                            transfer it subject to European Commission approved
                            contractual terms that impose equivalent data
                            protection obligations directly on the recipient
                            unless we are permitted under applicable data
                            protection law to make such transfers without such
                            formalities.
                            <br />
                            <br />
                            BMW FS will not share your contract information with
                            other Group Companies other than as specifically
                            mentioned in this Privacy Policy or the Use of
                            Information clause on the finance agreement.
                            <br />
                            <br />
                            Please contact us{" "}
                            <a href="https://www.bmw.co.uk/global/privacy-policy/contacting-us">
                                here
                            </a>{" "}
                            if you would like to see a copy of the specific
                            safeguards applied to the export of your
                            information.
                        </p>
                        <p>HOW TO CHANGE YOUR PRIVACY PREFERENCES.</p>
                        <p>
                            You can change your marketing privacy preferences,
                            or withdraw your consent in relation to how BMW UK,
                            BMW FS or BMW AG use your personal information in
                            one of the following ways:
                        </p>
                        <ul>
                            <li>
                                You can make individual changes directly in your
                                ConnectedDrive account or the Connected App,
                                where applicable.
                            </li>
                            <li>
                                You are also able to request information about
                                your data stored at BMW UK as well as request
                                the correction, deletion or restriction of your
                                personal data for analytics and/or marketing
                                use.
                            </li>
                            <li>
                                By contacting the BMW Customer Information
                                Centre on 0800 561 0666 *
                            </li>
                            <li>
                                Or by sending an email to{" "}
                                <a href="mailto:customer.information@bmw.co.uk">
                                    customer.information@bmw.co.uk
                                </a>{" "}
                                or{" "}
                                <a href="mailto:customer.information@mini.co.uk">
                                    customer.information@mini.co.uk
                                </a>{" "}
                            </li>
                            <li>Or by writing to us at:​</li>
                            <li>
                                Or by writing to us at:
                                <br />
                                <br />
                                BMW Customer Information Centre
                                <br />
                                Phoenix One
                                <br />
                                59 - 63 Farnham Road
                                <br />
                                Slough
                                <br />
                                Berkshire
                                <br />
                                SL1 3TN
                            </li>
                        </ul>
                        <p>
                            * Calls are free of charge plus your phone company's
                            access charge.
                        </p>
                        <p>
                            CONTACTING BMW GROUP UK ABOUT THIS PRIVACY POLICY.
                        </p>
                        <p>
                            If you have any questions in relation to our use of
                            your information you should first contact the BMW
                            Customer Information Centre in one of the following
                            ways:
                        </p>
                        <p>
                            · Call the centre on 0800 561 0666 (calls are free
                            of charge plus your phone company's access charge).
                        </p>
                        <ul>
                            <li>
                                Or by sending an email to{" "}
                                <a href="mailto:customer.information@bmw.co.uk">
                                    customer.information@bmw.co.uk
                                </a>{" "}
                                or{" "}
                                <a href="mailto:customer.information@mini.co.uk">
                                    customer.information@mini.co.uk
                                </a>{" "}
                            </li>
                            <li>
                                Or by writing to us at:
                                <br />
                                <br />
                                BMW Customer Information Centre
                                <br />
                                Phoenix One
                                <br />
                                59 - 63 Farnham Road
                                <br />
                                Slough
                                <br />
                                Berkshire
                                <br />
                                SL1 3TN
                            </li>
                        </ul>
                        <p>
                            In addition you may contact the responsible data
                            privacy officer listed. Click{" "}
                            <a href="https://www.bmw.co.uk/global/privacy-policy/data-privacy-officer-contact-details">
                                here
                            </a>{" "}
                            for their contact details.
                            <br />
                            <br />
                            Under certain conditions you have the right to
                            require us to:
                        </p>
                        <ul>
                            <li>
                                Provide you with further detail on the use we
                                make of your information
                            </li>
                            <li>Provide you with a copy of your information</li>
                            <li>
                                Update any inaccuracies in the information we
                                hold about you
                            </li>
                            <li>
                                Delete any information about you that we no
                                longer have a lawful ground to use
                            </li>
                            <li>
                                Remove you from any direct marketing lists when
                                you object or withdraw your consent
                            </li>
                            <li>
                                Provide you with your personal information in a
                                usable electronic format and transmit it to a
                                third party (right to data portability)
                            </li>
                            <li>
                                Restrict our use of your personal information
                            </li>
                            <li>
                                Cease carrying out certain processing activities
                                based on the legitimate interests ground unless
                                our reasons for undertaking that processing
                                outweigh any prejudice to your data protection
                                rights (click{" "}
                                <a href="https://www.bmw.co.uk/global/privacy-policy/how-your-information-is-used#legal-grounds">
                                    here
                                </a>{" "}
                                for more information).
                            </li>
                        </ul>
                        <p>
                            Your exercise of these rights is subject to certain
                            exemptions to safeguard the public interest (e.g.
                            the prevention or detection of crime), our interests
                            (e.g. the maintenance of legal privilege) and the
                            rights of third parties.
                            <br />
                            <br />
                            If you are dissatisfied with our use of your
                            information or our response to any exercise of these
                            rights you have the right to complain to the
                            Information Commissioner's Office which regulates
                            the processing of personal data in the UK (click{" "}
                            <a
                                href="https://ico.org.uk/"
                                target="_blank"
                                rel="noopener noreferrer"
                            >
                                here
                            </a>{" "}
                            for more information).
                        </p>
                    </Column>
                </Wrapper>
            </div>
        );
    }
}

export default Privacy;
