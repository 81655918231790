import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";

import Header from "./PublicHeader";
import Footer from "./Footer";
import { LogoutWrapper } from "@cortexglobal/cortex-auth-react-router-dom";
import { Button, Loading } from "@cortexglobal/rla-components";
export const AppContainer = styled.div`
    margin: auto;
    max-width: 400px;
    padding-top: 80px;
    padding-bottom: 80px;
    padding-left: 0;
    z-index: -9999px;
    min-height: 78vh;
`;

const loginUrl = "/auth/login";
class PrivateHeadOfficeApp extends Component {
    componentDidMount() {}

    render() {
        const { user, group } = this.props;
        return (
            <div>
                <Header />
                <AppContainer>
                    {user.loaded && group.loaded ? (
                        <React.Fragment>
                            <h1>Permission issue</h1>
                            <p>
                                There is an issue with your current permissions.
                                Please trying logging in again.
                            </p>
                            <LogoutWrapper loginUrl={loginUrl}>
                                {({ logout }) => (
                                    <Button
                                        href={loginUrl}
                                        onClick={logout}
                                        height={45}
                                        expanded
                                        style={{
                                            fontSize: "1em",
                                            fontWeight: "800",
                                            textTransform: "none",
                                            marginTop: "20px"
                                        }}
                                    >
                                        Log in
                                    </Button>
                                )}
                            </LogoutWrapper>{" "}
                        </React.Fragment>
                    ) : (
                        <Loading align="center" />
                    )}
                </AppContainer>
                <Footer />
            </div>
        );
    }
}
const mapStateToProps = state => {
    const { user, group } = state.auth;

    return { user, group };
};

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PrivateHeadOfficeApp);
