import styled from "styled-components";
import { Button, Row, Column } from "@cortexglobal/rla-components";

//import{ FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import UpdateStatusButton from "../../components/UpdateStatusButton";
import ProcessRewardButton from "../ProcessRewardButton";

import UpdateProbationButton from "../../components/UpdateProbationButton";
export const BodyRow = styled(Row)`
    background-color: #eaeaea;
    // box-shadow: 0 -5px 5px rgba(0, 0, 0, 0.8);
`;
export const HeaderRow = styled(Row)`
    display: none;
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        display: block;
    }
`;

export const RowWrapper = styled.div`
    margin-bottom: 0.5rem;
    overflow: hidden;
`;
export const HeaderColumn = styled(Column)`
    color: grey;
    height: 30px;
    select {
        margin-top: -0.5rem;
    }
`;
export const BodyColumn = styled(Column)`
    height: 60px;
    display: flex;
    align-items: center;
    overflow: hidden;
`;
export const BodyLabel = styled.span`
    display: ${props => (props.block ? "block" : "inline")};
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        display: none;
    }
`;

export const MarginButton = styled(Button)`
    margin-right: 1rem;
`;
export const StyledShowHideButton = styled(MarginButton).attrs(() => ({
    expanded: true
}))`
    padding: 0;
    // float: right;
    // display: block;
    // @media (min-width: ${props => props.theme.breakpoints.large}px) {
    //     float: none;
    // }
`;
export const StyledUpdateStatusButton = styled(UpdateStatusButton).attrs(
    ({ type }) => ({
        type: type || "primary",
        expanded: true
    })
)`
    margin-right: 1rem;
    padding: 0;
`;
export const StyledProcessRewardButton = styled(ProcessRewardButton).attrs(
    ({ type }) => ({
        type: type || "primary",
        expanded: true
    })
)`
    margin-right: 1rem;
    padding: 0;
`;

export const StyledUpdateProbationButton = styled(UpdateProbationButton).attrs(
    ({ type }) => ({
        type: type || "primary",
        expanded: true
    })
)`
    margin-right: 1rem;
    min-width: 90px;
`;
