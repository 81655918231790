import { combineReducers } from "redux";

import { routerReducer } from "react-router-redux";

import { authReducer as auth } from "@cortexglobal/cortex-auth-react-router-dom";
import survey from "../components/exit-survey/surveyReducer";
import staff from "../modules/manager/staff/staffReducer";
import roles from "../components/roles/roleReducer";
import retailer from "../components/retailer-information/retailerReducer";
import technicians from "../components/technician-summary/technicianReducer";
// import panels from "../components/panels/panelReducer";
import modal from "../reducers/popupReducer";
import leagues from "../modules/leagues/leagueReducer";
// import hallOfFame from "../modules/hallOfFame/hallOfFameReducer";
import redemptions from "../modules/manager/redemption/redemptionReducer";
import notificationsSent from "../components/notifications/notificationsReducer";
import {
    notificationsReducer as notifications,
    videoCompetitionReducer as videoCompetition
} from "@cortexglobal/bmw-shared-components";
const rootReducer = combineReducers({
    auth,
    leagues,
    modal,
    staff,
    redemptions,
    roles,
    retailer,
    technicians,
    notifications,
    notificationsSent,
    router: routerReducer,
    survey,
    videoCompetition
});

export default rootReducer;
