import React, { Component } from "react";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";
import BadgeTile from "./BadgeTile";

class BadgeTrack extends Component {

    showModal = (modalKey, item) => {
        this.props.showModal(modalKey, item);
    }

    render() {
        let { track, levels } = this.props;
        return <tr >
            <td
                style={{
                    borderRight:
                    "1px solid #ccc"
                }}
            >
                <div
                    style={{
                        fontSize: "7pt",
                        float: "right",
                        cursor: "pointer",
                        padding: "3px"
                    }}
                    onClick={this.showModal.bind(this, 'trackForm', track)}
                >
                    <FontAwesomeIcon icon="cog" />
                </div>
                <div
                    style={{
                        fontWeight: "bold"
                    }}
                >
                    {track.name}
                </div>
                <div
                    style={{
                        fontSize: "8pt"
                    }}
                >
                    {track.description}
                </div>
            </td>

            {levels.data.map(
                (level, index) => {
                    return <BadgeTile track={track} level={level} key={track.alias + '_' + index} showModal={this.showModal} />
                }
            )}
        </tr>
    }
}

export default BadgeTrack;
