import React, { Component } from "react";
import { connect } from "react-redux";
import { Switch, Route, withRouter } from "react-router-dom";
import { PrivateRoute } from "@cortexglobal/cortex-auth-react-router-dom";

import PrivateManagerApp from "./components/global/PrivateManagerApp";
import PrivateHeadOfficeApp from "./components/global/PrivateHeadOfficeApp";
import PrivateNoRoleApp from "./components/global/PrivateNoRoleApp";
import PublicApp from "./components/global/PublicApp";
import withTracker from "./components/routing/withTracker";

class TopLevelRouter extends Component {
    renderPrivateRoutes = (userType, groupType) => {
        //console.log(userType, groupType);
        if (userType === "manager") {
            return (
                <PrivateRoute
                    path="/"
                    component={withTracker(PrivateManagerApp)}
                />
            );
        }

        if (userType === "head-office" && groupType === "head-office") {
            return (
                <PrivateRoute
                    path="/"
                    component={withTracker(PrivateHeadOfficeApp)}
                />
            );
        }
        if (
            userType === "head-office" &&
            (groupType === "retailer" || groupType === "retailer-ire")
        ) {
            return (
                <PrivateRoute
                    path="/"
                    component={withTracker(PrivateManagerApp)}
                />
            );
        }
        return (
            <PrivateRoute path="/" component={withTracker(PrivateNoRoleApp)} />
        );
    };
    render() {
        const userType = this.props.user.role.type;
        const { type: groupType } = this.props.group;

        return (
            <Switch>
                <Route path="/auth" component={withTracker(PublicApp)} />
                <Route path="/info" component={withTracker(PublicApp)} />
                {this.renderPrivateRoutes(userType, groupType)}
            </Switch>
        );
    }
}

const mapStateToProps = state => {
    const { user, group } = state.auth;
    return { user, group };
};

export default withRouter(connect(mapStateToProps)(TopLevelRouter));
