import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import { loadComparisonLeague } from "../../leagues/leagueActions";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

import { LeagueTable, LeagueRow } from "../../leagues/styledComponents";

class ManagerComparisonLeague extends Component {
    componentDidMount() {
        if (!this.props.comparison.loaded) {
            this.props.loadComparisonLeague();
        }
    }
    render() {
        const { comparison, group } = this.props;
        if (!comparison.loaded || comparison.loading) {
            return <Loading />;
        }
        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <PageTitle
                            title="My Team"
                            subtitle="Who’s on the top spot in your team?"
                        />
                    </Column>
                </Row>
                {!comparison.loaded || comparison.loading ? (
                    <Loading />
                ) : (
                    <Row>
                        <Column>
                            <LeagueTable>
                                <thead>
                                    <tr>
                                        <th>Position</th>
                                        <th>Technician Name</th>
                                        <th>Gameplays</th>
                                        <th>High Score</th>
                                        <th>National Pos.</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {comparison.data
                                        .filter(leagueEntry => {
                                            return (
                                                leagueEntry.group_uuid ===
                                                group.uuid
                                            );
                                        })
                                        .map(leagueEntry => {
                                            return (
                                                <LeagueRow
                                                    key={leagueEntry.user_uuid}
                                                >
                                                    <td>
                                                        {
                                                            leagueEntry.group_position
                                                        }
                                                    </td>
                                                    <td>
                                                        {leagueEntry.user_name}
                                                    </td>
                                                    <td>
                                                        {leagueEntry.gameplays}
                                                    </td>
                                                    <td>
                                                        {
                                                            leagueEntry.total_correct
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            leagueEntry.national_position
                                                        }
                                                    </td>
                                                </LeagueRow>
                                            );
                                        })}
                                </tbody>
                            </LeagueTable>
                        </Column>
                    </Row>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { comparison } = state.leagues;
    const { group } = state.auth;

    return { comparison, group };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadComparisonLeague
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagerComparisonLeague);
