import React, { Component } from "react";
import { connect } from "react-redux";
import AppContext from "../../../context/AppContext";
import RetailerStatus from "../../../components/retailer-information/RetailerStatus";

class RetailerHome extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {({ user, group, retailers }) => (
                    <div>
                        <RetailerStatus retailers={retailers} />
                    </div>
                )}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

export default connect(mapStateToProps)(RetailerHome);
