import axios from "axios";

export const LOAD_CURRENT_TECHNICIAN_SUMMARY =
    "LOAD_CURRENT_TECHNICIAN_SUMMARY";
export const getCurrentTechnicianSummary = () => {
    return (dispatch) => {
        return axios
            .get(`/api/v1/manager/current-technician-summary`)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: LOAD_CURRENT_TECHNICIAN_SUMMARY,
                    data: data,
                });
            });
    };
};

export const LOAD_CURRENT_APPRENTICE_SUMMARY =
    "LOAD_CURRENT_APPRENTICE_SUMMARY";
export const getCurrentApprenticeSummary = () => {
    return (dispatch) => {
        return axios
            .get(`/api/v1/manager/current-apprentice-summary`)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: LOAD_CURRENT_APPRENTICE_SUMMARY,
                    data: data,
                });
            });
    };
};

export const LOAD_CURRENT_SERVICE_ADVISOR_SUMMARY =
    "LOAD_CURRENT_SERVICE_ADVISOR_SUMMARY";
export const getCurrentServiceAdvisorSummary = () => {
    return (dispatch) => {
        return axios
            .get(`/api/v1/manager/current-service-advisor-summary`)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: LOAD_CURRENT_SERVICE_ADVISOR_SUMMARY,
                    data: data,
                });
            });
    };
};

export const LOAD_CURRENT_SALES_EXECUTIVE_SUMMARY = 
    "LOAD_CURRENT_SALES_EXECUTIVE_SUMMARY";
export const getCurrentSalesExecutiveSummary = () => {
    return (dispatch) => {
        return axios
            .get(`/api/v1/manager/current-sales-executive-summary`)
            .then((response) => {
                const { data } = response;
                dispatch({
                    type: LOAD_CURRENT_SALES_EXECUTIVE_SUMMARY,
                    data: data,
                });
            });
    };
};
