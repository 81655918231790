import axios from "axios";

export const SURVEY_INFORMATION_LOADING = "SURVEY_INFORMATION_LOADING";
export const LOAD_EXIT_SURVEY_SUMMARY = "LOAD_EXIT_SURVEY_SUMMARY";
export const getExitSurveySummary = () => {
    return (dispatch, getState) => {
        if (!getState().retailer.loaded) {
            dispatch({ type: SURVEY_INFORMATION_LOADING, data: true });
        }

        return axios
            .get(`/api/v1/manager/exit-survey/summary`)
            .then(response => {
                const { data } = response;
                dispatch({
                    type: LOAD_EXIT_SURVEY_SUMMARY,
                    data: data
                });
            });
    };
};
