import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import {
    SubmitButton,
    FormikInput,
    InputError,
    Panel,
    Button,
    Column
} from "@cortexglobal/rla-components";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import queryString from "query-string";
import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { resetPassword } from "@cortexglobal/cortex-auth-redux";

const LoginSchema = Yup.object().shape({
    email: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("Required"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Required")
});

class ResetPassword extends Component {
    constructor(props) {
        super(props);

        let token = queryString.parse(this.props.location.search).token;

        if (!token) {
            return (window.location.href = "/auth/forgotten");
        }

        this.state = {
            token: token,
            tokenError: false,
            success: false,
            successMessage: ""
        };
    }

    submitForm = (values, { setErrors }) => {
        this.props
            .resetPassword(values)
            .then(response => {
                console.log("data", response);

                this.setState(state => ({
                    success: true,
                    successMessage: response.response_trans
                }));
            })
            .catch(e => {
                parseValidationErrors(e)
                    .then(({ errors }) => {
                        setErrors(errors);
                    })
                    .catch(e => {
                        if (
                            e.response &&
                            e.response.status &&
                            e.response.status === 403
                        ) {
                            this.setState(state => ({
                                tokenError: e.response.data.data.response_trans
                            }));
                        }
                    });
            });
        return false;
    };

    render() {
        const { token, tokenError, success, successMessage } = this.state;
        return (
            <div>
                <h1>Reset Password</h1>
                {success && (
                    <Column style={{ paddingBottom: "1rem" }}>
                        <Panel type="success" padding={1}>
                            <p
                                style={{
                                    textAlign: "center",
                                    paddingBottom: "1rem"
                                }}
                            >
                                {successMessage}
                            </p>
                        </Panel>
                        <Button
                            expanded
                            type="primary"
                            height={40}
                            element={"a"}
                            href="/auth/login"
                        >
                            Go to Login
                        </Button>{" "}
                    </Column>
                )}
                <Formik
                    initialValues={{
                        token: token,
                        email: "",
                        password: "",
                        password_confirmation: ""
                    }}
                    onSubmit={this.submitForm}
                    validationSchema={LoginSchema}
                >
                    {({ submitting }) => (
                        <Form className="form-group">
                            <FormikInput
                                type="text"
                                name="token"
                                placeholder=""
                                value={token}
                                readOnly={true}
                            />

                            {tokenError && <InputError error={tokenError} />}
                            <FormikInput
                                type="text"
                                name="email"
                                label="Email"
                                placeholder="user@domain.com"
                            />
                            <FormikInput
                                type="password"
                                name="password"
                                label="New Password"
                                placeholder=""
                            />
                            <FormikInput
                                type="password"
                                name="password_confirmation"
                                label="Confirm Password"
                                placeholder=""
                            />
                            <SubmitButton
                                expanded
                                label="Reset Password"
                                submitting={submitting}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

const mapStateToProps = (state, props) => {
    return {};
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            resetPassword
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ResetPassword);
