import React, { Component } from "react";
import { connect } from "react-redux";
import InitialInvites from "../staff/InitialInvites";
import AppContext from "../../../context/AppContext";
import RetailerInformation from "../../../components/retailer-information/RetailerInformation";
import ActionsRequired from "../../../components/actions-required/ActionsRequired";

class Home extends Component {
    state = {
        initialInvitesPending: true,
    };
    componentDidMount() {
        if (this.props.initialInvites.loaded) {
            this.setState({
                initialInvitesPending:
                    this.props.initialInvites.data.length > 0,
            });
        }
    }
    updateInitialInvitePendingStatus = (newStatus) => {
        this.setState({
            initialInvitesPending: newStatus,
        });
    };
    render() {
        return (
            <AppContext.Consumer>
                {({ user, group /*retailers*/ }) => (
                    <React.Fragment>
                        {this.state.initialInvitesPending ? (
                            <InitialInvites
                                updateInitialInvitePendingStatus={
                                    this.updateInitialInvitePendingStatus
                                }
                            />
                        ) : (
                            <React.Fragment>
                                <ActionsRequired />
                                <RetailerInformation
                                    group={group}
                                    // retailer={retailers}
                                />
                            </React.Fragment>
                        )}
                    </React.Fragment>
                )}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = (state) => {
    const { initialInvites, loading } = state.staff;

    return { initialInvites, loading };
};

export default connect(mapStateToProps)(Home);
