import React, { Component } from "react";
import PropTypes from "prop-types";
import { Card, Title } from "./dashboardComponents.js";

class DashboardPanel extends Component {
    render() {
        const { deepTitle } = this.props;
        return (
            <Card>
                <Title deepTitle={deepTitle}>{this.props.title}</Title>
                <div className="content">{this.props.children}</div>
            </Card>
        );
    }
}

DashboardPanel.propTypes = {
    deepTitle: PropTypes.bool
};
DashboardPanel.defaultProps = {
    deepTitle: false
};

export default DashboardPanel;
