import moment from "moment";

/**
 * Gets the next weekday from the start date.  The weekday can be supplied, otherwise it's assumed to be a Monday
 * @param {moment} startDate A moment date that you want the next specific weekday from
 * @param {int} isoWeekday The next day you require (defaults to Monday which is ISO weekday 1)
 */
export const getNextWeekDay = (startDate, isoWeekday = 1) => {
    const startDay = startDate.isoWeekday();
    // if the day is the required day
    if (startDay === isoWeekday) {
        // then just give me the date back
        return startDate;
    } else {
        // otherwise, give me *next week's* instance of that same day
        return startDate.add(1, "weeks").isoWeekday(isoWeekday);
    }
};
/**
 * Gets the previous weekday from the start date.  The weekday can be supplied, otherwise it's assumed to be a Monday
 * @param {moment} startDate A moment date that you want the next specific weekday from
 * @param {int} isoWeekday The next day you require (defaults to Monday which is ISO weekday 1)
 */
export const getPreviousWeekDay = (startDate, isoWeekday = 1) => {
    const startDay = startDate.isoWeekday();
    // if the day is the required day
    if (startDay === isoWeekday) {
        // then just give me the date back
        return startDate;
    } else {
        // otherwise, give me *previous week's* instance of that same day
        return startDate.subtract(1, "weeks").isoWeekday(isoWeekday);
    }
};

/**
 * Gets the date of the required weekday in the week of the supplied date.  The weekday can be supplied, otherwise it's assumed to be a Monday
 * @param {moment} startDate A moment date that you want the next specific weekday from
 * @param {int} isoWeekday The next day you require (defaults to Monday which is ISO weekday 1)
 */
export const getWeekDayInWeek = (startDate, isoWeekday = 1) => {
    const startDay = startDate.isoWeekday();
    // if we haven't yet passed the day of the week that I need:
    if (startDay <= isoWeekday) {
        // then just give me this week's instance of that day
        return startDate.isoWeekday(isoWeekday);
    } else {
        // otherwise, give me *next week's* instance of that same day
        return startDate.add(1, "weeks").isoWeekday(isoWeekday);
    }
};
