import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const InputErrorPanel = styled.span`
    font-size: 0.7rem;
    margin-top: -1rem;
    display: block;
    color: ${props => props.theme.input.error.color};
`;

const InputError = props => {
    if (!props.error) {
        return null;
    }
    console.log();
    return (
        <InputErrorPanel style={props.errorStyle}>
            {props.error}
        </InputErrorPanel>
    );
};

InputError.displayName = "InputError";

InputError.propTypes = {
    // error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool])
};

InputError.defaultProps = {
    error: ""
};

export default InputError;
