import axios from "axios";

export const RESET_BADGE_TRACKS = "RESET_BADGE_TRACKS";

export function resetBadgeTracks() {
    return {
        type: RESET_BADGE_TRACKS
    };
}

export const LOAD_BADGE_TRACKS = "LOAD_BADGE_TRACKS";

export function getBadgeTracks() {
    return (dispatch, getState) => {
        return axios
            .get(`/api/v1/badge-tracks`)
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: LOAD_BADGE_TRACKS,
                    data
                });
            })
            .catch(e => e);
    };
}

export const LOAD_BADGE_LEVELS = "LOAD_BADGE_LEVELS";

export function getBadgeLevels() {
    return (dispatch, getState) => {
        return axios
            .get(`/api/v1/badge-levels`)
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: LOAD_BADGE_LEVELS,
                    data
                });
            })
            .catch(e => e);
    };
}

export const UPDATE_TRACK = "UPDATE_TRACK";

export function updateTrack(input) {
    return (dispatch, getState) => {
        return axios
            .put(`/api/v1/track`, input)
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: UPDATE_TRACK,
                    data
                });
            })
            .catch(e => e);
    };
}

export const UPDATE_BADGE = "UPDATE_BADGE";

export function updateBadge(input) {
    return (dispatch, getState) => {
        return axios
            .put(`/api/v1/badge`, input)
            .then(response => response.data.data)
            .then(data => {
                dispatch({
                    type: UPDATE_BADGE,
                    data
                });
            })
            .catch(e => e);
    };
}
