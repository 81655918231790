import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, Row, Column } from "@cortexglobal/rla-components";

import StaffForm from "./StaffForm";
import { editStaffMember } from "./staffActions";

import LinkStyleButton from "../../../components/LinkStyleButton";
class EditStaff extends Component {
    state = {
        showEditModal: false
    };
    showEditModal = () => {
        this.setState({ showEditModal: true });
    };
    editStaffMember = values => {
        return this.props.editStaffMember(values).then(() => this.onClose());
    };
    onClose = () => {
        this.setState({
            showEditModal: false
        });
    };
    render() {
        const { member } = this.props;
        const { showEditModal } = this.state;
        return (
            <React.Fragment>
                <LinkStyleButton member={member} onClick={this.showEditModal}>
                    Edit Profile
                </LinkStyleButton>
                {showEditModal && (
                    <Modal visible={showEditModal} onClose={this.onClose}>
                        <Row>
                            <Column>
                                <h1 style={{ textAlign: "center" }}>
                                    Editing {member.first_name}{" "}
                                    {member.last_name}
                                </h1>
                            </Column>
                        </Row>
                        <StaffForm
                            member={member}
                            onSubmit={this.editStaffMember}
                        />
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {};
};
const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            editStaffMember
        },
        dispatch
    );

EditStaff.propTypes = {
    member: PropTypes.object
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditStaff);
