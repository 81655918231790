import { createStore, applyMiddleware, compose } from "redux";
import ReduxThunk from "redux-thunk";
import { routerMiddleware } from "react-router-redux";
import Raven from "raven-js"; // Or, you might already have this as `window.Raven`.
import createRavenMiddleware from "raven-for-redux";

import rootReducer from "./reducers";

//Configure error tracking
if (process.env.REACT_APP_LOG_ERRORS_TO_SENTRY === "true") {
    Raven.config(process.env.REACT_APP_SENTRY_DSN, {
        autoBreadcrumbs: {
            console: false
        }
    }).install();
}

export default function configureStore(initialState, history) {
    const composeEnhancers =
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
    const store = createStore(
        rootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(
                ReduxThunk,
                routerMiddleware(history),
                createRavenMiddleware(Raven, {
                    // Optionally pass some options here.
                })
            )
        )
    );

    if (process.env.NODE_ENV !== "production") {
        if (module.hot) {
            module.hot.accept("./reducers", () => {
                store.replaceReducer(rootReducer);
            });
        }
    }

    return store;
}
