import React, { Component } from "react";
import ReactGA from "react-ga";
import Raven from "raven-js";
import axios from "axios";

//Google Analytics
const gtm = "UA-73251467-5";

ReactGA.initialize(gtm, {
    debug: false
});

const withTracker = (WrappedComponent, options = {}) => {
    const trackPage = page => {
        axios
            .post("/api/v1/page-viewed", {
                pathname: page,
                hostname: window.location.hostname
            })
            .then(response => {})
            .catch(err => {
                //console.log("err", err);
                Raven.captureException(err);
            });
        if (!process.env.REACT_APP_LOG_PAGE_VIEWS) {
            return;
        }
        //console.log(page);
        ReactGA.set({
            page,
            ...options
        });
        ReactGA.pageview(page);
    };

    const HOC = class extends Component {
        componentDidMount() {
            const page = this.props.location.pathname;
            trackPage(page);
        }

        componentWillReceiveProps(nextProps) {
            const currentPage = this.props.location.pathname;
            const nextPage = nextProps.location.pathname;

            if (currentPage !== nextPage) {
                trackPage(nextPage);
            }
        }

        render() {
            return <WrappedComponent {...this.props} />;
        }
    };

    return HOC;
};

export default withTracker;
