import {
    LOADING_COMPARISON_GAME_LEAGUE,
    LOAD_COMPARISON_GAME_LEAGUE,
    LOAD_COMPARISON_GAME_GAMEPLAYS,
    LOADING_CHAMPIONS_LEAGUE,
    LOAD_CHAMPIONS_LEAGUE,
    LOADING_LEAGUE_VISITS,
    LOAD_LEAGUE_VISITS
} from "./leagueActions";

export const initialState = {
    comparison: {
        loaded: false,
        loading: false,
        data: []
    },
    champions: {
        loaded: false,
        loading: false,
        data: []
    },
    leagueVisits: {
        loaded: false,
        loading: false,
        data: []
    }
};
export default (state = initialState, action) => {
    switch (action.type) {
        case LOADING_CHAMPIONS_LEAGUE:
            return {
                ...state,
                champions: {
                    ...state.champions,
                    loading: true
                }
            };

        case LOAD_CHAMPIONS_LEAGUE:
            return {
                ...state,
                champions: {
                    ...state.champions,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };

        case LOADING_COMPARISON_GAME_LEAGUE:
            return {
                ...state,
                comparison: {
                    ...state.comparison,
                    loading: true
                }
            };
        case LOAD_COMPARISON_GAME_LEAGUE:
            return {
                ...state,
                comparison: {
                    ...state.comparison,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };
        case LOAD_COMPARISON_GAME_GAMEPLAYS:
            return {
                ...state,
                comparison: {
                    ...state.comparison,
                    loaded: true,
                    loading: false,
                    data: state.comparison.data.map(userTotals => {
                        const gameplayTotal = action.data.filter(
                            userGameplayCount => {
                                return (
                                    userGameplayCount.user_uuid ===
                                    userTotals.user_uuid
                                );
                            }
                        )[0];
                        if (gameplayTotal) {
                            userTotals.gameplays = gameplayTotal.gameplay_count;
                        }
                        return userTotals;
                    })
                }
            };
        case LOADING_LEAGUE_VISITS:
            return {
                ...state,
                leagueVisits: {
                    ...state.leagueVisits,
                    loading: true
                }
            };
        case LOAD_LEAGUE_VISITS:
            return {
                ...state,
                leagueVisits: {
                    ...state.leagueVisits,
                    loaded: true,
                    loading: false,
                    data: action.data
                }
            };

        default:
            return state;
    }
};
