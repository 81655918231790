import React, { Component } from "react";
import PropTypes from "prop-types";
// import styled from "styled-components";
import { cloneDeep } from "lodash";

import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DashboardPanel from "../dashboard/DashboardPanel";
import AppContext from "../../context/AppContext";
import { breakpoints } from "@cortexglobal/bmw-shared-components";

import { Row, Column, Loading } from "@cortexglobal/rla-components";
import NewTechnicianRunRate from "./NewTechnicianRunRate";
import NewTechnicianMonthlyTotals from "./NewTechnicianMonthlyTotals";
import TechnicianYTDOverview from "./TechnicianYTDOverview";
const initialState = {
    totals: {
        new: 0,
        leaving: 0,
        net: 0
    },
    months: {}
};
class TechnicianSummary extends Component {
    state = cloneDeep(initialState);

    componentDidMount() {
        this.processData();
    }
    componentDidUpdate(prevProps) {
        if (
            prevProps.monthlyRetailersSummary.loaded === false &&
            this.props.monthlyRetailersSummary.loaded
        ) {
            this.processData();
        }
    }

    processData = () => {
        const summaryInfo = this.props.monthlyRetailersSummary.data.reduce(
            (totals, monthData, index) => {
                const netGain =
                    monthData.new_technicians_month -
                    monthData.leaving_technicians_month;
                totals.totals = {
                    new: (totals.totals.new += monthData.new_technicians_month),
                    leaving: (totals.totals.leaving +=
                        monthData.leaving_technicians_month),
                    net: (totals.totals.net += netGain)
                };
                totals.months[monthData.month] = {
                    new: monthData.new_technicians_month,
                    leaving: monthData.leaving_technicians_month,
                    net: netGain
                };
                return totals;
            },
            cloneDeep(initialState)
        );

        this.setState({ ...summaryInfo });
    };

    render() {
        const { monthlyRetailersSummary, techniciansRequired } = this.props;
        if (!monthlyRetailersSummary.loaded) {
            return <Loading align="center" />;
        }
        return (
            <AppContext.Consumer>
                {({ windowWidth }) => {
                    // console.log(
                    //     windowWidth > breakpoints.xlarge,
                    //     windowWidth,
                    //     breakpoints.xlarge
                    // );
                    return (
                        <React.Fragment>
                            <Row>
                                <Column>
                                    <PageTitle
                                        title="New Technicians"
                                        subtitle="New technicians' status"
                                    />
                                </Column>
                            </Row>
                            <TechnicianYTDOverview
                                totals={this.state.totals}
                                techniciansRequired={techniciansRequired}
                            />
                            <Row
                                equaliseChildHeight={
                                    windowWidth > breakpoints.xlarge
                                }
                            >
                                <Column xlarge={6}>
                                    <DashboardPanel title="Total New Technicians">
                                        <NewTechnicianMonthlyTotals
                                            months={this.state.months}
                                        />
                                    </DashboardPanel>
                                </Column>
                                <Column xlarge={6}>
                                    <DashboardPanel title="Total new technicians: run-rate tracker">
                                        <NewTechnicianRunRate
                                            monthlyRetailersSummary={
                                                monthlyRetailersSummary
                                            }
                                            techniciansRequired={
                                                techniciansRequired
                                            }
                                        />
                                    </DashboardPanel>
                                </Column>
                            </Row>
                        </React.Fragment>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

TechnicianSummary.propTypes = { showProgress: PropTypes.bool };

TechnicianSummary.defaultProps = { showProgress: false };

export default TechnicianSummary;
