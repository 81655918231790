export default {
    "===": (a, b) => {
        return a === b;
    },
    "!==": (a, b) => {
        return a !== b;
    },
    "<": (a, b) => {
        return a < b;
    },
    "<=": (a, b) => {
        return a <= b;
    },
    ">": (a, b) => {
        return a > b;
    },
    ">=": (a, b) => {
        return a >= b;
    }
};
