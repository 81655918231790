import React, { Component } from "react";
import PropTypes from "prop-types";
import SortableHeader, { StyledHeader } from "../table/SortableHeader";

import {
    StyledTable,
    StyledBodyRow,
    StyledBodyData,
    RetailerName
} from "../table/headOfficeTable";
import NotifyRetailerButton from "../notifications/NotifyRetailerButton";

class EngagementStatusTable extends Component {
    render() {
        const { sortSettings, sortFunction, retailers } = this.props;
        return (
            <StyledTable>
                <thead>
                    <tr>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="name"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            Retailer Name
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="technicians_total_sessions"
                            sortSettings={sortSettings}
                        >
                            No. sessions
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="total_rewards"
                            sortSettings={sortSettings}
                        >
                            Total rewards
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="total_rewards_claimed"
                            sortSettings={sortSettings}
                        >
                            Total claimed
                        </SortableHeader>
                        <StyledHeader
                            sortFunction={sortFunction}
                            property="technicians_required"
                            sortSettings={sortSettings}
                        >
                            Actions
                        </StyledHeader>
                    </tr>
                </thead>
                <tbody>
                    {retailers.map(retailer => {
                        //console.log(retailer);
                        return (
                            <StyledBodyRow key={retailer.alias}>
                                <StyledBodyData align="left">
                                    <RetailerName>{retailer.name}</RetailerName>
                                    {retailer.alias}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.technicians_total_sessions}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.total_rewards}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.total_rewards_claimed}
                                </StyledBodyData>
                                <StyledBodyData>
                                    <NotifyRetailerButton retailer={retailer} />
                                </StyledBodyData>
                            </StyledBodyRow>
                        );
                    })}
                </tbody>
            </StyledTable>
        );
    }
}

EngagementStatusTable.propTypes = {
    retailers: PropTypes.array.isRequired,
    sortFunction: PropTypes.func.isRequired,
    sortSettings: PropTypes.shape({
        sortBy: PropTypes.string,
        sortDirection: PropTypes.oneOf(["", "asc", "desc"])
    })
};
export default EngagementStatusTable;
