import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";

/**
 * TODO - Investigate swapping react-paginate for this:
 * https://github.com/ChoTotOSS/react-paginating
 * It uses render props, so would allow for a nicer component architecture
 * It doesn't appear to natively handle margin pages (neighbouring pages)
 * but this tutorial explains an option:
 * https://scotch.io/tutorials/build-custom-pagination-with-react
 */
import ReactPaginate from "react-paginate";

import "./pagination.css";
export const StyledPagination = styled(ReactPaginate)`
    display: inline-block;
    padding-left: 15px;
    padding-right: 15px;
    li {
        display: inline-block;
    }
`;

const Pagination = ({ children, margin, padding, ...rest }) => (
    <StyledPagination {...rest} />
);

Pagination.displayName = "Pagination";

Pagination.propTypes = {
    pageCount: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    marginPagesDisplayed: PropTypes.number,
    pageRangeDisplayed: PropTypes.number,
    previousLabel: PropTypes.string,
    nextLabel: PropTypes.string,
    breakLabel: PropTypes.string,
    breakClassName: PropTypes.string,
    containerClassName: PropTypes.string,
    subContainerClassName: PropTypes.string,
    activeClassName: PropTypes.string
};

Pagination.defaultProps = {
    previousLabel: "<<",
    nextLabel: ">>",
    breakLabel: "...",
    marginPagesDisplayed: 2,
    pageRangeDisplayed: 5,
    breakClassName: "break-me",
    containerClassName: "pagination",
    subContainerClassName: "pages pagination",
    activeClassName: "active"
};

export default Pagination;
