import React, { Component } from "react";
import { Row, Column, Button, Loading } from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import ProfileDetails from "../../components/ProfileDetails";
import AppContext from "../../context/AppContext";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

class Profile extends Component {
    render() {
        return (
            <AppContext.Consumer>
                {({ user, group }) => {
                    if (!user.loaded || user.loading) {
                        return <Loading align="center" />;
                    }
                    return (
                        <div className="page page-profile">
                            <Row>
                                <Column medium={8}>
                                    <PageTitle
                                        title="My details"
                                        subtitle="A little information about you"
                                    />
                                </Column>
                                <Column
                                    medium={4}
                                    style={{ paddingTop: "1.5rem" }}
                                >
                                    <Button element={Link} to="/edit-profile">
                                        Edit profile
                                    </Button>
                                </Column>
                            </Row>
                            <Row style={{ padding: "0" }}>
                                <Column medium={12} style={{ padding: "0" }}>
                                    <ProfileDetails user={user} />
                                </Column>
                            </Row>
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

// const mapStateToProps = state => {
//     const { user } = state.auth;

//     return { user };
// };

// export default connect(mapStateToProps)(Profile);

export default Profile;
