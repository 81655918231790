import React, { Component } from "react";
import { Button, Modal } from "@cortexglobal/rla-components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NotifyForm from "./NotifyForm";

class NotifyRetailerButton extends Component {
    state = { showModal: false };
    showNotification = () => {
        this.setState({ showModal: true });
    };
    hideNotification = () => {
        this.setState({ showModal: false });
    };
    render() {
        const { showModal } = this.state;
        const { retailer, wording } = this.props;
        return (
            <div>
                <Button onClick={this.showNotification} title="Notify Retailer">
                    <FontAwesomeIcon icon="envelope" />
                    {wording && (
                        <span style={{ marginLeft: "10px" }}>{wording}</span>
                    )}
                </Button>
                {showModal && (
                    <Modal visible={showModal} onClose={this.hideNotification}>
                        <NotifyForm retailer={retailer} />
                    </Modal>
                )}
            </div>
        );
    }
}

export default NotifyRetailerButton;
