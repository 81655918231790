import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, InputField } from "@cortexglobal/rla-components";

import { forgotten } from "@cortexglobal/cortex-auth-redux";

const validate = values => {
    if (!values.email) {
        return {
            email: "Please enter your email."
        };
    }

    return {};
};

class RequestActivation extends Component {
    submitForgottenPassword(details) {
        return this.props.forgotten(details);
    }

    constructor(props) {
        super(props);
        this.state = {
            username: this.props.username ? this.props.username : "",
            password: this.props.password ? this.props.password : ""
        };
    }
    onChange = input => {
        this.setState({ [input.name]: input.value });
    };

    render() {
        const { handleSubmit, pristine, submitting } = this.props;

        return (
            <div>
                <h1>Forgotten Password</h1>
                <form
                    className="form-group"
                    onSubmit={handleSubmit(
                        this.submitForgottenPassword.bind(this)
                    )}
                >
                    <InputField
                        type="text"
                        name="email"
                        label="Email"
                        placeholder="user@domain.com"
                        onChange={this.onChange}
                    />

                    <Button disabled={pristine || submitting}>
                        Send me a reset link
                    </Button>
                </form>
            </div>
        );
    }
}

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            forgotten
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RequestActivation);
