import React from "react";
import styled, { css } from "styled-components";
import { shade, hexToRgb } from "../../../functions";

export const baseInputStyle = css`
    padding: 0 5px;
    height: ${props => props.height}px;
    width: ${props =>
        props.labelWidth ? `calc(100% - ${props.labelWidth}px)` : "100%"};
    border: 1px solid
        ${props =>
            props.error
                ? props.theme.input.error.borderColor
                : props.theme.input.borderColor};
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
    margin-bottom: ${props => props.theme.spacing.margin}rem;
    font-size: 1em;
    background: ${props => props.theme.input.background};
    color: ${props =>
        props.error ? props.theme.input.error.color : props.theme.input.color};

    // Input group styles
    ${props =>
        props.inputGroup
            ? css`
                  border-radius: 0 ${props => props.theme.input.radius}px
                      ${props => props.theme.input.radius}px 0;
                  border-left: none;
              `
            : css`
                  border-radius: ${props => props.theme.input.radius}px;
              `};

    // focus
    &:focus {
        outline: none;
        box-shadow: 0px 0px 0px 3px
            rgba(
                ${props => {
                    const rgb = hexToRgb(props.theme.input.focusColor);
                    return `${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2`;
                }}
            );
    }
`;

export default styled.input`
    ${baseInputStyle}
`;
