import React from "react";
import PropTypes from "prop-types";
import styled, { css } from "styled-components";
import BaseInput from "./baseInput";

const types = [
    "date",
    "datetime-local",
    "email",
    "hidden",
    "month",
    "number",
    "password",
    "search",
    "tel",
    "text",
    "time",
    "week"
];

export const StyledInput = styled(BaseInput)`
    ${props =>
        types.indexOf(props.type) !== -1 &&
        css`
            ::placeholder {
                color: ${props => props.theme.input.color};
                opacity: 0.5;
            }
        `};
`;

StyledInput.displayName = "StyledInput";

StyledInput.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    input: PropTypes.object,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string
};

StyledInput.defaultProps = {
    size: "default",
    type: "text",
    readOnly: false,
    error: false,
    height: 30
};

export default StyledInput;
