import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    SubmitButton,
    FormikInput,
    Loading
} from "@cortexglobal/rla-components";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { updateUser } from "./profileActions";

import AppContext from "../../context/AppContext";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

const LoginSchema = Yup.object().shape({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    email: Yup.string().email("Invalid email"),
    phone: Yup.string().nullable(),
    password: Yup.string()
        .min(8, "Your password must contain at least 8 characters")
        .max(70, "Too Long!")
        .matches(/^([a-zA-Z+]+[0-9+]+)$/, {
            excludeEmptyString: true,
            message:
                "You must provide a password containing letters and numbers"
        }),
    password_confirmation: Yup.string().when("password", {
        is: value => value !== undefined,
        then: Yup.string()
            .oneOf([Yup.ref("password"), null], "Passwords must match")
            .required("You must enter a matching password")
    })
});

class EditProfile extends Component {
    state = { submitting: false };

    submitForm = (values, { setErrors }) => {
        console.log(values);
        // return false;
        this.setState(state => ({
            submitting: true
        }));

        this.props
            .updateUser(values)
            .then(data => {
                this.setState(state => ({
                    submitting: false
                }));
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                    this.setState(state => ({
                        submitting: false
                    }));
                })
            );
    };

    render() {
        //console.log(this.props);
        const { submitting } = this.state;
        return (
            <AppContext.Consumer>
                {({ user, group }) => {
                    if (!user.loaded || user.loading) {
                        return <Loading align="center" />;
                    }
                    return (
                        <div className="page page-profile">
                            <Row>
                                <Column medium={10}>
                                    <PageTitle
                                        title="Edit Your details"
                                        subtitle="A little information about you"
                                    />
                                </Column>
                                <div className="form-container">
                                    <React.Fragment>
                                        <Formik
                                            initialValues={{
                                                role: user.role.id,
                                                first_name: user.first_name,
                                                last_name: user.last_name,
                                                email: user.email,
                                                phone:
                                                    user.additional_props.phone,
                                                password: "",
                                                password_confirmation: ""
                                            }}
                                            onSubmit={this.submitForm}
                                            validationSchema={LoginSchema}
                                            className="form-group"
                                        >
                                            {({ values, errors }) => (
                                                <Form className="form-group">
                                                    <Row>
                                                        <Column large={4}>
                                                            <FormikInput
                                                                type="text"
                                                                name="first_name"
                                                                label="First Name"
                                                                value={
                                                                    values.first_name
                                                                }
                                                                placeholder="Joe"
                                                            />
                                                            <FormikInput
                                                                type="text"
                                                                name="last_name"
                                                                label="Last Name"
                                                                value={
                                                                    values.last_name
                                                                }
                                                                placeholder="Bloggs"
                                                            />
                                                        </Column>
                                                        <Column large={4}>
                                                            <FormikInput
                                                                type="email"
                                                                name="email"
                                                                label="Email"
                                                                value={
                                                                    values.email
                                                                }
                                                                placeholder="joe.bloggs@email.com"
                                                                autoComplete="nope"
                                                            />

                                                            <FormikInput
                                                                type="tel"
                                                                name="phone"
                                                                label="Mobile"
                                                                value={
                                                                    values.phone
                                                                }
                                                                placeholder="0777123456"
                                                            />
                                                        </Column>
                                                        <Column large={4}>
                                                            <FormikInput
                                                                type="password"
                                                                name="password"
                                                                label="Password"
                                                                autocomplete="new-password"
                                                            />
                                                            <FormikInput
                                                                type="password"
                                                                name="password_confirmation"
                                                                label="Password Confirmation"
                                                                autocomplete="new-password"
                                                            />
                                                            {/*<FormikDatePicker
                                                        name="start_date"
                                                        label="Start Date"
                                                        value={
                                                            values.start_date
                                                        }
                                                        maxDate={new Date()}
                                                    />
                                                    <RoleSelect
                                                        value={values.role}
                                                    />*/}
                                                        </Column>
                                                    </Row>
                                                    <Row>
                                                        <Column small={4}>
                                                            <SubmitButton
                                                                expanded
                                                                submitting={
                                                                    submitting
                                                                }
                                                                disabled={
                                                                    submitting
                                                                }
                                                            >
                                                                Save
                                                            </SubmitButton>
                                                        </Column>
                                                    </Row>
                                                </Form>
                                            )}
                                        </Formik>
                                    </React.Fragment>
                                </div>
                            </Row>
                        </div>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

const mapStateToProps = state => {
    const { user } = state.auth;

    return { user };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            updateUser
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(EditProfile);
