import React from "react";
import PropsTypes from "prop-types";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
    Button,
    Modal,
    Row,
    Column,
    TextareaField,
} from "@cortexglobal/rla-components";
import { updateRewardStatus } from "../modules/manager/staff/staffActions";

const ErrorMessage = styled.p`
    padding-top: 1rem;
    color: ${(props) => props.theme.colors.alert};
`;
class ProcessRewardButton extends React.Component {
    state = { submitting: false, showingModal: "", error: "", notes: "" };
    showModal = () => {
        this.setState({
            showingModal: this.props.choice,
        });
    };
    onClose = () => {
        this.setState({
            showingModal: "",
        });
    };
    updateReward = () => {
        this.setState((state) => ({
            submitting: true,
            error: "",
        }));
        return this.props
            .updateRewardStatus(
                this.props.choice,
                this.props.userUuid,
                this.props.rewardDetail.uuid,
                this.state.notes
            )
            .then(() => this.onClose())
            .catch((e) => {
                this.setState((state) => ({
                    submitting: false,
                    error: e.message,
                }));
            });
    };
    render() {
        const {
            updating,
            updatingUserUuid,
            children,
            member,
            rewardDetail: detail,
            ...rest
        } = this.props;
        const { showingModal, submitting, error, notes } = this.state;

        //console.log("children", children);
        return (
            <React.Fragment>
                <Button
                    {...rest}
                    disabled={updating}
                    submitting={this.props.userUuid === updatingUserUuid}
                    onClick={this.showModal}
                >
                    {children}
                </Button>
                {showingModal === "approve" && (
                    <Modal
                        visible={showingModal === "approve"}
                        onClose={this.onClose}
                    >
                        <Row>
                            <Column>
                                <h2 style={{ textAlign: "center" }}>
                                    Approve the {detail.reward.name} <br />
                                    reward for {member.first_name}{" "}
                                    {member.last_name}
                                </h2>
                            </Column>
                        </Row>
                        <Row style={{ maxWidth: "66%" }} center>
                            <Column>
                                <p>
                                    This will reward your technician &pound;
                                    {detail.reward.amount}.
                                </p>
                                <p>Are you sure you want to proceed?</p>

                                <TextareaField
                                    label="Reason (optional)"
                                    name="notes"
                                    value={notes}
                                    onChange={(event) => {
                                        this.setState({
                                            notes: event.value,
                                        });
                                    }}
                                />
                                <Button
                                    onClick={this.updateReward}
                                    expanded
                                    type="alert"
                                    disabled={submitting}
                                >
                                    Approve
                                </Button>

                                {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Column>
                        </Row>
                    </Modal>
                )}
                {showingModal === "reject" && (
                    <Modal
                        visible={showingModal === "reject"}
                        onClose={this.onClose}
                    >
                        <Row>
                            <Column>
                                <h1 style={{ textAlign: "center" }}>
                                    WARNING!
                                </h1>
                            </Column>
                        </Row>
                        <Row style={{ maxWidth: "66%" }} center>
                            <Column>
                                <p>
                                    By rejecting{" "}
                                    <strong>
                                        {member.first_name} {member.last_name}
                                    </strong>{" "}
                                    they will not receive the{" "}
                                    {detail.reward.name} reward of &pound;
                                    {detail.reward.amount}.
                                </p>
                                <p>
                                    If you are sure you want to proceed, please
                                    provide a reason
                                </p>

                                <TextareaField
                                    label="Reason"
                                    name="notes"
                                    value={notes}
                                    onChange={(event) => {
                                        this.setState({
                                            notes: event.value,
                                        });
                                    }}
                                />
                                <Button
                                    onClick={this.updateReward}
                                    expanded
                                    type="alert"
                                    disabled={submitting || notes === ""}
                                >
                                    Reject
                                </Button>

                                {error && <ErrorMessage>{error}</ErrorMessage>}
                            </Column>
                        </Row>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { updating, updatingUserUuid } = state.staff;
    return { updating, updatingUserUuid };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateRewardStatus,
        },
        dispatch
    );

ProcessRewardButton.propTypes = {
    choice: PropsTypes.string.isRequired,
    uuid: PropsTypes.string.isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProcessRewardButton);
