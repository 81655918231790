import axios from "axios";

export const NOTIFICATIONS_LOADING = "NOTIFICATIONS_LOADING";
export const NOTIFICATIONS_LOADED = "NOTIFICATIONS_LOADED";
export const NOTIFICATIONS_ERROR = "NOTIFICATIONS_ERROR";
export const NOTIFICATIONS_RESET_UNREAD = "NOTIFICATIONS_RESET_UNREAD";
export const getNotifications = () => {
    return dispatch => {
        dispatch({
            type: NOTIFICATIONS_LOADING
        });

        return axios
            .get("/api/v1/notifications")
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: NOTIFICATIONS_LOADED,
                    data
                });
                dispatch({
                    type: NOTIFICATIONS_RESET_UNREAD
                });
            })
            .catch(e => {
                dispatch({
                    type: NOTIFICATIONS_ERROR,
                    data: e.message
                });
            });
    };
};

export const NOTIFICATIONS_SUMMARY_LOADING = "NOTIFICATIONS_SUMMARY_LOADING";
export const NOTIFICATIONS_SUMMARY_LOADED = "NOTIFICATIONS_SUMMARY_LOADED";
export const NOTIFICATIONS_SUMMARY_ERROR = "NOTIFICATIONS_SUMMARY_ERROR";
export const getNotificationsSummary = () => {
    return dispatch => {
        dispatch({
            type: NOTIFICATIONS_SUMMARY_LOADING
        });
        return axios
            .get(`/api/v1/notifications/received-summary`)
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: NOTIFICATIONS_SUMMARY_LOADED,
                    data
                });
            })
            .catch(e => {
                dispatch({
                    type: NOTIFICATIONS_SUMMARY_ERROR,
                    data: e.message
                });
            });
    };
};

export const resetUnreadNotificationTotal = () => {
    return dispatch => {
        dispatch({
            type: NOTIFICATIONS_SUMMARY_LOADING
        });
    };
};
