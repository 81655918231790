import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import axios from "axios";
import { saveAs } from "file-saver";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import PropTypes from "prop-types";
// import styled from "styled-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DashboardPanel from "../dashboard/DashboardPanel";
import { Row, Column } from "@cortexglobal/rla-components";
import LinkStyleButton from "../../components/LinkStyleButton";

import { Value } from "../dashboard/dashboardComponents";

import { getExitSurveySummary } from "../exit-survey/surveyActions";
class ExitSurvey extends Component {
    state = {
        downloading: false,
    };

    componentDidMount() {
        if (!this.props.exitSurvey.loaded) {
            this.props.getExitSurveySummary();
        }
    }

    downloadSummary = (includeDrafts = false) => {
        this.setState({ downloading: includeDrafts ? "all" : "live" });
        axios
            .get(
                `/api/v1/manager/exit-survey/download${
                    includeDrafts ? `?include-drafts=true` : ""
                }`
            )
            .then((response) => {
                this.setState({ downloading: false });
                saveAs(new Blob([response.data]), "exit-survey-results.csv");
            });
    };
    render() {
        const {
            exitSurvey: { data: exitSurvey },
            role,
            group,
        } = this.props;
        const { downloading } = this.state;
        const canSeeDownloadAll =
            role &&
            role.type === "head-office" &&
            group &&
            group.type === "head-office";
        console.log(role.type, group);
        return (
            <div>
                <Row>
                    <Column medium={5}>
                        <PageTitle
                            title="Exit Survey Summary"
                            subtitle="Check the details of all exit surveys"
                        />
                    </Column>
                    {canSeeDownloadAll && (
                        <Column medium={4} style={{ textAlign: "right" }}>
                            <LinkStyleButton
                                onClick={() => this.downloadSummary(true)}
                                disable={downloading === "all"}
                            >
                                <FontAwesomeIcon icon="download" />{" "}
                                {downloading === "all"
                                    ? "Downloading..."
                                    : "Download Summary (All Questions)"}
                            </LinkStyleButton>
                        </Column>
                    )}
                    <Column
                        medium={canSeeDownloadAll ? 3 : 7}
                        style={{ textAlign: "right" }}
                    >
                        <LinkStyleButton
                            onClick={() => this.downloadSummary(false)}
                            disable={downloading === "live"}
                        >
                            <FontAwesomeIcon icon="download" />{" "}
                            {downloading === "live"
                                ? "Downloading..."
                                : "Download Summary"}
                        </LinkStyleButton>
                    </Column>
                </Row>
                <Row>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="No. surveys sent"
                        >
                            <Value>
                                {(exitSurvey.total
                                    ? exitSurvey.total
                                    : 0
                                ).toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="No. completed surveys"
                        >
                            <Value>
                                {(exitSurvey.submitted
                                    ? exitSurvey.submitted
                                    : 0
                                ).toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="Amazon e-Voucher Total"
                        >
                            <Value>
                                &pound;
                                {(exitSurvey.consented
                                    ? exitSurvey.consented * 10
                                    : 0
                                ).toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                </Row>
            </div>
        );
    }
}

ExitSurvey.propTypes = {};

const mapStateToProps = (state) => {
    const { loading, exitSurvey } = state.survey;
    const {
        user: { role },
        group,
    } = state.auth;

    return { loading, exitSurvey, role, group };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators({ getExitSurveySummary }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ExitSurvey);
