import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { StyledShowHideButton } from "./index";

class ShowHideButton extends React.Component {
    render() {
        const { showingDetail, showDetails } = this.props;
        return (
            <StyledShowHideButton onClick={showDetails} type="secondary">
                {showingDetail ? (
                    <React.Fragment>
                        Hide{" "}
                        <FontAwesomeIcon icon="angle-up" transform="grow-3" />
                    </React.Fragment>
                ) : (
                    <React.Fragment>
                        Show{" "}
                        <FontAwesomeIcon icon="angle-down" transform="grow-3" />
                    </React.Fragment>
                )}
            </StyledShowHideButton>
        );
    }
}

ShowHideButton.propTypes = {
    showDetails: PropTypes.func,
    member: PropTypes.object,
    showingDetail: PropTypes.bool
};
export default ShowHideButton;
