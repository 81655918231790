import React from "react";
import PropTypes from "prop-types";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledSelectAdvanced from "../styledElements/StyledSelectAdvanced";

const FormikAdvancedSelect = ({
    type,
    name,
    label,
    disabled,
    options,
    ...rest
}) => {
    return (
        <React.Fragment>
            <Field name={name}>
                {({
                    field: { name, onChange },
                    form: {
                        values,
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue
                    }
                }) => {
                    const error = !!touched[name] && errors[name];
                    //console.log(values[name]);
                    return (
                        <div style={{ marginBottom: "1.2em" }}>
                            {label && (
                                <FormLabel name={name}>{label}</FormLabel>
                            )}{" "}
                            <StyledSelectAdvanced
                                {...rest}
                                name={name}
                                options={options}
                                id={name}
                                value={
                                    options
                                        ? options.filter(
                                              option =>
                                                  option.value === values[name]
                                          )
                                        : ""
                                }
                                onChange={value => {
                                    //console.log(value);
                                    setFieldValue(name, value.value);
                                    setFieldTouched(name, true);
                                }}
                                onBlur={() => {
                                    setFieldTouched(name, true);
                                }}
                                error={error}
                                disabled={disabled}
                            />
                        </div>
                    );
                }}
            </Field>
            <ErrorMessage name={name}>
                {msg => <InputError error={msg} />}
            </ErrorMessage>
        </React.Fragment>
    );
};

FormikAdvancedSelect.displayName = "FormikAdvancedSelect";

FormikAdvancedSelect.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            text: PropTypes.string.isRequired
        })
    ),
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    placeholder: PropTypes.string,
    multi: PropTypes.bool,
    creatable: PropTypes.bool,
    tags: PropTypes.bool,
    error: PropTypes.string
};

FormikAdvancedSelect.defaultProps = {
    label: ""
};

export default FormikAdvancedSelect;
