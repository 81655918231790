import axios from "axios";

export const RETAILER_INFORMATION_LOADING = "RETAILER_INFORMATION_LOADING";
export const LOAD_RETAILER_INFORMATION = "LOAD_RETAILER_INFORMATION";
export const getRetailerInformation = () => {
    return (dispatch, getState) => {
        if (!getState().retailer.loaded) {
            dispatch({ type: RETAILER_INFORMATION_LOADING, data: true });
        }

        return axios
            .get(`/api/v1/manager/retailer-information`)
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_RETAILER_INFORMATION,
                    data: data
                });
            });
    };
};

export const LOAD_RETAILER_SUMMARIES = "LOAD_RETAILER_SUMMARIES";
export const getRetailers = () => {
    return (dispatch, getState) => {
        if (!getState().retailer.loaded) {
            dispatch({ type: RETAILER_INFORMATION_LOADING, data: true });
        }

        return axios.get(`/api/v1/manager/retailers`).then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_RETAILER_SUMMARIES,
                data: data
            });
        });
    };
};

export const LOAD_RETAILERS_MONTHLY_SUMMARY = "LOAD_RETAILERS_MONTHLY_SUMMARY";
export const getRetailersMonthlySummary = () => {
    return (dispatch, getState) => {
        if (!getState().retailer.loaded) {
            dispatch({ type: RETAILER_INFORMATION_LOADING, data: true });
        }

        return axios
            .get(`/api/v1/manager/retailers-monthly-summary`)
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_RETAILERS_MONTHLY_SUMMARY,
                    data: data
                });
            });
    };
};

export const LOAD_RETAILERS_ENGAGEMENT_SUMMARY =
    "LOAD_RETAILERS_ENGAGEMENT_SUMMARY";
export const getRetailersEngagementSummary = () => {
    return (dispatch, getState) => {
        if (!getState().retailer.loaded) {
            dispatch({ type: RETAILER_INFORMATION_LOADING, data: true });
        }

        return axios
            .get(`/api/v1/manager/retailers-engagement-summary`)
            .then(response => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_RETAILERS_ENGAGEMENT_SUMMARY,
                    data: data
                });
            });
    };
};
