import React, { Component } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import {
    Row,
    Column,
    Loading,
    Button,
    InputField,
} from "@cortexglobal/rla-components";
import OverallStatusTable from "./OverallStatusTable";
import EngagementStatusTable from "./EngagementStatusTable";
import GameplayStatusTable from "./GameplayStatusTable";
import BadgeStatusTable from "./BadgeStatusTable";
import RecruitmentStatusTable from "./RecruitmentStatusTable";
const RetailerSummaryContainer = styled.div``;

const initialState = {
    tab: "overall",
    sortBy: "name",
    sortDirection: "asc",
    searchText: "",
    retailers: [],
};
class RetailerStatus extends Component {
    state = initialState;

    componentDidMount() {
        this.setState({
            retailers: this.props.retailers.data.map((retailer) => {
                return this.processRetailer(retailer);
            }),
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.retailers.loaded !== this.props.retailers.loaded) {
            this.setState({
                retailers: this.props.retailers.data.map((retailer) => {
                    return this.processRetailer(retailer);
                }),
            });
        }
    }

    processRetailer = (retailer) => {
        let totalActive =
            retailer.active_technicians +
            retailer.active_apprentices +
            retailer.active_service_advisors;
        let totalCurrent =
            retailer.current_technicians +
            retailer.current_apprentices +
            retailer.current_service_advisors;
        return {
            ...retailer,
            visible: true,
            programme_engagement:
                totalActive > 0 && totalCurrent > 0
                    ? (totalActive / totalCurrent) * 100
                    : 0,
        };
    };

    toggleSearchDirection = () => {
        if (this.state.sortDirection === "asc") {
            this.setState((state) => ({ sortDirection: "desc" }));
        } else {
            this.setState((state) => ({ sortDirection: "asc" }));
        }
    };

    sortRetailers = (property) => {
        if (this.state.sortBy === property) {
            this.toggleSearchDirection();
        } else {
            this.setState((state) => ({
                sortDirection: initialState.sortDirection,
            }));
        }
        this.setState((state) => {
            return {
                retailers: [
                    ...state.retailers.sort((a, b) => {
                        if (a[property] < b[property])
                            return state.sortDirection === "asc" ? -1 : 1;
                        if (a[property] > b[property])
                            return state.sortDirection === "asc" ? 1 : -1;
                        return 0;
                    }),
                ],
                sortBy: property,
            };
        });
    };

    updateSearch = (event) => {
        //console.log(event);
        const { value } = event;
        this.setState((state) => {
            return {
                retailers: [
                    ...state.retailers.map((retailer) => {
                        if (
                            retailer.name
                                .toLowerCase()
                                .indexOf(value.toLowerCase()) !== -1
                        ) {
                            retailer.visible = true;
                        } else {
                            retailer.visible = false;
                        }
                        return retailer;
                    }),
                ],
                searchText: value,
            };
        });
    };
    selectTab = (tab) => {
        this.setState((state) => ({
            sortDirection: initialState.sortDirection,
            sortBy: initialState.sortBy,
            tab,
        }));
    };
    renderTab = () => {
        const { sortBy, sortDirection, tab, retailers } = this.state;
        const visibleRetailers = retailers.filter(
            (retailer) => retailer.visible
        );
        switch (tab) {
            case "overall":
                return (
                    <OverallStatusTable
                        sortFunction={this.sortRetailers}
                        sortSettings={{ sortBy, sortDirection }}
                        retailers={visibleRetailers}
                    />
                );
            case "gameplay":
                return (
                    <GameplayStatusTable
                        sortFunction={this.sortRetailers}
                        sortSettings={{ sortBy, sortDirection }}
                        retailers={visibleRetailers}
                    />
                );
            case "badges":
                return (
                    <BadgeStatusTable
                        sortFunction={this.sortRetailers}
                        sortSettings={{ sortBy, sortDirection }}
                        retailers={visibleRetailers}
                    />
                );
            case "recruitment":
                return (
                    <RecruitmentStatusTable
                        sortFunction={this.sortRetailers}
                        sortSettings={{ sortBy, sortDirection }}
                        retailers={visibleRetailers}
                    />
                );
            case "engagement":
            default:
                return (
                    <EngagementStatusTable
                        sortFunction={this.sortRetailers}
                        sortSettings={{ sortBy, sortDirection }}
                        retailers={visibleRetailers}
                    />
                );
        }
    };
    render() {
        //console.log(this.state);
        const { loaded } = this.props.retailers;
        const { tab, searchText } = this.state;

        if (!loaded) {
            return <Loading align="center" />;
        }
        return (
            <RetailerSummaryContainer>
                <Row>
                    <Column medium={9}>
                        <PageTitle
                            title="Retailer status"
                            subtitle="View the status of all your retailers"
                        />
                    </Column>
                    <Column medium={3}>
                        <InputField
                            style={{ marginTop: "1rem" }}
                            name="searchText"
                            value={searchText}
                            placeholder="Search..."
                            onChange={this.updateSearch}
                        />
                    </Column>
                </Row>
                <Row>
                    <Column medium={2}>
                        <Button
                            expanded
                            type={tab === "overall" ? "primary" : "secondary"}
                            onClick={this.selectTab.bind(this, "overall")}
                        >
                            Overall Status
                        </Button>
                    </Column>
                    <Column medium={2}>
                        <Button
                            expanded
                            type={
                                tab === "recruitment" ? "primary" : "secondary"
                            }
                            onClick={this.selectTab.bind(this, "recruitment")}
                        >
                            Recruitment
                        </Button>
                    </Column>
                    <Column medium={2}>
                        <Button
                            expanded
                            type={
                                tab === "engagement" ? "primary" : "secondary"
                            }
                            onClick={this.selectTab.bind(this, "engagement")}
                        >
                            Programme Engagement
                        </Button>
                    </Column>
                    <Column medium={2}>
                        {" "}
                        <Button
                            expanded
                            type={tab === "gameplay" ? "primary" : "secondary"}
                            onClick={this.selectTab.bind(this, "gameplay")}
                        >
                            Gameplay Engagement
                        </Button>
                    </Column>
                    <Column medium={2}>
                        {" "}
                        <Button
                            expanded
                            type={tab === "badges" ? "primary" : "secondary"}
                            onClick={this.selectTab.bind(this, "badges")}
                        >
                            Badges
                        </Button>
                    </Column>
                    <Column medium={2}>
                        {false && (
                            <Button
                                expanded
                                type={
                                    tab === "overall" ? "primary" : "secondary"
                                }
                                onClick={this.selectTab.bind(this, "overall")}
                            >
                                Overall Status
                            </Button>
                        )}
                    </Column>
                </Row>
                <Row>
                    <Column>{this.renderTab()}</Column>
                </Row>
            </RetailerSummaryContainer>
        );
    }
}

RetailerStatus.propTypes = {
    retailers: PropTypes.object.isRequired,
};
export default RetailerStatus;
