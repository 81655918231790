import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
// import PropTypes from "prop-types";
// import styled from "styled-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DashboardPanel from "../dashboard/DashboardPanel";
import { Row, Column } from "@cortexglobal/rla-components";
import { Value } from "../dashboard/dashboardComponents";

import { getRetailersEngagementSummary } from "../retailer-information/retailerActions";
class ProgrammeEngagement extends Component {
    componentDidMount() {
        if (!this.props.engagement.loaded) {
            this.props.getRetailersEngagementSummary();
        }
    }
    render() {
        const {
            engagement: { data: engagement }
        } = this.props;

        console.log(engagement);
        return (
            <div>
                <Row>
                    <Column>
                        <PageTitle
                            title="Programme Engagement"
                            subtitle="Check the programme engagement levels"
                        />
                    </Column>
                </Row>
                <Row>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="No. daily sessions"
                        >
                            <Value>
                                {engagement.totalDailyViews &&
                                    engagement.totalDailyViews.toLocaleString()}{" "}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel deepTitle={true} title="No. page views">
                            <Value>
                                {engagement.totalPageViews &&
                                    engagement.totalPageViews.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="No. weekly quizzes"
                        >
                            <Value>
                                {engagement.totalQuizzes &&
                                    engagement.totalQuizzes.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="No. This or That gameplays"
                        >
                            <Value>
                                {engagement.totalComparisonGames &&
                                    engagement.totalComparisonGames.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel deepTitle={true} title="No. of badges">
                            <Value>
                                {engagement.totalBadges &&
                                    engagement.totalBadges.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                    <Column medium={6} large={4}>
                        <DashboardPanel
                            deepTitle={true}
                            title="No. of VHC Videos"
                        >
                            <Value>
                                {engagement.totalVideoEntries &&
                                    engagement.totalVideoEntries.toLocaleString()}
                            </Value>
                        </DashboardPanel>
                    </Column>
                </Row>
            </div>
        );
    }
}

ProgrammeEngagement.propTypes = {};

const mapStateToProps = state => {
    const { loading, engagement } = state.retailer;

    return { loading, engagement };
};
const mapDispatchToProps = dispatch =>
    bindActionCreators({ getRetailersEngagementSummary }, dispatch);
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProgrammeEngagement);
