import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import { loadChampionsLeague } from "../../leagues/leagueActions";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { LeagueTable, LeagueRow } from "../../leagues/styledComponents";

class ManagerChampionsLeague extends Component {
    componentDidMount() {
        if (!this.props.champions.loaded) {
            this.props.loadChampionsLeague(this.props.group.uuid);
        }
    }
    render() {
        const { champions, group } = this.props;

        if (!champions.loaded || champions.loading) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <PageTitle
                            title="My Team"
                            subtitle="Who’s on the top spot in your team?"
                        />
                    </Column>
                </Row>
                {!champions.loaded || champions.loading ? (
                    <Loading />
                ) : (
                    <Row>
                        <Column>
                            <LeagueTable>
                                <thead>
                                    <tr>
                                        <th>Retailer Position</th>
													 <th style={{textAlign: "left"}}>Technician Name</th>
                                        <th>National Position</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {champions.data.map(
                                        (leagueEntry, index) => {
                                            return (
                                                <LeagueRow key={index}>
                                                    <td>
                                                        {leagueEntry.position}
                                                    </td>
																	 <td style={{textAlign: "left"}}>{leagueEntry.user}</td>
                                                    <td>
                                                        {
                                                            leagueEntry.national_position
                                                        }
                                                    </td>
                                                    <td>{leagueEntry.score}</td>
                                                </LeagueRow>
                                            );
                                        }
                                    )}
                                </tbody>
                            </LeagueTable>
                        </Column>
                    </Row>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { champions } = state.leagues;
    const { group } = state.auth;

    return { champions, group };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadChampionsLeague
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ManagerChampionsLeague);
