import React, { Component } from "react";
import { Row, Column, Button, Modal } from "@cortexglobal/rla-components";
import { Link } from "react-router-dom";
import {
    BackgroundImage,
    VideoWall,
    CenteredButton,
    RulesModal,
    WinnersLink,
    HeroOverlayTitle,
    StyledList,
    CountDown,
    ListItem
} from "./videoCompetitionComponents";
import styled from "styled-components";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactCountdown from "react-countdown";

import PageTitle from "../components/PageTitle";
import { Loading } from "@cortexglobal/rla-components";
import { loadVideoWall } from "./videoCompetitionActions";
import {
    videoCompetitionGroupedSelector,
    videoShowModalSelector
} from "./videoCompetitionSelectors";

import { EntriesModal } from "./StyledModal";
import moment from "moment";

const now = moment();
const competitionEnd = moment("2020-02-29 23:59:59", "YYYY-MM-DD HH:mm:ss"); // actual end date (subject to change)

const StyledCountdownSection = styled.div`
    display: inline-block;
    h5 {
        margin: 0.5rem 0.3rem 0 0.3rem;
    }
    h6 {
        margin: 0rem 0.3rem 0 0.3rem;
        font-size: 0.5rem;
    }
`;
const CountdownSection = ({ value, label }) => {
    return (
        <StyledCountdownSection>
            <h5>{value <= 9 ? "0" + value : value}</h5>
            <h6>{label}</h6>
        </StyledCountdownSection>
    );
};
const CountdownSeparator = () => {
    return <span style={{ marginTop: "0.1rem" }}>:</span>;
};
// Renderer callback with condition
const countdownRenderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed) {
        // Render a completed state
        return <WinnersLink />;
    } else {
        // Render a countdown
        return (
            <React.Fragment>
                <h4
                    style={{
                        fontSize: "1.1em",
                        fontWeight: "bold"
                    }}
                >
                    Time Remaining
                </h4>
                <CountDown>
                    <section>
                        <CountdownSection value={days} label="Days" />
                        <CountdownSeparator />{" "}
                        <CountdownSection value={hours} label="Hours" />
                        <CountdownSeparator />{" "}
                        <CountdownSection value={minutes} label="Minutes" />
                        <CountdownSeparator />{" "}
                        <CountdownSection value={seconds} label="Seconds" />
                    </section>
                </CountDown>
            </React.Fragment>
        );
    }
};
class VideoCompetition extends Component {
    constructor(props) {
        super(props);

        this.state = { rulesModalVisible: false };
        // this.showRulesModal = this.showRulesModal.bind(this);
    }

    showRulesModal() {
        this.setState({ rulesModalVisible: true });
    }

    hideRulesModal() {
        this.setState({ rulesModalVisible: false });
    }

    componentDidMount() {
        if (!this.props.loaded) {
            this.props.loadVideoWall(9, 0);
        }
    }

    render() {
        const {
            videoCompetition,
            showModal,
            loadVideoWall,
            loading,
            allVideosLoaded,
            hideSummary
        } = this.props;

        const { rulesModalVisible } = this.state;

        return (
            <div
                className="page"
                style={{ overflow: "hidden", marginBottom: "8em" }}
            >
                <Modal
                    visible={rulesModalVisible}
                    onClose={() => this.hideRulesModal()}
                >
                    <PageTitle
                        style={{ marginTop: "3em" }}
                        title="How to enter"
                    />
                    <StyledList>
                        <li>
                            Technicians who use CitNow will be required to
                            submit a video under a ‘Non-EVHC’ video purpose in
                            CitNow, using their own name as the customer and
                            technician, as well as academy@citnow.com into the
                            customer email field.
                        </li>
                        <li>
                            Retailers who do not use CitNow will be required to
                            upload their video to{" "}
                            <a
                                href="https://wetransfer.com/"
                                target="_blank"
                                rel="nofollow"
                            >
                                WeTransfer
                            </a>{" "}
                            making sure the file title includes their name,
                            Retailer and date submitted. Once uploaded they will
                            need to send their unique link to{" "}
                            <a href="mailto:academy@citnow.com">
                                academy@citnow.com
                            </a>
                            , also with their name, Retailer and the date
                            submitted.
                        </li>
                    </StyledList>{" "}
                    <p>
                        All videos will be watched and if they meet the criteria
                        requirements, the best ones will make it onto the Video
                        Wall on Elite for all to see!{" "}
                    </p>
                </Modal>
                {showModal ? <EntriesModal>modal</EntriesModal> : null}
                {!hideSummary && (
                    <React.Fragment>
                        <Row
                            style={{
                                padding: "0",
                                textAlign: "center",
                                width: "100%"
                            }}
                        >
                            <BackgroundImage style={{ width: "100%", position: "relative" }}>
                                <HeroOverlayTitle style={{top: "50%"}}>
                                    Vehicle Health Check Video
                                </HeroOverlayTitle>
                            </BackgroundImage>
                        </Row>
                        <Row>
                            <Column medium={12} large={6} xlarge={6}>
                                <PageTitle title="Enter for your chance to win Hospitality British Touring Car Championship tickets*" />
                                <p
                                    style={{
                                        marginBottom: "1em",
                                        color: "#888889",
                                        fontSize: ".9rem"
                                    }}
                                >
                                    What do you need to do, and how can you
                                    enter?
                                </p>
                            </Column>
                        </Row>
                        <Row>
                            <Column medium={12} large={4} xlarge={4}>
                                <p>
                                    Proud of your Vehicle Health Check videos?
                                    If so, why not enter the Vehicle Health
                                    Check competition and if yours is chosen as
                                    the best in your area, you'll win a pair of
                                    tickets to this year's BTCC*. Don't be shy,
                                    there'll be plenty of chances to win,
                                    because one runner-up from each area will
                                    receive a £50 Amazon top-up to their Elite
                                    accounts.
                                </p>
                                <p
                                    style={{
                                        marginBottom: "10px",
                                        fontSize: "1.1em"
                                    }}
                                >
                                    <strong>Winners & Prizes</strong>
                                </p>
                                <StyledList>
                                    <li>
                                        9 Winners (one from each area) - pair of
                                        BTCC 2020 tickets*
                                    </li>
                                    <li>
                                        9 Runners up (one from each area) - £50
                                        Amazon top-up
                                    </li>
                                </StyledList>{" "}
                                <p style={{ marginTop: "10px" }}>
                                    *Travel and accommodation costs not
                                    included.
                                </p>
                                <br />
                                <p>
                                    All entries must be submitted by{" "}
                                    <strong>
                                        midnight, Saturday 29th February.
                                    </strong>
                                </p>
                                <Button
                                    style={{
                                        height: "50px",
                                        marginBottom: "1rem"
                                    }}
                                    onClick={() => this.showRulesModal()}
                                    expanded={true}
                                >
                                    How to Enter
                                </Button>
                            </Column>

                            <Column medium={12} large={4} xlarge={4}>
                                <p
                                    style={{
                                        marginBottom: "10px",
                                        fontSize: "1.1em"
                                    }}
                                >
                                    <strong>Entry requirements</strong>
                                </p>

                                <StyledList>
                                    <li>
                                        Must be a real car VHC video (no
                                        registration visible)
                                    </li>
                                    <li>Must be engaging to watch</li>
                                    <li>Simple for customers to understand</li>
                                    <li>
                                        Speech must be clear (avoid background
                                        noise)
                                    </li>
                                    <li>
                                        Ideally between 2 and 3 minutes long
                                    </li>
                                    <li>
                                        Camera must be horizontal not vertical
                                    </li>
                                </StyledList>
                            </Column>
                            <Column medium={12} large={4} xlarge={4}>
                                {now.isAfter(competitionEnd) ? (
                                    <WinnersLink />
                                ) : (
                                    <ReactCountdown
                                        date={competitionEnd.toDate()}
                                        // date={Date.now() + 5000}
                                        renderer={countdownRenderer}
                                    />
                                )}
                            </Column>
                        </Row>{" "}
                        {/* <Row>
                            <Column medium={12} large={3} xlarge={4}>
                                <Button
                                    style={{ width: "250px", height: "50px" }}
                                    onClick={this.showRulesModal}
                                >
                                    How to Enter
                                </Button>
                            </Column>
                            <Column medium={12} large={3} xlarge={4}></Column>
                            <Column medium={12} large={3} xlarge={4}></Column>
                        </Row> */}
                    </React.Fragment>
                )}
                <Row style={{ width: "100%", margin: "0", padding: "0" }}>
                    <Column medium={12} large={6} xlarge={4}>
                        {!hideSummary ? (
                            <p
                                style={{
                                    marginBottom: ".5em",
                                    color: "#888889",
                                    fontSize: ".9rem"
                                }}
                            >
                                Check out the competition, or just look for
                                inspiration.
                                <br />
                                <span
                                    style={{
                                        color: "black",
                                        fontSize: "1.3em",
                                        margin: "0",
                                        padding: "0",
                                        fontWeight: "600"
                                    }}
                                >
                                    The Videos
                                </span>
                            </p>
                        ) : (
                            <React.Fragment>
                                <BackgroundImage style={{ width: "1400px", position: "relative" }}>
                                    <HeroOverlayTitle style={{top: "50%"}}>
                                        Vehicle Health Check Video
                                    </HeroOverlayTitle>
                                </BackgroundImage>
                                <p
                                    style={{
                                        color: "black",
                                        fontSize: "1.3em",
                                        margin: "0",
                                        padding: "0",
                                        fontWeight: "600"
                                    }}
                                >
                                    The Videos
                                </p>
                                <p
                                    style={{
                                        marginBottom: ".5em",
                                        color: "#888889",
                                        fontSize: ".9rem"
                                    }}
                                >
                                    Check out the entries below.
                                </p>
                                {now.isAfter(competitionEnd) && (
                                    <Link
                                        style={{
                                            display: "block",
                                            marginBottom: "1rem"
                                        }}
                                        to="/vehicle-health-check-competition-winners"
                                    >
                                        View Winners
                                    </Link>
                                )}
                            </React.Fragment>
                        )}
                    </Column>
                </Row>
                <VideoWall videos={videoCompetition} loading={loading} />
                <Row style={{ marginTop: "3em" }}>
                    <Column medium={12} large={12} xlarge={12}>
                        {!allVideosLoaded ? (
                            <CenteredButton
                                disabled={loading}
                                onClick={loadVideoWall}
                            >
                                Load more videos
                            </CenteredButton>
                        ) : null}
                    </Column>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    const { loaded, loading, allVideosLoaded } = state.videoCompetition.entries;
    const videoCompetition = videoCompetitionGroupedSelector(state);
    const {
        modal: { show: showModal }
    } = state.videoCompetition;

    return { loading, loaded, videoCompetition, showModal, allVideosLoaded };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadVideoWall
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(VideoCompetition);
