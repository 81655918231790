import React, { Component } from "react";
import Helmet from "react-helmet";

class HeadContent extends Component {
    render() {
        return (
            <Helmet>
                <title>BMW Group Elite</title>
                <meta name="description" content="BMW Group Elite" />
            </Helmet>
        );
    }
}

export default HeadContent;
