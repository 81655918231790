import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Row, Column, Loading, Button } from "@cortexglobal/rla-components";
import { loadComparisonLeague } from "../../leagues/leagueActions";
import { PageTitle } from "@cortexglobal/bmw-shared-components";

import { LeagueTable, LeagueRow } from "../../leagues/styledComponents";

class HeadOfficeComparisonLeague extends Component {
    state = {
        showAll: false
    };
    componentDidMount() {
        if (!this.props.comparison.loaded) {
            this.props.loadComparisonLeague();
        }
    }
    filterLeague() {}
    render() {
        const { comparison } = this.props;
        const { showAll } = this.state;

        if (!comparison.loaded || comparison.loading) {
            return <Loading />;
        }

        return (
            <React.Fragment>
                <Row>
                    <Column>
                        <PageTitle
                            title="This or That"
                            subtitle="Who’s on the top spot?"
                        />
                    </Column>
                </Row>
                {!comparison.loaded || comparison.loading ? (
                    <Loading />
                ) : (
                    <Row>
                        <Column>
                            <LeagueTable>
                                <thead>
                                    <tr>
                                        <th>Position</th>
                                        <th style={{ textAlign: "left" }}>
                                            Technician Name
                                        </th>
                                        <th style={{ textAlign: "left" }}>
                                            Group
                                        </th>
                                        <th>Gameplays</th>
                                        <th>High Score</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {comparison.data
                                        .filter(leagueEntry => {
                                            if (showAll) {
                                                return true;
                                            }
                                            if (
                                                leagueEntry.national_position <=
                                                10
                                            ) {
                                                return true;
                                            }
                                            return false;
                                        })
                                        .map(leagueEntry => {
                                            return (
                                                <LeagueRow
                                                    key={leagueEntry.user_uuid}
                                                >
                                                    <td>
                                                        {
                                                            leagueEntry.national_position
                                                        }
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "left"
                                                        }}
                                                    >
                                                        {leagueEntry.user_name}
                                                    </td>
                                                    <td
                                                        style={{
                                                            textAlign: "left"
                                                        }}
                                                    >
                                                        {leagueEntry.group_name}
                                                    </td>
                                                    <td>
                                                        {leagueEntry.gameplays
                                                            ? leagueEntry.gameplays
                                                            : "..."}
                                                    </td>
                                                    <td>
                                                        {
                                                            leagueEntry.total_correct
                                                        }
                                                    </td>
                                                </LeagueRow>
                                            );
                                        })}
                                </tbody>
                            </LeagueTable>
                        </Column>
                        <Column
                            style={{ textAlign: "right", paddingTop: "1rem" }}
                        >
                            <Button
                                onClick={() => {
                                    this.setState(state => ({
                                        showAll: !state.showAll
                                    }));
                                }}
                            >
                                {showAll ? "Show top 10" : "Show all"}
                            </Button>
                        </Column>
                    </Row>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { comparison } = state.leagues;

    return { comparison };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            loadComparisonLeague
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HeadOfficeComparisonLeague);
