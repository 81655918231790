import { connect } from "react-redux";
import React, { Component } from "react";
import { bindActionCreators } from "redux";
import {
    Row,
    Column,
    Loading,
    Centred,
    TableList,
    Modal,
    Panel
} from "@cortexglobal/rla-components";

import {
    getBadgeTracks,
    getBadgeLevels,
    updateTrack,
    updateBadge
} from "./badgeActions";
import { FontAwesomeIcon  } from "@fortawesome/react-fontawesome";

import BadgeTrack from "./summary/BadgeTrack";
import LevelTitle from "./summary/LevelTitle";

import BadgeForm from "./forms/BadgeForm";
import TrackForm from "./forms/TrackForm";

class Badges extends Component {
    constructor() {
        super();
        this.state = {
            showModal: false,
            currentModal: "",
            modalItem: null
        };
    }

    componentWillMount() {
        if (!this.props.levels.loaded) {
            this.props.getBadgeLevels();
        }
        if (!this.props.tracks.loaded) {
            this.props.getBadgeTracks();
        }
    }

    closeModal = () => {
        this.setState({
            showModal: false,
            currentModal: "",
            modalItem: null
        });
    };

    showModal = (modalKey, item) => {
        console.log("item is : ", item);
        this.setState({
            showModal: true,
            currentModal: modalKey,
            modalItem: item
        });
    };

    render() {
        const { levels, tracks } = this.props;
        const { showModal, currentModal } = this.state;

        if (!tracks.loaded) {
            return (
                <Centred>
                    <Loading
                        type="spin"
                        color="rgba(255,255,225,0.2)"
                        align="center"
                        height="auto"
                    />
                </Centred>
            );
        }
        return (
            <div>
                <Row>
                    <Column>
                        <Panel padding={0}>
                            <TableList className="dashboard-table">
                                <thead>
                                    <tr>
                                        <th>
                                            Track Name
                                            <div
                                                style={{
                                                    float: "right",
                                                    fontSize: "7pt",
                                                    cursor: "pointer"
                                                }}
                                                onClick={this.showModal.bind(
                                                    this,
                                                    "trackForm",
                                                    null
                                                )}
                                            >
                                                <FontAwesomeIcon icon="plus" /> Add track
                                            </div>
                                        </th>
                                        {levels.data.map((level, index) => {
                                            return (
                                                <LevelTitle
                                                    level={level}
                                                    key={level.alias}
                                                />
                                            );
                                        })}
                                    </tr>
                                </thead>
                                <tbody>
                                    {tracks.data.map((track, index) => {
                                        return (
                                            <BadgeTrack
                                                track={track}
                                                levels={levels}
                                                key={track.uuid}
                                                showModal={this.showModal}
                                            />
                                        );
                                    })}
                                </tbody>
                            </TableList>
                        </Panel>
                    </Column>
                </Row>

                {showModal && (
                    <Modal visible={showModal} onClose={this.closeModal}>
                        {currentModal === "trackForm" && (
                            <TrackForm
                                track={this.state.modalItem}
                                updateTrack={this.props.updateTrack}
                            />
                        )}
                        {this.state.currentModal === "badgeForm" && (
                            <BadgeForm
                                badge={this.state.modalItem}
                                updateBadge={this.props.updateBadge}
                            />
                        )}
                    </Modal>
                )}
            </div>
        );
    }
}

function mapStateToProps(state) {
    const { tracks, levels } = state.badge;
    return {
        tracks,
        levels
    };
}

function mapDispatchToProps(dispatch) {
    return bindActionCreators(
        { getBadgeTracks, getBadgeLevels, updateTrack, updateBadge },
        dispatch
    );
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Badges);
