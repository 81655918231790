import axios from "axios";

export const LOADING_COMPARISON_GAME_LEAGUE = "LOADING_COMPARISON_GAME_LEAGUE";
export const LOAD_COMPARISON_GAME_LEAGUE = "LOAD_COMPARISON_GAME_LEAGUE";
export const loadComparisonLeague = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_COMPARISON_GAME_LEAGUE
        });
        return axios.get("/api/v1/comparison/league").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_COMPARISON_GAME_LEAGUE,
                data
            });
            dispatch(loadComparisonGameplays());
        });
    };
};

export const LOAD_COMPARISON_GAME_GAMEPLAYS = "LOAD_COMPARISON_GAME_GAMEPLAYS";
export const loadComparisonGameplays = () => {
    return (dispatch, getState) => {
        return axios.get("/api/v1/comparison/gameplay-count").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_COMPARISON_GAME_GAMEPLAYS,
                data
            });
        });
    };
};
export const LOADING_CHAMPIONS_LEAGUE = "LOADING_CHAMPIONS_LEAGUE";
export const LOAD_CHAMPIONS_LEAGUE = "LOAD_CHAMPIONS_LEAGUE";
export const loadChampionsLeague = (groupUuid = null) => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_CHAMPIONS_LEAGUE
        });

        let url = "/api/v1/manager/champions-league/league";
        url = typeof groupUuid == "string" ? url + `/${groupUuid}` : url;

        return axios.get(url).then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_CHAMPIONS_LEAGUE,
                data
            });
        });
    };
};

export const LOADING_LEAGUE_VISITS = "LOADING_LEAGUE_VISITS";
export const LOAD_LEAGUE_VISITS = "LOAD_LEAGUE_VISITS";
export const loadLeagueVisits = () => {
    return (dispatch, getState) => {
        dispatch({
            type: LOADING_LEAGUE_VISITS
        });

        return axios.get("/api/v1/manager/page-actions").then(response => {
            const { data } = response.data;
            dispatch({
                type: LOAD_LEAGUE_VISITS,
                data
            });
        });
    };
};
