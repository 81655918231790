import axios from "axios";
import {
    getItem,
    setItem,
    clearStorage,
    isJSON,
} from "@cortexglobal/cortex-utilities";

export const SET_LOGGED_IN_STATE = "SET_LOGGED_IN_STATE";

export const setupAuth = (redirect = false) => {
    return (dispatch) =>
        Promise.all([
            getItem("access_token"),
            // getItem("user"),
            // getItem("group"),
            // getItem("groups")
        ]).then((values) => {
            //console.log(values);
            //Values is an array of the results of each of the getItem promises return values
            //Each item is in the form of {key: value}, so we'll reduce this to asingle object
            //to make it easier to work with
            const auth = values.reduce((authObj, value) => {
                const key = Object.keys(value)[0];
                return {
                    ...authObj,
                    [key]: isJSON(value[key])
                        ? JSON.parse(value[key])
                        : value[key],
                };
            }, {});

            const { access_token: token, user, group, groups } = auth;

            //Setting the Authorization header to null should remove it (e.g. if token === null)
            axios.defaults.headers.common["Authorization"] = token;

            dispatch({
                type: SET_LOGGED_IN_STATE,
                loggedIn: !!token,
            });

            // If we have the token and the user in storage just set the user in state
            if (token && user) {
                dispatch({
                    type: SET_AUTH_USER_DETAILS,
                    user,
                });
            }

            if (token && !user) {
                dispatch(getUser());
            }

            if (group || groups) {
                dispatch({
                    type: SET_AUTH_USER_GROUPS,
                    groups,
                });

                dispatch({
                    type: SET_AUTH_USER_GROUP,
                    group,
                });
            } else if (token && user) {
                dispatch(getUserGroups());
            }

            dispatch({ type: SET_REDIRECT_STATE, redirect });
        });
};

export const SET_AUTH_USER_DETAILS = "SET_AUTH_USER_DETAILS";
export const SET_AUTH_USER_LOADING = "SET_AUTH_USER_LOADING";

export const getUser = () => {
    return (dispatch) => {
        dispatch({
            type: SET_AUTH_USER_LOADING,
        });
        axios
            .get(`/api/v1/user`)
            .then((response) => {
                setItem("user", response.data.data);

                dispatch(getUserGroups());

                dispatch({
                    type: SET_AUTH_USER_DETAILS,
                    user: response.data.data,
                });
            })
            .catch((e) => e);
    };
};

export const SET_REDIRECT_STATE = "SET_REDIRECT_STATE";

export function login(loginDetails) {
    return (dispatch) => {
        return axios
            .post(`/oauth/token`, {
                ...loginDetails,
                grant_type: "password",
                scope: "*",
            })
            .then(({ data }) => {
                setItem("token", data.access_token);
                setItem(
                    "refresh_token",
                    `${data.token_type} ${data.refresh_token}`
                );
                setItem(
                    "access_token",
                    `${data.token_type} ${data.access_token}`
                );

                dispatch(setupAuth(true));
            });
    };
}

export function logout() {
    return (dispatch) => {
        axios.delete("/api/v1/token").catch((e) => e);
        clearStorage();
        dispatch({ type: SET_REDIRECT_STATE, redirect: false });
        dispatch({ type: SET_LOGGED_IN_STATE, loggedIn: false });
    };
}

export function forgotten(details) {
    return (dispatch) => {
        return axios
            .post(`/api/v1/password/forgotten`, details)
            .then((response) => {
                console.log(response.data);

                dispatch({ type: SET_REDIRECT_STATE, redirect: true });
                return response.data;
            });
    };
}

export function resetPassword(details) {
    //console.log(details);
    return (dispatch) => {
        return axios
            .post(`/api/v1/password/reset`, details)
            .then((response) => {
                //console.log(response.data);
                dispatch({ type: SET_REDIRECT_STATE, redirect: true });
                return response.data.data;
            });
    };
}

export const SET_AUTH_USER_GROUPS = "SET_AUTH_USER_GROUPS";
export const getUserGroups = () => {
    return (dispatch) => {
        axios
            .get(`/api/v1/user-groups`)
            .then(({ data: { data: groups } }) => {
                setItem("groups", groups);

                dispatch({
                    type: SET_AUTH_USER_GROUPS,
                    groups,
                });

                groups.forEach((group) => {
                    if (group.active) {
                        setItem("group", group);

                        dispatch({
                            type: SET_AUTH_USER_GROUP,
                            group,
                        });
                    }
                });
            })
            .catch((e) => e);
    };
};

export const SET_AUTH_USER_GROUP = "SET_AUTH_USER_GROUP";
export const setUserGroup = ({ group }) => {
    return (dispatch) => {
        return axios
            .post(`/api/v1/user-groups`, { group })
            .then(({ data: { data: group } }) => {
                setItem("group", group);

                dispatch({
                    type: SET_AUTH_USER_GROUP,
                    group,
                });

                //Reload the whole app, as all the data will need refreshing
                window.location.reload(true);
            });
    };
};
