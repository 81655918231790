import React, { Component } from "react";
import PropTypes from "prop-types";
import { TARGET_TECH_RAMP_RATIO } from "../../config/app";
import SortableHeader, { StyledHeader } from "../table/SortableHeader";
import {
    StyledTable,
    StyledBodyRow,
    StyledBodyData,
    RetailerName,
    HighlightedStatus,
} from "../table/headOfficeTable";

import NotifyRetailerButton from "../notifications/NotifyRetailerButton";
class OverallStatusTable extends Component {
    render() {
        const { sortSettings, sortFunction, retailers } = this.props;
        return (
            <StyledTable>
                <thead>
                    <tr>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="name"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Retailer Name
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="current_technicians"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Total Technicians
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="active_technicians"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            No. Active
                            <br />
                            Technicians
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="current_apprentices"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Total Apprentices
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="active_apprentices"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            No. Active
                            <br />
                            Apprentices
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="current_service_advisors"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Total Service
                            <br />
                            Advisors
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="active_service_advisors"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            No. Active
                            <br />
                            Service Advisors
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="current_sales_executives"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Total Sales
                            <br />
                            Executives
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="active_sales_executives"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            No. Active
                            <br />
                            Sales Executives
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="programme_engagement"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            Programme
                            <br />
                            Engagement
                        </SortableHeader>
                        {/* <SortableHeader
                            sortFunction={sortFunction}
                            property="technician_recruitment"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            Current Ongoing
                            <br />
                            Recruitment
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="new_starters"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            New Starters
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="leavers"
                            sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Leavers
                        </SortableHeader> */}
                        <StyledHeader
                            // sortFunction={sortFunction}
                            // property="technicians_required"
                            // sortSettings={sortSettings}
                            align="left"
                            valign="top"
                        >
                            Actions
                        </StyledHeader>
                    </tr>
                </thead>
                <tbody>
                    {retailers.map((retailer) => {
                        return (
                            <StyledBodyRow key={retailer.alias}>
                                <StyledBodyData align="left">
                                    <RetailerName>{retailer.name}</RetailerName>
                                    {retailer.alias}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.current_technicians}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.active_technicians}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.current_apprentices}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.active_apprentices}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.current_service_advisors}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.active_service_advisors}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.current_sales_executives}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.active_sales_executives}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.programme_engagement > 0
                                        ? retailer.programme_engagement.toFixed(
                                              2
                                          ) + " %"
                                        : "N/A"}
                                </StyledBodyData>
                                {/* <StyledBodyData align="left">
                                    {retailer.technician_recruitment}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.new_starters}
                                </StyledBodyData>
                                <StyledBodyData align="left">
                                    {retailer.leavers}
                                </StyledBodyData> */}
                                <StyledBodyData align="left">
                                    <NotifyRetailerButton retailer={retailer} />
                                </StyledBodyData>
                            </StyledBodyRow>
                        );
                    })}
                </tbody>
            </StyledTable>
        );
    }
}

OverallStatusTable.propTypes = {
    retailers: PropTypes.array.isRequired,
    sortFunction: PropTypes.func.isRequired,
    sortSettings: PropTypes.shape({
        sortBy: PropTypes.string,
        sortDirection: PropTypes.oneOf(["", "asc", "desc"]),
    }),
};
export default OverallStatusTable;
