import styled from "styled-components";

export const StyledTable = styled.table`
    border-spacing: 0;
    border-collapse: collapse;
    width: 100%;
`;
export const StyledHeaderRow = styled.tr`
    th {
        padding: 1rem 0;
        color: #9f9f9f;
        text-align: ${props => {
            return props.align ? props.align : "center";
        }};
    }
`;
export const StyledBodyRow = styled.tr`
    background: #ebebeb;
`;
export const StyledBodyData = styled.td`
    text-align: ${props => {
        return props.align ? props.align : "center";
    }};
    padding: 1rem 1rem 1rem 0.6rem;
`;
export const RetailerName = styled.strong`
    display: block;
`;
export const HighlightedStatus = styled.strong`
    display: block;
    width: 100%;
    border-bottom: ${props => (props.positive ? "#f00" : "#00d911")} solid 4px;
`;
