import styled from "styled-components";
import { NavLink } from "react-router-dom";

const MainNavLink = styled(NavLink).attrs({
    exact: true,
    activeClassName: "active"
})`
    display: block;
    width: 100%;
    margin: 0 ${props => props.theme.navigation.navLink.margin}em;
    padding ${props => props.theme.navigation.navLink.padding}em;
    text-decoration: none;
    color: ${props => props.theme.navigation.navLink.color};
    text-transform: ${props => props.theme.navigation.navLink.textTransform};
    position: relative;
    font-weight: ${props => props.theme.navigation.navLink.fontWeight};
    :hover {
        background: ${props => props.theme.colors.lightGray};
    }
    &.active{
        background: ${props => props.theme.navigation.navLink.activeColor};
        color: ${props => props.theme.colors.white};
        font-weight: ${props =>
            props.theme.navigation.navLink.activeFontWeight};
    }
`;

MainNavLink.displayName = "MainNavLink";

MainNavLink.propTypes = {};

MainNavLink.defaultProps = {};
export default MainNavLink;
