import styled from "styled-components";

const LinkStyleButton = styled.button`
    margin-top: 1rem;
    text-decoration: underline;
    background: none;
    color: ${props => props.theme.colors.primary};
    border: none;
    font-weight: bold;
    cursor: pointer;
`;
export default LinkStyleButton;
