import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import DashboardPanel from "../dashboard/DashboardPanel";
import { Value, ProgressElement } from "../dashboard/dashboardComponents";
import { Row, Column, Loading } from "@cortexglobal/rla-components";
import CircularProgress from "../CircularProgress";
import { getRetailerInformation } from "./retailerActions";
const RetailerSummaryContainer = styled.div``;

const RowFlex = styled(Row)`
    > div {
        display: flex;
        flex-wrap: wrap;
    }
`;

const ColumnFlex = styled(Column)`
    float: none;
    padding-bottom: 1rem;

    .content {
        height: calc(100% - 40px);
        display: flex;
        flex-direction: column;
        justify-content: center;

        h3 {
            margin: 0;
        }
    }
`;

class RetailerInformation extends Component {
    state = {
        error: false,
    };
    componentDidMount() {
        if (!this.props.retailer.loaded) {
            this.props
                .getRetailerInformation()
                //.then(this.setState({ error: false }))
                .catch((e) => this.setState({ error: true }));
        }
    }
    render() {
        //console.log(this.state);
        const { error } = this.state;
        const { data, loaded } = this.props.retailer;
        if (error) {
            return (
                <Row style={{ paddingTop: "1rem" }}>
                    <Column>
                        <h1>Retailer Information Unavailable</h1>
                        <p>
                            Please contact a member of the support team:{" "}
                            <a href="mailto:info@bmwgroupelite.co.uk">
                                info@bmwgroupelite.co.uk
                            </a>
                        </p>
                    </Column>
                </Row>
            );
        }
        if (!loaded) {
            return <Loading align="center" />;
        }

        const { team } = this.props;
        const canViewSalesTeam = team === "sales" || team === "head-office";
        const canViewServiceTeam = team === "service" || team === "head-office";

        const technicianPercentage =
            data.current_technicians > 0
                ? (
                      (data.active_technicians / data.current_technicians) *
                      100
                  ).toFixed(0)
                : 0;
        const apprenticePercentage =
            data.current_apprentices > 0
                ? (
                      (data.active_apprentices / data.current_apprentices) *
                      100
                  ).toFixed(0)
                : 0;
        const serviceAdvisorPercentage =
            data.current_service_advisors > 0
                ? (
                      (data.active_service_advisors /
                          data.current_service_advisors) *
                      100
                  ).toFixed(0)
                : 0;
        const salesExecutivePercentage =
            data.current_sales_executives > 0
                ? (
                      (data.active_sales_executives /
                          data.current_sales_executives) *
                      100
                  ).toFixed(0)
                : 0;
        return (
            <RetailerSummaryContainer>
                <RowFlex>
                    <ColumnFlex>
                        <PageTitle
                            title="Retailer information"
                            subtitle="Your current status"
                        />
                    </ColumnFlex>
                </RowFlex>
                {canViewServiceTeam && (
                    <>
                        <RowFlex>
                            <ColumnFlex medium={4}>
                                <DashboardPanel title="Technician Engagement">
                                    <CircularProgress
                                        percentage={technicianPercentage}
                                        text={`${technicianPercentage}%`}
                                        initialAnimation
                                    />
                                </DashboardPanel>
                            </ColumnFlex>
                            <ColumnFlex medium={6}>
                                <DashboardPanel title="Technician Engagement">
                                    <ProgressElement
                                        title="Total No. Technicians"
                                        value={data.current_technicians}
                                        total={data.current_technicians}
                                    />
                                    <ProgressElement
                                        title="No. Active Technicians"
                                        value={data.active_technicians}
                                        total={data.current_technicians}
                                    />
                                </DashboardPanel>
                            </ColumnFlex>
                        </RowFlex>
                        <RowFlex>
                            <ColumnFlex medium={4}>
                                <DashboardPanel title="Apprentice Engagement">
                                    <CircularProgress
                                        percentage={apprenticePercentage}
                                        text={`${apprenticePercentage}%`}
                                        initialAnimation
                                    />
                                </DashboardPanel>
                            </ColumnFlex>
                            <ColumnFlex medium={6}>
                                <DashboardPanel title="Apprentice Engagement">
                                    <ProgressElement
                                        title="Total No. Apprentices"
                                        value={data.current_apprentices}
                                        total={data.current_apprentices}
                                    />
                                    <ProgressElement
                                        title="No. Active Apprentices"
                                        value={data.active_apprentices}
                                        total={data.current_apprentices}
                                    />
                                </DashboardPanel>
                            </ColumnFlex>
                        </RowFlex>
                        <RowFlex>
                            <ColumnFlex medium={4}>
                                <DashboardPanel title="Service Advisor Engagement">
                                    <CircularProgress
                                        percentage={serviceAdvisorPercentage}
                                        text={`${serviceAdvisorPercentage}%`}
                                        initialAnimation
                                    />
                                </DashboardPanel>
                            </ColumnFlex>
                            <ColumnFlex medium={6}>
                                <DashboardPanel title="Service Advisor Engagement">
                                    <ProgressElement
                                        title="Total No. Service Advisors"
                                        value={data.current_service_advisors}
                                        total={data.current_service_advisors}
                                    />
                                    <ProgressElement
                                        title="No. Active Service Advisors"
                                        value={data.active_service_advisors}
                                        total={data.current_service_advisors}
                                    />
                                </DashboardPanel>
                            </ColumnFlex>
                        </RowFlex>
                    </>
                )}
                {canViewSalesTeam && (
                    <RowFlex>
                        <ColumnFlex medium={4}>
                            <DashboardPanel title="Sales Executive Engagement">
                                <CircularProgress
                                    percentage={salesExecutivePercentage}
                                    text={`${salesExecutivePercentage}%`}
                                    initialAnimation
                                />
                            </DashboardPanel>
                        </ColumnFlex>
                        <ColumnFlex medium={6}>
                            <DashboardPanel title="Sales Executive Engagement">
                                <ProgressElement
                                    title="Total No. Sales Executives"
                                    value={data.current_sales_executives}
                                    total={data.current_sales_executives}
                                />
                                <ProgressElement
                                    title="No. Active Sales Executives"
                                    value={data.active_sales_executives}
                                    total={data.current_sales_executives}
                                />
                            </DashboardPanel>
                        </ColumnFlex>
                    </RowFlex>
                )}
                <RowFlex>
                    {canViewServiceTeam && (
                        <>
                            <ColumnFlex medium={4} large={3}>
                                <DashboardPanel
                                    title="Current Technician Recruitment"
                                    deepTitle={true}
                                >
                                    <Value>
                                        {data.technician_recruitment.toLocaleString()}
                                    </Value>
                                </DashboardPanel>
                            </ColumnFlex>
                            <ColumnFlex medium={4} large={3}>
                                <DashboardPanel
                                    title="Current Apprentice Recruitment"
                                    deepTitle={true}
                                >
                                    <Value>
                                        {data.apprentice_recruitment.toLocaleString()}
                                    </Value>
                                </DashboardPanel>
                            </ColumnFlex>
                            <ColumnFlex medium={4} large={3}>
                                <DashboardPanel
                                    title="Current Service Advisor Recruitment"
                                    deepTitle={true}
                                >
                                    <Value>
                                        {data.service_advisor_recruitment.toLocaleString()}
                                    </Value>
                                </DashboardPanel>
                            </ColumnFlex>
                        </>
                    )}
                    {canViewSalesTeam && (
                        <ColumnFlex medium={4} large={3}>
                            <DashboardPanel
                                title="Current Sales Executive Recruitment"
                                deepTitle={true}
                            >
                                <Value>
                                    {data.sales_executive_recruitment.toLocaleString()}
                                </Value>
                            </DashboardPanel>
                        </ColumnFlex>
                    )}
                </RowFlex>
                <RowFlex>
                    <ColumnFlex medium={4} large={3}>
                        <DashboardPanel title="New Starters" deepTitle={true}>
                            <Value>{data.new_starters.toLocaleString()}</Value>
                        </DashboardPanel>
                    </ColumnFlex>
                    <ColumnFlex medium={4} large={3}>
                        <DashboardPanel title="Leavers" deepTitle={true}>
                            <Value>{data.leavers.toLocaleString()}</Value>
                        </DashboardPanel>
                    </ColumnFlex>
                </RowFlex>
            </RetailerSummaryContainer>
        );
    }
}

const mapStateToProps = (state) => {
    const { information: retailer } = state.retailer;

    const { team } = state.auth?.user?.role;
    return { retailer, team };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getRetailerInformation,
        },
        dispatch
    );

RetailerInformation.propTypes = {
    group: PropTypes.object.isRequired,
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(RetailerInformation);

// import React, { Component } from "react";
// import PropTypes from "prop-types";
// import styled from "styled-components";
// import { connect } from "react-redux";
// import { bindActionCreators } from "redux";
// import { PageTitle } from "@cortexglobal/bmw-shared-components";
// import DashboardPanel from "../dashboard/DashboardPanel";
// import { Row, Column, Loading } from "@cortexglobal/rla-components";
// import { getRetailerInformation } from "./retailerActions";

// const RetailerInformationContainer = styled.div``;

// const Value = styled.h3`
//     display: block;
//     width: 100%;
//     text-align: center;
//     font-size: 3em;
// `;
// class RetailerInformation extends Component {
//     state = {
//         error: false
//     };
//     componentDidMount() {
//         if (!this.props.retailer.loaded) {
//             this.props
//                 .getRetailerInformation()
//                 //.then(this.setState({ error: false }))
//                 .catch(e => this.setState({ error: true }));
//         }
//     }
//     render() {
//         //console.log(this.state);
//         const { error } = this.state;
//         const { data, loaded } = this.props.retailer;
//         if (error) {
//             return (
//                 <Row style={{ paddingTop: "1rem" }}>
//                     <Column>
//                         <h1>Retailer Information Unavailable</h1>
//                         <p>
//                             Please contact a member of the support team:{" "}
//                             <a href="mailto:info@bmwgroupelite.co.uk">
//                                 info@bmwgroupelite.co.uk
//                             </a>
//                         </p>
//                     </Column>
//                 </Row>
//             );
//         }
//         if (!loaded) {
//             return <Loading align="center" />;
//         }
//         return (
//             <RetailerInformationContainer>
//                 <Row>
//                     <Column>
//                         <PageTitle
//                             title="Retailer information"
//                             subtitle="Your current status"
//                         />
//                     </Column>
//                 </Row>
//                 <Row>
//                     <Column medium={6}>
//                         <DashboardPanel title="Technician Engagement">
//                             <Value>
//                                 {data.current_technicians > 0
//                                     ? (
//                                           (data.active_technicians /
//                                               data.current_technicians) *
//                                           100
//                                       ).toFixed(2) + " %"
//                                     : "N/A"}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={6}>
//                         <DashboardPanel title="Apprentice Engagement">
//                             <Value>
//                                 {data.current_apprentices > 0
//                                     ? (
//                                           (data.active_apprentices /
//                                               data.current_apprentices) *
//                                           100
//                                       ).toFixed(2) + " %"
//                                     : "N/A"}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel
//                             title="Total Technicians"
//                             deepTitle={true}
//                         >
//                             <Value>
//                                 {data.current_technicians.toLocaleString()}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel
//                             title="Number of Active Technicians"
//                             deepTitle={true}
//                         >
//                             <Value>
//                                 {data.active_technicians.toLocaleString()}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel
//                             title="Total Apprentices"
//                             deepTitle={true}
//                         >
//                             <Value>
//                                 {data.current_apprentices.toLocaleString()}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel
//                             title="Number of Active Apprentices"
//                             deepTitle={true}
//                         >
//                             <Value>
//                                 {data.active_apprentices.toLocaleString()}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel
//                             title="Current Technician Recruitment"
//                             deepTitle={true}
//                         >
//                             <Value>
//                                 {data.technician_recruitment.toLocaleString()}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel
//                             title="Current Apprentice Recruitment"
//                             deepTitle={true}
//                         >
//                             <Value>
//                                 {data.apprentice_recruitment.toLocaleString()}
//                             </Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel title="New Starters" deepTitle={true}>
//                             <Value>{data.new_starters.toLocaleString()}</Value>
//                         </DashboardPanel>
//                     </Column>
//                     <Column medium={4} large={3}>
//                         <DashboardPanel title="Leavers" deepTitle={true}>
//                             <Value>{data.leavers.toLocaleString()}</Value>
//                         </DashboardPanel>
//                     </Column>
//                 </Row>
//             </RetailerInformationContainer>
//         );
//     }
// }
// const mapStateToProps = state => {
//     const { information: retailer } = state.retailer;
//     return { retailer };
// };

// const mapDispatchToProps = dispatch =>
//     bindActionCreators(
//         {
//             getRetailerInformation
//         },
//         dispatch
//     );

// RetailerInformation.propTypes = {
//     group: PropTypes.object.isRequired
// };
// export default connect(
//     mapStateToProps,
//     mapDispatchToProps
// )(RetailerInformation);
