import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const Wrapper = styled.div`
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    padding: 0.5rem 0;
    background: #ebebeb;
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        padding-top: 80px;
        justify-content: flex-start !important;
        flex-direction: column;
        width: 80px;
        height: 100%;
    }
`;

export const Link = styled(NavLink)`
    flex: 1;
    color: #656565;
    text-align: center;
    font-size: 0.7rem;
    text-decoration: none;
    border-right: 1px solid rgba(0, 0, 0, 0.4);
    &:last-of-type {
        border-right: none;
    }
    padding-top: 10px;
    &.active {
        color: #1c69d4;
    }
    @media (min-width: ${props => props.theme.breakpoints.large}px) {
        flex: none;
        display: block;
        height: 80px;
        border-right: none;
    }
`;

export const Icon = styled.div`
    display: block;
    margin: auto;
    font-size: 1.5rem;
    padding-top: 10px;
    &.active {
        color: #1c69d4;
    }
`;

export const Counter = styled.span`
    background: #1c69d4;
    font-size: 1.5em;
    width: 60px;
    height: 60px;
    border-radius: 99em;
    line-height: 1.4em;
    position: absolute;
    right: -15px;
    top: -10px;
`;
