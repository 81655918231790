import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "../functions";
import { darken, luminanace, hexToRgb } from "../functions";

const background = props =>
    props.theme.colors[props.type] ||
    props.theme.colors[props.theme.alert.default];

const color = props =>
    foregroundColor(
        background(props),
        0.75,
        props.theme.lightColor,
        props.theme.darkColor
    );

const isHollow = props => Boolean(props.hollow);

const outline = props => `inset 0 0 0 ${props.theme.alert.borderWidth}px
${background(props)}`;

const Badge = styled.span`
    border:none;
    border-radius: ${props => props.rounded ? props.theme.badge.borderRadius : 0}em;
    margin-bottom: ${props =>
        props.margin != undefined
            ? props.margin
            : props.theme.spacing.margin}em;
    transition: background-color 0.25s ease;
    box-shadow: ${props => outline(props)};
    padding: ${props => props.theme.spacing.padding/3}em;
    border-radius: ${props => props.rounded ? props.theme.badge.borderRadius : 0}em;
    font-weight: bold;
    display: ${props => props.theme.badge.display};
    font-size: ${props => props.fontSize}px;
    text-transform: ${props => props.uppercase ? "uppercase" : "normal"};
    background-color: ${props => !isHollow(props) ? background(props) : "transparent"};
    color: ${props => (isHollow(props) ? background(props) : color(props))};
`;

Badge.displayName = "Badge";

Badge.propTypes = {
    fontSize: PropTypes.number,
    rounded: PropTypes.bool,
    uppercase: PropTypes.bool
};

Badge.defaultProps = {
    fontSize: 16,
    rounded: true,
    uppercase: true,
    type: "primary"
};

export default Badge;
