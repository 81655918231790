import React, { Component } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import moment from "moment";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    SubmitButton,
    FormikSelect,
    FormikDatePicker,
    Row,
    Column,
    Loading
} from "@cortexglobal/rla-components";

import {
    parseValidationErrors,
    yupFormattedDate
} from "@cortexglobal/cortex-utilities";

const makeExitSurveySchema = () =>
    Yup.object().shape({
        user_uuid: Yup.string().required("Please select a technician")
    });

class ResendExitSurveyForm extends Component {
    submitForm = (values, { setErrors }) => {
        // return false;
        this.setState(state => ({
            submitting: true
        }));

        values.survey_alias = "exit-survey";

        axios
            .post(`/api/v1/manager/exit-survey/resend`, values)
            .then(({ data }) => {
                this.setState({
                    submitting: false
                });
                this.props.onClose();
            })
            .catch(e => e);
    };

    render() {
        const { submitting, invitesLoaded, invites, staff } = this.props;
        console.log(invites, staff);
        if (!invitesLoaded) {
            return <Loading />;
        }

        const groupInvites = invites.filter(invite => {
            const inviteUserUuid = invite.user ? invite.user.uuid : false;
            if (!inviteUserUuid) {
                return false;
            }
            return (
                staff.findIndex(member => {
                    console.log(inviteUserUuid, member.uuid);

                    return member.uuid === inviteUserUuid;
                }) !== -1
            );
        });

        return (
            <div className="form-container">
                <React.Fragment>
                    <Formik
                        initialValues={{ user_uuid: "" }}
                        onSubmit={this.submitForm}
                        validationSchema={makeExitSurveySchema()}
                        className="form-group"
                    >
                        {({ values, errors }) => {
                            return (
                                <Form className="form-group" autoComplete="off">
                                    <Row>
                                        <Column>
                                            <FormikSelect
                                                name="user_uuid"
                                                label="Technician name"
                                                value={values.user_uuid}
                                                options={groupInvites.reduce(
                                                    (staffOptions, invite) => {
                                                        return [
                                                            ...staffOptions,
                                                            {
                                                                value:
                                                                    invite.user
                                                                        .uuid,
                                                                text: `${invite.user.first_name} ${invite.user.last_name}`
                                                            }
                                                        ];
                                                    },
                                                    []
                                                )}
                                            />
                                        </Column>
                                        <Column large={6}>
                                            <SubmitButton
                                                expanded
                                                submitting={submitting}
                                                disabled={submitting}
                                            >
                                                Resend Link
                                            </SubmitButton>
                                        </Column>
                                    </Row>
                                </Form>
                            );
                        }}
                    </Formik>
                </React.Fragment>
            </div>
        );
    }
}

ResendExitSurveyForm.propTypes = {};

export default ResendExitSurveyForm;
