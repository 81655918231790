import styled from "styled-components";

import { Column } from "@cortexglobal/rla-components";
// import LoginImg from "../../img/bmw-service-image-tester.jpg";
import LoginImg from "../../img/bmw-elite-homepage-image.jpeg";

export { default as LoginForm } from "./LoginForm";
export const Hero = styled.div`
    background: url(${LoginImg});
    background-size: cover;
    background-position: center;
    max-width: 100%;
    min-height: 200px;
    margin-bottom: 30px;
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        min-height: 100%;
    }
`;

export const HeroColumn = styled(Column)`
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        width: 66%;
        float: right;
    }
`;
export const ContentColumn = styled(Column)`
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        padding: 4rem;
        width: 33%;
        float: left;
    }
`;
export const ViewTerms = styled.a`
    font-weight: 500;
    font-size: 0.7rem;
`;
