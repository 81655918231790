// import React from "react";
import styled from "styled-components";

export const LeagueTable = styled.table`
    border-spacing: 0;
    width: 100%;
    text-align: center;
    th {
        padding: 0.5rem;
        color: #939393;
    }
    td {
        padding: 0.5rem;
    }
`;
export const LeagueRow = styled.tr`
    background: #f0f0f0;
    padding: 0.3rem;
    :nth-of-type(even) {
        background: #d9d9d9;
    }
`;
