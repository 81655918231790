import React from "react";
import { Modal, Row, Column } from "@cortexglobal/rla-components";

const CookieModal = props => {
    const { visible, closeModal } = props;

    return (
        <Modal visible={visible} onClose={closeModal}>
            <Row>
                <Column>
                    <h2>Cookie Policy</h2>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p>I work at a BMW retailer based in the <a href="https://www.bmw.co.uk/en/footer/legal/cookie-policy.html">UK</a></p>
                </Column>
            </Row>
            <Row>
                <Column>
                    <p>I work at a BMW retailer based in the <a href="https://www.bmw.ie/en/footer/footer-section/cookie-policy.html">Republic of Ireland</a></p>
                </Column>
            </Row>
        </Modal>
    );
};

export default CookieModal;
