import React from "react";
import styled from "styled-components";
import moment from "moment";
import { Row, Column } from "@cortexglobal/rla-components";
import EditStaff from "../modules/manager/staff/EditStaff";
import UserSummary from "./global/UserSummary";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import { Badge } from "@cortexglobal/cortex-badges";

const DetailWrapper = styled.div`
    /* The animation code */
    .shown {
        transform-origin: top;
        transition: max-height 800ms ease-in, padding-bottom 100ms ease;
        overflow: hidden;
        max-height: 1500px;
        //transform: scaleY(1);
        height: auto;
        padding-top: inherit !important;
        padding-bottom: 1rem !important;
    }
    .hidden {
        transition: max-height 500ms ease-out, padding-bottom 300ms ease-in;
        max-height: 0;
        //transform: scaleY(0);
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
`;

const DetailMainRow = styled(Row)`
    background: linear-gradient(
        to bottom,
        #c9c9c9 0%,
        #e2e2e2 6%,
        #e2e2e2 100%
    );
`;
const StripedList = styled.div`
    list-style: none;
    margin: 0px;
    padding: 0px;
    margin-top: 20px;
    li {
        padding: 0.5em;
    }
    li:nth-of-type(odd) {
        background-color: rgba(0, 0, 0, 0.1);
    }
`;
const StatBoxes = styled.div`
        width: 100%
        display: flex;
        flex-direction: row;
        text-align: center;
        span {
            flex: 1;
            display: flex;
            flex-direction: column;
            font-weight: 800;
            background-color: #fafafa;
            height: 60px;
            align-content: center;
            justify-content: center;
            h4 {
                font-size: 0.8em;
                margin: 0px;
                font-weight: normal;
                color: #000000;
                opacity: 0.5;
            }
        }
`;
const FlexColumn = styled(Column)`
    display: flex;
    flex-direction: row;
    align-items: center;
    h4 {
        font-size: 0.8em;
        margin: 0px;
        font-weight: normal;
        color: #000000;
        opacity: 0.5;
    }
    p {
        margin: 0px;
        font-weight: bold;
        color: #000000;
    }
`;

class DetailRow extends React.Component {
    render() {
        const { member, group } = this.props;
        return (
            <DetailWrapper>
                <DetailMainRow key={this.props.member.uuid} collapse>
                    <Column
                        medium={12}
                        className={this.props.shown ? "shown" : "hidden"}
                    >
                        <Row collapse>
                            <Column medium={10}>
                                <UserSummary
                                    user={member}
                                    group={group}
                                    background="inherit"
                                    showGroupSwitcher={false}
                                />
                            </Column>
                            <Column medium={2} style={{ textAlign: "right" }}>
                                <EditStaff member={member} />
                            </Column>
                            <Column medium={4}>
                                <StripedList>
                                    <li>
                                        <strong>Retailer:</strong>{" "}
                                        {group && group.name}
                                    </li>
                                    <li>
                                        <strong>TSMI User ID:</strong>{" "}
                                        {member.external_ref &&
                                            member.external_ref}
                                    </li>
                                    <li>
                                        <strong>Last Active:</strong>{" "}
                                        {member.last_login_date
                                            ? member.last_login_date
                                            : "N/A"}
                                    </li>
                                </StripedList>
                            </Column>
                            <Column medium={4}>
                                <StripedList>
                                    <li>
                                        <strong>Email:</strong>{" "}
                                        {member.email && member.email}
                                    </li>
                                    <li>
                                        <strong>Secondary Email:</strong>{" "}
                                        {member.personal_email &&
                                            member.personal_email}
                                    </li>
                                    <li>
                                        <strong>Mobile:</strong>{" "}
                                        {member.mobile && member.mobile}
                                    </li>
                                </StripedList>
                            </Column>
                            <Column medium={4}>
                                <StripedList>
                                    <li>
                                        <strong>Start Date:</strong>{" "}
                                        {member.start_date && member.start_date}
                                    </li>
                                    <li>
                                        <strong>Probation Date:</strong>{" "}
                                        {member.probation_date &&
                                            member.probation_date}
                                    </li>
                                    <li>
                                        <strong>Length of Service:</strong>{" "}
                                        {member.start_date
                                            ? moment().diff(
                                                  moment(
                                                      member.start_date,
                                                      "DD/MM/YYYY"
                                                  ),
                                                  "years"
                                              ) + " years"
                                            : "N/A"}
                                    </li>
                                </StripedList>
                            </Column>
                        </Row>
                        <Row
                            style={{
                                marginTop: "1rem",
                                paddingBottom: "1rem",
                                backgroundColor: "rgba(0, 0, 0, 0.1)"
                            }}
                        >
                            <Column>
                                <PageTitle
                                    title="Gameplay Details"
                                    subtitle="Technician's gameplay engagement"
                                />
                            </Column>
                            <Column medium={6} xlarge={4}>
                                <h3>Weekly Quiz Stats</h3>
                                <StatBoxes>
                                    <span>
                                        <h4>Overall Score</h4>
                                        {member.question_count > 0
                                            ? (
                                                  (member.question_correct_count /
                                                      member.question_count) *
                                                  100
                                              ).toFixed(0)
                                            : 0}
                                        %
                                    </span>
                                    <span>
                                        <h4>Questions</h4>
                                        {member.question_count}
                                    </span>
                                    <span>
                                        <h4>Correct</h4>
                                        {member.question_correct_count}
                                    </span>
                                </StatBoxes>
                            </Column>
                            <Column medium={6} xlarge={4}>
                                <h3>This or That Stats</h3>
                                <StatBoxes>
                                    <span>
                                        <h4>Total Games</h4>
                                        {member.comparison_game_count}
                                    </span>
                                    <span>
                                        <h4>Best Score</h4>
                                        {member.comparison_game_best
                                            ? member.comparison_game_best
                                            : "-"}
                                    </span>
                                </StatBoxes>
                            </Column>
                        </Row>
                        <Row
                            style={{
                                marginTop: "1rem"
                            }}
                        >
                            <Column>
                                <PageTitle
                                    title="Badges"
                                    subtitle="What has your Technician earned"
                                />
                            </Column>
                            <Column large={6}>
                                {member.badges
                                    .slice(0)
                                    .reverse()
                                    .map(badge => {
                                        return (
                                            <FlexColumn
                                                medium={4}
                                                key={badge.slug}
                                            >
                                                <Badge
                                                    badge={badge}
                                                    forceFullOpacity={true}
                                                    showIcon={false}
                                                />
                                                <div
                                                    style={{
                                                        marginLeft: "1rem"
                                                    }}
                                                >
                                                    <h4>{badge.name}</h4>
                                                    <p>{badge.awarded}</p>
                                                </div>
                                            </FlexColumn>
                                        );
                                    })}
                            </Column>
                        </Row>
                    </Column>
                </DetailMainRow>
            </DetailWrapper>
        );
    }
}

export default DetailRow;
