import React from "react";
import ReactDOM from "react-dom";
import styled, { withTheme } from "styled-components";
// import { ZoomIn, ZoomOut } from "animate-css-styled-components";
import { isBrowser } from "../functions";

const TooltipText = styled.div`
    color: ${props => props.theme.tooltip.color};
    background: ${props => props.theme.tooltip.background};
    padding: ${props => props.theme.tooltip.padding / 2}em
        ${props => props.theme.tooltip.padding}em;
    border-radius: ${props => props.theme.tooltip.radius}em;
    transform: translate(-50%, -100%);
    max-width: 50%;
    position: absolute;
    opacity: 0.9;
    display: ${props => props.theme.tooltip.display}

    &:after {
        content: " ";
        position: absolute;
        bottom: -9px;
        border-top: 10px ${props => props.theme.tooltip.background} solid;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        left: 50%;
        margin-right: -50%;
        transform: translateX(-50%);
    }
`;

class Text extends React.Component {
    constructor(props) {
        super(props);
        if (isBrowser()) {
            this.el = document.createElement("div");
        }
    }

    componentWillMount() {
        // init portal element
        if (isBrowser()) {
            try {
                document.getElementsByTagName("BODY")[0].appendChild(this.el);
            } catch (err) {}
        }
    }

    componentWillUnmount() {
        // destroy portal element
        if (isBrowser()) {
            try {
                document.getElementsByTagName("BODY")[0].removeChild(this.el);
            } catch (err) {}
        }
    }

    // Update position of the tooltip
    handleMouseMove = e => {
        const x = e.clientX,
            y = e.clientY,
            tooltip = this.tooltip;
        //console.log(x, y, tooltip);

        if (tooltip) {
            tooltip.style.top = `${y - 20}px`;
            tooltip.style.left = `${x}px`;
            tooltip.style.display = "block";
        }
    };

    render() {
        const { visible, children } = this.props;

        return ReactDOM.createPortal(
            visible ? (
                <TooltipText ref={ref => (this.tooltip = ref)}>
                    {children}
                </TooltipText>
            ) : null,
            this.el
        );
    }
}
class Tooltip extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
        this.child = React.createRef();
    }

    render() {
        const { visible } = this.state;
        const { text } = this.props;

        const children = React.cloneElement(this.props.children, {
            onMouseMove: e => {
                this.child.current.handleMouseMove(e);
                if (!this.state.visible) {
                    this.setState({
                        visible: true
                    });
                }
            },
            onMouseLeave: () => {
                this.setState({ visible: false });
            },
            onClick: () => {
                //console.log("test", this.child);
            }
        });

        return (
            <React.Fragment>
                <Text ref={this.child} visible={visible}>
                    {text}
                </Text>
                {children}
            </React.Fragment>
        );
    }
}

Tooltip.displayName = "Tooltip";

export default withTheme(Tooltip);
