import React, { Component } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { bindActionCreators } from "redux";
import { Row, Column } from "@cortexglobal/rla-components";

import BadgeIcon from "bmw-technician-loyalty/src/img/icons/badge-icon.png";
import GameIcon from "bmw-technician-loyalty/src/img/icons/game-icon.png";
import PrizeIcon from "bmw-technician-loyalty/src/img/icons/prize-icon.png";
import RewardIcon from "bmw-technician-loyalty/src/img/icons/reward-icon.png";
import NetworkIcon from "bmw-technician-loyalty/src/img/icons/news-icon.png";

const Summary = styled.div`
    border-bottom: 1px solid rgba(112, 112, 113, 0.2);
    margin: 20px 0;

    a {
        text-decoration: none;
        color: #1c69d4;
        font-weight: normal;
    }
`;

const Notification = styled.p`
    border: 3px dashed #f9f9f9;
    padding: 20px;
    margin: 20px 0;
    font-size: 0.9em;
    position: relative;
    img {
        font-size: 1.6em;
        color: #1c69d4;
        max-width: 45px;
        max-height: 45px;
        height: auto;
        vertical-align: middle;
        margin-right: 20px;
    }
`;

class SentNotificationList extends Component {
    getIcon(type) {
        switch (type) {
            case "badge":
                return BadgeIcon;

            case "game":
                return GameIcon;

            case "prize":
                return PrizeIcon;

            case "reward":
                return RewardIcon;

            default:
                return NetworkIcon;
        }
    }

    render() {
        const notifications = this.props.notifications;

        return notifications.length > 0 ? (
            <div className="notificationList">
                {notifications.map((notification, index) => {
                    const icon = this.getIcon(notification.type);
                    return (
                        <Summary key={index}>
                            <Row>
                                <Column small={3} large={3}>
                                    Sent: {notification.sentWording}
                                </Column>
                                <Column small={3} large={3}>
                                    Recipients: {notification.stats.sent}
                                </Column>
                                <Column small={3} large={3}>
                                    Read: {notification.stats.read}
                                </Column>
                                <Column small={3} large={3}>
                                    Unread: {notification.stats.unread}
                                </Column>
                            </Row>

                            <Row>
                                <Column small={12} large={12}>
                                    <Notification>
                                        <img
                                            src={icon}
                                            alt={notification.type}
                                        />

                                        {notification.message}
                                        {notification.link && (
                                            <span>
                                                &nbsp; &hellip; &nbsp;
                                                <a href={notification.link}>
                                                    {notification.linkText ||
                                                        "Find out more"}
                                                </a>
                                            </span>
                                        )}
                                    </Notification>
                                </Column>
                            </Row>
                        </Summary>
                    );
                })}
            </div>
        ) : (
            <div className="notificationList">
                <Row>
                    <p>You have not sent any notifications</p>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => ({ loggedIn: state.auth.loggedIn });

const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch);

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SentNotificationList);
