import React from "react";
import PropTypes from "prop-types";
import { FieldArray, ErrorMessage } from "formik";
import StyledCheckbox from "../styledElements/styledCheckbox";
import InputError from "../styledElements/inputError";

const FormikCheckboxArray = ({
    type,
    name,
    label,
    options,
    labelWidth,
    labelAlign,
    inline,
    ...rest
}) => {
    return (
        <React.Fragment>
            <FieldArray name={name}>
                {({ push, remove, form: { values } }) => {
                    const fieldValues = values[name];

                    // console.log("field values", fieldValues);

                    return (
                        <React.Fragment>
                            {options.map(option => (
                                <StyledCheckbox
                                    key={option.value}
                                    name={name}
                                    checked={
                                        values[name] &&
                                        values[name].includes(option.value)
                                    }
                                    onChange={event => {
                                        if (event.target.checked)
                                            push(option.value);
                                        else {
                                            // console.log("values[name]", values[name]);
                                            // console.log("indexOf (option.value)", values[name].indexOf(option.value));

                                            const idx = values[name].indexOf(
                                                option.value
                                            );
                                            remove(idx);
                                        }
                                    }}
                                    text={option.text}
                                    inline={inline}
                                    value={option.value}
                                />
                            ))}
                        </React.Fragment>
                    );
                }}
            </FieldArray>

            <ErrorMessage name={name}>
                {msg => (
                    <InputError
                        errorStyle={{ marginTop: "0.2rem" }}
                        error={msg}
                    />
                )}
            </ErrorMessage>
        </React.Fragment>
    );
};

FormikCheckboxArray.displayName = "FormikCheckboxArray";

FormikCheckboxArray.propTypes = {
    name: PropTypes.string.isRequired,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            text: PropTypes.string.isRequired
        })
    ).isRequired,
    type: PropTypes.string,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    readOnly: PropTypes.bool,
    height: PropTypes.number,
    labelWidth: PropTypes.number,
    labelAlign: PropTypes.string,
    inline: PropTypes.bool
};

FormikCheckboxArray.defaultProps = {
    size: "default",
    type: "text",
    readOnly: false,
    height: 30,
    inline: false
};

export default FormikCheckboxArray;
