import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";

import NetworkIcon from "../../img/icons/news-icon.png";
import BadgeIcon from "../../img/icons/badge-icon.png";
import GameIcon from "../../img/icons/game-icon.png";
import PrizeIcon from "../../img/icons/prize-icon.png";
import RewardIcon from "../../img/icons/reward-icon.png";

const List = styled.ul`
    list-style: none;
    padding: 0px;
    margin: 0px;
    border-top: none;
    li {
        position: relative;
        height: auto;
        border-bottom: 1px solid rgba(112, 112, 113, 0.2);
        img {
            font-size: 1.6em;
            color: #1c69d4;
            max-width: 45px;
            max-height: 45px;
            height: auto;
            vertical-align: middle;
            margin-right: 20px;
        }
        small {
            margin: 0px;
            display: block;
            text-align: right;
            width: 100%;
            white-space: nowrap;
            color: #9f9f9f;
            margin-top: 15px;
            padding-top: 20px;
            padding-bottom: 20px;
        }
        p {
            padding-top: 20px;
            padding-bottom: 20px;
            margin: 0px;
            color: black;
            display: block;
            font-size: 0.9em;
        }
        a {
            text-decoration: none;
            color: #1c69d4;
            font-weight: normal;
        }
        &.new {
            font-weight: bold;
            &:before {
                content: "•";
                color: red;
                color: ${props => props.theme.colors.alert};
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
        }
    }
`;

class NotificationList extends Component {
    getIcon(type) {
        switch (type) {
            case "badge":
                return BadgeIcon;

            case "game":
                return GameIcon;

            case "prize":
                return PrizeIcon;

            case "reward":
                return RewardIcon;

            default:
                return NetworkIcon;
        }
    }

    render() {
        const notifications = this.props.notifications;

        return notifications.length > 0 ? (
            <List className="notificationList">
                {notifications.map((notification, index) => {
                    const icon = this.getIcon(notification.type);

                    let hasImage = !!icon;
                    let hasLink = !!notification.link;
                    let unread = !!notification.unread;
                    return (
                        <li key={index} className={unread ? "new" : ""}>
                            <Row>
                                <Column small={9} large={9}>
                                    <p>
                                        {hasImage && (
                                            <img
                                                src={icon}
                                                alt={notification.type}
                                            />
                                        )}

                                        {notification.message}

                                        {hasLink && (
                                            <span>
                                                &nbsp; &hellip; &nbsp;
                                                <a href={notification.link}>
                                                    {notification.linkText ||
                                                        "Find out more"}
                                                </a>
                                            </span>
                                        )}
                                    </p>
                                </Column>

                                <Column small={3} large={3}>
                                    <small>{notification.createdWording}</small>
                                </Column>
                            </Row>
                        </li>
                    );
                })}
            </List>
        ) : (
            <List className="notificationList">
                <li>
                    <Row>
                        <p>You have 0 notifications</p>
                    </Row>
                </li>
            </List>
        );
    }
}

export default NotificationList;
