import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Redirect } from "react-router-dom";
import { Formik, Form } from "formik";
import { Row, Column } from "@cortexglobal/rla-components";
import * as Yup from "yup";

import {
    SubmitButton,
    FormikInput,
    FormikCheckbox,
    InputError
} from "@cortexglobal/rla-components";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";

import { login } from "@cortexglobal/cortex-auth-redux";

const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .email("Invalid email")
        .required("Required"),
    password: Yup.string()
        .min(2, "Too Short!")
        .max(70, "Too Long!")
        .required("Required")
});
class LoginForm extends Component {
    state = { credentialsError: false, submitting: false };

    submitForm = (values, { setErrors }) => {
        this.setState(state => ({
            credentialsError: false
        }));
        this.props.login(values).catch(e => {
            parseValidationErrors(e)
                .then(({ errors }) => {
                    setErrors(errors);
                })
                .catch(e => {
                    if (
                        e.response &&
                        e.response.status &&
                        e.response.status === 401
                    ) {
                        this.setState(state => ({
                            credentialsError: e.response.data.message
                        }));
                    }
                });
        });

        return true;
    };

    render() {
        const { redirectToReferer, loggedInChecked } = this.props;
        if (redirectToReferer && loggedInChecked) {
            const { from } = this.props.location.state || {
                from: { pathname: "/" }
            };

            return <Redirect to={from} />;
        }
        const { credentialsError } = this.state;

        return (
            <React.Fragment>
                <Formik
                    initialValues={{
                        username: "",
                        password: "",
                        remember: false,
                        test: "",
                        range: 0,
                        comment: "",
                        time: ""
                    }}
                    onSubmit={this.submitForm}
                    validationSchema={LoginSchema}
                >
                    {({ submitting }) => (
                        <Form className="form-group">
                            <Row collapse>
                                <Column collapse>
                                    <FormikInput
                                        type="email"
                                        name="username"
                                        label="Email"
                                        placeholder="user@domain.com"
                                        labelAlign="right"
                                    />
                                    <FormikInput
                                        type="password"
                                        name="password"
                                        label="Password"
                                    />

                                    {credentialsError && (
                                        <InputError error={credentialsError} />
                                    )}

                                    <FormikCheckbox
                                        name="remember"
                                        label="Remember me?"
                                    />
                                </Column>
                            </Row>
                            <Row collapse>
                                <Column collapse>
                                    <SubmitButton
                                        expanded
                                        type="primary"
                                        label="Sign in"
                                        height={40}
                                        submitting={submitting}
                                    />
                                </Column>
                            </Row>
                        </Form>
                    )}
                </Formik>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { redirectToReferer, loggedInChecked } = state.auth;
    return { redirectToReferer, loggedInChecked };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            login
        },
        dispatch
    );
LoginForm.propTypes = {
    location: PropTypes.object.isRequired
};
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginForm);
