import React, { useEffect, useState } from "react";
import axios from "axios";
import styled from "styled-components";
import moment from "moment";
import {
    Row,
    Column,
    Pagination,
    Modal,
    Button,
    Loading,
} from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import CompetitionDetail from "./CompetitionDetail";

const CompetitionGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    grid-gap: 1rem;
`;

const Competition = styled.div`
    display: flex;
    flex-direction: column;
    padding: 1rem;
    /* border: 1px solid #ccc; */
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: all 0.2s;
    &:hover {
        background-color: #f9f9f9;
    }
`;

export const CompetitionThumbnail = styled.img`
    width: 100%;
    height: auto;
`;

const DetailButton = styled(Button)`
    margin-top: auto;
`;

export const MainHeading = styled.h3`
    margin-bottom: 0;
    font-size: 1.3rem;
    font-weight: 700;
`;

export const SubHeading = styled.h4`
    margin-bottom: 0;
    margin-top: 1rem;
`;
const Competitions = () => {
    const [competitions, setCompetitions] = useState([]);
    const [pagination, setPagination] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [selectedCompetition, setSelectedCompetition] = useState(null);
    useEffect(() => {
        setLoading(true);

        axios
            .get("/api/v1/manager/competitions", {
                params: {
                    page,
                    per_page: perPage,
                },
            })
            .then(({ data }) => {
                setCompetitions(data.data);
                setPagination(data.meta);
                setLoading(false);
            })
            .catch((err) => {
                console.log(err);
                setError(err);
                setLoading(false);
            });
    }, [page, perPage]);

    const onPerPageChange = ({ value }) => {
        setPerPage(value);
        setPage(1);
    };
    return (
        <React.Fragment>
            <Row>
                <Column>
                    <PageTitle
                        title="Competitions"
                        subtitle="View information on current and past competitions"
                    />
                </Column>
            </Row>
            <Row>
                {loading ? (
                    <Column>
                        <Loading />
                    </Column>
                ) : error ? (
                    <Column>Error loading competitions</Column>
                ) : (
                    <Column>
                        <CompetitionGrid>
                            {competitions.map((competition) => (
                                <Competition
                                    key={competition.uuid}
                                    onClick={() =>
                                        setSelectedCompetition(competition)
                                    }
                                >
                                    <MainHeading>
                                        {competition.name}
                                    </MainHeading>
                                    <p>
                                        {moment(competition.start_date).format(
                                            "DD/MM/YYYY"
                                        )}{" "}
                                        -{" "}
                                        {moment(competition.end_date).format(
                                            "DD/MM/YYYY"
                                        )}
                                    </p>
                                    {competition.thumbnail && (
                                        <CompetitionThumbnail
                                            src={competition.thumbnail}
                                            alt={competition.name}
                                        />
                                    )}
                                    <SubHeading>Role(s)</SubHeading>
                                    <p>
                                        {competition.roles.map(
                                            (role, index) => {
                                                const isLast =
                                                    index ===
                                                    competition.roles.length -
                                                        1;
                                                return isLast
                                                    ? role.name
                                                    : index ===
                                                      competition.roles.length -
                                                          2
                                                    ? role.name + " and "
                                                    : role.name + ", ";
                                            }
                                        )}
                                    </p>
                                    <DetailButton
                                        onClick={() =>
                                            setSelectedCompetition(competition)
                                        }
                                        expanded={true}
                                    >
                                        View Competition Details
                                    </DetailButton>
                                </Competition>
                            ))}
                        </CompetitionGrid>
                    </Column>
                )}
                <Column>
                    {pagination !== null && (
                        <div
                            style={{
                                margin: "1rem auto",
                                textAlign: "center",
                            }}
                        >
                            <Pagination
                                alwaysShow={true}
                                style={{ clear: "both" }}
                                currentPage={pagination.current_page - 1}
                                perPage={perPage}
                                pageCount={pagination.last_page}
                                onPerPageChange={onPerPageChange}
                                onPageChange={({ selected }) => {
                                    setPage(selected + 1);
                                }}
                                {...pagination}
                            />
                        </div>
                    )}
                </Column>
            </Row>
            <Modal
                visible={selectedCompetition}
                onClose={() => setSelectedCompetition(null)}
            >
                <CompetitionDetail competition={selectedCompetition} />
            </Modal>
        </React.Fragment>
    );
};

export default Competitions;
