import React, { Component } from "react";
import PropTypes from "prop-types";
import SortableHeader from "../table/SortableHeader";

import {
    StyledTable,
    StyledBodyRow,
    StyledBodyData,
    RetailerName
} from "../table/headOfficeTable";
class EngagementStatusTable extends Component {
    render() {
        const { sortSettings, sortFunction, retailers } = this.props;
        return (
            <StyledTable>
                <thead>
                    <tr>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="name"
                            sortSettings={sortSettings}
                            align="left"
                        >
                            Retailer Name
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="quiz_questions"
                            sortSettings={sortSettings}
                        >
                            Quiz Questions
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="quiz_correct_answers"
                            sortSettings={sortSettings}
                        >
                            Correct Answers
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="comparison_games"
                            sortSettings={sortSettings}
                        >
                            This or That Gameplays
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="comparison_game_average_top_score"
                            sortSettings={sortSettings}
                        >
                            This or That Average Top Score
                        </SortableHeader>
                        <SortableHeader
                            sortFunction={sortFunction}
                            property="comparison_game_top_score"
                            sortSettings={sortSettings}
                        >
                            This or That Top Score
                        </SortableHeader>
                    </tr>
                </thead>
                <tbody>
                    {retailers.map(retailer => {
                        //console.log(retailer);
                        return (
                            <StyledBodyRow key={retailer.alias}>
                                <StyledBodyData align="left">
                                    <RetailerName>{retailer.name}</RetailerName>
                                    {retailer.alias}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.quiz_questions}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.quiz_correct_answers}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.comparison_games}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.comparison_game_average_top_score}
                                </StyledBodyData>
                                <StyledBodyData>
                                    {retailer.comparison_game_top_score}
                                </StyledBodyData>
                            </StyledBodyRow>
                        );
                    })}
                </tbody>
            </StyledTable>
        );
    }
}

EngagementStatusTable.propTypes = {
    retailers: PropTypes.array.isRequired,
    sortFunction: PropTypes.func.isRequired,
    sortSettings: PropTypes.shape({
        sortBy: PropTypes.string,
        sortDirection: PropTypes.oneOf(["", "asc", "desc"])
    })
};
export default EngagementStatusTable;
