import React, { Component } from "react";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import { NavLink } from "react-router-dom";
import PrivateNav from "./PrivateNav";
import HeadOfficeNav from "./HeadOfficeNav";
import AppContext from "../../context/AppContext";
import logos from "../../img/logos.png";
import alarmIcon from "../../img/icons/alarm-bell.svg";
import userIcon from "../../img/icons/messenger-user-avatar.svg";

const headerSpacing = "1rem";

const HeaderPadding = {
    paddingTop: "1rem",
    paddingBottom: "0rem",
};

const Header = styled.div`
    color: ${(props) => props.theme.header.color};
    background: ${(props) => props.theme.header.background};
    width: 100%;
    position: fixed;
    z-index: 1;
    box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.1);
    min-height: 64px;
`;
const NavIconWrapper = styled.div`
    display: inline-block;
    min-width: 3rem;
    margin-left: 10px;
    &.active {
        color: #1c69d4;
    }
`;

const Icon = styled.span`
    display: inline-block;
    width: 25px;
    height: 25px;
    position: relative;
    margin-top: -20px;
    top: 7px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    &.userIcon {
        background-image: url(${userIcon});
    }
    &.alarmIcon {
        background-image: url(${alarmIcon});
    }
`;

class PrivateHeader extends Component {
    render() {
        return (
            <Header>
                <AppContext.Consumer>
                    {({ user, group }) => (
                        <Row
                            style={{
                                maxWidth: "100%",
                            }}
                        >
                            <Column
                                small={8}
                                style={{
                                    paddingTop: ".3em",
                                }}
                            >
                                <NavIconWrapper>
                                    {user.role &&
                                    user.role.type === "head-office" &&
                                    group.type === "head-office" ? (
                                        <HeadOfficeNav
                                            spacing={headerSpacing}
                                        />
                                    ) : (
                                        <PrivateNav spacing={headerSpacing} />
                                    )}
                                    &nbsp;
                                </NavIconWrapper>
                                <NavIconWrapper style={HeaderPadding}>
                                    <NavLink exact to="/profile">
                                        <Icon className="userIcon" />
                                    </NavLink>
                                </NavIconWrapper>
                                <NavIconWrapper style={HeaderPadding}>
                                    <NavLink exact to="/notifications">
                                        <Icon className="alarmIcon" />
                                    </NavLink>
                                </NavIconWrapper>
                            </Column>
                            <Column
                                large={4}
                                medium={4}
                                small={4}
                                style={{
                                    paddingBottom: HeaderPadding.paddingBottom,
                                    paddingTop: HeaderPadding.paddingTop,
                                    textAlign: "right",
                                }}
                            >
                                <NavLink to="/">
                                    <img
                                        src={logos}
                                        style={{ width: "90px" }}
                                        alt="BMW and Mini Logos"
                                    />
                                </NavLink>
                            </Column>
                        </Row>
                    )}
                </AppContext.Consumer>
            </Header>
        );
    }
}

export default PrivateHeader;
