import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { Formik, Form } from "formik";
import * as Yup from "yup";
import {
    Row,
    Column,
    SubmitButton,
    FormikSelect,
    FormikInput,
    FormikTextarea,
    Loading
} from "@cortexglobal/rla-components";

import { parseValidationErrors } from "@cortexglobal/cortex-utilities";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
import axios from "axios";

const NotificationSchema = Yup.object().shape({
    group_alias: Yup.string().required("Required"),
    role_type: Yup.string().required("Required"),
    message: Yup.string().required("Required"),
    link: Yup.string().notRequired(),
    link_text: Yup.string().notRequired()
});

class NotifyForm extends Component {
    state = { submitting: false, data: {}, error: false };

    submitForm = (values, { setErrors }) => {
        this.setState(state => ({
            submitting: true
        }));

        axios
            .post("/api/v1/notify", values)
            .then(({ data: { data } }) => {
                this.setState(state => ({
                    data: data
                }));
            })
            .catch(e =>
                parseValidationErrors(e).then(({ errors }) => {
                    setErrors(errors);
                })
            )
            .finally(() => {
                this.setState(state => ({
                    submitting: false
                }));
            });
    };

    render() {
        const { submitting } = this.state;
        const { retailer } = this.props;

        const global = this.props.retailer === undefined;
        const retailerAlias = undefined !== retailer ? retailer.alias : "";

        if (this.state.submitting) {
            return (
                <div className="notification-form loading">
                    <Loading height="120px" align="center" />
                    <p style={{ textAlign: "center" }}>
                        Sending notifications...
                    </p>
                </div>
            );
        }

        if (this.state.data && this.state.data.sent !== undefined) {
            const { data } = this.state;
            return (
                <div className="notification-form">
                    <Row>
                        <Column medium={12}>
                            <PageTitle title="Sending Complete" />
                        </Column>
                    </Row>
                    <br />
                    <Row className="success">
                        <Column medium={12}>
                            <p>
                                Sent to {data.stats.sent} user
                                {data.stats.sent === 1 ? "" : "s"}.
                            </p>
                        </Column>
                    </Row>
                </div>
            );
        }

        return (
            <div className="notification-form">
                <Row>
                    <Column medium={12}>
                        <PageTitle
                            title={
                                "Notify " +
                                (global ? "the Network" : "Retailer")
                            }
                            subtitle={
                                "Send an in-app notification to the " +
                                (global ? "network" : "retailer")
                            }
                        />
                    </Column>
                    <div className="form-container">
                        <React.Fragment>
                            <Formik
                                initialValues={{
                                    group_alias: global ? "*" : retailerAlias,
                                    role_type: "",
                                    message: "",
                                    link: ""
                                }}
                                onSubmit={this.submitForm}
                                validationSchema={NotificationSchema}
                                className="form-group"
                            >
                                {({ values, errors }) => (
                                    <Form className="form-group">
                                        <FormikInput
                                            type="hidden"
                                            name="group_alias"
                                            value={values.group_alias}
                                        />
                                        <Row>
                                            <Column large={6}>
                                                <FormikSelect
                                                    name="role_type"
                                                    label="Send notifications to"
                                                    value={values.role}
                                                    placeholder=""
                                                    options={[
                                                        {
                                                            value: "manager",
                                                            text:
                                                                "Managers only"
                                                        },
                                                        {
                                                            value: "technician",
                                                            text:
                                                                "Technicians only"
                                                        },
                                                        {
                                                            value: "*",
                                                            text: "All"
                                                        }
                                                    ]}
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column large={12}>
                                                <FormikTextarea
                                                    type="text"
                                                    name="message"
                                                    label="Message"
                                                    value={values.message}
                                                    placeholder="Enter message here..."
                                                />
                                            </Column>
                                        </Row>
                                        <Row>
                                            <Column large={6}>
                                                <FormikInput
                                                    type="text"
                                                    name="link"
                                                    label="Add URL"
                                                    value={values.link}
                                                    placeholder="https://...co.uk/url"
                                                />

                                                <SubmitButton
                                                    expanded
                                                    submitting={submitting}
                                                    disabled={submitting}
                                                >
                                                    Send notification
                                                </SubmitButton>
                                            </Column>
                                        </Row>
                                    </Form>
                                )}
                            </Formik>
                        </React.Fragment>
                    </div>
                </Row>
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {};
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            // notify
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(NotifyForm);
