import React, { Component } from "react";
import PropTypes from "prop-types";
import Draggable from "react-draggable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faPlus from "@fortawesome/free-solid-svg-icons/faPlus";
import faMinus from "@fortawesome/free-solid-svg-icons/faMinus";
import faExpandArrowsAlt from "@fortawesome/free-solid-svg-icons/faExpandArrowsAlt";
import { Loading, Button } from "../../index";

class Zoomable extends Component {
    state = {
        scale: 1,
        position: { x: 0, y: 0 },
        resetting: false
    };

    componentDidUpdate(prevProps) {
        if (prevProps.resetZoom === false && this.props.resetZoom === true) {
            this.reset();
        }
    }
    reset = () => {
        this.setState(state => ({
            scale: 1,
            position: { x: 0, y: 0 },
            resetting: true
        }));
        setTimeout(() => {
            this.setState({ resetting: false });
        }, this.props.transitionMilliseconds);
    };
    render() {
        const { updating, zoomStep, minZoom, maxZoom } = this.props;
        const { scale, position, resetting } = this.state;
        const childStyle = {
            maxWidth: "100%",
            opacity: updating ? "0.2" : "1",
            transition: resetting
                ? `all ${this.props.transitionMilliseconds}ms`
                : "none",
            boxShadow: "2px 2px 9px rgba(0,0,0,0.2)"
        };

        return (
            <div
                style={{
                    position: "relative",
                    overflow: "hidden"
                }}
            >
                <div
                    style={{
                        position: "absolute",
                        right: "15px",
                        top: "15px",
                        zIndex: "1"
                    }}
                >
                    <Button
                        onClick={() => {
                            this.setState(state => ({
                                scale:
                                    state.scale + zoomStep < maxZoom
                                        ? state.scale + zoomStep
                                        : maxZoom
                            }));
                        }}
                        disabled={scale >= maxZoom}
                    >
                        <FontAwesomeIcon icon={faPlus} />
                    </Button>
                    <Button
                        onClick={() => {
                            this.setState(state => ({
                                scale:
                                    state.scale - zoomStep > minZoom
                                        ? state.scale - zoomStep
                                        : minZoom
                            }));
                        }}
                        disabled={scale <= minZoom}
                    >
                        <FontAwesomeIcon icon={faMinus} />
                    </Button>
                    <Button onClick={this.reset} disabled={scale === 1}>
                        {" "}
                        <FontAwesomeIcon icon={faExpandArrowsAlt} />
                    </Button>
                </div>
                {updating && (
                    <div
                        style={{
                            position: "absolute",
                            width: "100%",
                            height: "100%"
                        }}
                    >
                        <Loading align="center" />
                    </div>
                )}
                <div
                    style={{
                        position: "relative",
                        transition: `all ${
                            this.props.transitionMilliseconds
                        }ms`,
                        transform: `scale(${scale})`,
                        padding: "1rem"
                    }}
                >
                    {" "}
                    <Draggable
                        scale={scale}
                        position={position}
                        onStop={(e, data) => {
                            this.setState({
                                position: { x: data.x, y: data.y }
                            });
                        }}
                    >
                        {this.props.children({ style: childStyle })}
                    </Draggable>
                </div>
            </div>
        );
    }
}

Zoomable.displayName = "Zoomable";

Zoomable.propTypes = {
    children: PropTypes.func.isRequired,
    transitionMilliseconds: PropTypes.number,
    zoomStep: PropTypes.number,
    minZoom: PropTypes.number,
    maxZoom: PropTypes.number,
    updating: PropTypes.bool,
    resetZoom: PropTypes.bool
};
Zoomable.defaultProps = {
    transitionMilliseconds: 500,
    zoomStep: 0.5,
    minZoom: 0.5,
    maxZoom: 10,
    updating: false,
    resetZoom: false
};

export default Zoomable;
