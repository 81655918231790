import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { SelectField as Select } from "@cortexglobal/rla-components";
// import {
//     setUserGroup,
//     getUserGroups
// } from "@cortexglobal/cortex-auth-react-router-dom";

import { setUserGroup, getUserGroups } from "@cortexglobal/cortex-auth-redux";
class GroupSwitcher extends Component {
    constructor(props) {
        super(props);

        this.state = {
            errors: [],
            activeGroup: ""
        };
    }

    componentDidMount() {
        this.setState({
            activeGroup: this.props.group.uuid
        });
    }

    componentWillReceiveProps(props) {
        if (this.state.activeGroup !== props.group.uuid) {
            this.setState({
                activeGroup: props.group.uuid
            });
        }
    }

    userGroupChanged = ({ value: group }) => {
        if (!group || this.state.activeGroup === group) {
            return false;
        }

        this.setState({ activeGroup: group });

        this.props
            .setUserGroup({ group })
            .then(group => {
                // console.warn(group);
            })
            .catch(e => e);
    };

    render() {
        if (!this.props.groups || this.props.groups.length <= 1) {
            return null;
        }
        return (
            <React.Fragment>
                {this.props.label && <label>{this.props.label}</label>}
                <Select
                    name="user-group"
                    onChange={this.userGroupChanged}
                    options={this.props.groups.map(group => ({
                        value: group.uuid,
                        text: group.name
                    }))}
                    value={this.state.activeGroup}
                    clearable={false}
                />
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => {
    const { user, group, groups } = state.auth;

    return { user, group, groups };
};

const mapDispatchToProps = dispatch =>
    bindActionCreators(
        {
            setUserGroup,
            getUserGroups
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GroupSwitcher);
