import React, { Component } from "react";
import { ForgottenPasswordForm } from "@cortexglobal/cortex-auth-react-router-dom";
import { Row } from "@cortexglobal/rla-components";

import { HeroColumn, Hero, ContentColumn } from "../../components/auth";

class ForgottenPassword extends Component {
    render() {
        return (
            <div className="page page-login">
                <Row collapse equaliseChildHeight>
                    <HeroColumn collapse>
                        <Hero />
                    </HeroColumn>
                    <ContentColumn>
                        <div className="form-container">
                            <h1>Forgotten Password</h1>
                            <ForgottenPasswordForm />
                        </div>
                    </ContentColumn>
                </Row>
            </div>
        );
    }
}

export default ForgottenPassword;
