import React from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import faCircleNotch from "@fortawesome/free-solid-svg-icons/faCircleNotch";

import Button from "./button";

const SubmitButton = ({
    icon,
    submitting,
    submittingIcon,
    children,
    label,
    ...props
}) => {
    return (
        <Button disabled={submitting} {...props} type="submit">
            {submitting ? submittingIcon : icon ? icon : ""}{" "}
            {label ? label : children}
        </Button>
    );
};

SubmitButton.displayName = "SubmitButton";

SubmitButton.propTypes = {
    submitting: PropTypes.bool,
    /** The Icon to use whilst NOT submitting - probably a Font Awesome icon like   <FontAwesomeIcon icon={faCircleNotch} spin />
     * If none is supplied no icon will display
     */
    icon: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
    /** The Icon to use whilst submitting - probably a Font Awesome icon like  <FontAwesomeIcon icon={faCircleNotch} spin /> */
    submittingIcon: PropTypes.object
};

SubmitButton.defaultProps = {
    submitting: false,
    icon: false,
    submittingIcon: <FontAwesomeIcon icon={faCircleNotch} spin />
};

export default SubmitButton;
