import React, { Component } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import isNil from "lodash/isNil";

import VideoWallBackground from "./img/videowall.png";
import { Row, Column, Button, Loading } from "@cortexglobal/rla-components";
import { PageTitle } from "@cortexglobal/bmw-shared-components";
//import StyledVideo from "./StyledVideo";
import StyledVideoPlaceholder from "./StyledVideoPlaceholder";

export const LargeButton = styled(Button)`
    width: 100%;
    height: 5rem;
    border-radius: 5px;
    font-size: 1.45rem;
`;

export const CountDown = styled.div`
    background: #1c69d4;
    color: white;
    font-size: 2em;
    font-weight: bold;
    text-align: center;
    border-radius: 5px;
    padding: 0.4em 5%;
    section {
        display: flex;
        justify-content: space-evenly;
        max-width: 3rem;
        margin: auto;
    }
`;

export const StyledList = styled.ul`
    margin-block-start: 0;
    margin-block-end: 0;
    /* font-size: 1.5em; */
    /* font-size: 1rem; */
    padding-inline-start: 22px;

    li {
        /* line-height: 1.6rem; */
        margin: 0.3rem 0;
        padding: 0;
    }

    /* span {
        font-size: 1rem;
        margin: 0;
        padding: 0;
        vertical-align: top;
    } */

    @media only screen and (max-width: 400px) {
        margin-bottom: 1em;
    }
`;

export const HeroOverlayTitle = styled.h1`
    position: absolute;
    text-align: left;
    top: 24%;
    color: white;
    margin-left: 30px;

    @media only screen and (max-width: 400px) {
        top: 30%;
        font-size: 1.7em;
        margin-left: 20px;
    }

    @media only screen and (max-width: 320px) {
        top: 40%;
        font-size: 1.6em;
        margin-left: 20px;
    }
`;

export const CenteredButton = styled(Button)`
    margin: auto;
    display: block;
    width: 250px;
    height: 50px;
`;

export const BackgroundImage = styled.div`
    width: 100%;
    height: 250px;
    background-image: url(${VideoWallBackground});
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: block;

    img {
        width: 250px;
        height: 157px;
        margin-top: 50px;
    }
`;

const Play = styled.div`
    font-size: 3em;
    position: absolute;
    top: calc(50% - 20px);
    left: 0;
    right: 0;
    bottom: 0;
`;

const ContentWrap = styled.div`
    position: relative;
    width: 100%;
    height: 100;
`;

const Small = styled.small`
    font-weight: bold;
    font-size: 1.1em;
    align-self: flex-end;
    margin-left: 0.5em;
    margin-bottom: 0.3em;
    position: absolute;
    bottom: 0;
    left: 0;
`;

const Overlay = props => (
    <div className={props.className}>
        <ContentWrap>
            <Play>></Play>
            <Small>{props.retailer}</Small>
        </ContentWrap>
    </div>
);

export const WinnersLink = props => (
    <Link
        style={{
            display: "block",
            marginBottom: "1rem"
        }}
        to="/vehicle-health-check-competition-winners"
    >
        <LargeButton>View Winners</LargeButton>
    </Link>
);

export const StyledOverlay = styled(Overlay)`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: white;
    display: flex;
    line-height: 100%;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.3);
`;

const Video = props => (
    <div className={props.className}>
        <div>
            <video controls>{props.children}</video>
            <h3 style={{ marginBottom: ".5em" }}>
                {props.user}, {props.retailer}
            </h3>
            <h3 style={{ float: "left", marginTop: "0em", color: "grey" }}>
                {props.date}
            </h3>
            <a href="javascript:;">
                <div
                    onClick={props.hideVideoModal}
                    style={{ float: "right" }}
                    className={props.className}
                >
                    <h3
                        style={{
                            float: "right",
                            textDecoration: "underline",
                            margin: "0em 2em 0em 1em",
                            color: "grey"
                        }}
                    >
                        Close
                    </h3>
                </div>
            </a>
        </div>
    </div>
);

export const StyledVideo = styled(Video)`
    max-width: 1400px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    height: 100%;

    h3 {
        text-transform: capitalize;
        text-align: left;
        margin: 1em 2em;
    }

    div {
        background-color: white;
    }

    video {
        width: 100%;
        max-width: 650px;
        height: 100%;
        max-height: 550px;
        background-color: darkGrey;
    }
`;

export const VideoWall = props => (
    <React.Fragment>
        {props.videos.map((row, index) => (
            <Row key={index}>
                {row.map((video, index) => (
                    <Column key={index} medium={12} large={6} xlarge={4}>
                        <StyledVideoPlaceholder
                            video={video}
                            src={video.imageUrl}
                        />
                    </Column>
                ))}
            </Row>
        ))}
        {props.loading ? <Loading align="center" /> : null}
    </React.Fragment>
);

const VideoTitle = props => (
    <React.Fragment>
        <h3 style={{ marginBottom: "0", marginTop: "1em" }}>{props.title}</h3>
        <p style={{ color: "rgb(136,136,137)", marginBottom: "0.2em" }}>
            {props.subtitle}
        </p>
    </React.Fragment>
);

export const VideoWinners = props => (
    <React.Fragment>
        {props.videoWinners.map((row, index) => {
            const { winner, runnersUp } = row;

            if (row.group === "Area 9") {
                return null;
            }

            if (row.runnersUp.length > 1) {
                const runnerUp = !isNil(runnersUp[0]) ? runnersUp[0] : {};
                const runnerUp2 = !isNil(runnersUp[0]) ? runnersUp[1] : {};

                return (
                    <React.Fragment key={index}>
                        <Row>
                            <Column medium={12} large={6} xlarge={4}>
                                <VideoTitle
                                    title={`${row.group} Winner`}
                                    subtitle="Watch the video voted as the best"
                                />
                                <StyledVideoPlaceholder
                                    video={winner}
                                    src={winner !== null ? winner.imageUrl : ""}
                                />
                            </Column>
                            <Column medium={12} large={6} xlarge={4}>
                                <VideoTitle
                                    title={`${row.group} Runner Up`}
                                    subtitle="Watch the video voted as the runner up"
                                />
                                <StyledVideoPlaceholder
                                    video={runnerUp}
                                    src={
                                        runnerUp !== null
                                            ? runnerUp.imageUrl
                                            : ""
                                    }
                                />
                            </Column>
                        </Row>
                        <Row>
                            <Column medium={12} large={6} xlarge={4}>
                                <VideoTitle
                                    title={`${row.group} Runner Up`}
                                    subtitle="Watch the video voted as the runner up"
                                />
                                <StyledVideoPlaceholder
                                    video={runnerUp2}
                                    src={
                                        runnerUp !== null
                                            ? runnerUp2.imageUrl
                                            : ""
                                    }
                                />
                            </Column>
                        </Row>
                    </React.Fragment>
                );
            } else {
                const runnerUp = !isNil(runnersUp[0]) ? runnersUp[0] : {};

                return (
                    <React.Fragment key={index}>
                        <Row>
                            <Column medium={12} large={6} xlarge={4}>
                                <VideoTitle
                                    title={`${row.group} Winner`}
                                    subtitle="Watch the video voted as the best"
                                />
                                <StyledVideoPlaceholder
                                    video={winner}
                                    src={winner !== null ? winner.imageUrl : ""}
                                />
                            </Column>
                            <Column medium={12} large={6} xlarge={4}>
                                <VideoTitle
                                    title={`${row.group} Runner Up`}
                                    subtitle="Watch the video voted as the runner up"
                                />
                                <StyledVideoPlaceholder
                                    video={runnerUp}
                                    src={
                                        runnerUp !== null
                                            ? runnerUp.imageUrl
                                            : ""
                                    }
                                />
                            </Column>
                        </Row>
                    </React.Fragment>
                );
            }
        })}
        {props.loading ? <Loading align="center" /> : null}
    </React.Fragment>
);

export const ListItem = styled.li`
    list-style: none;
    position: relative;
    &:before {
        content: "•";
        position: absolute;
        left: -0.9rem;
        font-size: 1rem;
    }
`;

// const CloseButton = styled.a`
//     text-align: right;
//     text-decoration: underline;
//     float: right;
//     cursor: pointer;
//     &:after {
//         clear: both;
//     }
// `;

// const ModalBackground = styled.div`
//     position: fixed;
//     z-index: 1;
//     top: 0;
//     left: 0;
//     right: 0;
//     bottom: 0;
//     background-color: rgba(0, 0, 0, 0.8);
// `;

// const ModalForeground = styled.div`
//     display: flex;
//     text-align: center;
//     max-width: 1400px;
//     align-items: center;
//     justify-content: center;
//     padding: 0;
//     z-index: -1;
//     height: 100%;
// `;

// export class UnstyledRulesModal extends Component {
//     constructor(props) {
//         super(props);
//         this.state = { visible: false };
//         this.hideModal = this.hideModal.bind(this);
//     }

//     hideModal() {
//         this.setState({ visible: false });
//     }

//     componentDidUpdate(prevProps) {
//         const { visible } = this.props;

//         if (visible === true) {
//             this.setState({ visible: visible });
//         }
//     }

//     render() {
//         const { children, className } = this.props;
//         const { visible } = this.state;

//         return visible ? (
//             <ModalBackground onClick={this.hideModal}>
//                 <ModalForeground>
//                     <div className={className}>
//                         <CloseButton onClick={this.hideModal}>
//                             Close
//                         </CloseButton>
//                         {children}
//                     </div>
//                 </ModalForeground>
//             </ModalBackground>
//         ) : null;
//     }
// }

// export const RulesModal = styled(UnstyledRulesModal)`
//     padding: 1rem 2rem 2rem 2rem;
//     background-color: white;
//     text-align: left;
//     max-width: 50rem;
// `;
