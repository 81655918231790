import axios from "axios";

export const STAFF_LOADING = "STAFF_LOADING";
export const STAFF_UPDATING = "STAFF_UPDATING";
export const LOAD_STAFF = "LOAD_STAFF";
export const getStaff = () => {
    return (dispatch, getState) => {
        // if (getState().staff.loading) {
        //     return;
        // }
        if (!getState().staff.loaded) {
            dispatch({ type: STAFF_LOADING, data: true });
        }
        dispatch({ type: STAFF_UPDATING, data: true });

        axios
            .get(`/api/v1/manager/staff`)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_STAFF,
                    data: data,
                });
                dispatch({ type: STAFF_LOADING, data: false });
                dispatch({ type: STAFF_UPDATING, data: false });
            })
            .catch((e) => e);
    };
};
export const LOAD_INITIAL_INVITES = "LOAD_INITIAL_INVITES";
export const getInitialInvites = () => {
    return (dispatch) => {
        dispatch({ type: STAFF_LOADING, data: true });
        axios
            .get(`/api/v1/manager/initial-invites`)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_INITIAL_INVITES,
                    data,
                });
                dispatch({ type: STAFF_LOADING, data: false });
            })
            .catch((e) => e);
    };
};
export const submitInitialInvites = (values) => {
    return (dispatch) => {
        dispatch({ type: STAFF_UPDATING, data: true });
        axios
            .post(`/api/v1/manager/initial-invites`, values)
            .then((response) => {
                const { data } = response.data;
                dispatch({
                    type: LOAD_INITIAL_INVITES,
                    data,
                });
                dispatch({ type: STAFF_UPDATING, data: false });
            })
            .catch((e) => e);
    };
};

const startUserUpdate = (dispatch, userUuid) => {
    dispatch({ type: STAFF_UPDATING, data: true });
    dispatch({ type: UPDATING_USER_UUID, data: userUuid });
};
const commitUserUpdate = (dispatch, userUuid, data) => {
    dispatch({
        type: UPDATE_USER,
        uuid: userUuid,
        data,
    });
    dispatch({ type: STAFF_UPDATING, data: false });
    dispatch({ type: UPDATING_USER_UUID, data: "" });
};
export const UPDATE_USER = "UPDATE_USER";
export const UPDATING_USER_UUID = "UPDATING_USER_UUID";
export const updateUserStatus = (newStatus, userUuid, notes = "") => {
    return (dispatch) => {
        startUserUpdate(dispatch, userUuid);
        return axios
            .put(`/api/v1/manager/staff/${userUuid}/status`, {
                status: newStatus,
                notes,
            })
            .then((response) => {
                const { data } = response.data;
                commitUserUpdate(dispatch, userUuid, data);
            })
            .catch((e) => {
                dispatch({ type: STAFF_UPDATING, data: false });
                dispatch({ type: UPDATING_USER_UUID, data: "" });
                throw e;
            });
    };
};

export const extendProbation = (userUuid, values) => {
    return (dispatch) => {
        startUserUpdate(dispatch, userUuid);
        return axios
            .put(`/api/v1/manager/staff/${userUuid}/extend-probation`, values)
            .then((response) => {
                const { data } = response.data;
                commitUserUpdate(dispatch, userUuid, data);
            })
            .catch((e) => {
                dispatch({ type: STAFF_UPDATING, data: false });
                dispatch({ type: UPDATING_USER_UUID, data: "" });
                throw e;
            });
    };
};

export const approveProbation = (userUuid) => {
    return (dispatch) => {
        startUserUpdate(dispatch, userUuid);
        return axios
            .put(`/api/v1/manager/staff/${userUuid}/approve-probation`, {})
            .then((response) => {
                const { data } = response.data;
                commitUserUpdate(dispatch, userUuid, data);
            })
            .catch((e) => {
                dispatch({ type: STAFF_UPDATING, data: false });
                dispatch({ type: UPDATING_USER_UUID, data: "" });
                throw new Error(e.response.data.message);
            });
    };
};

export const updateRewardStatus = (
    choice,
    userUuid,
    rewardUuid,
    notes = ""
) => {
    return (dispatch) => {
        startUserUpdate(dispatch, userUuid);
        return axios
            .put(`/api/v1/manager/staff/${userUuid}/reward/${rewardUuid}`, {
                choice,
                notes,
            })
            .then((response) => {
                const { data } = response.data;
                commitUserUpdate(dispatch, userUuid, data);
            })
            .catch((e) => {
                dispatch({ type: STAFF_UPDATING, data: false });
                dispatch({ type: UPDATING_USER_UUID, data: "" });
                throw e;
            });
    };
};
export const editStaffMember = (member) => {
    return (dispatch) => {
        startUserUpdate(dispatch, member.uuid);
        return axios
            .put(`/api/v1/manager/staff/${member.uuid}`, member)
            .then((response) => {
                const { data } = response.data;
                commitUserUpdate(dispatch, member.uuid, data);
            });
    };
};
export const ADD_USER = "ADD_USER";
export const inviteStaffMember = (member) => {
    return (dispatch) => {
        dispatch({ type: STAFF_UPDATING, data: true });
        return axios.post(`/api/v1/manager/staff`, member).then((response) => {
            const { data } = response.data;
            // console.log("data", data);
            dispatch({
                type: ADD_USER,
                data,
            });
            dispatch({ type: STAFF_UPDATING, data: false });
        });
    };
};
export const sendSurvey = (values) => {
    return (dispatch) => {
        dispatch({ type: STAFF_UPDATING, data: true });
        return axios
            .post(`/api/v1/manager/exit-survey`, values)
            .then((response) => {
                const { data } = response.data;
                commitUserUpdate(dispatch, data.uuid, data);
            });
    };
};
