import React, { Component } from "react";
import styled from "styled-components";
import { colors } from "../config/theme";

const SectionMainHeading = styled.h2`
    font-size: 1.1rem;
    line-height: 1.3rem;
    margin: ${({ margin }) => margin}};
`;

const SectionSubHeading = styled.p`
    width: 100%;
    font-size: 0.8rem;
    line-height: 0.9rem;
    color: ${colors.altGray};
    display: block;
    margin: 0 0 0.4rem 0;
`;

const SectionTitle = ({ title, subtitle, margin = "1rem 0 0.1rem 0" }) => {
    return (
        <div>
            <SectionMainHeading margin={margin}>{title}</SectionMainHeading>
            {subtitle && <SectionSubHeading>{subtitle}</SectionSubHeading>}
        </div>
    );
};

export default SectionTitle;
