import React, { Component } from "react";
import moment from "moment";
// import PropTypes from "prop-types";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
const StyledTable = styled.table`
    width: 100%;
    font-size: 0.7rem;
    tbody tr:nth-child(odd) {
        background-color: #e2e2e2;
    }
`;

const TotalTD = styled.td`
    text-align: center;
`;

class NewTechnicianMonthlyTotals extends Component {
    generateMonthsThisYear = () => {
        let months = [];
        const year = moment().format("YYYY");
        for (let i = 1; i <= 12; i++) {
            const monthDate = moment(`${year}-${i}-01`, "YYYY-M-DD");
            months.push({
                date: monthDate.format("YY-MM-DD"),
                month: monthDate.format("MMMM")
            });
        }
        return months;
    };
    render() {
        const { months } = this.props;
        const monthsThisYear = this.generateMonthsThisYear();
        return (
            <div>
                <Row>
                    <Column>
                        <StyledTable>
                            <thead>
                                <tr>
                                    <th style={{ textAlign: "left" }}>Month</th>
                                    <th>New</th>
                                    <th>Leavers</th>
                                    <th>Net</th>
                                </tr>
                            </thead>
                            <tbody>
                                {monthsThisYear.map(month => {
                                    return (
                                        <tr key={month.month}>
                                            <td>{month.month}</td>
                                            <TotalTD>
                                                {months[month.date] !==
                                                undefined
                                                    ? months[month.date].new
                                                    : ""}
                                            </TotalTD>
                                            <TotalTD>
                                                {months[month.date] !==
                                                undefined
                                                    ? months[month.date].leaving
                                                    : ""}
                                            </TotalTD>
                                            <TotalTD>
                                                {months[month.date] !==
                                                undefined
                                                    ? months[month.date].net
                                                    : ""}
                                            </TotalTD>
                                        </tr>
                                    );
                                })}
                            </tbody>
                        </StyledTable>
                    </Column>
                </Row>
            </div>
        );
    }
}

NewTechnicianMonthlyTotals.propTypes = {};

export default NewTechnicianMonthlyTotals;
