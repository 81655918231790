import React from "react";
import PropTypes from "prop-types";

import styled from "styled-components";

const Hero = styled.div`
    background: url(${props => props.backgroundImage});
    background-size: cover;
    background-position: ${props =>
        props.backgroundPosition ? props.backgroundPosition : "0% 50%"};
    max-width: 100%;
    min-height: 140px;

    margin: 0 0 1rem 0;
    @media (min-width: ${props =>
            props.breakpoint
                ? props.breakpoint
                : props.theme.breakpoints.large}px) {
        margin: 1rem;
        width: 66%;
        border-radius: 5px;
    }
`;

Hero.propTypes = {
    //backgroundImage: PropTypes.string().isRequired();
};
export default Hero;
