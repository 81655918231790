import React from "react";
import PropsTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";

import {
    Modal,
    Row,
    Column,
    Button,
    Select,
} from "@cortexglobal/rla-components";
import {
    PROGRAM_START_DATE,
    DATE_FORMAT,
} from "@cortexglobal/bmw-shared-components";

import {
    updateUserStatus,
    editStaffMember,
} from "../modules/manager/staff/staffActions";
import StaffForm from "../modules/manager/staff/StaffForm";

class UpdateStatusButton extends React.Component {
    state = {
        showEditModal: false,
        showDeleteModal: false,
        notes: "",
    };
    showEditModal = () => {
        this.setState({ showEditModal: true });
    };
    editStaffMember = (values) => {
        return this.props.editStaffMember(values).then(() => this.onClose());
    };
    onClose = () => {
        this.setState({
            showEditModal: false,
            showDeleteModal: false,
        });
    };
    updateStatus = () => {
        const { newStatus, user } = this.props;
        const { notes } = this.state;
        //If we're making a user active and they started after the program started
        // and they are a technician, we need to check their probation date is set
        const isTechnician = user.role && user.role.type === "technician";
        if (
            isTechnician &&
            newStatus === "active" &&
            moment(user.start_date, DATE_FORMAT).isSameOrAfter(
                PROGRAM_START_DATE
            ) &&
            user.probation_date === ""
        ) {
            this.setState({ showEditModal: true });
            return null;
        }

        if (newStatus === "account-deleted") {
            this.setState({ showDeleteModal: true });
            return null;
        }

        if (!this.props.confirm || window.confirm(this.props.confirm)) {
            this.props.updateUserStatus(
                this.props.newStatus,
                this.props.user.uuid,
                notes
            );
        }
        this.onClose();
    };
    render() {
        const {
            updating,
            updatingUserUuid,
            user,
            children,
            updateUserStatus,
            newStatus,
            ...rest
        } = this.props;
        const { showEditModal, showDeleteModal, notes } = this.state;

        //console.log("children", children);

        return (
            <React.Fragment>
                <Button
                    {...rest}
                    disabled={updating}
                    //submitting={userUuid === updatingUserUuid}
                    onClick={this.updateStatus}
                >
                    {children}
                </Button>
                {showEditModal && (
                    <Modal visible={showEditModal} onClose={this.onClose}>
                        <Row>
                            <Column>
                                <h1 style={{ textAlign: "center" }}>
                                    Please confirm {user.first_name}{" "}
                                    {user.last_name} details before approving
                                </h1>
                            </Column>
                        </Row>
                        <StaffForm
                            member={user}
                            onSubmit={this.editStaffMember}
                        />
                    </Modal>
                )}
                {showDeleteModal && (
                    <Modal visible={showDeleteModal} onClose={this.onClose}>
                        <Row>
                            <Column>
                                <h1 style={{ textAlign: "center" }}>Warning</h1>
                                <h3 style={{ textAlign: "center" }}>
                                    You are about to delete {user.first_name}{" "}
                                    {user.last_name} from the Elite programme
                                </h3>
                                <p>
                                    If you are sure you want to proceed, please
                                    select a reason from the following:
                                </p>
                                <Select
                                    onChange={({ value }) => {
                                        console.log("value", value);
                                        this.setState({ notes: value });
                                    }}
                                    options={[
                                        {
                                            value: "Redundancy",
                                            text: "Redundancy",
                                        },
                                        {
                                            value: "Dismissal",
                                            text: "Dismissal",
                                        },
                                        {
                                            value: "Did not pass probation",
                                            text: "Did not pass probation",
                                        },
                                        {
                                            value: "Moved to another BMW Group dealership",
                                            text: "Moved to another BMW Group dealership",
                                        },
                                        {
                                            value: "Gone to work for a competitor",
                                            text: "Gone to work for a competitor",
                                        },
                                        {
                                            value: "Gone to pursue a different career opportunity",
                                            text: "Gone to pursue a different career opportunity",
                                        },
                                        {
                                            value: "Retirement",
                                            text: "Retirement",
                                        },
                                        {
                                            value: "Personal reasons",
                                            text: "Personal reasons",
                                        },
                                        {
                                            value: "Other",
                                            text: "Other",
                                        },
                                    ]}
                                />
                            </Column>
                        </Row>
                        <Row style={{ marginTop: "1rem" }}>
                            <Column>
                                <Button
                                    type="alert"
                                    onClick={() =>
                                        this.props.updateUserStatus(
                                            newStatus,
                                            user.uuid,
                                            `Deleted: ${notes}`
                                        )
                                    }
                                    disabled={notes === ""}
                                >
                                    Delete
                                </Button>
                            </Column>
                        </Row>
                    </Modal>
                )}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    const { updating, updatingUserUuid } = state.staff;
    return { updating, updatingUserUuid };
};
const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            updateUserStatus,
            editStaffMember,
        },
        dispatch
    );

UpdateStatusButton.propTypes = {
    newStatus: PropsTypes.string.isRequired,
    user: PropsTypes.object.isRequired,
};
export default connect(mapStateToProps, mapDispatchToProps)(UpdateStatusButton);
