import styled from "styled-components";
import { Row } from "@cortexglobal/rla-components";
// import HeroImg from "../../img/bmw-service-image-tester.jpg";
import HeroImg from "../../img/bmw-hero-min.jpeg";

export const Hero = styled.div`
    background: url(${HeroImg});
    background-size: cover;
    background-position: 10% 54%;
    max-width: 100%;
    min-height: 200px;
`;

export const Wrapper = styled(Row)`
    @media (min-width: ${(props) => props.theme.breakpoints.large}px) {
        width: 66%;
    }
`;
