import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";
import styled from "styled-components";

import Header from "./PublicHeader";
import Footer from "./Footer";

import Login from "../../modules/auth/Login";
import ForgottenPassword from "../../modules/auth/ForgottenPassword";
import ResetPassword from "../../modules/auth/ResetPassword";
import ActivateAccount from "../../modules/auth/ActivateAccount";
// import Terms from "../../modules/infoPages/Terms";
import TermsUk from "../../modules/infoPages/TermsUk";
import TermsIe from "../../modules/infoPages/TermsIe";
import Privacy from "../../modules/infoPages/Privacy";
import Cookies from "../../modules/infoPages/Cookies";
import { app } from "../../config/theme";

const Container = styled.div`
    width: 100%;
    // max-width: 20em;
    margin: auto;
`;

const AppContainer = styled.div`
    padding-bottom: ${app.headerHeight}px;
`;
class PublicApp extends Component {
    render() {
        return (
            <Container>
                <Header />
                <AppContainer>
                    <Switch>
                        <Route path="/auth/login" component={Login} />
                        <Route
                            path="/auth/forgotten"
                            component={ForgottenPassword}
                        />
                        <Route path="/auth/reset" component={ResetPassword} />
                        <Route
                            path="/auth/invitation/:activation"
                            component={ActivateAccount}
                        />
                        <Route
                            path="/info/uk/terms-and-conditions"
                            component={TermsUk}
                        />
                        <Route
                            path="/info/ie/terms-and-conditions"
                            component={TermsIe}
                        />
                    </Switch>
                </AppContainer>
                <Footer />
            </Container>
        );
    }
}

export default PublicApp;
