import axios from "axios";
import {
    filterValidVideos,
    validateVideoWinners
} from "./videoCompetitionFunctions";
import { videoLoadedCountSelector } from "./videoCompetitionSelectors";

export const SHOW_VIDEO_MODAL = "SHOW_VIDEO_MODAL";
export function showVideoModal(videoId) {
    return dispatch => {
        dispatch({
            type: SHOW_VIDEO_MODAL,
            data: videoId
        });
    };
}

export const HIDE_VIDEO_MODAL = "HIDE_VIDEO_MODAL";
export function hideVideoModal() {
    return dispatch => {
        dispatch({ type: HIDE_VIDEO_MODAL });
    };
}

export const COUNT_LOADED_VIDEOS = "COUNT_LOADED_VIDEOS";
export function countLoadedVideos(count) {
    return dispatch => {
        dispatch({
            type: COUNT_LOADED_VIDEOS,
            data: count
        });
    };
}

export const LOAD_VIDEO_WALL = "LOAD_VIDEO_WALL";
export const LOADED_ALL_VIDEO_WALL = "LOADED_ALL_VIDEO_WALL";
export const LOADING_VIDEO_WALL = "LOADING_VIDEO_WALL";
export const LOAD_VIDEO_WALL_ERROR = "LOAD_VIDEO_WALL_ERROR";
export const LOAD_VIDEO_WALL_WINNERS = "LOAD_VIDEO_WALL_WINNERS";
export const LOADED_VIDEO_WALL_WINNERS = "LOADED_VIDEO_WALL_WINNERS";

export const loadVideoWallWinners = () => {
    return dispatch => {
        dispatch({ type: LOAD_VIDEO_WALL_WINNERS });

        return axios.post("/api/v1/video-wall/winners").then(response => {
            const { data } = response.data;

			   data.reduce((array, item, key) => {
					if (item.winner === null) {
						item.winner = {};
					}

					// if (item.runnerUp === null) {
						// item.runnerUp = {};
					// }
			   },[]);

            dispatch({ type: LOADED_VIDEO_WALL_WINNERS, data });
        });
    };
};

export const loadVideoWall = () => {
    return (dispatch, getState) => {
        // Get number of videos already loaded and skip these
        const skip = videoLoadedCountSelector(getState());

        // Number of videos to retrieve
        const take = 6;
        dispatch({ type: LOADING_VIDEO_WALL });

        return axios
            .post("/api/v1/video-wall", { take, skip })
            .then(response => {
                const { data } = response.data;

                //Filter out videos with no Image or Video
                const validatedVideos = filterValidVideos(data);
                dispatch({ type: LOAD_VIDEO_WALL, data: validatedVideos });

                // No further videos
                if (validatedVideos.length === 0) {
                    dispatch({ type: LOADED_ALL_VIDEO_WALL });
                }

                dispatch(countLoadedVideos(take));
                //
                // if (validateVideos(data)) {
                //     dispatch({ type: LOAD_VIDEO_WALL, data });

                //     // No further videos
                //     if (data.length === 0) {
                //         dispatch({ type: LOADED_ALL_VIDEO_WALL });
                //     }

                //     dispatch(countLoadedVideos(take));
                // } else {
                //     console.error("Returned data was formatted incorrectly");

                //     dispatch({ type: LOAD_VIDEO_WALL_ERROR });
                // }
            });
    };
};
