import moment from "moment";

export const PROGRAM_START_DATE_STRING = "31/03/2019";
export const PROGRAM_START_DATE = moment(
    PROGRAM_START_DATE_STRING,
    "DD/MM/YYYY"
);
export const PROGRAM_START_DATE_IRE_STRING = "01/03/2020";
export const PROGRAM_START_DATE_IRE = moment(
    PROGRAM_START_DATE_IRE_STRING,
    "DD/MM/YYYY"
);
export const EXISTING_STAFF_REWARD_DATE_STRING = "10/12/2019";
export const EXISTING_STAFF_REWARD_DATE = moment(
    EXISTING_STAFF_REWARD_DATE_STRING,
    "DD/MM/YYYY"
);
