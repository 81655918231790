export const formatPosition = score => {
    const j = score % 10;
    const k = score % 100;
    if (j == 1 && k != 11) {
        return score + "st";
    }
    if (j == 2 && k != 12) {
        return score + "nd";
    }
    if (j == 3 && k != 13) {
        return score + "rd";
    }
    return score + "th";
};
