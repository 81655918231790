import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import styled, { css } from "styled-components";
import { Field, ErrorMessage } from "formik";
import InputError from "../styledElements/inputError";
import FormLabel from "../styledElements/label";
import StyledDatePicker from "../styledElements/datePicker/StyledDatePicker";

const DatePickerContainer = styled.div`
    width: 100%;
`;
const FormikDatePicker = ({
    type,
    name,
    label,
    disabled,
    value,
    labelWidth,
    labelAlign,
    dateFormat,
    ...rest
}) => {
    return (
        <DatePickerContainer>
            <Field name={name}>
                {({
                    field: { name, ...field },
                    form: {
                        values,
                        touched,
                        errors,
                        setFieldTouched,
                        setFieldValue
                    }
                }) => {
                    const error = !!touched[name] && errors[name];

                    return (
                        <React.Fragment>
                            {label && (
                                <FormLabel
                                    align={labelAlign}
                                    width={labelWidth}
                                    name={name}
                                    {...rest}
                                >
                                    {label}
                                </FormLabel>
                            )}
                            <StyledDatePicker
                                {...rest}
                                value={value}
                                disabled={disabled}
                                name={name}
                                dateFormat={dateFormat}
                                error={error}
                                onChange={momentDate => {
                                    const value =
                                        momentDate &&
                                        moment.isMoment(momentDate)
                                            ? momentDate.format(dateFormat)
                                            : moment();

                                    setFieldValue(name, value);
                                    setFieldTouched(name, true);
                                }}
                                onBlur={() => {
                                    setFieldTouched(name, true);
                                }}
                            />
                        </React.Fragment>
                    );
                }}
            </Field>
            <ErrorMessage name={name}>
                {msg => <InputError error={msg} />}
            </ErrorMessage>
        </DatePickerContainer>
    );
};

FormikDatePicker.propTypes = {
    name: PropTypes.string.isRequired,
    size: PropTypes.string,
    expanded: PropTypes.bool,
    inlineLabel: PropTypes.bool,
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    dateFormat: PropTypes.string,
    selected: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
FormikDatePicker.defaultProps = {
    expanded: true,
    dateFormat: "DD/MM/YYYY",
    selected: moment()
};
export default FormikDatePicker;
