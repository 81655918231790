import { REDEMPTIONS_LOADING, LOAD_REDEMPTIONS } from "./redemptionActions";

export const initialState = {
    loading: false,
    loaded: false,
    data: []
};

export default function staff(state = initialState, action) {
    switch (action.type) {
        case REDEMPTIONS_LOADING:
            return {
                ...state,
                loading: action.data
            };

        case LOAD_REDEMPTIONS:
            return {
                ...state,
                loaded: true,
                data: action.data,
                loading: false
            };

        default:
            return state;
    }
}
