import React from "react";
import PropTypes from "prop-types";
import styled, { css, withTheme } from "styled-components";
import { foregroundColor } from "../functions";
import { darken, luminanace, hexToRgb } from "../functions";
// import { RubberBand } from "animate-css-styled-components";

const background = (props) =>
    props.theme.colors[props.type] ||
    props.theme.colors[props.theme.button.default];

const color = (props) =>
    foregroundColor(
        background(props),
        0.75,
        props.theme.lightColor,
        props.theme.darkColor
    );

const isHollow = (props) => Boolean(props.hollow);

const isExpanded = (props) => Boolean(props.expanded);

const outline = (props) => `inset 0 0 0 ${props.theme.button.borderWidth}px
${background(props)}`;

let Button = styled(({ element, children, hollow, expanded, ...props }) => {
    // console.log(props);
    return React.createElement(element, props, children);
}).attrs({ role: "button" })`
    border: none;
    text-align: center;
    font-family: inherit;
    height: ${(props) => props.height}px;
    line-height: ${(props) => props.height + 3}px;
    font-size: ${(props) => props.theme.button.fontSize}px;
    font-weight: ${(props) => props.theme.button.fontWeight};
    text-transform: ${(props) => props.theme.button.textTransform};
    border-radius: ${(props) => props.theme.button.borderRadius}px;
    transition: background-color 0.25s ease;
    box-shadow: ${(props) => outline(props)};
    padding: 0 ${(props) => props.theme.spacing.padding}em;
    display: inline-block;
    text-decoration: none;
    background-color: ${(props) =>
        !isHollow(props) ? background(props) : "transparent"};
    color: ${(props) => (isHollow(props) ? background(props) : color(props))};

    //  disabled button styles
    ${(props) =>
        props.disabled
            ? css`
                  opacity: 0.5;
                  cursor: not-allowed;
              `
            : css`
                  cursor: pointer;
              `};

    // active style
    ${(props) =>
        !isHollow(props) &&
        !props.disabled &&
        css`
            &:hover {
                background-color: ${(props) => darken(background(props), 0.8)};
                box-shadow: none;
            }
        `};

    // expanded
    ${(props) =>
        isExpanded(props) &&
        css`
            width: 100%;
        `};

    // focus
    &:focus {
        outline: none;
        box-shadow: ${(props) => outline(props)},
            0px 0px 0px 3px
                rgba(
                    ${(props) => {
                        const rgb = hexToRgb(background(props));
                        return `${rgb.r}, ${rgb.g}, ${rgb.b}, 0.2`;
                    }}
                );
    }
`;

Button = withTheme(Button);

Button.displayName = "Button";

Button.propTypes = {
    /** Optional: you can choose an element for the button. Defaults to button.*/
    element: PropTypes.oneOfType([PropTypes.string, PropTypes.func]),
    expanded: PropTypes.bool,
    disabled: PropTypes.bool,
    hollow: PropTypes.bool,
    margin: PropTypes.number,
    height: PropTypes.number,
};

Button.defaultProps = {
    element: "button",
    expanded: false,
    disabled: false,
    hollow: false,
    height: 36,
    type: "default",
};

export default Button;
