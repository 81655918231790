import React from "react";
import { theme } from "@cortexglobal/bmw-shared-components";

import { slide as Menu } from "react-burger-menu";

const getStyle = spacing => ({
    bmBurgerButton: {
        position: "absolute",
        width: "20px",
        height: "17px"
    },
    bmBurgerBars: {
        background: theme.colors.black
    },
    bmBurgerBarsHover: {
        background: "#a90000"
    },
    bmCrossButton: {
        height: "24px",
        width: "24px"
    },
    bmCross: {
        background: "#bdc3c7"
    },
    bmMenuWrap: {
        position: "fixed",
        height: "100%",
        left: 0,
        top: 0
    },
    bmMenu: {
        background: theme.colors.white,
        padding: "2.5em 0 0 0",
        fontSize: "1.15em"
    },
    bmMorphShape: {
        fill: "#373a47"
    },
    bmItemList: {
        color: "#b8b7ad",
        padding: "0.8em"
    },
    bmItem: {
        display: "inline-block"
    },
    bmOverlay: {
        background: "rgba(0, 0, 0, 0.3)",
        top: 0
    }
});

export default ({ children, style, ...props }) => {
    const styles = getStyle(
        props.theme,
        props.spacing ? props.spacing : "1rem"
    );
    return (
        <div style={{ ...style }}>
            <Menu styles={styles} {...props}>
                {children}
            </Menu>
        </div>
    );
};
