import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { FormikSelect, SelectField } from "@cortexglobal/rla-components";

import { getStaffRoles } from "./roleActions";

class RoleSelect extends Component {
    componentDidMount() {
        if (!this.props.loaded) {
            //Request appropriate roles based on user's team
            let roleTypes = "technician,apprentice,service-advisor";
            if (this.props.team === "sales") {
                roleTypes = "sales-executive";
            }

            this.props.getStaffRoles(roleTypes);
        }
    }
    render() {
        const { label, value, roles, loading, formikSelect, onChange, error } =
            this.props;
        //console.log(value);
        if (loading) {
            return null;
        }
        if (formikSelect) {
            return (
                <FormikSelect
                    name="role_id"
                    label={label}
                    value={value}
                    options={roles}
                />
            );
        }

        return (
            <SelectField
                name="role_id"
                options={roles}
                value={value}
                error={error}
                onChange={onChange}
            />
        );
    }
}

const mapStateToProps = (state) => {
    const { loaded, data: roles } = state.roles;
    const { team } = state.auth?.user?.role;
    return { roles, loaded, team };
};

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            getStaffRoles,
        },
        dispatch
    );

RoleSelect.propTypes = {
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    formikSelect: PropTypes.bool,
    onChange: PropTypes.func,
};
RoleSelect.defaultProps = {
    label: "Job Role",
    formikSelect: true,
};
export default connect(mapStateToProps, mapDispatchToProps)(RoleSelect);
