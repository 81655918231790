import PropTypes from "prop-types";
import styled, { withTheme } from "styled-components";
import { boolean, text, select, number } from "@storybook/addon-knobs";
import Dropzone from "react-dropzone";

const dropzone = props => props.theme.dropzone.types[props.type];

const StyledDropzone = styled(Dropzone)`
    background: ${props => dropzone(props).background};
    margin-bottom: ${props => props.theme.dropzone.margin}em;
    padding: ${props => props.theme.dropzone.padding}em;
    border: ${props => props.theme.dropzone.border};
    color: ${props => dropzone(props).color};
`;

StyledDropzone.displayName = "StyledDropzone";

StyledDropzone.propTypes = {
    type: PropTypes.string
};

StyledDropzone.defaultProps = {
    type: "default"
};

export default withTheme(StyledDropzone);
