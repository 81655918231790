import {
    RETAILER_INFORMATION_LOADING,
    LOAD_RETAILER_INFORMATION,
    LOAD_RETAILER_SUMMARIES,
    LOAD_RETAILERS_MONTHLY_SUMMARY,
    LOAD_RETAILERS_ENGAGEMENT_SUMMARY
} from "./retailerActions";

export const initialState = {
    loading: false,
    information: {
        loaded: false,
        data: {}
    },
    summaries: {
        loaded: false,
        data: []
    },
    monthly: {
        loaded: false,
        data: []
    },
    engagement: {
        loaded: false,
        data: {}
    }
};

export default function retailer(state = initialState, action) {
    switch (action.type) {
        case RETAILER_INFORMATION_LOADING:
            return {
                ...state,
                loading: action.data
            };

        case LOAD_RETAILER_INFORMATION:
            return {
                ...state,
                loading: false,
                information: {
                    ...state.information,
                    loaded: true,
                    data: action.data
                }
            };

        case LOAD_RETAILER_SUMMARIES:
            return {
                ...state,
                loading: false,
                summaries: {
                    ...state.summaries,
                    loaded: true,
                    data: action.data
                }
            };

        case LOAD_RETAILERS_MONTHLY_SUMMARY:
            return {
                ...state,
                loading: false,
                monthly: {
                    ...state.monthly,
                    loaded: true,
                    data: action.data
                }
            };

        case LOAD_RETAILERS_ENGAGEMENT_SUMMARY:
            return {
                ...state,
                loading: false,
                engagement: {
                    ...state.engagement,
                    loaded: true,
                    data: action.data
                }
            };

        default:
            return state;
    }
}
