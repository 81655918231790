import React, { Component } from "react";
import styled, { css } from "styled-components";
import Row from "./row";
import Column from "./column";
import Panel from "./panel";

const Title = styled.h1`
    margin-right: auto;
`;

const Flex = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
`;

const PageTitle = ({ title, children }) => (
    <Panel>
        <Row>
            <Column>
                <Flex>
                    <Title>{title}</Title>
                    {children}
                </Flex>
            </Column>
        </Row>
    </Panel>
);

export default PageTitle;
