import {
    SURVEY_INFORMATION_LOADING,
    LOAD_EXIT_SURVEY_SUMMARY
} from "./surveyActions";

export const initialState = {
    loading: false,
    exitSurvey: {
        loaded: false,
        data: {}
    }
};

export default function survey(state = initialState, action) {
    switch (action.type) {
        case SURVEY_INFORMATION_LOADING:
            return {
                ...state,
                loading: action.data
            };

        case LOAD_EXIT_SURVEY_SUMMARY:
            return {
                ...state,
                loading: false,
                exitSurvey: {
                    ...state.exitSurvey,
                    loaded: true,
                    data: action.data
                }
            };

        default:
            return state;
    }
}
