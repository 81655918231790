import React, { Component } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import styled from "styled-components";
import { Row, Column } from "@cortexglobal/rla-components";
import logos from "../../img/logos.png";
import bmwGroupLogo from "../../img/bmwgroup-logo.png";

const Header = styled.div`
    padding: 0.7rem 0 0.2rem 0;
    background: ${(props) => props.theme.header.background};
    box-shadow: 0px 7px 6px 0px rgba(0, 0, 0, 0.1);
`;
const Logo = styled(Link)`
    max-width: 180px;
    max-height: 40px;
    display: block;
    font-weight: 900;
    font-family: "bmw_groupbold", "bmw_groupregular", "Arial", sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    img {
        height: 32px;
    }
`;
class PublicHeader extends Component {
    render() {
        return (
            <Header>
                <Row>
                    <Column large={10} medium={9} small={10}>
                        <Logo to="/">
                            <img src={bmwGroupLogo} alt="BMW Group Logo" />
                        </Logo>
                    </Column>
                    <Column
                        large={2}
                        medium={3}
                        small={2}
                        style={{ textAlign: "right" }}
                    >
                        <img
                            src={logos}
                            style={{ width: "90px" }}
                            alt="BMW and Mini Logos"
                        />
                    </Column>
                </Row>
            </Header>
        );
    }
}

const mapStateToProps = (state) => ({ loggedIn: state.auth.loggedIn });

const mapDispatchToProps = (dispatch) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(PublicHeader);
