export const isJSON = str => {
    if (typeof str === "undefined" || str === "") {
        return false;
    }
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
};

export const UCFirstLetter = string => {
    const stringCopy = (" " + string).slice(1);
    return stringCopy.charAt(0).toUpperCase() + stringCopy.slice(1);
};

export const removeObjectLevelByKey = (item, keyToRemove = "data") => {
    if (typeof item !== "object" || item === null) return item;
    let newObjectItem = Array.isArray(item[keyToRemove]) ? [] : {};
    for (let key in item) {
        if (key !== keyToRemove)
            newObjectItem[key] = removeObjectLevelByKey(item[key], keyToRemove);
    }

    if (item[keyToRemove]) {
        for (let key in item[keyToRemove]) {
            if (key !== keyToRemove) {
                newObjectItem[key] = removeObjectLevelByKey(
                    item[keyToRemove][key],
                    keyToRemove
                );
            }
        }
    }
    return newObjectItem;
};

export function findItem(state, key, uuid) {
    return findObjectInArray(findInObject(state)(key))(uuid);
}

function findInObject(object) {
    return key => {
        return object[key];
    };
}

function findObjectInArray(array) {
    return key => {
        return array.filter(item => {
            if (item.uuid === key) {
                return item;
            }
            return false;
        })[0];
    };
}

export function isValidEmail(email) {
    // var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    // above causing warning but might be correct
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
}

export function buildCollection(formCollectionData) {
    var formCollection = {};
    for (var key in formCollectionData) {
        if (
            formCollectionData.hasOwnProperty(key) &&
            typeof formCollectionData[key] === "object"
        ) {
            formCollection[key] = JSON.stringify(
                formCollectionData[key].values
            );
        }
    }
    return formCollection;
}

export const parseValidationErrors = e => {
    return new Promise((resolve, reject) => {
        if (!e.response || !e.response.status || e.response.status !== 422) {
            return reject(e);
        }

        const {
            response: {
                data: { errors: messages }
            }
        } = e;

        const errors = Object.keys(messages || []).reduce((carry, key) => {
            if (messages[key][0]) {
                carry[key] = messages[key][0];
            }

            return carry;
        }, {});

        resolve({ errors });
    });
};

export const calculatePercentage = (loaded, total) =>
    Math.floor(loaded * 1.0) / total;

export const getNavigatorLanguage = () => {
    // Different browsers have the user locale defined
    // on different fields on the `navigator` object, so we make sure to account
    // for these different by checking all of them
    return (
        (navigator.languages && navigator.languages[0]) ||
        navigator.language ||
        navigator.userLanguage
    );
};
