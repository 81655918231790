import React, { Component } from "react";
import styled from "styled-components";
import AppContext from "../../context/AppContext";

import { Row } from "@cortexglobal/rla-components";
import DetailRow from "../DetailRow";

import {
    BodyRow,
    BodyColumn,
    BodyLabel,
    RowWrapper,
    HeaderColumn,
    HeaderRow,
    StyledUpdateStatusButton,
    StyledUpdateProbationButton,
    StyledProcessRewardButton
} from "../table/index";

import ShowHideButton from "../table/ShowHideButton";

const ActionsRow = styled(Row)`
    width: 100%;
    padding: 0;
`;
class ActionsRequiredTable extends Component {
    state = {
        showing: ""
    };
    renderActions = (actions_required, member) => {
        const { actionType, action, details: detail } = actions_required;
        //console.log(actionType, action);
        switch (actionType) {
            case "rewards":
                //console.log(action);

                //return action.map(detail => {
                // console.log(detail);
                return (
                    <ActionsRow key={member.uuid + detail.uuid}>
                        <BodyColumn medium={5}>
                            <p>{detail.reward.name}</p>
                        </BodyColumn>
                        <BodyColumn medium={7}>
                            <StyledProcessRewardButton
                                choice="approve"
                                userUuid={member.uuid}
                                rewardDetail={detail}
                                member={member}
                            >
                                Approve
                            </StyledProcessRewardButton>
                            <StyledProcessRewardButton
                                choice="reject"
                                userUuid={member.uuid}
                                rewardDetail={detail}
                                member={member}
                                type="alert"
                            >
                                Reject
                            </StyledProcessRewardButton>
                        </BodyColumn>
                    </ActionsRow>
                );
            //});
            case "account":
                if (action === "ProbationRequiresApproval") {
                    return (
                        <ActionsRow key={member.uuid}>
                            <BodyColumn medium={5}>
                                Probation Requires Approval
                            </BodyColumn>
                            <BodyColumn medium={7}>
                                <StyledUpdateProbationButton
                                    newStatus="approve"
                                    member={member}
                                >
                                    Approve
                                </StyledUpdateProbationButton>
                                <StyledUpdateProbationButton
                                    newStatus="extend"
                                    member={member}
                                    type="alert"
                                >
                                    Extend
                                </StyledUpdateProbationButton>
                            </BodyColumn>
                        </ActionsRow>
                    );
                }
                if (action === "RequiresApproval") {
                    return (
                        <ActionsRow key={member.uuid}>
                            <BodyColumn medium={5}>
                                Requires Approval
                            </BodyColumn>
                            <BodyColumn medium={7}>
                                <StyledUpdateStatusButton
                                    newStatus="active"
                                    user={member}
                                >
                                    Approve
                                </StyledUpdateStatusButton>
                                <StyledUpdateStatusButton
                                    newStatus="approval-rejected"
                                    user={member}
                                    type="alert"
                                >
                                    Reject
                                </StyledUpdateStatusButton>
                            </BodyColumn>
                        </ActionsRow>
                    );
                }
                if (action === "RequiresReactivation") {
                    return (
                        <ActionsRow key={member.uuid}>
                            <BodyColumn medium={5}>
                                Invite has Expired
                            </BodyColumn>
                            <BodyColumn medium={7}>
                                <StyledUpdateStatusButton
                                    newStatus="added"
                                    user={member}
                                >
                                    Resend
                                </StyledUpdateStatusButton>
                                <StyledUpdateStatusButton
                                    newStatus="activation-cancelled"
                                    user={member}
                                    type="alert"
                                >
                                    Cancel
                                </StyledUpdateStatusButton>
                            </BodyColumn>
                        </ActionsRow>
                    );
                }
                return null;
            default:
                return null;
        }
    };

    render() {
        return (
            <AppContext.Consumer>
                {({ staff, loading, user, group }) => {
                    return (
                        <React.Fragment>
                            <HeaderRow>
                                <HeaderColumn large={3}>Name</HeaderColumn>
                                <HeaderColumn large={9}>Details</HeaderColumn>
                            </HeaderRow>
                            {this.props.staff.map(member => {
                                const showingDetail =
                                    this.state.showing === member.uuid;
                                return (
                                    <RowWrapper key={member.uuid}>
                                        <BodyRow showingDetail>
                                            <BodyColumn large={3}>
                                                <BodyLabel>Name:</BodyLabel>
                                                {member.first_name}{" "}
                                                {member.last_name}
                                                <br />
                                                {member.role &&
                                                    member.role.name}
                                            </BodyColumn>

                                            <BodyColumn large={7}>
                                                {member.actions_required.map(
                                                    actions_required => {
                                                        return this.renderActions(
                                                            actions_required,
                                                            member
                                                        );
                                                    }
                                                )}
                                            </BodyColumn>
                                            <BodyColumn large={2}>
                                                <ShowHideButton
                                                    showDetails={() => {
                                                        const showing = showingDetail
                                                            ? ""
                                                            : member.uuid;
                                                        this.setState(
                                                            state => ({
                                                                showing
                                                            })
                                                        );
                                                    }}
                                                    showingDetail={
                                                        showingDetail
                                                    }
                                                />
                                            </BodyColumn>
                                        </BodyRow>

                                        <DetailRow
                                            member={member}
                                            group={group}
                                            shown={showingDetail}
                                        />
                                    </RowWrapper>
                                );
                            })}
                        </React.Fragment>
                    );
                }}
            </AppContext.Consumer>
        );
    }
}

export default ActionsRequiredTable;
